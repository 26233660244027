import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const CreatePerformanceTarget = ({ isOpen, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [performanceTarget, setPerformanceTarget] = useState({
    financialYear: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [
      {
        productId: "",
        productname: "",
        productcode: "",
        target: "",
        weight: 1, // Default weight to 1 to avoid division by zero
        annualQuantity: 0,
        monthlyQuantity: 0,
      },
    ],
  });
  const url = "/api/performance/create";

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setPerformanceTarget({
      financialYear: "",
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      products: [
        {
          productId: "",
          productname: "",
          productcode: "",
          target: "",
          weight: 1,
          annualQuantity: 0,
          monthlyQuantity: 0,
        },
      ],
    });
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoading(true);
    try {
      const trans = await axios.get(
        `/api/product/orgcode=${orgcode}/productstatus=true/productTypeId=${1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProduct(trans.data);
      setPerformanceTarget({
        ...performanceTarget,
        products: trans.data.map((item) => ({
          productId: item.id,
          productname: item.productname,
          productcode: item.productcode,
          target: 0,
          weight: item.netWeight || 1, // Use item.weight or default to 1
          annualQuantity: 0,
          monthlyQuantity: 0,
        })),
      });
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoading(false);
  };

  const rsubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(url, performanceTarget, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Performance Target Added successfully");
        onRequestClose();
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleDateChange = (startDate) => {
    const startYear = startDate.getFullYear();
    const endYear = startYear + 1;

    setPerformanceTarget({
      ...performanceTarget,
      financialYear: `April ${startYear} - March ${endYear}`,
    });
  };

  const handleTargetChange = (value, index) => {
    const newTargets = [...performanceTarget.products];
    const targetValue = parseFloat(value) || 0;
    const weight = newTargets[index].weight;

    newTargets[index].target = targetValue;
    newTargets[index].annualQuantity = Math.round((targetValue * 1000) / weight);
    newTargets[index].monthlyQuantity = Math.round(newTargets[index].annualQuantity / 12);

    setPerformanceTarget({
      ...performanceTarget,
      products: newTargets,
    });
  };

  const handleNumericInput = (e, index) => {
    // Only allow numeric values and handle edge cases
    const value = e.target.value.replace(/[^0-9.]/g, '');
    handleTargetChange(value, index);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Performance</Modal.Title>
        {loading && <div className="loading"></div>}
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={rsubmit}>
          <div className="mb-3">
            Financial Year :
            <DatePicker
              selected={performanceTarget.financialYear ? new Date(`${performanceTarget.financialYear.split(' ')[1]}-04-01`) : null}
              onChange={handleDateChange}
              dateFormat="MMMM yyyy"
              placeholderText="Select Financial Year"
              showMonthYearPicker
              minDate={new Date(`${new Date().getFullYear()}-04-01`)}
              maxDate={new Date(`${new Date().getFullYear() + 1}-03-31`)}
            />
            {performanceTarget.financialYear && (
              <p>Selected Financial Year: {performanceTarget.financialYear}</p>
            )}
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Code</th>
                <th>Target</th>
                <th>Annual Quantity</th>
                <th>Monthly Quantity</th>
              </tr>
            </thead>
            <tbody>
              {performanceTarget.products.map((product, index) => (
                <tr key={product.productId}>
                  <td>{product.productname}</td>
                  <td>{product.productcode}</td>
                  <td>
                    <input
                      type="text"
                      placeholder="0"
                      style={{ border: "none" }}
                      value={product.target}
                      onChange={(e) => handleNumericInput(e, index)}
                    />
                  </td>
                  <td>{product.annualQuantity}</td>
                  <td>{product.monthlyQuantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button variant="primary" type="submit">
            Save
          </Button>
          &nbsp;
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </form>
        {loading && <div className="loading"></div>}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CreatePerformanceTarget;
