import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import ProducteditModalPopup from "./productedit";
import Modalpopup from "./Modalpopup";
import AddWarehouse from "./AddWarehouse";  // Rename this if it is also related to product category
import { MdDeleteForever } from "react-icons/md";
import WarehouseeditModalPopup from "./Warehouseedit";  // Rename this if it is related to product category
import Addproductcategory from "./Addproductcategory";
import ProductcategoryeditModalPopup from "./updateproductcategory";
function Productcategory() {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);
  const [productCategories, setProductCategories] = useState([]); // Renamed for product category
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      const categoryResponse = await axios.get(`/api/productcategories/orgcode=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductCategories([...categoryResponse.data]);  // Updated to set product categories
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setProductCategories([]); // Updated
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setProductCategories([]); // Updated
  };

  const handleCategoryEdit = (categoryId) => {
    setSelectedCategoryId(categoryId);  // Updated
    setIsCategoryEditModalOpen(true);  // Updated
  };

  const closeCategoryEditModal = () => {
    setIsCategoryEditModalOpen(false);  // Updated
    setSelectedCategoryId(null);  // Updated
    getData();
  };

  const onClickHandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
        url: "/api/productcategory",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      getData();
      setLoading(false); 
    } catch (error) {
      setLoading(false); 
    }
  };

  const filteredCategories = productCategories.filter((category) =>
    (category.categoryname?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
    (category.categoryType?.categorytype?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  function onClickhandler(e, id, disabled) {
    setLoading(id);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios.post(`/api/productcategories/update`, {
      id: id,
      disable: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
   
      .then((response) => {
        setLoading(null);
        toast.success(" status updated");
        getData();
      })
      .catch((error)=>{
        handleError(error);
      });
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Product Category
              <input
                type="text"
                className="form-control"
                style={{
                  width: '200px',
                  display: 'inline-block',
                  paddingLeft: '10px',
                  marginLeft: '20px',
                }}
                placeholder="Search Product Category"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button>
            </div>

            <br />
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                   <th> Category Name</th>
                    <th>Category Type</th>
                    <th>Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCategories.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredCategories.map((item) => {
                      return (
                        <tr key={item.id}>
                              <td>{item.categoryname}</td> 
                          <td>{item.categoryType.categorytype}</td> 
                        
                          <td>
                        <div className="form-check form-switch">
                          {loading == item.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!item.disable}
                            onClick={(e) => onClickhandler(e, item.id, !item.disable)}
                            value={item.id}
                          />
                        </div>
                      </td>
                          <td>
                            <button
                              onClick={() => handleCategoryEdit(item.id)}  // Updated
                              style={{
                                border: "none",
                                background: "white",
                                size: "150px",
                              }}
                            >
                              <BiEditAlt />
                            </button>
                            {/* <button
                              style={{
                                border: "none",
                                background: "white",
                                color: "grey",
                                position: "relative",
                                left: "10px",
                                fontSize: "24px",
                              }}
                              onClick={(e) => {
                                onClickHandler(e, item.id);  // Updated
                              }}
                            >
                              <MdDeleteForever />
                            </button>{" "} */}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div>
              {isCategoryEditModalOpen && (
                <ProductcategoryeditModalPopup
                  isOpen={isCategoryEditModalOpen}  
                  onRequestClose={closeCategoryEditModal}  
                  categoryId={selectedCategoryId}  
                />
              )}

              {isCreateModalOpen && (
                <Addproductcategory  // Rename this modal to handle product category if needed
                  isOpen={isCreateModalOpen}
                  onRequestClose={handleCreateModalClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productcategory;
