import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import CreatePerformanceTarget from "./addPerformanceTarget";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";

function PerformanceTarget() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null); 
  const [selectedYear, setSelectedYear] = useState(null); 
  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

  const getData = async () => {
    setLoading(true);

    try {
      const performanceTargetResponse = await axios.get(`/api/performance/orgcode=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let filteredData = [...performanceTargetResponse.data];
      
      if (selectedMonth !== null && selectedYear !== null) {
        filteredData = filteredData.filter(item =>
          new Date(item.date).getMonth() === selectedMonth &&
          new Date(item.date).getFullYear() === selectedYear
        );
      } else if (selectedMonth !== null) {
        filteredData = filteredData.filter(item => new Date(item.date).getMonth() === selectedMonth);
      } else if (selectedYear !== null) {
        filteredData = filteredData.filter(item => new Date(item.date).getFullYear() === selectedYear);
      }
      setProduct(filteredData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, [selectedDate, selectedMonth, selectedYear]);

  const handleViewDetails = async (date) => {
    setSelectedDate(date);
    if (date !== null) {
        const entity = {
            orgcode: orgCode,
            financialYear: date,
        };
        setLoading(true);
        try {
          const response = await axios.post(
              '/api/performance/getby-financialyear', entity, {
                headers: { Authorization: `Bearer ${token}` },
            });
          const updatedData = response.data.map(item => ({
            ...item,
            annualQuantity: Math.round((item.target * 1000) / (item.productDetails.netWeight || 1)),
            monthlyQuantity: Math.round((Math.round((item.target * 1000) / (item.productDetails.netWeight || 1)) / 12))
          }));
          setModalData(updatedData);
          setShowModal(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    getData();
    setModalData([]);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);
  };

  const handleAddCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" }); 
    return `${year} ${month}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      await axios.post(`/api/performance/update`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Target Updated Successfully");
      handleCloseModal(); 
    } catch (error) {
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const targetValue = parseFloat(value) || 0;
    setModalData(prevData => {
      const newData = [...prevData];
      const weight = newData[index].productDetails.netWeight || 1;
      newData[index] = {
        ...newData[index],
        target: targetValue,
        annualQuantity: Math.round((targetValue * 1000) / weight),
        monthlyQuantity: Math.round(Math.round((targetValue * 1000) / weight) / 12)
      };
      return newData;
    });
  };

  return (
    <div>
      <AdminDashboard />
      <div className="col-10 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            {loading && <div className="loading"></div>}
            <div className="card-title form-group d-flex align-items-center justify-content-between">Performance Details
           <button className="btn btn-primary" onClick={handleAddProduct}>
             ADD
           </button> 
         </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Financial Year</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {product.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  product.map((currentProduct, index) => {
                    const isFirstRow = index === 0;
                    const prevDate = isFirstRow
                      ? null
                      : product[index - 1].date;
                    const currentDate = currentProduct.date;
                    const showDate = isFirstRow || prevDate !== currentDate;

                    return (
                      <tr key={currentProduct.id}>
                        {showDate && (
                          <>
                            <td>{currentProduct.financialYear}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  handleViewDetails(currentProduct.financialYear)
                                }
                              >
                                View
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Performance Target Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {loading && <div className="loading"></div>}
              <div>
                <form onSubmit={handleSubmit}>
                  <div>Financial Year: {selectedDate}</div>
                  <div>Orgcode: {orgCode}</div> 
                  <br/>
                  {modalData.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Product Code</th>
                          <th>Product Name</th>
                          <th>Target</th>
                          <th>Annual Quantity</th>
                          <th>Monthly Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.map((data, index) => (
                          <tr key={data.id}>
                            <td>{data.productDetails.productcode}</td>
                            <td>{data.productDetails.productname}</td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={data.target}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </td>
                            <td>{data.annualQuantity}</td>
                            <td>{data.monthlyQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No data available for the selected date</p>
                  )}
                  <br />
                  <div className="d-flex justify-content-end">
                    <Button type="submit" className="btn btn-primary">
                      Save Changes
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal} className="ms-2">
                      Close
                    </Button>
                  </div>
                </form>
              </div>
          </Modal.Body>
        </Modal>
      )}

      {isModalOpen && (
        <CreatePerformanceTarget isOpen={isModalOpen} onRequestClose={handleAddCloseModal} />
      )}
    </div>
  );
}

export default PerformanceTarget;
