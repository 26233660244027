import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "./search";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import Financialmanagment from "./financialManagment";
import { error } from "jquery";
import { Pagination } from "react-bootstrap";
function FleetFinancials() {
  const [uproductcost, setUproductcost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);

  const date = new Date();
  const token = JSON.parse(localStorage.getItem("data")).token;


  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const searchName = async (text, pageNumber = 0) => {
    setSearchstate(!searchstate);
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      const res = await axios.get(
        `/api/fleetfinancial/getfleetfinancials/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          params: { page: pageNumber },
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      console.log(res);
      
      setUproductcost(res.data.content);  
      setCurrentPage(res.data.number);    
      setTotalPages(res.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  

  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/fleetfinancial/getfleetfinancials/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`, {
        params: { page: pageNumber }, 
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproductcost(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  
  const getVehicleAndProduct = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const pro = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setProduct(pro.data);
  
    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  }catch(error){
    handleError(error);
  }
  };

  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');

  // Filter function
  const filteredUproductcost = uproductcost.filter((cost) => {
    const vehicleMatches = selectedVehicle ? cost.vehicle === selectedVehicle : true;
    const productMatches = selectedProduct ? cost.productname === selectedProduct : true;
    return vehicleMatches && productMatches;
  });


  useEffect(() => {
    getVehicleAndProduct();
    getData();
  }, []);

  const handlefinancialmanagment = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
 
  const navigate = useNavigate();
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }

      const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;
        let startPage = Math.max(0, currentPage - 2);
        let endPage = Math.min(totalPages - 1, currentPage + 2);
    
        if (currentPage <= 2) {
          endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
        }
    
        if (currentPage >= totalPages - 3) {
          startPage = Math.max(0, totalPages - maxPageNumbersToShow);
        }
    
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i + 1}
            </Pagination.Item>
          );
        }
        return pageNumbers;
      };

      const handlePageChange = (pageNumber) => {
        getData(pageNumber);
      };
    

  return (
   
      <div id="layoutDrawer_content">
      {loading && <div className="loading"></div>} 

      <main>
      <div className="col-12 mx-xl-auto ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="card-title">Product Cost Details</h5>
        <div>
          <Search searchName={searchName} />
        </div>
        <button className="btn btn-primary" onClick={handlefinancialmanagment}>
          ADD
        </button>
              
            </div>   
            <div className="filter-dropdowns mt-4">
<div className="row">
<div
        className="col-3">
  <div controlId="vehicleFilter">
    <label>FILTER BY VEHICLE ID : </label>
    
    <select
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
            >
              <option value="">All</option>
              {vehicle.map((v) => (
                <option key={v.id} value={v.truckinfo}>
                  {v.truckinfo}
                </option>
              ))}
            </select>
    </div>

  </div>

  <div className="col-4 ">
  <div controlId="productFilter">
  <label>FILTER BY PRODUCT NAME :</label>
            <select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">All</option>
              {product.map((p) => (
                <option key={p.id} value={p.productname}>
                  {p.productname}
                </option>
              ))}
            </select>
   
  </div>
  </div>
  </div>
</div>
                  <br />
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                          textAlign:"center"
                        }}
                      >
                        <th>Date</th>
                        <th>Vehicle Id</th>
                        <th>Product Code</th>

                        <th>Product Name</th>
                        <th>Sales Cost</th>
                        <th>New Conection Cost</th>

                        <th>Additional Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                    {searchstate &&
                        filteredUproductcost.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchstate &&
                        filteredUproductcost.map((cost) => {
                          return (
                            <tr key={cost.id}>
                              <td>{cost.date}</td>
                              <td>{cost.vehicle}</td>
                              <td>{cost.productcode}</td>

                              <td>{cost.productname}</td>

                              <td>{cost.salecost}</td>

                              <td>{cost.newconection}</td>
                              <td>{cost.additionalcost}</td>
                            </tr>
                           );
                          }))}
                     {!searchstate &&
                        filteredUproductcost.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchstate &&
                        filteredUproductcost.map((cost) => {
                          return (
                            <tr key={cost.id}>
                              <td>{cost.date}</td>
                              <td>{cost.vehicle}</td>
                              <td>{cost.productcode}</td>

                              <td>{cost.productname}</td>

                              <td>{cost.salecost}</td>

                              <td>{cost.newconection}</td>
                              <td>{cost.additionalcost}</td>
                            </tr>
                          );
                        }))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
                  {isModalOpen && (
                  <Financialmanagment isOpen={isModalOpen} onRequestClose={handleCloseModal}/>
                  )}

                </div>
             
                </div>    
            </div>
      </main>
    </div>
  
  );
}

export default FleetFinancials;
