import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import ProducteditModalPopup from "./productedit";
import Modalpopup from "./Modalpopup";
import AddWarehouse from "./AddWarehouse";
import { MdDeleteForever } from "react-icons/md";
import WarehouseeditModalPopup from "./Warehouseedit";
function WareHouse() {
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [isWarehouseEditModalOpen, setIsWarehouseEditModalOpen] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      const warehouseResponse = await axios.get(`/api/warehouse/orgcode=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWarehouse([...warehouseResponse.data]);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setWarehouse([]);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setWarehouse([]);
  };

  const handleWarehouseEdit = (warehouseId) => {
    setSelectedWarehouseId(warehouseId);
    setIsWarehouseEditModalOpen(true);
  };

  const closeWarehouseEditModal = () => {
    setIsWarehouseEditModalOpen(false);
    setSelectedWarehouseId(null);
    getData();

  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/warehouse",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    getData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
const filteredData = warehouse.filter((warehouse) =>
  warehouse.warehousename.toLowerCase().includes(searchTerm.toLowerCase())
);
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Ware House
              <input
  type="text"
  className="form-control"
  style={{
    width: '200px',  
    display: 'inline-block', 
    paddingLeft: '10px', 
    marginLeft: '20px',
  }}  placeholder="Search Ware House"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
/>
              <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button>
            </div>

            <br />
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th> Ware House Name</th>
                    <th>Address</th>
                    <th>GPS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.warehousename}</td>
                          <td>{item.address}</td>
                          <td>
                              Latitude: {item.latitude}<br />
                              Longitude: {item.longitude}
                          </td>
                          <td>
                            <button
                              onClick={() => handleWarehouseEdit(item.id)}
                              style={{
                                border: "none",
                                background: "white",
                                size: "150px",
                              }}
                            >
                              <BiEditAlt />
                            </button>
                            <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "10px",
                            fontSize: "24px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              item.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div>
              {isWarehouseEditModalOpen && (
                <WarehouseeditModalPopup
                  isOpen={isWarehouseEditModalOpen}
                  onRequestClose={closeWarehouseEditModal}
                  warehouseId={selectedWarehouseId}
                />
              )}

              {isCreateModalOpen && (
                <AddWarehouse
                  isOpen={isCreateModalOpen}
                  onRequestClose={handleCreateModalClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WareHouse;
