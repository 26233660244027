import React, { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import "./Toggle.css";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function UpdateTransactionOutgoing(props) {
  const [time, setTime] = useState(new Date());
  var curr_hour = time.getHours();
  var curr_min = time.getMinutes();

  var curr_sec = time.getSeconds();

  const text = curr_hour + ":" + curr_min + ":" + curr_sec;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const searchDate = props.params.searchDate;
  const navigate=useNavigate();
  const [selectedVehicle, setSelectedVehicle] = useState(""); 

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    status: "full",
    vehicle: "",
    approvalstatus: "",
    approvaldate: "",
    date: searchDate,
    time: text,
   
    products: [
      {
        productcode: "",
        productname: "",
        quantity: "",
        sealintact: "Yes",
        noofsealintact: 0,
        correctweight: "Yes",
        noofcorrectweight: 0,
        bungleak: "No",
        noofbungleak: 0,
        bodyleak: "No",
        noofbodyleak: 0,
        appearance:"Ok",
        noofappearance:0,
      },
    ],
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const url = "/api/transactionoutgoing/create";
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    setLoading(true)
    const trans = await axios.get(
      `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true/productTypeId=${1}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setProduct(trans.data);
    setTransaction({
      ...transaction,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
        sealintact: "Yes",  
        noofsealintact: 0,
        correctweight: "Yes", 
        noofcorrectweight: 0,
        bungleak: "No",
        noofbungleak: 0,
        bodyleak: "No",
        noofbodyleak: 0,
        appearance: "Ok",
        noofappearance: 0,
      })),
    });
    setLoading(false)
    const vehicles = await axios.get(
      `/api/vehicle/getAllActiveVehicles/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setVehicle(vehicles.data);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);


  function rsubmit(e) {
    e.preventDefault();
    if (!selectedVehicle) {
      toast.error("Please select a vehicle");
      return;
    }

    
    // console.log("Transaction data before submit:", transaction); // Debugging line

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        url,
        transaction,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Transaction Outgoing updated successfully");
        navigate("/users/transaction");
      })

      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  function selectVehicle(e) {
    const selectedValue = e.target.value;
  
    setSelectedVehicle(selectedValue);
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      vehicle: selectedValue,
      products: prevTransaction.products.map((product) => ({
        ...product,
       
      })),
    }));
  }
  function rhandle(e, field, index, productcode) {
    const actualValue = e.target.value.trim() || 0; 
  
    if (field === "quantity") {
      setTransaction((prevTransaction) => {
        const updatedProducts = [...prevTransaction.products];
        updatedProducts[index] = {
          ...updatedProducts[index],
          quantity: actualValue,
        };
        return { ...prevTransaction, products: updatedProducts };
      });
    } else if (field === "noofsealintact") {
      setTransaction((prevTransaction) => {
        const updatedProducts = prevTransaction.products.map((product) =>
          product.productcode === productcode
            ? { ...product, noofsealintact: actualValue, sealintact: actualValue ? "No" : product.sealintact }
            : product
        );
        return { ...prevTransaction, products: updatedProducts };
      });
    } else if (field === "noofcorrectweight") {
      setTransaction((prevTransaction) => {
        const updatedProducts = prevTransaction.products.map((product) =>
          product.productcode === productcode
            ? { ...product, noofcorrectweight: actualValue, correctweight: actualValue ? "No" : product.correctweight }
            : product
        );
        return { ...prevTransaction, products: updatedProducts };
      });
    } else if (field === "noofbungleak") {
      setTransaction((prevTransaction) => {
        const updatedProducts = prevTransaction.products.map((product) =>
          product.productcode === productcode
            ? { ...product, noofbungleak: actualValue, bungleak: actualValue ? "Yes" : product.bungleak }
            : product
        );
        return { ...prevTransaction, products: updatedProducts };
      });
    } else if (field === "noofbodyleak") {
      setTransaction((prevTransaction) => {
        const updatedProducts = prevTransaction.products.map((product) =>
          product.productcode === productcode
            ? { ...product, noofbodyleak: actualValue, bodyleak: actualValue ? "Yes" : product.bodyleak }
            : product
        );
        return { ...prevTransaction, products: updatedProducts };
      });
     
  } else if (field === "noofappearance") {
    setTransaction((prevTransaction) => {
      const updatedProducts = prevTransaction.products.map((product) =>
        product.productcode === productcode
          ? { ...product, noofappearance: actualValue, appearance: actualValue === "Not Ok" ? "0" : product.appearance }
          : product
      );
      return { ...prevTransaction, products: updatedProducts };
    });
  } else {
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      [field]: actualValue,
    }));
  }
}
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  function handleToggleChange(e, index) {
    const isChecked = e.target.checked;
    setTransaction((prevTransaction) => {
      const newProducts = [...prevTransaction.products];
      newProducts[index] = {
        ...newProducts[index],
        sealintact: isChecked ? "Yes" : "No",
        noofsealintact: isChecked ? 0 : newProducts[index].noofsealintact
      };
      return { ...prevTransaction, products: newProducts };
    });
  }

  function handleToggleChange1(e, index) {
    const isChecked = e.target.checked;
    setTransaction((prevTransaction) => {
      const newProducts = [...prevTransaction.products];
      newProducts[index] = {
        ...newProducts[index],
        correctweight: isChecked ? "Yes" : "No",
        noofcorrectweight: isChecked ? 0 : newProducts[index].noofcorrectweight
      };
      return { ...prevTransaction, products: newProducts };
    });
  }

  function handleToggleChange2(e, index) {
    const isChecked = e.target.checked;
    setTransaction((prevTransaction) => {
      const newProducts = [...prevTransaction.products];
      newProducts[index] = {
        ...newProducts[index],
        bungleak: isChecked ? "Yes" : "No",
        noofbungleak: isChecked ? 0 : newProducts[index].noofbungleak
      };
      return { ...prevTransaction, products: newProducts };
    });
  }

  function handleToggleChange3(e, index) {
    const isChecked = e.target.checked;
    setTransaction((prevTransaction) => {
      const newProducts = [...prevTransaction.products];
      newProducts[index] = {
        ...newProducts[index],
        bodyleak: isChecked ? "Yes" : "No",
        noofbodyleak: isChecked ? 0 : newProducts[index].noofbodyleak
      };
      return { ...prevTransaction, products: newProducts };
    });
  }

  function handleToggleChange4(e, index) {
    const isChecked = e.target.checked;
    setTransaction((prevTransaction) => {
      const newProducts = [...prevTransaction.products];
      newProducts[index] = {
        ...newProducts[index],
        appearance: isChecked ? "Ok" : "Not Ok",
        noofappearance: isChecked ? 0 : newProducts[index].noofappearance,
      };
      return { ...prevTransaction, products: newProducts };
    });
  }
  const handleKeyPress = (e) => {
    const isValidChar = /^\d$/.test(e.key);
    if (!isValidChar) {
      e.preventDefault();
    }
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-12  mx-xl-auto px-xl-9">
        <div >
          <div >
            {/* <div className="card-title">Transaction Outgoing Updates</div> */}
            <br />

            <div className="col-xl-12 mx-xl-auto">
              <div className="card card-raised mb-3 card-move-right">
                <div className="card-body p-4 ">
                <div className="card-title">Transaction Outgoing Updates</div>
<br/>
<br/>
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div>
                      <label for="date">Date</label>
                      <input
                        id="date"
                        type="date"
                        value={transaction.date}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="truckinfo">Vehicle</label>
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={selectedVehicle}
                        onChange={(e) => selectVehicle(e, "vehicle")}
                       
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Id</th>
                              <th>Product Name</th>
                              <th className="quantity-col">Quantity</th>
                              <th>Seal Intact</th>
                              <th>Correct Weight</th>
                              <th>Bung Leak</th>
                              <th>Body Leak</th>
                              <th>Appearance</th>


                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td className="quantity-col">
                                    <input
                                        id="id"
                                        type="text"
                                        onKeyPress={handleKeyPress}

                                        placeholder="0"
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "quantity", index)
                                        }
                                      />
                                    </td>
                                    <td>
                                    <div className="toggle-container">
    <span className="label-no">No</span>
    <label className="toggle-switch">  
                                  <input
                                type="checkbox"
                                id={`toggle-${index}`}
                                checked={product.sealintact === "Yes"}
                                onChange={(e) => handleToggleChange(e, index, "sealintact")}
                              />

                                <span className="slider"></span>
    </label>
    <span className="label-yes">Yes</span>
  </div>
                            <br />
                          
                            {product.sealintact === "No" && (
                              <input
                                className="input-field"
                                type="text"
                                value={product.noofsealintact}
                                onKeyPress={handleKeyPress}

                                placeholder="No. of sealintact"                                onChange={(e) =>
                                  rhandle(e, "noofsealintact", index, product.productcode)
                                }
                              />
                            )}
                          </td>
                          <td>
                          <div className="toggle-container">
    <span className="label-no">No</span>
    <label className="toggle-switch">
                                    <input
                                type="checkbox"
                                id={`correctweight-toggle-${index}`}
                                checked={product.correctweight === "Yes"}
                                onChange={(e) => handleToggleChange1(e, index, "correctweight")}
                              />
                               <span className="slider"></span>
    </label>
    <span className="label-yes">Yes</span>
  </div>
                            <br />
                            
                            {product.correctweight === "No" && (                           
                                 <input
                                className="input-field"
                                type="text"
                                value={product.noofcorrectweight}
                                onKeyPress={handleKeyPress}

                                placeholder="No. of correctweight"
                                onChange={(e) => rhandle(e, "noofcorrectweight", index, product.productcode)}
                              />
                            )}
                          </td>
                          <td>
                          <div className="toggle-container">
    <span className="label-no">No</span>
    <label className="toggle-switch">
                                    <input
                                type="checkbox"
                                id={`bungleak-toggle-${index}`}
                                checked={product.bungleak === "Yes"}
                                onChange={(e) => handleToggleChange2(e, index, "bungleak")}
                              />
                               <span className="slider"></span>
    </label>
    <span className="label-yes">Yes</span>
  </div>
                            <br />
                           
                            {product.bungleak === "Yes" && (
                              <input
                                className="input-field"
                                type="text"
                                value={product.noofbungleak}
                                onKeyPress={handleKeyPress}

                                placeholder="No. of bungleak"
                                onChange={(e) => rhandle(e, "noofbungleak", index, product.productcode)}
                              />
                            )}
                          </td>
                          <td>
                          <div className="toggle-container">
    <span className="label-no">No</span>
    <label className="toggle-switch">
                                    <input
                                type="checkbox"
                                id={`bodyleak-toggle-${index}`}
                                checked={product.bodyleak === "Yes"}
                                onChange={(e) => handleToggleChange3(e, index, "bodyleak")}
                              />
                              <span className="slider"></span>
    </label>
    <span className="label-yes">Yes</span>
  </div>
                            <br />
                          
                            {product.bodyleak === "Yes" && (
                              <input
                                className="input-field"
                                type="text"
                                value={product.noofbodyleak}
                                onKeyPress={handleKeyPress}

                                placeholder="No. of bodyleak"
                                onChange={(e) => rhandle(e, "noofbodyleak", index, product.productcode)}
                              />
                            )}
                          </td>

                          <td>
                          <div className="toggle-container">
    <span className="label-no">Not Ok</span>
    <label className="toggle-switch">  
                                  <input
                                type="checkbox"
                                id={`appearance-toggle-${index}`}
                                checked={product.appearance === "Ok"}
                                onChange={(e) => handleToggleChange4(e, index, "appearance")}
                              />
                               <span className="slider"></span>
    </label>
    <span className="label-yes">OK</span>
  </div>
                            <br />
                           
                            {product.appearance === "Not Ok" && (
                              <input
                                className="input-field"
                                type="text"
                                value={product.noofappearance}
                                onKeyPress={handleKeyPress}

                                placeholder="No. of appearance"
                                onChange={(e) =>
                                  rhandle(e, "noofappearance", index, product.productcode)
                                }
                              />
                            )}
                          </td>
        
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default withRouter(UpdateTransactionOutgoing);
