import React from "react";
import AdminDashboard from "../components/admindashboard";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaCircle } from "react-icons/fa"; 
import { Link} from "react-router-dom";

function SqcTypesreports() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Reportspage");
  };

  return (
    <div>
      <div>
        <br/>
        <br/>
        <AdminDashboard />
      </div>
      <br />
      <br />

      <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised mb-5">

          <div className="card-body">
            <div className="card-title form-group">
              <h3>SQC Report Types</h3>
              <br/>
              <div className="row">
                <div className="col-4">
                <Link to="/users/SQCwithinvoiceReport" className="btn btn-link w-80">
                  <FaCircle className="me-2" size={8} /> SQC by Invoice Number
                </Link>
                </div>
                <div className="col-4">
                <Link to="/users/SQCwithsqcnumberReport" className="btn btn-link w-80">
                  <FaCircle className="me-2" size={8} /> SQC by SQC Number
                </Link>
                </div>
                </div>
                <div className="row">
                <div className="col-4">
                <Link to="/users/SQCwithtruckinfowithdateReport" className="btn btn-link w-80">
                  <FaCircle className="me-2" size={8} /> SQC by TruckInfo and Date
                </Link>
                </div>
                <div className="col-4">
                <Link to="/users/SQCReport" className="btn btn-link w-80">
                  <FaCircle className="me-2" size={8} /> SQC by Product
                </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                <Link to="/users/SQCsummaryReport" className="btn btn-link w-80">
                  <FaCircle className="me-2" size={8} /> SQC Summary Report
                </Link>
                </div>
                </div>
              <br/>
              <Button
                style={{ marginLeft: "5%" }}
                variant="secondary"
                onClick={handleBack}
              >
                Back
              </Button>
            
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default SqcTypesreports;
