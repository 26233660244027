import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";
import './totalervreport.css'; // Import your CSS file

function Invoicereport() {
  const [reportParams, setReportParams] = useState({
    month: new Date().getMonth() + 1, // Current month
    year: new Date().getFullYear(),
  });

  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({
      ...prevState,
      [field]: parseInt(value, 10),
    }));
  };

  // Fetch invoice data
  const fetchInvoiceData = async () => {
    const { month, year } = reportParams;

    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get(
        `/api/invoicetotal/orgcode=${orgCode}/month=${month}/year=${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setInvoiceData(response.data);
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      toast.error("Failed to load invoice data.");
    } finally {
      setLoading(false);
    }
  };

  // Convert month number to month name
  const getMonthName = (monthNumber) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber - 1] || "";
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!invoiceData) {
      toast.error("No data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const tableColumn = ["Date", "INVOICEID", ...Object.keys(invoiceData.product_totals)];
    const tableRows = [];

    // Create table rows with nested product data
    const invoiceMap = new Map();

    Object.entries(invoiceData.product_totals).forEach(([product, entries]) => {
      entries.forEach(({ invoiceid, quantity, date }) => {
        if (!invoiceMap.has(invoiceid)) {
          invoiceMap.set(invoiceid, { date, invoiceid, products: {} });
        }
        const invoice = invoiceMap.get(invoiceid);
        invoice.products[product] = quantity;
      });
    });

    invoiceMap.forEach(({ date, invoiceid, products }) => {
      const rowData = [date, invoiceid];
      tableColumn.slice(2).forEach((product) => {
        rowData.push(products[product] || 0);
      });
      tableRows.push(rowData);
    });

    const margins = { top: 20, bottom: 20, left: 10, right: 10 };
    const logoWidth = 40;
    const logoHeight = 25;
    const titleOffsetY = 60;
    const tableStartY = titleOffsetY + 20;

    // Add logo image
    doc.addImage(img, 'PNG', margins.left, margins.top, logoWidth, logoHeight);

    // Add address text on the right side
    const addressX = doc.internal.pageSize.width - margins.right - 100; // Adjust X position to fit the address
    doc.setFontSize(16);
    doc.setTextColor(86, 150, 214);
    doc.text("Vijay Gas Agencies", addressX, margins.top);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text("1010/27 Mysore Road, Gundlupete - 571111", addressX, margins.top + 10);

    // Add report title
    doc.setFontSize(14);
    doc.setTextColor(86, 150, 214);
    doc.text("Monthly Invoice Report", margins.left, titleOffsetY);

    // Add month and year
    const monthName = getMonthName(reportParams.month);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Month: ${monthName}, Year: ${reportParams.year}`, margins.left, titleOffsetY + 10);

    // Add table
    doc.autoTable(tableColumn, tableRows, { startY: tableStartY });

    doc.save("total_invoice_report.pdf");
  };

  return (
    <div>
      <AdminDashboard />
      <br /><br />

      <div className="col-8 mx-xl-auto px-xl-10">
        <div className="card card-raised">
          <br />
          <br />
          <div className="card card-raised total-erv-card">
            <br />
            <div className="card-title">Total Invoice Report</div>
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img className="top-bar__logo" src={img} alt="logo" />
                      <div className="ml-3">
                        <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
                        <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete - 571111</p>
                      </div>
                    </div>
                  </div>
                  <br /><br />

                  <form onSubmit={(e) => e.preventDefault()}>
                    <div>
                      &nbsp;&nbsp;&nbsp;
                      <label htmlFor="month">Month:</label>
                      <input
                        id="month"
                        type="number"
                        min="1"
                        max="12"
                        value={reportParams.month}
                        onChange={(e) => handleInputChange(e, "month")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp;
                      <label htmlFor="year">Year:</label>
                      <input
                        id="year"
                        type="number"
                        value={reportParams.year}
                        onChange={(e) => handleInputChange(e, "year")}
                      />
                    </div>
                    <br />

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={fetchInvoiceData}
                      disabled={loading}
                    >
                      Generate Report
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={generatePDF}
                      disabled={!invoiceData}
                    >
                      Download PDF
                    </button>
                  </form>

                  {loading && <div className="loading">Loading...</div>}

                  {invoiceData && (
                    <div className="table-container">
                      <br />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>INVOICEID</th>
                            {Object.keys(invoiceData.product_totals).map((product) => (
                              <th key={product}>{product}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(invoiceData.product_totals).flatMap(([product, entries]) =>
                            entries.map(({ invoiceid, quantity, date }, index) => (
                              <tr key={`${invoiceid}-${product}-${index}`}>
                                <td>{date}</td>
                                <td>{invoiceid}</td>
                                {Object.keys(invoiceData.product_totals).map((productKey) => (
                                  <td key={`${invoiceid}-${productKey}-${index}`}>
                                    {entries.find((entry) => entry.invoiceid === invoiceid && entry.product === productKey)?.quantity || 0}
                                  </td>
                                ))}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Invoicereport;
