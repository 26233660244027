import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";
import './totalervreport.css'; // Import your CSS file

function SQCsummaryReport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    // Fetch products from API
    const fetchProducts = async () => {
      const token = JSON.parse(localStorage.getItem("data"))?.token;
      try {
        const response = await axios.get(`/api/product`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const productsData = response.data.map((product) => ({
          id: product.id,
          productname: product.productname,
        }));

        setProducts(productsData);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch products");
      }
    };

    fetchProducts();
  }, []);

  // Fetch report data from API
  const getData = async () => {
    if (!productFilter || !selectedYear) {
      toast.error("Please select both product and year.");
      return;
    }

    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);
    try {
      const year = selectedYear.getFullYear(); // Extract the year
      const response = await axios.get(
        `/api/sqcreport/orgcode=${orgcode}/productname=${productFilter}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Report Data Response:", response.data);

      // Ensure reportData is correctly set
      setReportData(response.data || {});
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!reportData.monthly_aggregates) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Load the image asynchronously
      const imgData = new Image();
      imgData.src = img;

      imgData.onload = () => {
        // Add logo to the left side
        const logoWidth = 70;
        const logoHeight = 40;
        const logoX = 10;
        const logoY = 10;
        doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Add address and company name to the right side
        doc.setFontSize(18);
        doc.setTextColor(86, 150, 214);
        const textX = doc.internal.pageSize.width - 10; // Position on the right side
        const textY = logoY + 10;
        doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        const addressText = "1010/27 Mysore Road, Gundlupete";
        const addressY = textY + 10;
        doc.text(addressText, textX, addressY, { align: "right" });

        // Prepare table data
        const monthlyAggregates = reportData.monthly_aggregates;
        const months = Object.keys(monthlyAggregates);
        const tableData = months.map((month) => {
          const data = monthlyAggregates[month];
          return [
            month,
            data.total_gross_weight.toFixed(2),
            data.total_tare_weight.toFixed(2),
            data.total_actual_weight.toFixed(2),
            data.total_difference.toFixed(2),
            data.total_seal_intact,
            data.total_product_returned,
          ];
        });

        // Generate the table in the PDF
        doc.autoTable({
          startY: addressY + 20, // Start the table after the address
          head: [
            [
              "Month",
              "Total Gross Weight",
              "Total Tare Weight",
              "Total Actual Weight",
              "Total Difference",
              "Total Seal Intact",
              "Total Product Returned",
            ],
          ],
          body: tableData,
          theme: "striped",
          styles: { overflow: "linebreak" },
        });

        // Save the generated PDF
        doc.save("sqc_report.pdf");
      };

      imgData.onerror = () => {
        toast.error("Failed to load logo image.");
      };
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleProductChange = (event) => {
    setProductFilter(event.target.value);
  };

  const handleYearChange = (date) => {
    setSelectedYear(date);
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="container mt-4 d-flex justify-content-center">
        <div className="card card-raised" style={{ width: "100%", maxWidth: "600px" }}>
          <div className="card card-raised total-erv-card">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <div className="mt-4">
              <div className="table-container">
                <label>Select year:</label>
                <DatePicker
                  selected={selectedYear}
                  onChange={handleYearChange}
                  dateFormat="yyyy"
                  showYearPicker
                  className="form-control"
                />
              </div>
              <br /><br />
              <div className="form-group">
                <label>Product:</label>
                <select
                  className="form-control"
                  value={productFilter}
                  onChange={handleProductChange}
                >
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.productname}>
                      {product.productname}
                    </option>
                  ))}
                </select>
              </div>
              <br />
              <div className="form-group">
                <button className="btn btn-primary" onClick={getData} disabled={loading}>
                  {loading ? "Loading..." : "Fetch Data"}
                </button>
              </div>
              <br />
              <div className="form-group">
                <button className="btn btn-success" onClick={generatePDF} disabled={loading}>
                  {loading ? "Generating PDF..." : "Generate PDF"}
                </button>
              </div>
            </div>
            {loading && <div className="loading">Loading...</div>}

            {reportData.monthly_aggregates ? (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Total Gross Weight</th>
                    <th>Total Tare Weight</th>
                    <th>Total Actual Weight</th>
                    <th>Total Difference</th>
                    <th>Total Seal Intact</th>
                    <th>Total Product Returned</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(reportData.monthly_aggregates).map((month) => {
                    const data = reportData.monthly_aggregates[month];
                    return (
                      <tr key={month}>
                        <td>{month}</td>
                        <td>{data.total_gross_weight.toFixed(2)}</td>
                        <td>{data.total_tare_weight.toFixed(2)}</td>
                        <td>{data.total_actual_weight.toFixed(2)}</td>
                        <td>{data.total_difference.toFixed(2)}</td>
                        <td>{data.total_seal_intact}</td>
                        <td>{data.total_product_returned}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SQCsummaryReport;
