import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import moment from "moment";
import ManagerSidebar from "./managerSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import "./Spinner.css";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function UpdateExceptionOpenstock(props) {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const searchDate = props.params.searchDate
  

  const [openstock, setOpenstock] = useState({
    date: currentDate,

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname:"" }],
  });
  const [openingstock, setOpeningstock] = useState({
    date: currentDate,

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "",productname:""}],
  });
  const [openstock1, setOpenstock1] = useState({
    date: searchDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });

  const [product, setProduct] = useState([]);
  const [product1, setProduct1] = useState([]);
  const [productstate1, setProductstatus1] = useState([]);
  const [product2, setProduct2] = useState([]);
  const [productstate2, setProductstatus2] = useState([]);
  const [productstate, setProductstatus] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState(1); // New state for product type filter
  const [productTypes, setProductTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [quantity, setQuantity] = useState([
    {
      quantity1: "",
      quantity2: "",
      quantity3: "",
    },
  ]);
  

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(`/api/productcategorytype`, {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("data")).token}` },
        });
        setProductTypes(response.data);
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };

    fetchProductTypes();
  }, []);
  const navigate = useNavigate();
  const url = "/api/Openingstock/update";
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      // Fetch all products
      const trans = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const filteredProducts = trans.data.filter(
        (product) => product.productCategory?.productCategoryTypeId === 2
      );

      setProduct(filteredProducts);
      setLoader(false);

      if (filteredProducts.length === 0) {
        toast.error("No products of type 'Sales' found.");
      }

      const ps = await axios.get("/api/entitytype", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProductstatus(ps.data);

      const proddo = [];
      filteredProducts.forEach((element) => {
        const item = { pid: element.productcode, productname: element.productname };
        ps.data.forEach((psitem) => {
          item[psitem.producttype] = '';
        });
        proddo.push(item);
      });

      setOpenstock({
        ...openstock,
        prodDo: proddo,
      });

    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [productTypeFilter]);
  
  function onClickUpdate(e) {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
  
    const newpro = [];
  
    if (openstock && Array.isArray(openstock.prodDo)) {
    openstock.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype] || 0),
          productname:element.productname
        };

        newpro.push(item);
      });
    });
  }

  if (openstock1 && Array.isArray(openstock1.prodDo)) {
    openstock1.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype] || 0),
          productname:element.productname
        };

        newpro.push(item);
      });
    });
  }

    
    const reqbody = {
      datetime: openstock.date,

      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      prodDo: newpro,
    };
    axios
      .post(
        "/api/Openingstock/update",
        reqbody,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        toast.success("openingstock updated successfully");
      
      const exceptionData = {
        expdate: openstock.date,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      };

      axios
        .post("/api/exceptiondate/create", exceptionData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          navigate("/users/stock");
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          handleError(error);
        });
    })
    .catch((error) => {
      setLoader(false);
     handleError(error);
    });
}

  function rhandle(e, field, index) {
    let actualValue = e.target.value;
  
    const newproddo = [...openstock.prodDo];
    newproddo[index] = { ...openstock.prodDo[index], [field]: actualValue };
    const newos = { ...openstock, prodDo: newproddo };
    if (field == "date") {
      newos.date = actualValue;
    }
    setOpenstock(newos);
  }
  const getData1 = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      // Fetch all products
      const trans = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Filter products by productType: "Refill Sales"
      const filteredProducts1 = trans.data.filter(
        (product) => product.productCategory?.productCategoryTypeId === 1
      );

      setProduct1(filteredProducts1);
      setLoader(false);

      if (filteredProducts1.length === 0) {
        toast.error("No products of type 'Refill' found.");
      }

      const ps1 = await axios.get("/api/entitytype", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProductstatus1(ps1.data);

      const proddo = [];
      filteredProducts1.forEach((element) => {
        const item = { pid: element.productcode, productname: element.productname };
        ps1.data.forEach((psitem) => {
          item[psitem.producttype] = '';
        });
        proddo.push(item);
      });

      setOpenstock1({
        ...openstock1,
        prodDo: proddo,
      });

    } catch (error) {
      handleError(error);
    }
  };

 
  useEffect(() => {
    getData1();
  }, []);

  function rhandle1(e, field, index) {
    let actualValue = e.target.value;

    const newproddo = [...openstock1.prodDo];
    newproddo[index] = { ...openstock1.prodDo[index], [field]: actualValue };
    const newos = { ...openstock1, prodDo: newproddo };
    if (field === "date") {
      newos.date = actualValue;
    }
    setOpenstock1(newos);
  }
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>{" "}
     
      <br /> 
      <div style={{ position: "relative", top: "100px", left: "150px" }} className="col-8 mx-xl-auto ps-xl-6">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">OpenStock Update</div>
              {loader && <div className="loading"></div>}
            </div>
            <br />
            <div>
              <label htmlFor="date">Date: </label>
              <input
                id="date"
                type="text"
                value={openstock.date}
              />
            </div>
            <div style={{ marginLeft: "38%", top: "5px" }}>
              <div className="filter-container d-flex align-items-center">
                <select
                  value={productTypeFilter}
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
              </div>
            </div>

             
            <br />
            <br /> 
            {productTypeFilter === 1 ? (
  <>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock1.prodDo.map((products, index) => (
                  
                  <tr key={index}>
                      <td>{products.productname}</td>

                      <td key={index}>
                        <input
                          id="quantity1"
                          type="text"
                          placeholder="0"
                         value={products.full}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle1(e, "full", index)}
                          />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity2"
                          type="text"
                          placeholder="0"
                         value={products.empty}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle1(e, "empty", index)}
                          />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity3"
                          type="text"
                          placeholder="0"
                          value={products.defective}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle1(e, "defective", index)}
                          />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            </>
          ) : null}
         
           {productTypeFilter === 2 ? (
            <>
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Product</th>
                  <th>Quantity</th>
                 
                </tr>
              </thead>
              <tbody>
                {openstock.prodDo.map((products, index) => (
                  <tr key={index}>
                    <td>{products.productname}</td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={products.full}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle(e, "full", index)}
                      />
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={products.empty || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle(e, "empty", index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="0"
                        value={products.defective || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle(e, "defective", index)}
                      />
                    </td> */}
                  </tr>
                  
                ))}
              </tbody>
            </table>
            </>
          ) : null}

          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={(e) => onClickUpdate(e)}
            >
              Update 
            </button>
            <Link  to="/users/stock" style={{textDecoration:"none", position: "absolute", left: "150px"}}>
 <button className="btn btn-secondary" type="button">Back</button>
  </Link>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(UpdateExceptionOpenstock);
