import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";

 
 
function Settings() {
 
  return (
    <div>
    <div>
      <AdminDashboard />
    </div>
    <br />
    <br />
    <br />
    <br />

    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised">
        <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
            Settings
          </div>
           <br></br>
            <div>
            <Link
              to="/users/api-settings">
              API Settings
             </Link>
            </div>
            <br></br>
            <div>
            <Link
              to="/users/ProfileSettings">
              Profile
             </Link>
          </div>
          <br></br>
            <div>
            <Link
              to="/users/ProfileSettings">
              organization Settings
             </Link>
          </div>
          <br></br>
          <div>
          <Link
              to="/users/tariff-freight">
              Tariff Freight
             </Link>
          </div>

          <div className="mt-4">
          <Link
              to="/users/performance">
              Performance Settings
             </Link>
          </div>
           
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;