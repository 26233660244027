import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";
import './loadin.css';

function LoadinReport1() {
  const [orgCode, setOrgCode] = useState("");
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [productTypeid, setProductTypeid] = useState("");
  const [reportData, setReportData] = useState(null);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [columnTotals, setColumnTotals] = useState({});
  const [invoiceData, setInvoiceData] = useState(null);

  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const [reportParams, setReportParams] = useState({
    month: new Date().getMonth() + 1, 
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/productcategories/orgcode=${orgcode}/disable=false`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product types:", error.response || error);
        toast.error("Failed to load product types.");
      }
    };

    fetchProductTypes();
  }, []);

  const fetchReportData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const productResponse = await axios.get(
        `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${productTypeid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(productResponse.data);

      const response = await axios.get(`/api/loadouttotal/orgcode=${orgCode}/producttypeid=${productTypeid}/month=${month.getMonth() + 1}/year=${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Group data by date, truck info, and ervid
      const groupedData = response.data.daily_totals.reduce((acc, current) => {
        const existing = acc.find(item => item.date === current.date && item.truckinfo === current.truckinfo && item.ervid === current.ervid);
        if (existing) {
          existing.products.push({
            productname: current.productname,
            quantity: current.quantity,
          });
        } else {
          acc.push({
            date: current.date,
            truckinfo: current.truckinfo,
            ervid: current.ervid,
            products: [{
              productname: current.productname,
              quantity: current.quantity,
            }]
          });
        }
        return acc;
      }, []);

      // Calculate column totals for each product
      const totals = {};
      groupedData.forEach(row => {
        row.products.forEach(prod => {
          if (!totals[prod.productname]) {
            totals[prod.productname] = 0;
          }
          totals[prod.productname] += prod.quantity;
        });
      });

      setReportData(groupedData);
      setColumnTotals(totals);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("Failed to load report data.");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "year") setYear(value);
    if (id === "productType") setProductTypeid(value);
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const generatePDF = () => {
    if (!reportData) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();
    
      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);
    
      // Add report name below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Monthly Load-out Report"; // Updated name of the report
      const reportNameY = logoY + logoHeight + 10; // Adjust spacing below the logo (decreased to 10)
      doc.text(reportName, logoX, reportNameY);
    
      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10; // Position on the right side
      let textY = logoY + 10; // Starting position for the text
    
      // Add company name with spacing
      doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });
      textY += 8; // Add space below company name
    
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      textY += 4; // Add additional space before address
      doc.text(addressText, textX, textY, { align: 'right' });
    
      // Create table with report data
      const tableColumn = ["Date", "Truck Info", "ERVID", ...products.map(p => p.productname)];
      const tableRows = [];
    
      reportData.forEach((row) => {
        const rowData = [
          row.date,
          row.truckinfo,
          row.ervid,
          ...products.map(p => {
            const prod = row.products.find(pr => pr.productname === p.productname);
            return prod ? prod.quantity : 0;
          }),
        ];
        tableRows.push(rowData);
      });
    
      // Add column totals row
      const totalRow = ["Total", "", "", ...products.map(p => columnTotals[p.productname] || 0)];
      tableRows.push(totalRow);
    
      // Add table to PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        margin: { top: logoHeight + 30, left: 10, right: 10 }, // Adjust margin to accommodate logo and address
        styles: { fontSize: 8 },
        columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 }, 2: { cellWidth: 30 } },
      });
    
      // Save the PDF
      doc.save("monthly_loadout_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF.");
    }
  }    


  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <div className="card card-raised mt-4">
        <div className="card-body">
          <br />
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()} className="text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <label htmlFor="month">Month:</label>
                    <DatePicker
                      selected={month}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                  <br />
                  <div>
                    <label htmlFor="productType">Product Category:</label>
                    <select
                      id="productType"
                      value={productTypeid}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Product Category</option>
                      {productData.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.categoryname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={fetchReportData}
                    disabled={loading || !productTypeid || !month || !year}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={generatePDF}
                    disabled={!reportData}
                  >
                    Download PDF
                  </button>
                </form>
                <br />
                {loading && <div className="loading">Loading...</div>}

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  reportData && reportData.length > 0 && (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Truck Info</th>
                          <th>ERVID</th>
                          {products.map((p) => (
                            <th key={p.id}>{p.productname}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.truckinfo}</td>
                            <td>{row.ervid}</td>
                            {products.map((p) => {
                              const prod = row.products.find(pr => pr.productname === p.productname);
                              return <td key={p.id}>{prod ? prod.quantity : 0}</td>;
                            })}
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="2">Total</td>
                          <td></td>
                          {products.map(p => (
                            <td key={p.id}>{columnTotals[p.productname] || 0}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  )
                )}
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadinReport1;
