import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const Addprofiledetails = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    userid:JSON.parse(localStorage.getItem("data")).userid,
    name: "",
    orgcode: "",
    date: currentDate,
    contact: "",
    username:"",
    gstin:"",
    pan: "",
    tan: "",
    addressLine1: "",
    addressLine2: "",
    city:"",
    pincode:"",
    state:"",
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  
  const url = "/api/organizationprofiles/createprofile";
  const navigate = useNavigate();

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Profile created successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  const handleChange = (e) => {
    const { id, value } = e.target;
    const newdata = { ...newProduct };
      newdata[id] = value;
      if (id === "productTypeId") {
        if (value !== "1") {
          newdata.netWeight = 0;
        }
      }
    setNewProduct(newdata);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Profile Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="name"
                      value={newProduct.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="username">Email</label>
                    <input
                      className="form-control"
                      id="username"
                      type="text"
                      placeholder="username"
                      value={newProduct.username}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="orgcode">Orgcode</label>
                    <input
                      className="form-control"
                      id="orgcode"
                      type="text"
                      placeholder="orgcode"
                      value={newProduct.orgcode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="contact">Contact</label>
                    <input
                      className="form-control"
                      id="contact"
                      type="text"
                      placeholder="contact"
                      value={newProduct.contact}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="addressLine1">Address1</label>
                    <input
                      className="form-control"
                      id="addressLine1"
                      type="text"
                      placeholder="addressLine1"
                      value={newProduct.addressLine1}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="addressLine2">Address2</label>
                    <input
                      className="form-control"
                      id="addressLine2"
                      type="text"
                      placeholder="addressLine2"
                      value={newProduct.addressLine2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="city">City</label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="city"
                      value={newProduct.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="state">State</label>
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      placeholder="state"
                      value={newProduct.state}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="pincode">Pincode</label>
                    <input
                      className="form-control"
                      id="pincode"
                      type="text"
                      placeholder="pincode"
                      value={newProduct.pincode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="gstin">GST</label>
                    <input
                      className="form-control"
                      id="gstin"
                      type="text"
                      placeholder="gstin"
                      value={newProduct.gstin}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="pan">Pan</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="pan"
                      value={newProduct.pan}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="tan">Tan</label>
                    <input
                      className="form-control"
                      id="tan"
                      type="text"
                      placeholder="tan"
                      value={newProduct.tan}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Addprofiledetails;
