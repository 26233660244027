import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function WarehouseeditModalPopup({ isOpen, onRequestClose, warehouseId }) {
  const [uproduct1, setUproduct1] = useState({
    warehousename: "",
    address: "",
    latitude:"",
    longitude:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/warehouse/${warehouseId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (warehouseId) {
      getData();
    }
  }, [warehouseId]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/warehouse/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("updated successfully");
        getData();

        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;

   
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Ware House Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="warehousename"> Ware House Name</label>
                  <input
                    className="form-control"
                    id="warehousename"
                    type="text"
                    placeholder="warehousename"
                    value={uproduct1.warehousename}
                    onChange={(e) => rhandle(e, "warehousename")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="address">Address</label>
                  <input
                    className="form-control"
                    id="address"
                    type="text"
                    placeholder="address"
                    value={uproduct1.address}
                    onChange={(e) => rhandle(e, "address")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="latitude">Latitude</label>
                  <input
                    className="form-control"
                    id="latitude"
                    type="text"
                    placeholder="latitude"
                    value={uproduct1.latitude}
                    onChange={(e) => rhandle(e, "latitude")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="longitude">Longitude</label>
                  <input
                    className="form-control"
                    id="longitude"
                    type="text"
                    placeholder="longitude"
                    value={uproduct1.longitude}
                    onChange={(e) => rhandle(e, "longitude")}
                  />
                  </div>
              </div>
                 
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default WarehouseeditModalPopup;