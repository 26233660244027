import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./transaction.css";
import { useNavigate } from "react-router-dom";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { GrFormView } from "react-icons/gr";


function Loaddetails() {
  const [Loadin, setLoadin] = useState([]);
  const [Loadout, setLoadout] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  
  const [transactions1, setTransaction1] = useState([]);
  const [transactionInModalIsOpen, setTransactionInModalIsOpen] = useState(false);
const [loadinModalIsOpen, setloadinModalIsOpen] = useState(false);
const [loadoutModalIsOpen, setloadoutModalIsOpen] = useState(false);

const [loadinEditModalOpen, setLoadinEditModal] = useState(false);
const [loadoutEditModal, setLoadoutEditModal] = useState(false);

const [selectedProductName, setSelectedProductName] = useState(null);
const [selectedVehicleId, setSelectedVehicleId] = useState(null);
const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

const [loadinDetails, setloadinDetails] = useState([]);
const [transactionInDetails, setTransactionInDetails] = useState([]);
const [ttinDate, setTtinDate] =useState(null);
const [selectedVehicleId1, setSelectedVehicleId1] = useState(null);
const [selectedervId, setSelectedervId] = useState(null);

const [loadoutDetails, setloadoutDetails] = useState([]);
const [ttoutDate, setTtoutDate] =useState(null);
const [loadinLoader, setLoadinLoader] = useState(false); // Loader for Load Incoming section
const [loadoutLoader, setLoadoutLoader] = useState(false); // Loader for Load Outgoing section
const [dayEndDetails, setDayEndDetails] = useState(null);

const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();
  date.setDate(date.getDate() );
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [searchDate, setSearchDate]= useState(currentDate);
  const today = new Date();
  async function searchName(text) {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setSearchbydate(false);
      setSearchDate(text);
      const loadInResponse = await axios.get(
        `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadin(loadInResponse.data);

      const loadOutResponse = await axios.get(
        `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadout(loadOutResponse.data);

      const dayEndRes = await axios.get(
        `/api/dayendtransactiondate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDayEndDetails(dayEndRes.data);
      setLoader(false);
  
    } catch (error) {
      setLoader(false);
      handleError(error);
    }

  }
  const [transactions, setTransaction] = useState([]);
  const [loader, setLoader] = useState(false);

  const [product, setProduct] = useState([]);

  const getData = async () => {
    const date = new Date();

    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    setLoader(true);
    const trans1 = await axios.get(
      `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setTransaction1(trans1.data);

    const trans = await axios.get(
      `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setTransaction(trans.data);
    setLoader(false);
  }catch(error){
    setLoader(false);
    handleError(error);
  }
  };

  const onClickdownloadLoadIn = async (e, invoiceid) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadin-file/download/invoiceid=${invoiceid}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const onClickdownloadLoadOut= async (e, ervid) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadout-file/download/ervid=${ervid}`,
      method: "GET",
      responseType: "blob",
       headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prod = await axios.get(
          `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true/productTypeId=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        setProduct(prod.data);
        const dayEndRes = await axios.get(
          `/api/dayendtransactiondate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDayEndDetails(dayEndRes.data);
      } catch (error) {
       handleError(error);
      }
    };
  
    fetchData();
    getData();
  }, []);
  const handleViewButtonClick = async (invoiceid) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
  
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/sqcform/orgcode=${orgCode}/date=${searchDate}/invoicenumber=${invoiceid}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoader(false);

      if (response.data.length === 0) {
        navigate(`/users/addsqcnumber`);
      } else {
        navigate(`/users/sqcnumber/${searchDate}`);
      }
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };


  const closeloadinModal = () => {
    setloadinModalIsOpen(false);
    searchName(searchDate);
  };

  const handleDeleteLoadin = async (invoiceid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   setLoader(true);
    try {
      const response = await axios.delete(
        `/api/loadindetailsdo/invoiceid=${invoiceid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Load in deleted successfully");
      searchName(searchDate);
      setLoader(false);
    } catch (error) {
      setLoader(false);
       handleError(error);
    }
  };

  
  const handleEditLoadin = async (invoiceId) => {
    navigate(`/users/loadin-details/${invoiceId}`);
  };


  const handleDeleteLoadout = async (ervid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   setLoader(true);
    try {
      const response = await axios.delete(
        `/api/loadoutdetailsdo/ervid=${ervid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Load out deleted successfully");
      searchName(searchDate);
      setLoader(false);
    } catch (error) {
      setLoader(false);
       handleError(error);
    }
  };

  const handleEditLoadout = async (ervId) => {
    navigate(`/users/loadout-details/${ervId}`);
  };

  

  const closeloadoutModal = () => {
    setloadoutModalIsOpen(false);
    searchName(searchDate);
  };

  const navigate = useNavigate();
  function updateLoadin(e, searchDate) {
    navigate(`/users/updateloadin/${searchDate}`);
  }

  function updateLoadout(e, searchDate) {
    navigate(`/users/updateloadout/${searchDate}`);
  }
  const handleLoadinRefresh = async () => {
    setLoadinLoader(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const loadinResponse = await axios.get(
        `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${orgcode}/date=${searchDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadin(loadinResponse.data);
    } catch (error) {
     setLoadin(false);
     handleError(error);
    }
    setLoadinLoader(false); // Set Load Incoming loader to false
  };

  const handleLoadoutRefresh = async () => {
    setLoadoutLoader(true); // Set Load Outgoing loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const loadoutResponse = await axios.get(
        `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${orgcode}/date=${searchDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadout(loadoutResponse.data);
    } catch (error) {
      setLoadoutLoader(false);
      handleError(error);
    }
    setLoadoutLoader(false); // Set Load Outgoing loader to false
  };


      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }
      return (
        <div>
          <div>
            <AdminDashboard />
          </div>
          <br />
          <br /> <br /> <br />
          <div style={{ position: "relative", left: "260px" }}>
            <Search searchName={searchName} />
          </div>
          <br />
          {loadinLoader && <div className="loadin-loading"></div>}
    
          <div className="loadin" id="loadin">
            <div>
              <div className="col-12 mx-xl-auto ps-xl-10">
                <div className="card card-raised">
                  <div className="card-body">
                    <div className="d-flex">
                      <div
                        className="card-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Load Incoming Details
    
                        {loadinLoader && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "20px",
                              top: "10px",
                            }}
                          ></div>
                        )}
                      </div>
                      <div className="col-9 d-flex justify-content-end ps-xl-6">
                        <button className="btn btn-primary" onClick={(e) => updateLoadin(e, searchDate)}>
                          Create Invoice
                        </button>
                        <span style={{ marginLeft: '5px' }}></span>
                        <button className="btn btn-primary" onClick={handleLoadinRefresh}>
                          Refresh
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead style={{ position: "sticky", top: 0, backgroundColor: " #6200ea", color: "white" }}>
                          <tr
                            style={{
                              fontSize: "17px",
                              textAlign: "center",
                            }}
                          >
                            <th>Vehicle Id</th>
                            {product.map((prod, index) => (
    <th key={index}>{prod.productname}</th>
  ))}
                            <th>Invoice Id</th>
                            <th>Taxable value</th>
                            <th>Taxes</th>
                            <th>Delivery Charges</th>
                            <th>Invoice Total</th>
                            <th>Action</th>
                            <th>Document</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchbydate && transactions1.length === 0 ? (
                            <tr>
                              <td colSpan="10" className="text-center">
                                No Data Available
                              </td>
                            </tr>
                          ) : (
                            searchbydate &&
                            transactions1.map((ttin, tIndex) => (
                              <tr className="text-center" key={tIndex}>
                                <td>
                                  <div>{ttin.truckinfo}</div>
                                  <div>{ttin.truckDrivers?.drivername}</div>
                                  <div>{ttin.truckDrivers?.contact}</div>
                                </td>
                                  {product.map((ps, pIndex) => {          
                            const productQty = ttin.loadDetails.find((item) => item.productname === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty ? productQty.quantity : 0}
                                    </th>
                                  );
                                })}
                                <td>{ttin.invoiceid}</td>
                                <td>{ttin.taxableValue}</td>
                                <td>{ttin.taxes}</td>
                                <td>{ttin.deliveryCharges}</td>
                                <td>{ttin.invoiceTotal}</td>
                                <td>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    {/* View Button */}
    <div
      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginBottom: '8px' }}
      title="view"
      onClick={() => handleViewButtonClick(ttin.invoiceid)}
    >
      VIEW SQC
    </div>
    
    {/* Icons: Delete and Edit */}
    
    <div style={{ display: 'flex', gap: '10px' }}>
  <span>
    <MdDeleteForever
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "18px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Delete"
      onClick={() => !dayEndDetails && handleDeleteLoadin(ttin.invoiceid)}
    />
  </span>
  <span>
    <MdEdit
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "17px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Edit"
      onClick={() => !dayEndDetails && handleEditLoadin(ttin.invoiceid)}
    />
  </span>
</div>

  </div>
</td>

                                <td>
                                  <button className="btn btn-primary" onClick={(e) => onClickdownloadLoadIn(e, ttin.invoiceid)}>
                                    Download
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                          {!searchbydate && Loadin.length === 0 ? (
                            <tr>
                              <td colSpan="10" className="text-center">
                                No Data Available
                              </td>
                            </tr>
                          ) : (
                            !searchbydate &&
                            Loadin.map((ttin, tIndex) => (
                              <tr className="text-center" key={tIndex}>
                                <td>
                                  <div>{ttin.truckinfo}</div>
                                  <div>{ttin.truckDrivers?.drivername}</div>
                                  <div>{ttin.truckDrivers?.contact}</div>
                                </td>
                                {product.map((ps, pIndex) => {
                                  const productQty = ttin.loadDetails.find((item) => item.productname === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty ? productQty.quantity : 0}
                                    </th>
                                  );
                                })}
                                <td>{ttin.invoiceid}</td>
                                <td>{ttin.taxableValue}</td>
                                <td>{ttin.taxes}</td>
                                <td>{ttin.deliveryCharges}</td>
                                <td>{ttin.invoiceTotal}</td>
                                <td>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    {/* View Button */}
    <div
      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginBottom: '8px' }}
      title="view"
      onClick={() => handleViewButtonClick(ttin.invoiceid)}
    >
      VIEW SQC
    </div>
    
    {/* Icons: Delete and Edit */}
    <div style={{ display: 'flex', gap: '10px' }}>
  <span>
    <MdDeleteForever
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "18px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Delete"
      onClick={() => !dayEndDetails && handleDeleteLoadin(ttin.invoiceid)}
    />
  </span>
  <span>
    <MdEdit
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "17px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Edit"
      onClick={() => !dayEndDetails && handleEditLoadin(ttin.invoiceid)}
    />
  </span>
</div>
  </div>
</td>
                                <td>
                                  <button className="btn btn-primary" onClick={(e) => onClickdownloadLoadIn(e, ttin.invoiceid)}>
                                    Download
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
              <br/>
      <br/>
      {loadoutLoader && <div className="loadout-loading"></div>}

      <div className="loadout" id="loadout">
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div className="card-title">Load Outgoing Details
                  {loadoutLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                  </div>
                 <div className="col-9 d-flex justify-content-end ps-xl-6">
                
                    <button className="btn btn-primary" onClick={(e) => updateLoadout(e, searchDate)}>Create ERV</button>
                 
                    <span style={{ marginLeft: '5px' }}></span>
   <button className="btn btn-primary" onClick={handleLoadoutRefresh}>
        Refresh 
      </button>
                  </div>
                </div>
                <br />
                <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr
                      style={{
                        fontSize: "17px",
                        backgroundColor: " #6200ea",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <th>Vehicle Id</th>
                        {product.map((prod, index) => (
                         <th key={index}>{prod.productname}</th>
                         ))}
                      <th>ERV Id</th>
                      <th>Actions</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                  {searchbydate && transactions.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchbydate &&
                      transactions.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td><div>{tout.truckinfo}</div>
                            <div>{tout.truckDriver?.drivername}</div>
                            <div>{tout.truckDriver?.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = tout.loadOutDetails.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
                            <td>{tout.ervid}</td>
                            <td> 

                            <div style={{ display: 'flex', gap: '10px' }}>
  <span>
    <MdDeleteForever
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "18px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Delete"
      onClick={() => !dayEndDetails && handleDeleteLoadout(tout.ervid)}
    />
  </span>
  <span>
    <MdEdit
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "17px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Edit"
      onClick={() => !dayEndDetails && handleEditLoadout(tout.ervid)}
    />
  </span>
</div>
    </td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.ervid)
                                  }
                                >
                                  Download
                                </button>
                              </th>


                          </tr>
                        );
                      }))}
                     {!searchbydate && Loadout.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchbydate &&
                      Loadout.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td><div>{tout.truckinfo}</div>
                            <div>{tout.truckDriver?.drivername}</div>
                            <div>{tout.truckDriver?.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = tout.loadOutDetails.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
                            <td>{tout.ervid}</td>
                            <td> 
                            <div style={{ display: 'flex', gap: '10px' }}>
  <span>
    <MdDeleteForever
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "18px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Delete"
      onClick={() => !dayEndDetails && handleDeleteLoadout(tout.ervid)}
    />
  </span>
  <span>
    <MdEdit
      style={{ 
        cursor: dayEndDetails ? 'not-allowed' : 'pointer', 
        fontSize: "17px", 
        color: dayEndDetails ? 'gray' : 'inherit' 
      }}
      title="Edit"
      onClick={() => !dayEndDetails && handleEditLoadout(tout.ervid)}
    />
  </span>
</div>
</td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.ervid)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                           
                          </tr>
                        );
                      }))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
         

          {loader && <div className="loading"></div>}
        </div>
      </div>
    </div>
  );
}

export default Loaddetails;
