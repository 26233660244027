import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";

function ExemptedCustomerDeliveryReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState(""); // New state for customer name

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      return;
    }

    if (!customerNumber || !customerName) {
      toast.error("Please enter both customer number and customer name.");
      return;
    }

    setLoading(true);

    try {
      const encodedCustomerName = encodeURIComponent(customerName);
      const response = await axios.get(
        `/api/exemptedCustomerDetails/totalAmountByCustomer/orgcode=${orgcode}/customerNumber=${customerNumber}/customerName=${encodedCustomerName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setReportData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch report data.");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo
      doc.addImage(img, "PNG", 10, 10, 50, 20);

      // Report title
      doc.setFontSize(16);
      doc.text("Exempted Customer Delivery Report", 10, 35);

      // Company name and address
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      doc.text("Vijay Gas Agencies", doc.internal.pageSize.width - 10, 15, { align: "right" });
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text("1010/27 Mysore Road, Gundlupete - 571111", doc.internal.pageSize.width - 10, 25, { align: "right" });

      const tableData = reportData.map((item) => [
        item.date,
        item.productName,
        item.quantity,
        item.rate,
        item.price,
        item.tariff,
        item.totalAmount,
      ]);

      doc.autoTable({
        startY: 40,
        head: [["Date", "Product Name", "Quantity", "Rate", "Price", "Tariff", "Total"]],
        body: tableData,
        theme: "striped",
        styles: { overflow: "linebreak" },
      });

      doc.save("exempted_customer_delivery_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div>
                    <label htmlFor="customerNumber">Customer Number:</label>
                    <input
                      type="text"
                      id="customerNumber"
                      value={customerNumber}
                      onChange={(e) => setCustomerNumber(e.target.value)}
                      className="form-control"
                      placeholder="Enter customer number"
                    />
                  </div>
                  <br />
                  <div>
                    <label htmlFor="customerName">Customer Name:</label>
                    <input
                      type="text"
                      id="customerName"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className="form-control"
                      placeholder="Enter customer name"
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={getData}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    disabled={loading || !reportData.length}
                    className="btn btn-secondary"
                    onClick={generatePDF}
                  >
                    {loading ? "Generating PDF..." : "Download PDF"}
                  </button>
                  {loading && <div className="loading">Loading...</div>}
                </div>
              </div>
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Price</th>
                        <th>Tariff</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.length > 0 ? (
                        reportData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.productName}</td>
                            <td>{item.quantity}</td>
                            <td>{item.rate}</td>
                            <td>{item.price}</td>
                            <td>{item.tariff}</td>
                            <td>{item.totalAmount}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ExemptedCustomerDeliveryReport;
