import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl"; // Adjust the import path as needed
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import img from "../images/report.png";

function AgentMappingReport() {
  const [reportParams, setReportParams] = useState({
    month: "",
    year: new Date().getFullYear(),
  });

  const [mappingReport, setMappingReport] = useState(null);
  const [truckInfo, setTruckInfo] = useState({});
  const [totalAgents, setTotalAgents] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTruckInfo();
  }, []);

  const fetchTruckInfo = async () => {
    try {
      const response = await axios.get("/api/vehicle");
      const truckData = response.data.reduce((acc, truck) => {
        acc[truck.truckId] = truck.truckDetails;
        return acc;
      }, {});
      setTruckInfo(truckData);
    } catch (error) {
      console.error("Error fetching truck information:", error);
      toast.error("Failed to load truck information. Please check the console for details.");
    }
  };

  const fetchMappingReport = async () => {
    // Check for valid month and year input
    if (!reportParams.month || !reportParams.year || reportParams.month < 1 || reportParams.month > 12) {
      toast.error("Please select a valid month (1-12) and year.");
      return;
    }

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data"))?.token;
      console.log("Token:", token); // Log the token for debugging
      const response = await axios.get(
        `/api/agentMappingReport/${reportParams.month}/${reportParams.year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("API Response:", response.data); // Log the response data for debugging
      const dailyTotals = response.data.agent_daily_totals;
      let total = 0;
      dailyTotals.forEach((dailyTotal) => {
        const agentsCount = Object.values(dailyTotal).filter(
          (value) => value !== "" && value !== "date"
        ).length;
        total += agentsCount;
      });
      setTotalAgents(total);
      setMappingReport(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching agent mapping report:", error);
      if (error.response) {
        console.error("Response data:", error.response.data); // Log the response data for debugging
      }
      toast.error("Failed to load agent mapping report. Please check the console for details.");
      setLoading(false);
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({ ...prevState, [field]: value }));
  };

  const generatePDF = () => {
    if (!mappingReport) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Increased the logo size
      const imgData = img;
      const logoWidth = 70;
      const logoHeight = 40;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10; // Position on the right side
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: 'right' });

      // Adding the report title below the logo and address
      doc.setFontSize(16);
      const reportTitleY = logoY + logoHeight + 20;
      doc.text("Agent Mapping Report (Monthly)", logoX, reportTitleY);

      // Adding month, year, and total agents mapped below the title
      doc.setFontSize(14);
      const detailsY = reportTitleY + 10;
      doc.text(`Month: ${reportParams.month}, Year: ${reportParams.year}`, logoX, detailsY);
      doc.text(`Total Agents Mapped: ${totalAgents}`, logoX, detailsY + 10);

      // Adding the table with agent mapping details
      const tableColumn = ["Date", "Truck Info"];
      const tableRows = mappingReport.agent_daily_totals.map((dailyTotal) => [
        dailyTotal.date,
        truckInfo[dailyTotal.truckinfo] || "N/A",
      ]);

      doc.autoTable(tableColumn, tableRows, { startY: detailsY + 20 });

      doc.save("agent_mapping_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF.");
    }
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br /><br/>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised" style={{ width: "80%", maxWidth: "800px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <h5 className="text-center">Agent Mapping Report</h5>
            <div className="form-group">
              <label htmlFor="month">Month</label>
              <input
                type="number"
                id="month"
                className="form-control"
                value={reportParams.month}
                onChange={(e) => handleInputChange(e, "month")}
                style={{ width: "100px" }} // Reduced width of the input box
              />
            </div>
            <div className="form-group">
              <label htmlFor="year">Year</label>
              <input
                type="number"
                id="year"
                className="form-control"
                value={reportParams.year}
                onChange={(e) => handleInputChange(e, "year")}
                style={{ width: "100px" }} // Reduced width of the input box
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={fetchMappingReport}
              disabled={loading}
            >
              {loading ? "Loading..." : "Download"}
            </button>
            {mappingReport && (
              <div>
                <h6 className="mt-4">Total Agents: {totalAgents}</h6>
                <button className="btn btn-success mt-3" onClick={generatePDF}>
                  Generate PDF
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <div className="loading">Loading...</div>}

      {mappingReport && (
        <div className="d-flex justify-content-center align-items-center mt-4">
          <div className="card card-raised" style={{ width: "80%", maxWidth: "800px" }}>
            <div className="card-body">
              <h5 className="text-center">Agent Mapping Details</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Truck Info</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingReport.agent_daily_totals.map((dailyTotal, index) => (
                    <tr key={index}>
                      <td>{dailyTotal.date}</td>
                      <td>{truckInfo[dailyTotal.truckinfo] || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default AgentMappingReport;
