import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const OfficeFinancialCreate = ({ isOpen, onRequestClose }) => {
  const [time, setTime] = useState(new Date());
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [uproductcost, setUproductcost] = useState({
    date: currentDate,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [
      {
        productcode: "",
        productname: "",
        productType: "",
        salesCost: "",
        additionalCost: "",
        newConCost: "",
      },
    ],
  });

  const [product, setProduct] = useState([]);
  const [officeDetails, setOfficeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = "/api/officefinancial/create";

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const pro = await axios.get(
        `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProduct(pro.data);

      const response = await axios.get(
        `/api/officefinancial/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const fetchedOfficeDetails = response.data;

      const updatedProducts = pro.data.map((item) => {
        const officeDetail = fetchedOfficeDetails.find(
          (officeItem) => officeItem.productcode === item.productcode
        );

        return {
          productcode: item.productcode,
          productname: item.productname,
          productCategoryTypeId: item.productCategory?.productCategoryTypeId,
          salesCost: officeDetail ? officeDetail.salecost : item.pprice?.price,
          additionalCost: officeDetail ? officeDetail.additionalcost : item.addPrice?.additionalPrice,
          newConCost: officeDetail ? officeDetail.newconection : item.newConPrice?.newConPrice,
        };
      });

      setUproductcost({
        ...uproductcost,
        products: updatedProducts,
      });

      setOfficeDetails(fetchedOfficeDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, uproductcost, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false);
        navigate("/users/financialdetails");
        toast.success("Product cost updated successfully");
        onRequestClose();
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  function rhandle(e, field, actualIndex) {
    let actualValue = e.target.value;

    if (["salesCost", "newConCost", "additionalCost", "quantity"].includes(field)) {
      const newProducts = [...uproductcost.products];
      newProducts[actualIndex] = { ...newProducts[actualIndex], [field]: actualValue };
      setUproductcost({ ...uproductcost, products: newProducts });
    } else {
      setUproductcost({ ...uproductcost, [field]: actualValue });
    }
  }

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  }

  const refillProducts = uproductcost.products
    .map((product, index) => ({ ...product, originalIndex: index }))
    .filter((product) => product.productCategoryTypeId === 1);

  const salesProducts = uproductcost.products
    .map((product, index) => ({ ...product, originalIndex: index }))
    .filter((product) => product.productCategoryTypeId === 2);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="xl">
      <Modal.Header closeButton>
        {loading && <div className="loading"></div>}
        <Modal.Title>Update Product Cost</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={rsubmit}>
                <div>
                  <label htmlFor="date">Date</label>
                  <input id="date" value={currentDate} readOnly />
                </div>
                <br />

                {/* Refill Products Table */}
                <h5>Refill Products</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product Code</th>
                      <th>Product Name</th>
                      <th>Sales Cost</th>
                      <th>Additional Cost</th>
                      <th>New Connection Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refillProducts.map((product) => (
                      <tr key={product.productcode}>
                        <td>{product.productcode}</td>
                        <td>{product.productname}</td>
                        <td>
                          <input
                            type="text"
                            placeholder={product.salesCost}
                            style={{ border: "none" }}
                            onChange={(e) => rhandle(e, "salesCost", product.originalIndex)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder={product.additionalCost}
                            style={{ border: "none" }}
                            onChange={(e) => rhandle(e, "additionalCost", product.originalIndex)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder={product.newConCost}
                            style={{ border: "none" }}
                            onChange={(e) => rhandle(e, "newConCost", product.originalIndex)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />

                {/* Sales Products Table */}
                <h5>Sales Products</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product Code</th>
                      <th>Product Name</th>
                      <th>Sales Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesProducts.map((product) => (
                      <tr key={product.productcode}>
                        <td>{product.productcode}</td>
                        <td>{product.productname}</td>
                        <td>
                          <input
                            type="text"
                            placeholder={product.salesCost}
                            style={{ border: "none" }}
                            onChange={(e) => rhandle(e, "salesCost", product.originalIndex)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="form-group d-flex align-items-center justify-content-between mt-5">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={onRequestClose}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};

export default OfficeFinancialCreate;
