import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import "./Spinner.css";
import AddquantityModalPopup from "./Addquantity";
import RemovequantityModalPopup from "./Removequantity";
import { FiInfo } from "react-icons/fi";
import AddHistory from "./AddHistory";
import RemoveHistory from "./Removequantitymodal";
function StockManagement() {
  const [selectedStockId, setSelectedStockId] = useState(null);
  const [selectedStockproduct, setSelectedStockproduct] = useState(null);
  const [selectedStockquantity, setSelectedStockquantity] = useState(null);
  const [isStockEditModalOpen, setIsStockEditModalOpen] = useState(false);
  const [isStockEditModalOpen1, setIsStockEditModalOpen1] = useState(false);
  const [selectedStockproductId, setSelectedStockproductId] = useState(null);
  const [isAddHistoryModalOpen, setIsAddHistoryModalOpen] = useState(false);
  const [addHistory, setAddHistory] = useState([]); 
  const [isRemoveHistoryModalOpen, setIsRemoveHistoryModalOpen] = useState(false);
  const [removeHistory, setRemoveHistory] = useState([]);
  const [stocks, setStocks] = useState([]); 
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0); 
  const [totalPages, setTotalPages] = useState(0);   
  const [pageSize] = useState(10);  
  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      const stockResponse = await axios.get(`/api/vendorproduct`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStocks([...stockResponse.data]);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setStocks([]);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setStocks([]);
    getData();
  };

  const handleStockEdit = (stockId) => {
    setSelectedStockId(stockId);
    setIsStockEditModalOpen(true);
  };

  const closeStockEditModal = () => {
    setIsStockEditModalOpen(false);
    setSelectedStockId(null);
    getData();
  };

  const onClickDeleteHandler = async (e, id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try {
      await axios({
        url: "/api/stock",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleOpenModal = (productname, quantity,id) => {
    setSelectedStockproduct(productname); 

    setSelectedStockquantity(quantity); 
    setSelectedStockproductId(id); 

    setIsStockEditModalOpen(true); 
  };

  const closeModal = () => {
    setIsStockEditModalOpen(false);
    setSelectedStockproduct(null);
    setSelectedStockquantity(null);
    setSelectedStockproductId(null); 
    getData();
  };
 
  const handleOpenModal1 = (productname, quantity ,id) => {
    setSelectedStockproduct(productname); 

    setSelectedStockquantity(quantity); 
    setSelectedStockproductId(id); 

    setIsStockEditModalOpen1(true); 
  };

  const closeModal1 = () => {
    setIsStockEditModalOpen1(false);
    setSelectedStockproduct(null);
    setSelectedStockquantity(null);
    setSelectedStockproductId(null); 
    getData();
  };
  const openAddQuantityModal = async (id) => {
    setSelectedStockproductId(id); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/addquantity/vendorproductid=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAddHistory(response.data);
    } catch (error) {
    //   handleError(error);
    }
    setIsAddHistoryModalOpen(true);
  };
  const closeAddHistoryModal = () => {
    setSelectedStockproductId(null);
    setIsAddHistoryModalOpen(false);
    getData();
  };
  const openRemoveQuantityModal = async (id) => {
    setSelectedStockproductId(id); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/removequantity/vendorproductid=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRemoveHistory(response.data);
    } catch (error) {
    //   handleError(error);
    }
    setIsRemoveHistoryModalOpen(true);
  };
  const closeRemoveHistoryModal = () => {
    setSelectedStockproductId(null);
    setIsRemoveHistoryModalOpen(false);
    getData();
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Stock Adjustment
              <input
                type="text"
                className="form-control"
                style={{
                  width: '200px',
                  display: 'inline-block',
                  paddingLeft: '10px',
                  marginRight: '380px',
                }}
                placeholder="Search Product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button> */}
            </div>

            <br />
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Add Quantity</th>
                    <th>Remove Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {stocks.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    stocks
                    .filter((item) =>
                      item.productname.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((item) => (
                      
                        <tr key={item.id}>
                          <td>{item.productname}</td>
                          <td>{item.quantity}</td>
                          <td>
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <button
      className="btn btn-primary btn-sm"
      onClick={() => handleOpenModal(item.productname, item.quantity, item.id)}
    >
      Add
    </button>
    <span>
      <FiInfo onClick={() => openAddQuantityModal(item.id)} style={{ cursor: 'pointer' }} />
    </span>
  </div>
</td>
                        <td>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleOpenModal1(item.productname,item.quantity,item.id)}
                          >
                            Remove
                          </button>
                          <span>
      <FiInfo onClick={() => openRemoveQuantityModal(item.id)} style={{ cursor: 'pointer' }} />
    </span>
  </div>
                        </td>
                   
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>

            <div>
             
            </div>
          </div>
        </div>
      </div>

      {isStockEditModalOpen && (
              <AddquantityModalPopup
                isOpen={isStockEditModalOpen}
                onRequestClose={closeModal}
                productname={selectedStockproduct}
                quantity={selectedStockquantity}
                vendorproductid={selectedStockproductId}


              />
            )}
             {isStockEditModalOpen1 && (
              <RemovequantityModalPopup
                isOpen={isStockEditModalOpen1}
                onRequestClose={closeModal1}
                productname={selectedStockproduct}
                quantity={selectedStockquantity}
                vendorproductid={selectedStockproductId}


              />
            )}

{isAddHistoryModalOpen && (
              <AddHistory
                onClose={closeAddHistoryModal}
                initialPriceHistory={addHistory}
                vendorproductid={selectedStockproductId}
              />
            )}
            {isRemoveHistoryModalOpen && (
              <RemoveHistory
                onClose={closeRemoveHistoryModal}
                initialPriceHistory={removeHistory}
                vendorproductid={selectedStockproductId}
              />
            )}

    </div>
  );
}

export default StockManagement;
