import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function SQCReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");

  // Fetch products from API
  const fetchProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgCode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    try {
      const response = await axios.get(`/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const productsData = response.data.map((product) => ({
        id: product.id,
        productname: product.productname,
      }));

      setProducts(productsData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  // Fetch data from API
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/sqcreport/orgcode=${orgcode}/productname=${productFilter}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Calculate difference in grams for each item
      const dataWithDifference = response.data.sqc_details.map((item) => ({
        ...item,
        difference_in_grams: item.actual_weight - item.tare_weight,
      }));

      setReportData(dataWithDifference || {});
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
  if (reportData.length === 0) {
    toast.error("No data available to generate PDF.");
    return;
  }

  try {
    const doc = new jsPDF();

    // Load the image asynchronously
    const imgData = new Image();
    imgData.src = img;

    imgData.onload = () => {
      // Add logo to the left side
      const logoWidth = 60;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Add "SQC Report" below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportTextY = logoY + logoHeight + 15; // Increased space below logo
      doc.text("SQC Report", logoX, reportTextY);

      // Add the month below the "SQC Report"
      doc.setFontSize(12);
      const monthTextY = reportTextY + 10; // Increased space below report title
      const month = new Date().toLocaleString('default', { month: 'long' }); // Get the current month name
      doc.text(month, logoX, monthTextY);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10; // Position on the right side
      const textY = logoY + 10; // Adjust Y position as needed
      doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: "right" });

      // Prepare table data
      const tableData = reportData.map((item) => [
        item.product_code,
        item.serial_number,
        item.seal_intact,
        item.date,
        item.gross_weight,
        item.tare_weight,
        item.actual_weight,
        item.difference_in_grams,
      ]);

      // Generate the table in the PDF
      const tableStartY = monthTextY + 15; // Increased space below month text
      doc.autoTable({
        startY: tableStartY,
        head: [
          [
            "Product Code",
            "Serial Number",
            "Seal Intact",
            "Date",
            "Gross Weight",
            "Tare Weight",
            "Actual Weight",
            "Difference (g)",
          ],
        ],
        body: tableData,
        theme: "striped",
        styles: { overflow: "linebreak" },
      });

      // Save the generated PDF
      doc.save("sqc_report.pdf");
    };

    imgData.onerror = () => {
      toast.error("Failed to load logo image.");
    };
  } catch (error) {
    console.error("Error generating PDF: ", error);
    toast.error("Failed to generate report.");
  }
};


  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const handleProductChange = (event) => {
    setProductFilter(event.target.value);
  };

  useEffect(() => {
    fetchProducts(); // Fetch products on component mount
  }, []);

  useEffect(() => {
    if (month && year && productFilter) {
      getData();
    }
  }, [month, year, productFilter]);

  return (
    <div>
      <br/><br/><br/><br/>
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      
      <AdminDashboard/>
      <div className="card card-raised" style={{ width: '600px' }}>
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center">
            <img className="top-bar__logo" src={img} alt="logo" />
            <div className="ml-3">
              <h3 className="report-title">Vijay Gas Agencies</h3>
              <p className="report-address">
                1010/27 Mysore Road, Gundlupete
              </p>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
  <div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
    <label>Product:</label>
    <select
      className="form-control"
      value={productFilter}
      onChange={handleProductChange}
    >
      <option value="">Select Product</option>
      {products.map((product) => (
        <option key={product.id} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
  </div>
  
  <div className="form-group" style={{ flex: 1, marginLeft: '10px' }}>
    <label>Month:</label>
    <DatePicker
      selected={month}
      onChange={handleMonthChange}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      className="form-control"
    />
  </div>
</div>

          <button
            className="btn btn-primary mt-3"
            onClick={getData}
            disabled={loading || !productFilter}
          >
            {loading ? "Loading..." : "Generate Report"}
          </button>
          {reportData.length > 0 && (
            <button
              className="btn btn-success mt-3 ml-2"
              onClick={generatePDF}
            >
              Download PDF
            </button>
          )}
          <br />
          <br />
          {reportData.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Product Code</th>
                    <th>Serial Number</th>
                    <th>Seal Intact</th>
                    <th>Gross Weight</th>
                    <th>Tare Weight</th>
                    <th>Actual Weight</th>
                    <th>Difference (g)</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.product_code}</td>
                      <td>{item.serial_number}</td>
                      <td>{item.seal_intact}</td>
                      <td>{item.gross_weight}</td>
                      <td>{item.tare_weight}</td>
                      <td>{item.actual_weight}</td>
                      <td>{item.difference_in_grams}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    </div>
  );
}

export default SQCReport;
