import React, { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import Search from "./search";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import ExpensesEditModalPopup from "./Expensesmodal";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function UpdateCashPaymentExpenses(props) {
  const [time, setTime] = useState(new Date());
  var curr_hour = time.getHours();
  var curr_min = time.getMinutes();

  var curr_sec = time.getSeconds();

  const text = curr_hour + ":" + curr_min + ":" + curr_sec;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const searchDate = props.params.searchDate;
  const navigate=useNavigate();
  const [selectedVehicle, setSelectedVehicle] = useState(""); 
  const [loading, setLoading] = useState(false);

  const [expenses, setExpenses] = useState({
    date: searchDate,
    amount:"",
    description:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    vehicleid:""
  });

  const [vehicle, setVehicle] = useState([]);
  const [product, setProduct] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const url = "/api/cashpaymentexpenses/create";
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    setLoading(true)
    const vehicles = await axios.get(
      `/api/vehicle/getAllActiveVehicles/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setVehicle(vehicles.data);
    const response  = await axios.get(
      `/api/cashpaymentexpenses/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setProduct([...response.data]);

    setLoading(false);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);


  function rsubmit(e) {
    e.preventDefault();
    if (!selectedVehicle) {
      toast.error("Please select a vehicle");
      return;
    }
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        url,
        expenses,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        setLoading(false);
        toast.success("Expenses added successfully");
        searchName(searchDate);
      })

      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  function selectVehicle(e) {
    const selectedValue = e.target.value;
  
    setSelectedVehicle(selectedValue);
    setExpenses((prevExpenses) => ({
      ...prevExpenses,
      vehicleid: selectedValue,

    }));
  }

  function rhandle(e, field) {
    let actualValue = e.target.value;
      setExpenses({ ...expenses, [field]: actualValue });
    
  }

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);

    setLoading(true);
      axios
      .get( `/api/cashpaymentexpenses/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })

    .then((res) => {
      setProduct(res.data);
      setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      })
      
    }
    const handleEdit = async (id) => {
      setSelectedId(id);
      setIsModalOpen(true);
  
    
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      getData();
    };
    const onClickhandler = async (e, id) => { 
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(id);
      try{
        await axios({
        url: "/api/cashpaymentexpenses",
        method: "delete",
   
        headers: { Authorization: `Bearer ${token}` },
   
        data: {
          id: `${id}`,
       
          orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      setLoading(false); 
      getData();
    } catch (error) {
      if (error.response && error.response.status === 500) {
          const errorMessage = error.response.data.message;
          console.error("Error deleting Expenses:", errorMessage);
          toast.error(errorMessage);
      } else {
          console.error("Error deleting Expenses:", error);
      }
      setLoading(false);
  }
  };
  
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-9">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Expenses Update</div>
            <br />
            {loading && <div className="loading"></div>}

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  <div >
                      <label for="date">Date</label>
                      <input
                        id="date"
                        type="date"
                        value={expenses.date}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                     &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
             
                      <label for="vehicleid">Vehicle</label>
                      <select
                        id="vehicleid"
                        type="text"
                        placeholder="vehicle"
                        value={selectedVehicle}
                        onChange={(e) => selectVehicle(e, "vehicleid")}
                       
                      >
                        <option>Select Vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <br/>
                   
                    

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                    <label htmlFor="amount">Amount</label>

                    <input
                      className="form-control"
                      id="amount"
                      type="text"
                      placeholder="0"
                      value={expenses.amount}
                      onChange={(e) => rhandle(e, "amount")}
                    />
                    </div>
                    </div>

                    <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="description">Description</label>

                    <textarea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    value={expenses.description}
                    onChange={(e) => rhandle(e, "description")}
                    rows="3" // You can adjust the number of rows as needed
                    ></textarea>
                    </div>
                    </div> 
                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <br/>
      <br/>
      {loading && <div className="loading"></div>}

      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Expenses Details
           
           
            <div style={{ position: "relative", right: "580px" ,fontSize:"14px"}}>
        <Search searchName={searchName} />{" "}
      </div>
      </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   {/* <th>Date</th> */}
                   <th>Vehicle</th>
                  <th>Amount</th>
                  <th>Description</th>
                   <th>Action</th>

                </tr>
              </thead>
              <tbody>
              { searchbydate && product.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

            searchbydate &&   product.map((product) => {
                  return (
                    <tr key={product.id}>
                     {/* <td>{product.date}</td> */}
                     <td>{product.vehicleid}</td>

                      <td>{product.amount}</td>
                      <td>{product.description}</td>
                      <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
              <tbody>
              { !searchbydate && product.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

            !searchbydate &&   product.map((product) => {
                  return (
                    <tr key={product.id}>
                     {/* <td>{product.date}</td> */}
                     <td>{product.vehicleid}</td>

                      <td>{product.amount}</td>
                      <td>{product.description}</td>
                      <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                   </td>
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           

          </div>
        </div>{" "}
      </div>
      {isModalOpen && (
              <ExpensesEditModalPopup
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                Id={selectedId}
              />
            )}
    </div>
  );
}

export default withRouter(UpdateCashPaymentExpenses);
