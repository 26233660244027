import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

const AddWarehouse = ({ isOpen, onRequestClose }) => {


  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [uproduct, setUproduct] = useState({
    warehousename: "",
    address: "",
    latitude:"",
    longitude:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/warehouse/create";
  const navigate = useNavigate();
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproduct,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Added successfully");
    onRequestClose()   ;
       
      })

      .catch((error) => {
        
          setLoading(false);
      handleError(error);
      });
    } 
  const rhandle = (e) => {
    const { id, value } = e.target; 
  
    setUproduct((prevUproduct) => ({
      ...prevUproduct,
      [id]: value, 
    }));
  };    

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Warehouse </Modal.Title>
    </Modal.Header>
    <Modal.Body>

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="warehousename">Ware House Name</label>
                        <input
                          className="form-control"
                          id="warehousename"
                          type="text"
                          placeholder="warehousename"
                          value={uproduct.warehousename}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="address">Address</label>
                        <input
                          className="form-control"
                          id="address"
                          type="text"
                          placeholder="address"
                          value={uproduct.address}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="latitude">Latitude</label>
                        <input
                          className="form-control"
                          id="latitude"
                          type="text"
                          placeholder="latitude"
                          value={uproduct.latitude}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="longitude">Longitude</label>
                        <input
                          className="form-control"
                          id="longitude"
                          type="text"
                          placeholder="longitude"
                          value={uproduct.longitude}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    
                   
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                </div>
              </div>
            </div>


          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default AddWarehouse;
