import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import PurchaseEdit from "./PurchaseEdit";
import {
  Card, CardContent, Grid, Typography, TextField, Button,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip, InputAdornment, Pagination
} from '@mui/material';
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEye, AiOutlineShareAlt, AiOutlinePlus } from 'react-icons/ai';
import { MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function PurchaseList() {
  const [purchase, setPurchases] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const navigate = useNavigate();
  const [purchaseid,setPurchaseid] = useState("");
  const [currentPage, setCurrentPage] = useState(1); 
  const itemsPerPage = 5;

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiscalStartMonth = 3;


    const fiscalYearStart = new Date(today.getFullYear(), fiscalStartMonth, 1);
    const fiscalYearEnd = new Date(today.getFullYear() + 1, fiscalStartMonth - 1, 31);


    return today >= fiscalYearStart ? { startYear: currentYear, endYear: currentYear + 1 }
      : { startYear: currentYear - 1, endYear: currentYear };
  };

  const { startYear, endYear } = getCurrentFiscalYear();


  const defaultStartDate = `${startYear}-04-01`;
  const defaultEndDate = `${endYear}-03-31`;

  const [dateRange, setDateRange] = useState({ startDate: defaultStartDate, endDate: defaultEndDate });

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/purchase-order/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedData = response.data
      ? response.data.sort((a, b) => b.id - a.id)
      : [];
      setPurchases(response.data || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen, isEditModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleEditModalOpen = (purchase) => {
    setSelectedPurchase(purchase);
    setIsEditModalOpen(true);
  };

  const handleEditPurchase = (purchase) => {
  
      navigate(`/users/PurchaseEdit/${purchase}`);
   
  };

  const handleViewPurchase = (purchase) => {
   
      navigate(`/users/PurchaseView/${purchase}`);
  };

  const filteredquotation = purchase.filter((purchase) => {
    const quoteNumber = String(purchase.number || "");
    return quoteNumber.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredquotation.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  


  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">

          <Card>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" component="div">Purchase Order</Typography>
                <Grid container item xs={12} justifyContent="flex-end" alignItems="center"> {/* Align everything to the right */}
                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiSearch style={{ fontSize: '16px' }} />
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '16px' }, // Smaller height and font size
                      }}
                      sx={{ width: '150px', height: '30px' }} // Smaller search bar width and height
                    />
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.startDate}
                      onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.endDate}
                      onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                      InputProps={{
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  {/* <Grid item container alignItems="center" spacing={1} sx={{ marginLeft: '8px' }}> Move icons closer to fields */}
                  <Tooltip title="Filter">
                    <IconButton sx={{ padding: '5px' }}>
                      <MdFilterList style={{ fontSize: '20px' }} /> {/* Smaller filter icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineEye style={{ fontSize: '20px' }} /> {/* Smaller eye icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineShareAlt style={{ fontSize: '20px' }} /> {/* Smaller share icon */}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<span style={{ color: 'white' }}>New Purchase</span>} arrow>
                    <NavLink to="/users/Purchases" style={{ textDecoration: 'none' }}>
                      <IconButton
                        sx={{
                          padding: '5px',
                          backgroundColor: 'blue',
                          color: 'white',
                          borderRadius: '4px',
                          width: '120px',
                          height: '40px',
                          '&:hover': { backgroundColor: 'darkblue' }
                        }}
                      >
                        <AiOutlinePlus style={{ fontSize: '15px', marginRight: '5px' }} />
                        <span style={{ fontSize: '12px' }}>New Purchase</span>
                      </IconButton>
                    </NavLink>
                  </Tooltip>

                </Grid>

              </Grid>
              {/* </Grid> */}
              <br />

              {/* Product Table */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Order Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">No Data Available</TableCell>
                    </TableRow>
                  ) : (
                    currentItems.map((purchase) => (
                      <TableRow key={purchase.id}>
                        <TableCell>{purchase.purchaseid}</TableCell>
                        <TableCell>{purchase.orderdate}</TableCell>
                        <TableCell>{purchase.duedate}</TableCell>
                        <TableCell>{purchase.delivarystatus}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEditPurchase(purchase.id)}>
                            <BiEditAlt />
                          </IconButton>
                          <Tooltip title="View">
                            <IconButton sx={{ padding: '5px' }} onClick={() =>handleViewPurchase(purchase.id)}>
                              <AiOutlineEye style={{ fontSize: '25px' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </CardContent>
            {/* Pagination */}
            <Pagination
              count={Math.ceil(filteredquotation.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                '& .MuiPaginationItem-root': {
                  color: 'blue',
                },
                '& .Mui-selected': {
                  backgroundColor: 'blue',
                  color: 'white',
                },
              }}
            />


          </Card>
        </div>
      </div>
    </div >
  );
}

export default PurchaseList;
