import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import ContacteditModalPopup from "./Contacteditmodal";
import Addcontact from "./Addcontact";
import { useNavigate, useParams } from "react-router-dom";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
import AdminDashboard from "./admindashboard";
import StockistcontactpersonViewModal from "./Stockistcontactperson";
import AddVendorProduct from "./Createvendorproduct";
import StockistproducteditModalPopup from "./stoclistprodycredit";
import Stockistmapping from "./Stockistmapping";
import { IoMdAddCircleOutline } from "react-icons/io";
import ViewStockistmapping from "./Viewstockist";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import TransporterContactPersons from "./transporterContactPersons";
import TrucksDetails from "./trucksDetails";

function ViewTransporterProfile() {
    const { transporterId } = useParams();

    const [transporterDetails, setTransporterDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVendorId, setSelectedVendorId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen1, setIsEditModalOpen1] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [activeTab, setActiveTab] = useState('contactPersons');
    

    const getData = async () => {
        setLoading(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
 
          const response = await axios.get(`/api/transporter-details/${transporterId}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
          setTransporterDetails(response.data);   
        } catch (err) {
         setLoading(false);
         handleError(err);
        } finally {
          setLoading(false);
        }
      };

   
    useEffect(() => {
        if (transporterId) {
          getData();
        }
    }, [transporterId]);

    const navigate = useNavigate();
    function handleError(error) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate(`/signin`);
            toast.error("Your session has expired. Please login.");
          } else if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }  else if (error.response.data && error.response.data.code) {
            toast.error(error.response.data.code);
          }
           else {
            toast.error("An error occurred.");
          }
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred.");
        }
    }

    const handleBackButtonClick = () => {
      navigate(-1);
    };
  
    
      return (
        <div>
        <AdminDashboard />
       
        <div className="col-12 ps-xl-10 mt-10">
        <Button variant="secondary mb-3" onClick={handleBackButtonClick}>
    Back
  </Button>
  <div className="card card-raised">
    <div className="card-body">
      <div className="d-flex justify-content-between align-items-center">
        <div className="card-title mb-3">{transporterDetails.transporterName}</div>
      </div>
      <div>
        <div className="mb-2">Email: {transporterDetails.email}</div>
        <div className="mb-2">Phone Number: {transporterDetails.phoneNumber}</div>
        <div className="mb-2">Address: {transporterDetails.address}</div>
      </div>
    </div>
  </div>
</div>


  <br/>
  
  <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised">
            <div className="card-body">
              <Tabs
                id="transporter-tabs"
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
                className="mb-3"
              >
                <Tab eventKey="contactPersons" title="Contact Persons">
                {activeTab === 'contactPersons' && (
                  <TransporterContactPersons selectedTransporterId={transporterId} />
                )}
                </Tab>
                <Tab eventKey="trucks" title="Trucks">
                {activeTab === 'trucks' && (
                  <TrucksDetails selectedTransporterId={transporterId} />
                )}
                </Tab>

              </Tabs>
            </div>
          </div>
        </div>
        </div>
 
    );
}


export default ViewTransporterProfile;
