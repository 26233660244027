import React, { useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust import path as needed
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router";

const AddTruckTransporter = ({ isOpen, onRequestClose }) => {
  const [loading, setLoading] = useState(false);

  const [transporters, setTransporters] = useState({
    transporterName: "",
    email: "",
    phoneNumber: "",
    address: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    contactPersons: [],
  });

  const [newContactPerson, setNewContactPerson] = useState({
    contactPersonName: "",
    phoneNumber: "",
    email: "",
  });

  const [showContactModal, setShowContactModal] = useState(false);

  // Handle changes for input fields
  const handleChange = (e) => {
    const { id, value } = e.target;
    setTransporters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle new contact person input change
  const handleNewContactChange = (e) => {
    const { id, value } = e.target;
    setNewContactPerson((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Add contact person
  const handleAddContactPerson = () => {
    setTransporters((prevState) => ({
      ...prevState,
      contactPersons: [...prevState.contactPersons, newContactPerson],
    }));
    setNewContactPerson({ contactPersonName: "", phoneNumber: "", email: "" });
    setShowContactModal(false);
  };

  // Remove contact person
  const handleDeleteContact = (index) => {
    const updatedContacts = [...transporters.contactPersons];
    updatedContacts.splice(index, 1);
    setTransporters((prevState) => ({
      ...prevState,
      contactPersons: updatedContacts,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/transporterContactPersonDo/create", transporters, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Transporter added successfully");
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  const navigate = useNavigate();

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Transporter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Card className="mb-4">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Transporter Name</Form.Label>
                  <Form.Control
                    id="transporterName"
                    type="text"
                    placeholder="Transporter Name"
                    value={transporters.transporterName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={transporters.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    id="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    value={transporters.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    id="address"
                    type="text"
                    placeholder="Address"
                    value={transporters.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            {/* Contact Person Section */}
            <Card className="mb-4">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <Modal.Title style={{ fontSize: "16px" }}>
                  Contact Person
                </Modal.Title>
                <Button
                  variant="primary"
                  className="btn-sm"
                  onClick={() => setShowContactModal(true)}
                >
                  Add
                </Button>
              </Card.Body>
            </Card>

            {transporters.contactPersons.length > 0 && (
              <Card className="mb-4">
                <Card.Body>
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transporters.contactPersons.map((person, index) => (
                        <tr key={index}>
                          <td>{person.contactPersonName}</td>
                          <td>{person.phoneNumber}</td>
                          <td>{person.email}</td>
                          <td>
                            <Button
                              size="sm"
                              onClick={() => handleDeleteContact(index)}
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                            >
                              <MdDeleteForever style={{ color: "black", fontSize: "21px" }} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            )}
            <Button variant="secondary me-3" onClick={onRequestClose}>
            Close
          </Button>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Save"}
            </Button>
          </Form>
        </Modal.Body>
      
      </Modal>

      {/* Contact Modal */}
      <Modal show={showContactModal} onHide={() => setShowContactModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Contact Person Name</Form.Label>
            <Form.Control
              id="contactPersonName"
              type="text"
              value={newContactPerson.contactPersonName}
              onChange={handleNewContactChange}
              placeholder="Enter Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              id="phoneNumber"
              type="text"
              value={newContactPerson.phoneNumber}
              onChange={handleNewContactChange}
              placeholder="Enter Phone Number"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label> Email</Form.Label>
            <Form.Control
              id="email"
              type="email"
              value={newContactPerson.email}
              onChange={handleNewContactChange}
              placeholder="Enter Email"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary me-3" onClick={() => setShowContactModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddContactPerson}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTruckTransporter;
