import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";
import ConsumableModalOpen from "./consumable";
import RefillOfficeSalesModalOpen from "./refillOfficeSalesModal";


const Createofficesale = ({ isOpen, onRequestClose, date }) => {
  const [productTypes, setProductTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);

  const [selectedProductType, setSelectedProductType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newProduct, setNewProduct] = useState({
    productType: ""
  });
  useEffect(() => {
    fetchProductTypes(); // Fetch product types when component mounts
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/productcategorytype", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProductTypes(response.data);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleProductTypeClick = (productTypeId) => {
    setSelectedProductType(productTypeId);
    if(productTypeId===1){
    setIsRefillModalOpen(true);
    }else{
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    if(selectedProductType===1){
    setIsRefillModalOpen(false);
    }else{
    setIsModalOpen(false);
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Office Sale</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <br />
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product Category Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productTypes.map((type) => (
                      <tr key={type.id}>
<td>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => handleProductTypeClick(type.id)}
                        >
                          {type.categorytype}
                        </button>
                      </td>                      </tr>
                    ))}
                  </tbody>
                </table>
              
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>


          {isModalOpen && (
          <ConsumableModalOpen isOpen={isModalOpen} onRequestClose={handleCloseModal} productTypeId={selectedProductType} 
          date={date}
          />
          )}

{isRefillModalOpen && (
          <RefillOfficeSalesModalOpen isOpen={isRefillModalOpen} onRequestClose={handleCloseModal}
          date={date}
          />
          )}
         
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );


};

export default Createofficesale;
