import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import ContacteditModalPopup from "./Contacteditmodal";
import Addcontact from "./Addcontact";
function VendorViewModal({ isOpen, onRequestClose, vendorId }) {
  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchVendorData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/vendorcontactperson/vendorid=${vendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVendor(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Error fetching vendor details");
    }
  };

  useEffect(() => {
    if (vendorId) {
      fetchVendorData();
    }
  }, [vendorId]);
  const handleEdit = async (vendorId) => {
    setSelectedVendorId(vendorId);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchVendorData();
 
  };
  const handleAdd = async () => {
    setSelectedVendorId(vendorId);
 
      setIsModalOpen(true);
   
  };
  const closeModal = () => {
    setIsModalOpen(false);
    fetchVendorData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/vendorcontactperson",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    fetchVendorData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
const filteredVendors = vendor.filter((vendor) =>
  vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
);
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      <Modal.Title style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <div>
    Contact Person Details
    <input
      type="text"
      className="form-control"
      style={{
        width: '200px',
        display: 'inline-block',
        paddingLeft: '10px',
        marginLeft: '20px',
      }}
      placeholder="Search name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
  <button
    className="btn btn-primary"
    onClick={handleAdd}
    style={{
      fontSize: '12px', // Reduce font size
      padding: '5px 10px', // Adjust padding
      left:"210px"
    }}
  >
    ADD
  </button>
</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
          <div>
           
           <table className="table">

          <thead >
                <tr
                 
                >
                  <th style={{ padding: '0.5rem' }}>Name</th>
                  <th style={{ padding: '0.5rem' }}>Email</th>
                  <th style={{ padding: '0.5rem' }}>Phone</th>
                  <th style={{ padding: '0.5rem' }}>Action</th>

                </tr>
              </thead>
              <tbody>
              {filteredVendors.length > 0 ? (
                filteredVendors.map((vendor, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #ddd', backgroundColor: '#fff' }}>
                    <td style={{ padding: '0.5rem' }}>{vendor.name}</td>
                    <td style={{ padding: '0.5rem' }}>{vendor.email}</td>
                    <td style={{ padding: '0.5rem' }}>{vendor.phone}</td>
                    <td> 
                     <button
                      onClick={() => handleEdit(vendor.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "10px",
                            fontSize: "24px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              vendor.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center" style={{ padding: '0.5rem' }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
            </table>
            {isEditModalOpen && (
              <ContacteditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                vendorId={selectedVendorId}
                />
            )}
            {isModalOpen && (
              <Addcontact
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                vendorId={selectedVendorId}
                />
            )}
          </div>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VendorViewModal;
