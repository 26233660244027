import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import { Select, MenuItem } from "@mui/material";
import { Card, CardContent, Grid, Typography, Tooltip, Table, IconButton, TextField, Button, DialogContent, Box, TableCell, TableBody, TableHead, TableContainer, Paper, TableRow, Dialog } from '@mui/material';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import PurchaseModal from "./PurchaseModal";
 
function Purchases() {
  const [purchases, setPurchases] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const userid = JSON.parse(localStorage.getItem("data"))?.userid;
  const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;
  const [orgAddress, setOrgAddress] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedVendorAndStockist, setSelectedVendorAndStockist] = useState(null);
  const [productList, setProductList] = useState([]);
  const [vendorProductId, SetVendorProductId] = useState([])
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);
  const [billTo, setBillTo] = useState("");
  const [shipTo, setShipTo] = useState("");
  const [orderDate, setOrderDate] = useState(currentDate);
  const [receiveDate, setReceiveDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [memo, setMemo] = useState("");
  const [description, setDescription] = useState("");
  const [stockist, setStockist] = useState([]);
  const [customFields, setCustomFields] = useState(() => {
    const savedData = localStorage.getItem('cutomFields');
    return savedData ? JSON.parse(savedData) : [];
  });
 
 
  useEffect(() => {
    const fetchPurchaseData = async () => {
      setLoader(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/purchase-order/orgcode=${orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPurchases(response.data || []);
      } catch (error) {
        handleError(error);
      } finally {
        setLoader(false);
      }
    };
 
    fetchPurchaseData();
  }, [orgcode]);
 
  const fetchOrgAddress = async (userid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
 
      const { addressLine1, addressLine2, name, state, city, pincode, gstin, pan, contact } = response.data;
      const fullAddress = `${addressLine1 || ''} ${addressLine2 || ''} ${name || ''} ${state || ''} ${pincode || ''} ${gstin || ''} ${pan || ''}${contact || ''}`.trim();
      setOrgAddress({
        addressLine1,
        addressLine2,
        name,
        city,
        state,
        pincode,
        gstin,
        pan,
        gstin,
        contact
      });
    } catch (error) {
      handleError(error);
    }
  };
  const handleErrors = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
  };
 
  useEffect(() => {
    if (userid) {
      fetchOrgAddress(orgAddress);
    }
  }, [userid]);
 
  useEffect(() => {
    setShipTo(orgAddress);
    setBillTo(orgAddress);
  }, [orgAddress]);
  
 
 
 
  const handleOpenContactModal = () => setOpenContactModal(true);
  const handleCloseContactModal = () => setOpenContactModal(false);
 
  const createPurchase = async (purchaseData) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/purchaseOrderDo/create`, purchaseData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Purchase order created successfully!");
      navigate('/users/PurchaseList');
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };
 
  const handleSaveCustomFields = (fields) => {
    setCustomFields((prevFields) => [...(prevFields || []), fields]);
    setOpenCustomFieldModal(false);
  };
 
  const handleSaveVendorAndStockist = (data) => {
    clearAllItems(); 
    setSelectedVendorAndStockist({
      selectedVendor: data.selectedVendor,
      selectedStockistVendorid: data.selectedStockistVendorid,
      selectedStockistId: data.selectedStockistId,
      selectedStockistName: data.selectedStockistName,
      selectedStockistAddress: data.selectedStockistAddress,
      selectedStockistEmail: data.selectedStockistEmail,
      selectedStockistPhone: data.selectedStockistPhone
    });
 
    if (data.selectedStockistId) {
      fetchProducts(data.selectedStockistId);
    }
 
    setOpenContactModal(false);
  
  };
  const fetchProducts = async (stockistId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`api/stockistmapping/stockistid=${stockistId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const products = response.data || [];
      setProductList(products); 
  
      if (products.length > 0 && products[0]?.vendorproduct?.vendorid !== undefined) {
        const vendorId = response.data[0]?.vendorproduct?.vendorid || null;
        SetVendorProductId(vendorId); 
        console.log("Vendor ID:", vendorId);
      } else {
        console.warn("Vendor ID not found in response data.");
      }
    } catch (error) {
      toast.error("Error fetching products");
      setProductList([]); 
    }
  };
  
 
 
  const handleSavePurchases = () => {
    if (!dueDate) {
      toast.error("Due date is required. Please provide a valid due date.");
      return;
    }
    const billToAddress = orgAddress ? `${orgAddress.name}, ${orgAddress.addressLine1}, ${orgAddress.addressLine2}, ${orgAddress.city}, ${orgAddress.state}, ${orgAddress.pincode}, GST: ${orgAddress.gstin}, PAN: ${orgAddress.pan}, Contact: ${orgAddress.contact}` : "N/A";
    const shipToAddress = orgAddress ? `${orgAddress.name}, ${orgAddress.addressLine1}, ${orgAddress.addressLine2}, ${orgAddress.city}, ${orgAddress.state}, ${orgAddress.pincode}, GST: ${orgAddress.gstin}, PAN: ${orgAddress.pan}, Contact: ${orgAddress.contact}` : "N/A";
 
    const purchaseData = {
      orgcode,
      vendorId: vendorProductId,
      stockistId: selectedVendorAndStockist?.selectedStockistId || "",
      purchasedate: currentDate,
      billTo: billToAddress,
      shipTo: shipToAddress,
      orderdate: orderDate,
      duedate: dueDate,
      receiveDate: receiveDate || "",
      memo,
      description,
      purchasedProducts: purchases.map((purchase) => ({
        id:purchase.id,
        vendorProductId: purchase.vendorProductId,
        quantity: purchase.quantity || 1,
      })),
    };
 
    createPurchase(purchaseData);
  };
 
  const handleAddPurchaseRow = () => {
    setPurchases([...purchases, { vendorProductId: "", description: "", quantity: 1 }]);
  };
 
  const handleProductChange = (index, event) => {
    const newPurchases = [...purchases];
    newPurchases[index].vendorProductId = event.target.value;
    setPurchases(newPurchases);
  };
 
  const handleFieldValueChange = (index, fieldKey, value) => {
    const updatedPurchases = [...purchases];
    updatedPurchases[index][fieldKey] = value;
    setPurchases(updatedPurchases);
  };
 
  const handleDeleteRow = (index) => {
    setPurchases((prevPurchases) => prevPurchases.filter((_, i) => i !== index));
  };
 
  const handleBackClick = () => {
    navigate(-1);
  };
 
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  const clearAllItems = () => {
    setPurchases([]);
    localStorage.removeItem('productData');
  };
 
  const handleCancel = () => {
    navigate(-1)
  }
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Card>
          <CardContent>
            <Tooltip title="Back" arrow>
              <IconButton onClick={handleBackClick}>
                <AiOutlineArrowLeft style={{ fontSize: '25px' }} />
              </IconButton>
            </Tooltip>
 
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Purchases Order</Typography>
            </Grid>
            <br />
 
            <div>
             
              <p><strong>Stockist ID:</strong> {selectedVendorAndStockist?.selectedStockistId || "Not Selected"}</p>
              <p><strong>Stockist:</strong> {selectedVendorAndStockist?.selectedStockistName || "Not Selected"}</p>
              <p><strong>Address:</strong> {selectedVendorAndStockist?.selectedStockistAddress || "Not Selected"}</p>
              <p><strong>Email:</strong> {selectedVendorAndStockist?.selectedStockistEmail || "Not Selected"}</p>
              <p><strong>Phone:</strong> {selectedVendorAndStockist?.selectedStockistPhone || "Not Selected"}</p>
 
              <Button variant="contained" color="primary" onClick={handleOpenContactModal}>
                + Add a Stockist
              </Button>
 
              <PurchaseModal
                open={openContactModal}
                onClose={handleCloseContactModal}
                onSave={handleSaveVendorAndStockist}
              />
            </div>
            <br />
 
            <Grid container spacing={3}>
 
              {/* Bill To Section */}
              <Grid item xs={6}>
                <Typography variant="h6">Bill To</Typography>
                <Typography variant="body1">
                  <strong>Organization Name: </strong>{orgAddress?.name || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Address Line 1: </strong>{orgAddress?.addressLine1 || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Address Line 2: </strong>{orgAddress?.addressLine2 || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>City: </strong>{orgAddress?.city || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Pincode: </strong>{orgAddress?.pincode || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>State: </strong>{orgAddress?.state || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>PAN: </strong>{orgAddress?.pan || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>GST: </strong>{orgAddress?.gstin || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Contact Number: </strong>{orgAddress?.contact || "Not Available"}
                </Typography>
              </Grid>
 
              <br />
              <Grid item xs={6}>
                <Typography variant="h6">Ship To</Typography>
                <Typography variant="body1">
                  <strong>Organization Name: </strong>{orgAddress?.name || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Address Line 1: </strong>{orgAddress?.addressLine1 || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Address Line 2: </strong>{orgAddress?.addressLine2 || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>City: </strong>{orgAddress?.city || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Pincode: </strong>{orgAddress?.pincode || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>State: </strong>{orgAddress?.state || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>PAN: </strong>{orgAddress?.pan || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>GST: </strong>{orgAddress?.gstin || "Not Available"}
                </Typography>
                <Typography variant="body1">
                  <strong>Contact Number: </strong>{orgAddress?.contact || "Not Available"}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
 
              <Grid item xs={12}>
                <Grid container spacing={2} direction="row">
 
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Order Date"
                      type="date"
                      value={orderDate}
                      onChange={(e) => setOrderDate(e.target.value)}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
 
 
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Due Date"
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
 
 
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Receive Date"
                      type="date"
                      value={receiveDate}
                      onChange={(e) => setReceiveDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
 
 
            <br />
            <Button variant="contained" color="primary" onClick={handleAddPurchaseRow} disabled={!selectedVendorAndStockist}>
              + Add Row
            </Button>
            <br />
            {selectedVendorAndStockist ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchases.map((purchase, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Select
                            value={purchase.vendorProductId}
                            onChange={(e) => handleProductChange(index, e)}
                            displayEmpty
                            fullWidth
                          >
                            <MenuItem value="" disabled>Select Product</MenuItem>
                            {productList.map((product) => (
                              <MenuItem key={product.id} value={product.productid}>
                                {product.vendorproduct.productname}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            value={purchase.description}
                            onChange={(e) => handleFieldValueChange(index, "description", e.target.value)}
                            required
                          />
                        </TableCell>
 
                        <TableCell>
                          <TextField
                            type="number"
                            value={purchase.quantity}
                            onChange={(e) => handleFieldValueChange(index, "quantity", e.target.value)}
                            required
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteRow(index)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" color="textSecondary">
                Please select a stockist to add Product rows.
              </Typography>
            )}
            <br />
            <button className="btn btn-link" onClick={clearAllItems}> Clear All Items</button>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Memo"
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Add a memo for this purchase"
 
                />
              </Grid>
            </Grid>
 
 
            <br />
            <br />
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Grid >
                <Button variant="contained" color="primary" onClick={handleSavePurchases}>
                  Save
                </Button>
              </Grid>
            </Grid>
 
            {/* <Button variant="contained" color="secondary" onClick={() => setOpenCustomFieldModal(true)}>
              + Add Custom Field
            </Button> */}
 
            {/* <Dialog open={openCustomFieldModal} onClose={() => setOpenCustomFieldModal(false)}>
              <DialogContent>
                <Addcustomfieldspurchase onSave={handleSaveCustomFields} onClose={() => setOpenCustomFieldModal(false)} />
              </DialogContent>
            </Dialog> */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
 
export default Purchases;
 
 