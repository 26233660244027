import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust import path as needed
import { useNavigate } from "react-router";

const AddTransporterContactPerson = ({ isOpen, onRequestClose, transporterId, selectedContact }) => {

  const [loading, setLoading] = useState(false);
  const [contactPersons, setContactPersons] = useState({
    id: "",
    contactPersonName: "",
    phoneNumber: "",
    email: "",
    transporterId: transporterId,
  });

  const navigate = useNavigate();

  // Handle changes for input fields
  const handleChange = (e) => {
    const { id, value } = e.target;
    setContactPersons((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle form submit for create or update
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      if (selectedContact) {
        // If selectedContact exists, trigger the update API
        await axios.post(`/api/transporter-contact-person/update`, contactPersons, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Contact person updated successfully");
      } else {
        // If no selectedContact, trigger the create API
        await axios.post("/api/transporter-contact-person/create", contactPersons, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Contact person added successfully");
      }
      setLoading(false);
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  // Error handler
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  // Populate form if selectedContact exists
  useEffect(() => {
    if (selectedContact) {
      setContactPersons(selectedContact);
    }
  }, [selectedContact]);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedContact ? "Edit Contact Person" : "Add Contact Person"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            id="contactPersonName"
            type="text"
            value={contactPersons.contactPersonName}
            onChange={handleChange}
            placeholder="Enter Name"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            id="phoneNumber"
            type="text"
            value={contactPersons.phoneNumber}
            onChange={handleChange}
            placeholder="Enter Phone Number"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            id="email"
            type="email"
            value={contactPersons.email}
            onChange={handleChange}
            placeholder="Enter Email"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary me-3" onClick={onRequestClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {selectedContact ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTransporterContactPerson;
