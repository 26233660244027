import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

const Addstockistproduct = ({ isOpen, onRequestClose ,vendorId}) => {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [uproduct, setUproduct] = useState({
    vendorid:vendorId,
    productname: "",
    sku: "",
    hsncode: "",
    baseprice:"",
    gstrate:"",
    date:currentDate,
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [gstOptions, setGstOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const url = "/api/vendorproduct/create";
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      fetchGstOptions(); // Fetch GST options when modal is opened
    }
  }, [isOpen]);

  const fetchGstOptions = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get('/api/tax', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGstOptions(response.data); 
    } catch (error) {
      console.error("Error fetching GST options", error);
      toast.error("Failed to load GST options");
    }
  };
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproduct,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Added successfully");
    onRequestClose()   ;
       
      })

      .catch((error) => {
        
          setLoading(false);
      handleError(error);
      });
    } 
  const rhandle = (e) => {
    const { id, value } = e.target; 
  
    setUproduct((prevUproduct) => ({
      ...prevUproduct,
      [id]: value, 
    }));
  };    

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Products</Modal.Title>
    </Modal.Header>
    <Modal.Body>

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="productname">Product Name</label>
                        <input
                          className="form-control"
                          id="productname"
                          type="text"
                          placeholder="productname"
                          value={uproduct.productname}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="sku">SKU</label>
                        <input
                          className="form-control"
                          id="sku"
                          type="text"
                          placeholder="sku"
                          value={uproduct.sku}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="hsncode">HSN Code</label>
                        <input
                          className="form-control"
                          id="hsncode"
                          type="text"
                          placeholder="hsncode"
                          value={uproduct.hsncode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="baseprice">Base price</label>
                        <input
                          className="form-control"
                          id="baseprice"
                          type="text"
                          placeholder="baseprice"
                          value={uproduct.baseprice}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="gstrate">GST </label>
                        <select
                          className="form-control"
                          id="gstrate"
                          type="text"
                          placeholder="gstrate"
                          value={uproduct.gstrate}
                          onChange={(e) => rhandle(e)}
                        >
                          <option value="">Select GST</option>
                      {gstOptions.map((gst) => (
                        <option key={gst.id} value={gst.tax}>
                          {gst.tax}%
                        </option>
                      ))}
                    </select>
                      </div>
                    </div>
                   
                    
                   
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                </div>
              </div>
            </div>


          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Addstockistproduct;
