import React, { useEffect, useState } from "react";
import { Modal, Button, Pagination } from 'react-bootstrap';
import axios from "../api/baseurl";

const AddHistory = ({ onClose, vendorproductid }) => {
  const [localPriceHistory, setLocalPriceHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);  // Default page size
  const [totalPages, setTotalPages] = useState(1);

  const token = JSON.parse(localStorage.getItem("data")).token;

  // Fetch data based on page number
  const fetchPriceHistory = async (page) => {
    try {
      const response = await axios.get(`/api/addquantity/vendorproductid=${vendorproductid}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, size: pageSize }
      });
      setLocalPriceHistory(response.data.content);
      setCurrentPage(response.data.number);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchPriceHistory when the component mounts or page changes
  useEffect(() => {
    fetchPriceHistory(currentPage);
  }, [currentPage]);

  // Pagination rendering logic
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);  // Start 2 pages before the current page
    let endPage = Math.min(totalPages - 1, currentPage + 2); // End 2 pages after the current page

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  // Pagination controls
  const handleFirst = () => setCurrentPage(0);
  const handleLast = () => setCurrentPage(totalPages - 1);
  const handlePrevious = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Quantity History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Add Quantity</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(localPriceHistory) && localPriceHistory.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.date}</td>
                <td>{entry.addquantity}</td>
                <td>{entry.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {/* Pagination controls */}
        <Pagination style={{ justifyContent: 'center', flexGrow: 1 }}>
          {/* First Page */}
          <Pagination.First onClick={handleFirst} disabled={currentPage === 0} />

          {/* Previous Page */}
          <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 0} />

          {/* Page Numbers */}
          {renderPageNumbers()}

          {/* Next Page */}
          <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages - 1} />

          {/* Last Page */}
          <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages - 1} />
        </Pagination>
        
        {/* Close Button */}
        <Button variant="secondary" onClick={onClose} style={{ marginLeft: '10px' }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddHistory;
