import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineDelete } from "react-icons/ai";
import { IconButton, Tooltip, Button } from "@mui/material";
import AdminDashboard from "./admindashboard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from "react-toastify";
import "./ViewQuotation.css";

function ViewInvoice(){
        const { id } = useParams();
        const navigate = useNavigate();
        const [invoiceDetails, setInvoiceDetails] = useState({});
        const [consumerdata, setConsumerdata] = useState(null);
        const [productData, setProductData] = useState([]);
        const [files, setFiles] = useState([]);
        const [loading, setLoading] = useState(false);
        const [showTaxDetails, setShowTaxDetails] = useState(false);
        const [orgprofile, setOrgprofile] = useState([]);
        const orgcodes = JSON.parse(localStorage.getItem("data")).orgcode;
    
    
    
        useEffect(() => {
            if (id) {
                fetchQuotationData(id);
            }
    
        }, [id]);
    
        useEffect(() => {
            if (invoiceDetails?.consumername) {
                fetchConsumerData(invoiceDetails.consumername);
            }
            fetchOrganization();
        }, [invoiceDetails]);
    
        const fetchQuotationData = async (id) => {
            setLoading(true);
            try {
                const token = JSON.parse(localStorage.getItem("data")).token;
                const response = await axios.get(`/api/invoice/id=${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.data) {
                    setInvoiceDetails(response.data);
                    setProductData(response.data.invoicedetails || []); // Extract quotes data and set it to productData state
                    setFiles(response.data.files || []); // Extract quotes data and set it to productData state
    
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                handleError(error);
            }
        };
    
        const fetchOrganization = async () => {
            setLoading(true);
    
            try {
                const token = JSON.parse(localStorage.getItem("data")).token;
                const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcodes}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setOrgprofile(response.data);
                console.log(response.data);
    
            } catch (error) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        };
    
        const fetchConsumerData = async () => {
            setLoading(true);
            const Consumername = invoiceDetails.consumername;
            console.log(Consumername)
            try {
                const formattedConsumername = Consumername.replace(/\//g, '-');
                const token = JSON.parse(localStorage.getItem("data")).token;
                const response = await axios.get(`/api/consumerdetails/consumername=${formattedConsumername}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.data && response.data.length > 0) {
                    setConsumerdata(response.data[0]);
                } else {
                    setConsumerdata(null);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                handleError(error);
            }
        };
    
    
        const handleFileClick = (filedata) => {
            // Function to handle file click and display or download the file
            const fileUrl = `data:application/octet-stream;base64,${filedata}`;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'attachment'; // You can change the filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
    
        const calculateSubtotal = () => {
            return productData.reduce((sum, product) => {
                const quantity = parseFloat(product.quantity) || 0;
                const price = parseFloat(product.price) || 0;
                return sum + (quantity * price);
            }, 0).toFixed(2);
        };
    
        const calculateSubtotalAfterDiscount = () => {
            const subtotal = parseFloat(calculateSubtotal());
            const discountAmount = invoiceDetails.discount;
            const subtotalAfterDiscount = subtotal - discountAmount;
            return subtotalAfterDiscount.toFixed(2);
        };
    
        const toggleTaxDetails = () => {
            setShowTaxDetails(!showTaxDetails);
        };
    
    
        const TotalTaxAmount = (productData) => {
            return productData.reduce((sum, product) => {
                const tax = parseFloat(product.tax);
                const Beforeamount = parseFloat(product.beforetax)
                const totaltax = (tax * Beforeamount) / 100;
    
                return sum + totaltax;
            }, 0).toFixed(2);
        };
    
    
    
    
        const calculateRoundingOff = () => {
            // Calculate the total before rounding
            const totalBeforeRounding = parseFloat(calculateSubtotalAfterDiscount()) + parseFloat(TotalTaxAmount(productData));
            const decimalPart = totalBeforeRounding - Math.floor(totalBeforeRounding);
    
            // Determine if we need to round up or down
            let roundOffAmount;
            if (decimalPart > 0.5) {
                roundOffAmount = Math.ceil(totalBeforeRounding) - totalBeforeRounding;
            } else {
                roundOffAmount = Math.floor(totalBeforeRounding) - totalBeforeRounding;
            }
    
    
            return {
                roundedTotal: totalBeforeRounding + roundOffAmount,
                roundOff: roundOffAmount.toFixed(2)
            };
        };
        const resultValue = calculateRoundingOff();
    
        const handleError = (error) => {
            if (error.response && error.response.status === 404) {
                toast.error(error.response.data.message);
            } else {
                toast.error("An error occurred while fetching the data");
            }
        };
    
        const handleBackClick = () => {
            navigate(-1);
        };
    
        const handlePrint = () => {
            window.print();
        };
    
        return (
            <div>
                <AdminDashboard />
                <br />
                <br />
                <br />
                <br />
                {loading && <div className="loading"></div>}
    
                <div className="col-12 mx-xl-auto ps-xl-10">
                    <div
                        style={{
                            width: '100%',
                            height: '50px',
                            backgroundColor: 'lightgray',
                            marginBottom: '20px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <h4 style={{ marginLeft: '50px' }}>Invoice</h4>
                        </div>
                    </div>
    
                    <Tooltip title="Back" arrow>
                        <IconButton
                            onClick={handleBackClick}
                            className="no-print"
                            sx={{
                                padding: '2px',
                                backgroundColor: 'darkgray',
                                color: 'blue',
                                borderRadius: '10px',
                                width: "60px",
                                height: "30px",
                                left: '3%',
                                '&:hover': { backgroundColor: 'darkgray' }
                            }}
                        >
                            <AiOutlineArrowLeft style={{ fontSize: '25px' }} />
                        </IconButton>
                    </Tooltip>
    
                    <div className="container" style={{ padding: '20px' }}>
                        <div className="container" style={{ padding: '20px' }}>
                            <div className="row align-items-center">
                                {/* Organization Details Section */}
                                <div className="col-md-8">
                                    <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{orgprofile?.name}</p>
                                    <p style={{ marginBottom: '5px' }}>{`${orgprofile?.addressLine1}, ${orgprofile?.addressLine2}`}</p>
                                    <p style={{ marginBottom: '5px' }}>{orgprofile?.pincode}</p>
                                    <p style={{ marginBottom: '5px' }}>{orgprofile?.contact}</p>
                                    <p style={{ marginBottom: '0' }}>GSTIN : {orgprofile?.gstin}</p>
                                </div>
    
                                {/* Logo Section */}
                                <div className="col-md-4 text-end">
                                    <img
                                        className="img-fluid mb-1"
                                        id="image"
                                        src={`${orgprofile?.imageMeta},${orgprofile?.logo}`}
                                        alt="Organization Logo"
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            background: "#ccc",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                    {/* Consumer Details Section */}
                    <div style={{ padding: '20px' }}> {/* Reduced padding */}
                        <div className="row">
                            <h5>To</h5>
                            {consumerdata ? (
                                <div className="col-md-6">
                                    <p style={{ marginBottom: '5px' }}>{consumerdata.consumername}</p>
                                    <p style={{ marginBottom: '5px' }}>{`${consumerdata.address1}, ${consumerdata.address2}`}</p>
                                    <p style={{ marginBottom: '5px' }}>{consumerdata.address3}</p>
                                    <p style={{ marginBottom: '5px' }}>{consumerdata.phone}</p>
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
    
    
                            {/* Quotation Details Section */}
                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                                <div style={{ marginLeft: '10px' }}>
                                    <p style={{ marginBottom: '0' }}>#No : {invoiceDetails?.invoiceid}</p>
                                    <div>  <span style={{ fontWeight: 'bold' }}>Invoice Date </span>:  {invoiceDetails?.quotesdate || ''} </div>
                                    <div>  <span style={{ fontWeight: 'bold' }}>  Due Date </span>: {invoiceDetails?.duedate || ''}</div>
                                    <div>  <span style={{ fontWeight: 'bold' }}>Ship By (expected) </span>: {invoiceDetails?.shibydate || ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                    <div className="col-12 mb-3" style={{ padding: '20px' }}>
                        <div className="card-body">
                            <table className="table" >
                                <thead>
                                    <th style={{ width: "10%" }}>Product</th>
                                    <th style={{ width: "10%" }}>Product Code</th>
                                    <th style={{ width: "10%" }}>Description</th>
                                    <th style={{ width: "2%" }}>Quantity</th>
                                    <th style={{ width: "4%" }}>Unit</th>
                                    <th style={{ width: "5%" }}>Unit Price</th>
                                    <th style={{ width: "5%" }}>Before tax</th>
                                    <th style={{ width: "10%" }}>Tax</th>
                                    <th style={{ width: "8%" }}>Amount</th>
    
                                </thead>
                                <tbody>
                                    {productData.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.product}</td>
                                            <td>{product.productcode}</td>
                                            <td>{product.description}</td>
                                            <td>{product.quantity}</td>
                                            <td>{product.uom}</td>
                                            <td>{(product.price).toFixed(2)}</td>
                                            <td>{(product.beforetax).toFixed(2)}</td>
                                            <td>
                                                <div>
                                                    Tax: {product.tax}% , ₹{(product.tax * product.beforetax) / 100}
                                                </div>
                                                <div>
                                                    CGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                                </div>
                                                <div>
                                                    SGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                                </div>
                                            </td>
                                            <td>{(product.amount).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* </div> */}
                        </div>
                    </div>
    
    
    
                    <div className="col-md-6 mb-2" style={{ padding: '20px' }}>
                        <textarea
                            className={`form-control`}
                            value={invoiceDetails.memo || ''}
                            readOnly
                        />
    
                    </div>
    
                    {/* <div style={{ padding: '20px' }}>
                        <h5>Attached Files</h5>
                        {files.length > 0 ? (
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>
                                        <a
                                            // href="#/users/ViewQuotation"
                                            onClick={() => handleFileClick(file.filedata)} // Handle file click
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            View File {index + 1}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No attached files</p>
                        )}
                    </div> */}
    
    
    
    
    
    
                    <div className="col-12 col-md-6 offset-md-7">
    
    
    
                        <div className="row">
                            <div className="col-md-7 d-flex justify-content-end align-items-center" >
                                <label>Subtotal :  ₹ {calculateSubtotal()}</label>
    
                            </div>
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center">
                                <label> Discount:₹ {invoiceDetails.discount}</label>
                            </div>
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center">
                                <label> Before Tax:  ₹  {calculateSubtotalAfterDiscount()}</label>
                            </div>
    
    
                            <div className="col-md-6 d-flex justify-content-end align-items-center" >
                                <label> Tax</label>
    
                                <Tooltip title="Show tax breakdown">
                                    <IconButton onClick={toggleTaxDetails} style={{ marginLeft: '10px' }}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>:
                            
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center"  style={{ marginLeft: '0px' }}>
                                <label>  ₹ {TotalTaxAmount(productData)} </label>
                            </div>
                            </div>
    
                            {showTaxDetails && (
                                <div className="col-12 mt-2">
                                    <div style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                                        <h6>Tax Breakdown:</h6>
                                        <div>CGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</div>
                                        <div>SGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</div>
                                    </div>
                                </div>
                            )}
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center">TCS: ₹ 0.00</div>
    
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center">Rounding Off:
                                ₹ {resultValue.roundOff}
                            </div>
    
                            <div className="col-md-7 d-flex justify-content-end align-items-center fw-bold">Total: {resultValue.roundedTotal.toFixed(2)}</div>
    
                        </div>
                    </div>
    
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrint}
                            className="no-print"
                            style={{ marginLeft: '10px', height: '35px' }}
                        >
                            Print
                        </Button>
                    </div>
    
                </div>
            </div>
        );
   
    
} export default ViewInvoice;