import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard"
import "./Spinner.css";
import { useNavigate,useParams } from "react-router-dom";
import DriverDetailsModal from "./DriverDetailsmodal";
import { MdPersonAddAlt1 } from "react-icons/md";

function EditLoadInDetails() {
 const { invoiceId }= useParams();
  const navigate = useNavigate();
  const [loadDetails, setLoadDetails] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    truckinfo: "",
    date: "",
    invoiceid: "",
    file: null,
    fileSize: "",
    fileContentType:"",
    taxableValue:"",
    taxes:"",
    deliveryCharges:"",
    invoiceTotal:"",
    loadDetails: [
      {
        productcode: "",
        productname: "",
        quantity: "",
        status: "full",
      },
    ],
    truckDrivers:{
      id:"",
      drivername:"",
      contact:"",
    }
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const url = "/api/loadindetailsdo/update";

  const token = JSON.parse(localStorage.getItem("data")).token;
  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      
      const trans = await axios.get(
        `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true/productTypeId=${1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Get the load details by loadInMetaId
      const loadRes = await axios.get(
        `/api/loadindetailsdo/getLoadDetailsByInvoiceid/invoiceId=${invoiceId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Update the state with the data from the loadRes
      setLoadDetails({
        ...loadRes.data, // Load in details data from API
        loadDetails: trans.data.map((item) => ({
          productcode: item.productcode,
          productname: item.productname,
          quantity: loadRes.data.loadDetails.find(ld => ld.productcode === item.productcode)?.quantity || 0, 
          status: "full"
        })),
      });
  
      setProduct(trans.data);
      setLoading(false);
  
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, loadDetails, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Load in updated successfully");
        navigate("/users/loaddetails");
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;

    if (field == "quantity") {
      const newproduct = [...loadDetails.loadDetails];
      newproduct[index] = { ...newproduct[index], quantity: actualValue || 0 };

      setLoadDetails({ ...loadDetails, loadDetails: newproduct });
    } else {
      setLoadDetails({ ...loadDetails, [field]: actualValue });
    }
  }

  const calculateInvoiceTotal = () => {
    const { taxableValue, taxes, deliveryCharges } = loadDetails;
    const invoiceTotal =
      parseFloat(taxableValue || 0) +
      parseFloat(taxes || 0) +
      parseFloat(deliveryCharges || 0);
    setLoadDetails({ ...loadDetails, invoiceTotal: invoiceTotal.toFixed(2) });
  };

  useEffect(() => {
    calculateInvoiceTotal();
  }, [loadDetails.taxableValue, loadDetails.taxes, loadDetails.deliveryCharges]);

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...loadDetails,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setLoadDetails(pdfObject);
  };

 
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const handleAddModalOpen = () => {
    setIsModalOpen(true); 
  };

  const handleModalClose = () => {
    setIsModalOpen(false); 
  };

  const handleDriverSelect = (driver) => {
    setLoadDetails({
      ...loadDetails,
      truckDrivers: {
        id:driver.id,
        drivername:driver.drivername,
        contact: driver.contact,
      }
    });
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      {loading && <div className="loading"></div>}
      <div
        className="col-8  mx-xl-auto px-xl-5 "
        style={{ position: "relative", left: "150px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Load Incoming Details</div>
            <br />

            <div className="col-xl-12 mx-xl-auto ">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                  <div className="form-floating-sm mb-2 d-flex align-items-center">
                      <label for="date">Date:</label>
                      <input
                        id="date"
                        type="date"
                        value={loadDetails.date}
                        // onChange={(e) => rhandle(e, "date")}
                        readOnly
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="truckinfo">Vehicle Id:</label>
                      <input
                        id="truckinfo"
                        type="text"
                        placeholder="Truck Info"
                        value={loadDetails.truckinfo}
                        // onChange={(e) => {
                        //   const value = e.target.value.toUpperCase(); // Convert value to uppercase
                        //   rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                        // }}
                        readOnly
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="invoiceid">Invoice Id:</label>
                      <input
                        id="invoiceid"
                        type="text"
                        placeholder="Invoice Id"
                        value={loadDetails.invoiceid}
                        // onChange={(e) => rhandle(e, "invoiceid")}
                        readOnly
                      />
                    </div>{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;

                      <div className="row">
                              <div className="form-floating-sm mb-2 d-flex align-items-center">
                                <label htmlFor="file" className="me-">Upload Document:</label>
                                <input
                                  className="form-control"
                                  id="file"
                                  type="file"
                                  placeholder="Upload document"
                                  onChange={(e) => handlePdfFileChange(e)}
                                />
                                <label htmlFor="drivername" className="mx-3">Driver Name:</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                <input
                                  id="drivername"
                                  type="text"
                                  placeholder="Driver Name"
                                  value={loadDetails.truckDrivers.drivername}
                                  readOnly
                                  onChange={(e) => rhandle(e, "drivername")}
                                />
   <span
                            className="pulse-icon"
                            onClick={handleAddModalOpen} 
                            style={{ cursor: 'pointer', marginLeft: '10px',fontSize:"17px"}}
                          >
                            <MdPersonAddAlt1 />
                          </span>
</div>
                                <label htmlFor="contact" className="mx-3">Contact:</label>
                                <input
                                  id="contact"
                                  type="text"
                                  placeholder="Contact Number"
                                  value={loadDetails.truckDrivers.contact}
                                  readOnly
                                  onChange={(e) => {
                                    const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                    const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                                    setLoadDetails({ ...loadDetails, contact: limitedText }); // Update the state with limited text
                                  }}
                                />
                              </div>
                            </div>

                    
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadDetails.loadDetails.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder={product.quantity}
                                        style={{ border: "none" }}
                                        onKeyPress={(e) => {
                                          // Allow only numeric characters
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => rhandle(e, "quantity", index)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <div className="container">
  <div className="row mb-2">
    <div className="col-md-3 me-6">
      <label htmlFor="taxableValue">Taxable Value:</label>
      <input
        id="taxableValue"
        type="text"
        className="form-control"
        placeholder="0"
        value={loadDetails.taxableValue}
        onChange={(e) => rhandle(e, "taxableValue")}
      />
    </div>
    <div className="col-md-3 me-6">
      <label htmlFor="taxes">Taxes:</label>
      <input
        id="taxes"
        type="text"
        className="form-control"
        placeholder="0"
        value={loadDetails.taxes}
        onChange={(e) => rhandle(e, "taxes")}
      />
    </div>
    <div className="col-md-3">
      <label htmlFor="deliveryCharges">Delivery Charges:</label>
      <input
        id="deliveryCharges"
        type="text"
        className="form-control"
        placeholder="0"
        value={loadDetails.deliveryCharges}
        onChange={(e) => rhandle(e, "deliveryCharges")}
      />
    </div>
  </div>

  <div className="row mb-2">
    <div className="col-md-3">
      <label htmlFor="invoiceTotal">Invoice Total:</label>
      <input
        id="invoiceTotal"
        type="text"
        className="form-control"
        placeholder="0"
        value={loadDetails.invoiceTotal}
        style={{ width: "150%" }} 
        readOnly
      />
    </div>
  </div>
</div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) =>
                        rsubmit(e)
                      }
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

{isModalOpen && (
        <DriverDetailsModal isOpen={isModalOpen} onRequestClose={handleModalClose}  onDriverSelect={handleDriverSelect} />
)}
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default EditLoadInDetails;
