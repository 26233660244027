import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const DaDenominations = ({ isOpen, onRequestClose, daCashId}) => {
  const [denominations, setDenominations] = useState([]);
  const [depositDetails, setDepositDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/DeliveryagentDenomination/daCashPaymentId=${daCashId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDenominations(response.data);

      const depositResponse = await axios.get(
        `/api/bankdepositdetails/daCashPaymentId=${daCashId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDepositDetails(depositResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
     handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  
 
  useEffect(() => {
  getData();
  }, [daCashId]);

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage('');
  };

  return (
    
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Finance Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>}
    
<div className="card-title">Cash</div>
      <table className="table table-bordered ">
        <thead>
        <tr>
        <th>Face Value</th>
        <th>Denomination Number</th>
        <th>Total</th>
                
              </tr>
            </thead>
            <tbody>
            {denominations.length > 0 ? (
            denominations.map((d, index) => (
            <tr key={index}>
                <td>{d.facevalue}</td>
                    <td>{d.denominationnumber}</td>
                    <td>
                    {d.facevalue * d.denominationnumber}
                    </td>
                   </tr>
            ))
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
              </tr>
            )}

              </tbody>
            </table>
            <br></br>

            <div className="card-title">Bank Deposit</div>
            <table className="table table-bordered ">
        <thead>
        <tr>
        <th>Reference Number</th>
        <th>Deposit Amount</th>
        {/* <th>Proof</th> */}
                
              </tr>
            </thead>
            <tbody>
            {depositDetails.length > 0 ? (
            depositDetails.map((d, index) => (
            <tr key={index}>
                <td>{d.referenceNumber}</td>
                    <td>
                    <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                      {d.depositAmount}
                      <span>
                    {d.image && (
                        <Button
                          variant="link"
                          onClick={() => handleImageClick(d.image)}
                          style={{cursor:'pointer'}}
                          title="View Receipt"
                        >
                          <FontAwesomeIcon icon={faImage} />
                        </Button>
                      )}
                      </span>
                      </div>
                      </td>
                   </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
            </tr>
          )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for displaying image */}
      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Deposit Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedImage && (
          <img src={`data:image/jpeg;base64, ${selectedImage}`}
          alt="image" style={{ width: '100%' }} />
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

</>
      );
    }
export default DaDenominations;
