import React, { useEffect, useState } from "react";
import { Modal, Button, Pagination } from 'react-bootstrap';
import axios from "../api/baseurl";

const RemoveHistory = ({ onClose, vendorproductid }) => {
  const [localPriceHistory, setLocalPriceHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);  // Default page size
  const [totalPages, setTotalPages] = useState(1);

  const token = JSON.parse(localStorage.getItem("data")).token;

  // Fetch data based on page number
  const fetchPriceHistory = async (page) => {
    try {
      const response = await axios.get(`/api/removequantity/vendorproductid=${vendorproductid}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, size: pageSize }
      });
      setLocalPriceHistory(response.data.content);
      setCurrentPage(response.data.number);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchPriceHistory when the component mounts or page changes
  useEffect(() => {
    fetchPriceHistory(currentPage);
  }, [currentPage]);

  // Helper function to create a range of page numbers
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const startPage = Math.max(currentPage - 2, 0);
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages - 1);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  // Pagination Controls
  const handleFirst = () => setCurrentPage(0);
  const handleLast = () => setCurrentPage(totalPages - 1);
  const handlePrevious = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Remove Quantity History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Remove Quantity</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(localPriceHistory) && localPriceHistory.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.date}</td>
                <td>{entry.removequantity}</td>
                <td>{entry.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between', // Space between pagination and close button
            alignItems: 'center', // Vertically center items
            width: '100%',
          }}
        >
          {/* Pagination controls */}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Pagination>
              {/* First Page */}
              <Pagination.First onClick={handleFirst} disabled={currentPage === 0} />

              {/* Previous Page */}
              <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 0} />

              {/* Page Numbers */}
              {getPageNumbers().map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber + 1}
                </Pagination.Item>
              ))}

              {/* Next Page */}
              <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages - 1} />

              {/* Last Page */}
              <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages - 1} />
            </Pagination>
          </div>
          <Button variant="secondary" onClick={onClose} style={{ marginLeft: '10px' }}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveHistory;
