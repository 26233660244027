import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function Additionalreport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");

  // Fetch data from API
  const getData = async () => {
  
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/additionaldetails/orgcode=${orgcode}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReportData(response.data.product_totals || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
     
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Add the name of the report below the logo
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0); // Set text color to black
        const reportName = "Additional Report"; // Name of the report
        const reportNameY = logoY + logoHeight + 5; // Position below the logo
        doc.text(reportName, logoX, reportNameY); // Draw the report name
    

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10; // Position on the right side
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: 'right' });

      const tableData = reportData.map(item => [
        item.date,
        item.customer_name,
        item.customer_number,
        item.product_name,
        item.quantity,
        item.phone_number
      ]);
  
      doc.autoTable({
        startY: addressY + 20, // Start the table after the address
        head: [['Date', 'Customer Name', 'Customer Number', 'Product Name', 'Quantity', 'Phone Number']],
        body: tableData,
        theme: 'striped',
        styles: { overflow: 'linebreak' },
      });
  
      doc.save("additional.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };



  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div>
                    <label htmlFor="month">Month:</label>
                    <DatePicker
                      selected={month}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={getData}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    disabled={loading || !reportData.length}
                    className="btn btn-secondary"
                    onClick={generatePDF}
                  >
                    {loading ? "Generating PDF..." : "Download PDF"}
                  </button>
                  {loading && <div className="loading">Loading...</div>}
                </div>
              </div>
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Customer Number</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.length > 0 ? (
                        reportData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.customer_name}</td>
                            <td>{item.customer_number}</td>
                            <td>{item.product_name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.phone_number}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">No Data Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Additionalreport;
