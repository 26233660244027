import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Pagination, Card, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AddTruckTransporter from "./addTruckTransporter";
import AdminDashboard from "./admindashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EditTruckTransporter from "./editTransporterDetails";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

function TruckTransporters() {
  const [transportersDetails, setTransportersDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTransporter, setSelectedTransporter] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transporterToDelete, setTransporterToDelete] = useState(null);

  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    setLoading(true);
    try {
      const searchUrl = `/api/transporter-details/orgcode=${orgcode}`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTransportersDetails(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const trans = await axios.get(`/api/transporter-details/getByOrgcode/orgcode=${orgcode}`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setTransportersDetails(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  const handleAddTransporters = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const filteredData = transportersDetails.filter((details) =>
    (details.email?.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (details.transporterName?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleViewTransporter = async (transporterId) => {
    navigate(`/users/view-transporter/${transporterId}`);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleEditTransporter = (transporter) => {
    setSelectedTransporter(transporter);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    getData(currentPage);
  };

  const confirmDeleteTransporter = (transporter) => {
    setTransporterToDelete(transporter);
    setShowConfirmModal(true);
  };

  const handleDeleteTransporter = async () => {
    if (!transporterToDelete) return;

    setLoading(true);
    try {
      await axios.delete(`/api/transporter-details`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { id: transporterToDelete.id },
      });

      toast.success("Deleted successfully");
      setLoading(false);
      setShowConfirmModal(false);
      setTransporterToDelete(null);
      getData();
    } catch (error) {
      setLoading(false);
      handleError(error);
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      {loading && <div className="loading"></div>}
      <div className="col-13  mx-xl-auto ps-xl-10">
        <div className="card card-raised " style={{ marginTop: "10%" }}>
          <div className="card-body ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Transporters</span>

              <span style={{ position: "absolute", display: "flex", left: "380px", width: '300px' }}>
                <input
                  type="search"
                  className='form-control'
                  placeholder='Search by name or email'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ paddingLeft: "2rem", width: "100%" }}
                />
                <span className="position-absolute" style={{ left: "15px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </span>

              <button className="btn btn-primary" onClick={handleAddTransporters}>
                ADD
              </button>
            </div>

            <br />
            <Row>
              {filteredData.map((transporters) => (
                <Col key={transporters.id} md={4} className="mb-3">
                  <div
                    className="card card-raised h-100"
                    onClick={() => handleViewTransporter(transporters.id)}
                    style={{ cursor: "pointer" }}
                    title="View Transporter"
                  >
                    <div className="card-body">
                      <Card>
                        <Card.Body>
                          <div className="card-title text-center">
                            <Card.Text className="d-flex justify-content-end">
                              <FaEdit
                                style={{ fontSize: "18px", cursor: "pointer", marginRight: "10px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditTransporter(transporters);
                                }}
                                title="Update Transporter"
                              />
                              <MdDeleteForever
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmDeleteTransporter(transporters);
                                }}
                                title="Delete Transporter"
                              />
                            </Card.Text>
                            <Card.Text>{transporters.transporterName}</Card.Text>
                          </div>
                          <br />
                          <Card.Text>Email: {transporters.email}</Card.Text>
                          <Card.Text>Phone Number: {transporters.phoneNumber}</Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
<Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Delete</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete <strong>{transporterToDelete?.transporterName}</strong>?<br />
    <br />
    This action is irreversible and will result in the permanent loss of all associated data for this transporter. 
    Please proceed with caution.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary me-3" onClick={() => setShowConfirmModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleDeleteTransporter}>
      Confirm
    </Button>
  </Modal.Footer>
</Modal>

      {/* Add Transporter Modal */}
      {isModalOpen && (
      <AddTruckTransporter isOpen={isModalOpen} onRequestClose={handleCloseModal} />
      )}
      {/* Edit Transporter Modal */}
      {isEditModalOpen && (
      <EditTruckTransporter isOpen={isEditModalOpen} onRequestClose={handleCloseEditModal} transporterDetails={selectedTransporter}/>
      )}
    </div>
  );
}

export default TruckTransporters;
