import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ProductdetailsModal from "./updateproducts";
import Superproduct from "./Superproducts";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import ProductdetailsstepperModal from "./Createproductstepper";

const Modalpopupstepper = ({ isOpen, onRequestClose, productTypeId,categoryId,orgcode }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showNextPopup, setShowNextPopup] = useState(false);
  const [openStock, setOpenStock] = useState([]);

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const trans = await axios.get(`/api/Openingstock/orgcode=${orgcode}/datetime=${currentDate}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setOpenStock(trans.data);
  }catch(error){
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }



  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClose = () => {
    setSelectedOption(null); // Reset selected option
    setShowNextPopup(false); // Reset show next popup state
    onRequestClose(); // Close the modal
  };

  // const handleNext = () => {
  //   if (openStock.length > 0) {
  //     setShowNextPopup(true); 
  //   } else {
  //     toast.warning("Please complete the day-end process for the previous date in order to create a product.");
  //   }
  // };
  const handleNext = () => {
    setShowNextPopup(true);
  };


  const radioLabelStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 0",
  };

  const radioInputStyle = {
    marginRight: "8px", // Adjust spacing between radio button and label text
  };

  const modalBodyStyle = {
    marginBottom: "20px",
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Select product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <div>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                value="preconfigured"
                checked={selectedOption === "preconfigured"}
                onChange={handleOptionChange}
                style={radioInputStyle}
              />
              Preconfigured Products
            </label>
          </div>
          <div>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                value="createNew"
                checked={selectedOption === "createNew"}
                onChange={handleOptionChange}
                style={radioInputStyle}
              />
              Create New Products
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  size="sm" onClick={handleClose}>
            Close
          </Button>
          <span style={{marginRight:"10px"}}></span>
          {/* <Button variant="primary" size="sm" onClick={handleNext} disabled={!selectedOption}>
            Next
          </Button> */}
             <Button variant="primary" size="sm" onClick={handleNext} disabled={!selectedOption}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {showNextPopup && selectedOption === "preconfigured" && (
        <Superproduct isOpen={true} onRequestClose={handleClose} typeId={productTypeId} orgcode={orgcode} />
      )}
      
      {showNextPopup && selectedOption === "createNew" && (
        <ProductdetailsstepperModal isOpen={true}   categoryId={categoryId} onRequestClose={handleClose} typeId={productTypeId}  orgcode={orgcode}/>
      )}
    </>
  );
};

export default Modalpopupstepper;
