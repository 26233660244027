import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "../api/baseurl";
import { TextField, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const DriverDetailsModal = ({ isOpen, onRequestClose, onDriverSelect }) => {
  const [driverDetails, setDriverDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
  const [newDriver, setNewDriver] = useState({ drivername: '', contact: '' });
  const [searchTerm, setSearchTerm] = useState(""); 

  const navigate = useNavigate();
  const fetchDriverDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.get(`/api/truck-drivers/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDriverDetails(response.data);
    } catch (error) {
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDriverDetails();
    }
  }, [isOpen]);

 // Filter drivers based on search term
const filteredDrivers = driverDetails.filter(driver =>
  (driver.drivername && driver.drivername.toLowerCase().includes(searchTerm.toLowerCase())) ||
  (driver.contact && String(driver.contact).includes(searchTerm)) // Ensure number is a string
);


  const handleSelectDriver = (driver) => {
    onDriverSelect(driver);
    onRequestClose();
  };

  const handleAddDriverClick = () => {
    setIsAddDriverModalOpen(true);
  };

  const handleAddDriverClose = () => {
    setIsAddDriverModalOpen(false);
    setNewDriver({ drivername: '', contact: '' });
  };

  const handleNewDriverChange = (e) => {
    const { name, value } = e.target;
    setNewDriver({ ...newDriver, [name]: value });
  };

  const handleAddDriverSubmit = (e) => {
    e.preventDefault();
    const updatedDriverDetails = [...driverDetails, newDriver];
    setDriverDetails(updatedDriverDetails);

    onDriverSelect(newDriver);
    setNewDriver({ drivername: '', contact: '' });

    handleAddDriverClose();
    onRequestClose();

  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  const modalStyles = {
    content: {
      width: 'auto',
      height: 'auto',
      maxHeight: '70%',
      top: '15%',
      left: '40%',
      right: 'auto',
      bottom: 'auto',
      padding: '15px',
      overflow: 'auto',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    marginTop: '20px',
    fontSize: '1.2em',
  };

  const thStyles = {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '15px',
    borderBottom: '2px solid #ddd',
    fontSize: '0.8em',
    textAlign: 'left',
  };

  const tdStyles = {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    textAlign: 'center',
    fontSize: '0.8em',
    cursor: 'pointer',
    backgroundColor: '#fff',
  };

  const closeButtonStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: '15px',
  };

  const addButtonStyles = {
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '0.9em',
    marginBottom: '15px',
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Driver Details"
        style={modalStyles}
      >
        <h5>Driver Details</h5>
         {/* Search input for filtering drivers */}
         <div style={{ position: "relative", marginBottom: "15px", width: "250px" }}>
          <input
            type="search"
            className="form-control"
            placeholder="Search by name or number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              paddingLeft: "2.5rem",
              width: "100%",
            }}
          />
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: "1rem",
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
        <button onClick={handleAddDriverClick} style={addButtonStyles}>
          Add Driver
        </button>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            {filteredDrivers.length === 0 ? (
              <div>No data available</div>
            ) : (
              <table style={tableStyles}>
                <thead>
                  <tr>
                    <th style={thStyles}>Driver Name</th>
                    <th style={thStyles}>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDrivers.map((driver, index) => (
                    <tr
                      key={index}
                      style={tdStyles}
                      onClick={() => handleSelectDriver(driver)}
                    >
                      <td>{driver.drivername}</td>
                      <td>{driver.contact}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        <button onClick={onRequestClose} style={closeButtonStyles}>
          Close
        </button>
      </Modal>

      {/* Add Driver Modal */}
      <Modal
        isOpen={isAddDriverModalOpen}
        onRequestClose={handleAddDriverClose}
        contentLabel="Add Driver"
        style={modalStyles}
      >
        {/* <h5>Add Driver</h5> */}
        <Box
      component="form"
      onSubmit={handleAddDriverSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '250px', // Reduced width
        margin: '0 auto',
      }}
    >
      <TextField
        label="Driver Name"
        name="drivername"
        value={newDriver.drivername}
        onChange={handleNewDriverChange}
        required
        fullWidth
        size="small" // Reduced size
      />
      <TextField
        label="Phone Number"
        name="contact"
        value={newDriver.contact}
        onChange={handleNewDriverChange}
        required
        fullWidth
        size="small" // Reduced size
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          type="submit"
          variant="contained"
          color="success"
          sx={{ flexGrow: 1, fontSize: '0.8em', padding: '6px 8px', mr: 1 }} // Reduced size
        >
          Add Driver
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="error"
          sx={{ flexGrow: 1, fontSize: '0.8em', padding: '6px 8px', ml: 1 }} // Reduced size
          onClick={handleAddDriverClose}
        >
          Cancel
        </Button>
      </Box>
    </Box>
      </Modal>
    </>
  );
};

export default DriverDetailsModal;
