import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'; // Import InputGroup for better alignment
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { FaSearch } from "react-icons/fa";

const VehicleHistoryModal = ({ isOpen, onRequestClose, vehicleId }) => {
  const [agentHistory, setAgentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(""); // State to hold the selected date

  // Function to format date into YYYY-MM-DD
  const formatDate = (date) => {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Set default date as current date and fetch data when modal opens
  useEffect(() => {
    const currentDate = formatDate(new Date());
    setSelectedDate(currentDate);
    fetchData(currentDate); // Fetch data based on the current date when the modal opens
  }, [isOpen]); // Fetch data whenever the modal opens

  // Fetch data when search is triggered
  const fetchData = async (date) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/DeliveryAgentVehicleMapping/vehicleid=${vehicleId}/date=${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAgentHistory(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  
  // Error handling function
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  // Handle date change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Set the new selected date
  };

  // Handle search button click to trigger API call
  const handleSearch = () => {
    fetchData(selectedDate); // Fetch data based on the selected date
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delivery Agent Mapped History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* InputGroup for search input and search button alignment */}
        <div className="d-flex align-items-center mb-2">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
          <Button
            onClick={handleSearch}
            className="btn btn-primary"
            style={{
              marginLeft: "5px"
            }}
          >
            <FaSearch style={{ background: "#6200ea", color: "white" }} />
          </Button>
        </div>


        <table className="table mt-4">
          <thead>
            <tr>
              <th>Delivery Agent</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr><td colSpan="2">Loading...</td></tr>
            ) : (
              agentHistory.length > 0 ? agentHistory.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.name}</td>
                </tr>
              )) : (
                <tr><td colSpan="2">No data available</td></tr>
              )
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VehicleHistoryModal;
