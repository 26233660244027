import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ProductdetailsModal from "./updateproducts";
import Superproduct from "./Superproducts";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";

const Modalpopup = ({ isOpen, onRequestClose, productTypeId,categoryId }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showNextPopup, setShowNextPopup] = useState(false);
  const [openStock, setOpenStock] = useState([]);
  const [orgStartDate, setOrgStartDate] = useState(null);

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
 
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const trans = await axios.get(`/api/Openingstock/orgcode=${orgcode}/datetime=${currentDate}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setOpenStock(trans.data);
  }catch(error){
    handleError(error);
  }
  };

  const getOrganizationProfile = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(
        `/api/organizationprofiles/Orgcode=${orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const createdDate = response.data.createddate;
      const parsedDate = parseDate(createdDate);
      setOrgStartDate(parsedDate);
        } catch (error) {
      handleError(error);
    }
  };
  const parseDate = (dateString) => {
    const [day, monthAbbr, year] = dateString.split("/");
    const monthMap = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04",
      May: "05", Jun: "06", Jul: "07", Aug: "08",
      Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    };
    const month = monthMap[monthAbbr];
    return `${year}-${month}-${day.padStart(2, "0")}`;
  };

  useEffect(() => {
    getData();
    getOrganizationProfile();
    getOrganizationProfile();
  }, []);
 
  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
 
 
 
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
 
  const handleClose = () => {
   
    setSelectedOption(null); // Reset selected option
    setShowNextPopup(false); // Reset show next popup state
    onRequestClose(); // Close the modal
  };

 
  // const handleNext = () => {
  //   // console.log("orgStartDate:", orgStartDate);
  //   // console.log("currentDate:", currentDate);
  //   if (orgStartDate === currentDate) {
  //     setShowNextPopup(true); 
  //   } else if (openStock.length > 0) {
  //     setShowNextPopup(true); 
  //   } else {
  //     toast.warning("Please complete the day-end process for the previous date in order to create a product.");
  //   }
  // };

  const handleNext = () => {
    setShowNextPopup(true);
  };
  const radioLabelStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 0",
  };
 
  const radioInputStyle = {
    marginRight: "8px", // Adjust spacing between radio button and label text
  };
 
  const modalBodyStyle = {
    marginBottom: "20px",
  };
 
  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Select product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <div>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                value="preconfigured"
                checked={selectedOption === "preconfigured"}
                onChange={handleOptionChange}
                style={radioInputStyle}
              />
              Preconfigured Products
            </label>
          </div>
          <div>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                value="createNew"
                checked={selectedOption === "createNew"}
                onChange={handleOptionChange}
                style={radioInputStyle}
              />
              Create New Products
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  size="sm" onClick={handleClose}>
            Close
          </Button>
          <span style={{marginRight:"10px"}}></span>
          {/* <Button variant="primary" size="sm" onClick={handleNext} disabled={!selectedOption}>
            Next
          </Button> */}
             <Button variant="primary" size="sm" onClick={handleNext} disabled={!selectedOption}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
 
      {showNextPopup && selectedOption === "preconfigured" && (
        <Superproduct isOpen={true} onRequestClose={handleClose} typeId={productTypeId} />
      )}
      {showNextPopup && selectedOption === "createNew" && (
        <ProductdetailsModal isOpen={true}  categoryId={categoryId} onRequestClose={handleClose} typeId={productTypeId} />
      )}
    </>
  );
};
 
export default Modalpopup;
 
 