import React, { useEffect } from "react";
import { Typography, Grid, TextField, InputLabel, MenuItem, FormControl } from "@mui/material";
import axios from "../api/baseurl";

const CustomFieldDisplay = ({ customFields, handleFieldValueChange }) => {
    useEffect(() => {
        const fetchCustomFields = async () => {
            try {
                const response = await axios.get('/api/customfield');
                const fetchedFields = response.data;
               
                if (fetchedFields && fetchedFields.length > 0) {
                    handleFieldValueChange(fetchedFields);
                }
            } catch (error) {
                console.error("Error fetching custom fields:", error);
            }
        };

        fetchCustomFields();
    }, [handleFieldValueChange]);

    useEffect(() => {

        if (customFields) {
            localStorage.setItem('cutomFields', JSON.stringify(customFields));
        }
    }, [customFields]);

    const handleInputChange = (index, value) => {
        const updatedFields = [...customFields];
        updatedFields[index].value = value;
        handleFieldValueChange(updatedFields);
    };

    return (
        <div>
       

            {customFields && customFields.length > 0 ? (
                <Grid container spacing={2}>
                    {customFields.map((field, index) => (
                        <Grid item xs={2} key={index}>
                            <InputLabel htmlFor={`field-${index}`} shrink sx={{ fontSize: '1.25rem' }}>
                                {field.fieldname}
                            </InputLabel>
                            <FormControl fullWidth variant="outlined">
                                {field.fieldType === "text" ? (
                                    <TextField
                                        id={`field-${index}`}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter text"
                                        onChange={(e) =>
                                            handleFieldValueChange(index, "value", e.target.value)
                                        }
                                        value={field.value || ""}
                                        style={{
                                            backgroundColor: "white",
                                            width: "100%", // Change to 100% to fill the space of Grid item
                                        }}
                                    />
                                ) : field.fieldType === "number" ? (
                                    <TextField
                                        id={`field-${index}`}
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter number"
                                        onChange={(e) =>
                                            handleFieldValueChange(index, "value", e.target.value)
                                        }
                                        value={field.value || ""}
                                        style={{
                                            backgroundColor: "white",
                                            width: "100%", 
                                        }}
                                    />
                                ) : field.fieldType === "date" ? (
                                    <TextField
                                        id={`field-${index}`}
                                        type="date"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) =>
                                            handleFieldValueChange(index, "value", e.target.value)
                                        }
                                        value={field.value || ""}
                                        style={{
                                            backgroundColor: "white",
                                            width: "100%", 
                                        }}
                                    />
                                ) : field.fieldType === "select" ? (
                                    <TextField
                                        id={`field-${index}`}
                                        select
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) =>
                                            handleFieldValueChange(index, "value", e.target.value)
                                        }
                                        value={field.value || ""}
                                        style={{
                                            backgroundColor: "white",
                                            width: "100%", 
                                        }}
                                    />

                                        ) : field.fieldType === "user" ? (
                                        <TextField
                                            id={`field-${index}`}
                                           type="text"
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) =>
                                                handleFieldValueChange(index, "value", e.target.value)
                                            }
                                            value={field.value || ""}
                                            style={{
                                                backgroundColor: "white",
                                                width: "100%",
                                            }}
                                        >





                                            {/* Dynamic options can be filled here */}
                                            {field.prod.map((prodItem, prodIndex) => (
                                                <MenuItem key={prodIndex} value={prodItem.value}>
                                                    {prodItem.value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        ) : (
                                        <Typography variant="body1">Unsupported field type</Typography>
                                )}
                                    </FormControl>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body1">No custom fields available.</Typography>
            )}
        </div>
    );
};

export default CustomFieldDisplay;
