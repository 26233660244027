import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast } from "react-toastify";
import AdminDashboard from "./admindashboard";
import { IoSearchOutline } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import "./Spinner.css";
import "./Loader.css";
import Modal from "react-modal";
import { BiEditAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import ConsumableModalOpen from "./consumable";
import { Button } from "react-bootstrap";
import Createofficesale from "./Officesale";
import LiveGodownupdate from "./Livegodown";
import { MdDeleteForever } from "react-icons/md";
import ConversionModal from "./Conversionmodal";
import Consumablelivegodown from "./Consumablelivegodown";
import OfficeRefillSalesDetails from "./officeSalesDetailsModal";
import OfficeConsumables from "./officeConsumablesSalesModal";
import UpdateOfficeExpenses from "./officeExpensesPopup";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import UpdateDaCashDetails from "./updateDaCashPayment";
import { AiFillDelete } from "react-icons/ai";
import UpdateDaCashDetails1 from "./UpdateOfficeExpenses";
import DaDenominations1 from "./daDenomination";
import { FiInfo } from "react-icons/fi";
import BeyondLpgStock from "./beyondLpgStock";
import RefillOfficeSalesModalOpen from "./refillOfficeSalesModal";
import DaDenominations from "./daDenominations";

function Counter() {
  const [time, setTime] = useState(new Date());
  var curr_hour = time.getHours();
  var curr_min = time.getMinutes();

  var curr_sec = time.getSeconds();

  // const searchDate = props.params.searchDate;
  const text = curr_hour + ":" + curr_min + ":" + curr_sec;

  const date = new Date();
    const date1 = new Date();
    date.setDate(date.getDate());
    date1.setDate(date1.getDate());
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const datetime = `${year}-${month}-${day} ${curr_hour}:${curr_min}:${curr_sec}`;

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    status: "full",
    deliveryagentid: "",
    approvalstatus: "",
    approvaldate: "",
    date: currentDate,
    time: text,
    products: [
      {
        productcode: "",
        productname: "",
       // productType:"",
        quantity: 0,
        neworg: 0,
        exempted: 0,
        additional: 0,
        onlinepay: 0,
        vehicle: 0,
        transfer: 0,
        returnedproduct:0
      },
    ],
  });

    const [openstock, setOpenstock] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState("");
    const [isUpdateDaCashModalOpen, setIsUpdateDaCashModalOpen] = useState(false);
    const [daCashById, setDaCashById] = useState("");
    // const [vehicle, setVehicle] = useState([]);
    // const [searchbyv, setSearchbyv] = useState(true);
    const [searchbyvehicle, setSearchbyvehicle] = useState([]);
    const [product, setProduct] = useState([]);
    const [payedcash, setPayedcash] = useState([]);
    const [denominationsModalIsOpen, SetDenominationsModalIsOpen] = useState(false);
    const [denominations, setDenominations] = useState([]);
    const [depositDetails, setDepositDetails] = useState([]);
    // const [tsearchstate, setTsearchstate] = useState(true);
    const [vehicle, setVehicle] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const [vehicledetails, setVehicledetails] = useState({
      vehicleid: "",
    });
  
   
    const [orgStartDate, setOrgStartDate] = useState("");
   
    const [searchstate, setSearchstate] = useState(true);
   
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
   
    const [searchbyv, setSearchbyv] = useState(true);
    const [searchbyPro, setSearchbyPro] = useState(true);
  
   
  
    
    const [loader8, setLoader8] = useState(false);
    const [loader9, setLoader9] = useState(false);

  
   
    const [searchDate, setSearchDate]= useState(currentDate);
  
  
    const [consumable, setConsumable] = useState([]);
    const [conversion, setConversion] = useState([]);

    const [refill, setRefill] = useState([]);
    const [iscreatesaleModalOpen, setIscreatesaleModalOpen] = useState(false);
    const [productTypes, setProductTypes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
    const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
    const [officeRefillSales, setOfficeRefillsales] = useState([]);
    const [consumableDetails, setConsumableDetails] = useState([]);
    const [officeRefillsalesModalIsOpen, setOfficeRefillsalesModalIsOpen] = useState(false);
    const [officeConsumablesModalIsOpen, setOfficeConsumablesModalIsOpen] = useState(false);
    const [selectedProductName, setSelectedProductName] = useState(null);
    const [isExpensesModalOpen, setIsExpensesModalOpen] = useState(false);
    const [selectedExpenses, setSelectedExpenses] = useState(null);
    const [tsearchstate, setTsearchstate] = useState(true);

    const [selectedProductType, setSelectedProductType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newProduct, setNewProduct] = useState({
      productType: ""
    });
    const [total, setTotal] = useState([
      {
        total: 0,
      },
    ]);
    let finaltotal1 = 0;
    let finaltotal = 0;

    function formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0'); 
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      return `${day}/${monthNames[monthIndex]}/${year}`;
    }
    const navigate = useNavigate();
   
    useEffect(() => {
      fetchProductTypes(); // Fetch product types when component mounts
   
    }, []);
    useEffect(() => {
   getData();
    }, [iscreatesaleModalOpen]);
  
    const fetchProductTypes = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get("/api/productcategorytype", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProductTypes(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching product types:", error);
      }
    };

    const searchName = async (text) => {
      const fSearchDate = formatDate(text);
      const fSearchDateObj = new Date(fSearchDate);
  const orgStartDateObj = new Date(orgStartDate);
  setSearchstate(false);
  setSearchbyPro(true);
  setSearchbyv(true);
  setSearchDate(text);
  
  setTsearchstate(false);
  if (fSearchDateObj < orgStartDateObj) {
    setErrorMessage(true);
    setLoading(false); 
  } else {
    setErrorMessage(false);
  
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      await Promise.all([
         
          axios
          .get(
            `/api/consumableproduct/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${text}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setConsumable(response.data);
          }),
          axios
          .get(
            `/api/refillproduct/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${text}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setRefill(response.data);
          }),     
      ])
  
  
   .finally(() => {
      setLoading(false); // Set loading to false after request completes (both success and failure)
  });  
  }  
    };

    const expensesSearch = async (text) => {
      setLoading(true);
      setSearchDate(text);
      const token = JSON.parse(localStorage.getItem("data")).token;
      await Promise.all([
         
          axios
          .get(
            `/api/cashpaymentexpenses/office-expenses/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${text}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setExpenses(response.data);
          }),
            ])
  
   .finally(() => {
      setLoading(false);
  });  
   
    };

    const cashPaymentSearch = async (text) => {
      setLoading(true);
      setSearchDate(text);
      const token = JSON.parse(localStorage.getItem("data")).token;
      await Promise.all([
         
          axios
          .get(
            `/api/DeliveryAgentCashPayment/office-payment/orgid=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/datetime=${text}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setPayedcash(response.data);
          }),
            ])
  
   .finally(() => {
      setLoading(false);
  });  
   
    };

    const conversionSearch = async (text) => {
      setLoading(true);
      setSearchDate(text);
      const token = JSON.parse(localStorage.getItem("data")).token;
      await Promise.all([
         
          axios
          .get(
            `/api/conversion-exchange/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${text}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setConversion(response.data);
          }),
            ])
  
   .finally(() => {
      setLoading(false);
  });  
   
    };
    
    const getData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
    
      setLoading(true)
    
  
  
      await Promise.all([
       
          axios
          .get(
            `/api/refillproduct/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${currentDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            setRefill(response.data);
          }),
          axios
          .get(
            `/api/consumableproduct/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${currentDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            setConsumable(response.data);
          }),
         
          axios
          .get(
            `/api/conversion-exchange/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${currentDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            setConversion(response.data);
          }),
       
        axios
          .get(
            `/api/product/productstatus=true/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setProduct(res.data);
          }),
  
        axios
          .get(
            `/api/vehicle/orgid=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setVehicle(res.data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              //toast.success(error.response.data.message);
            } else {
              //toast.error(error.response.data.message);
            }
          }),
          axios
          .get(
            `/api/DeliveryAgentCashPayment/office-payment/orgid=${
              JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${currentDate}
            `,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setPayedcash(res.data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              //toast.success(error.response.data.message);
            } else {
              //toast.error(error.response.data.message);
            }
          }),
          
          axios
          .get(
            `/api/cashpaymentexpenses/office-expenses/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }/date=${currentDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setExpenses(res.data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              //toast.success(error.response.data.message);
            } else {
              //toast.error(error.response.data.message);
            }
          }),

          
        ])
        .finally(() => {
          setLoading(false); // Set loading to false after all requests complete
        });
      };
  
    const getOrganizationProfile = async () => {
      const token = JSON.parse(localStorage.getItem('data')).token;
  
      try {
        const response = await axios.get(
          `/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOrgStartDate(response.data.createddate);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Organization profile not found:', error.response.data.message);
          // Handle 404 error (organization profile not found)
        } else {
          console.error('Error fetching organization profile:', error.response.data.message);
          // Handle other errors
        }
      }
    };
  
    const [products, setProducts]=useState([]);
    useEffect(() => {
      const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      axios
          .get(
            `/api/product/productstatus=true/orgcode=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setProducts(res.data);
          })
        }
        fetchData();
  
      getData();
      getOrganizationProfile();
    }, []);
    

    const handleRefresh8 = async () => {
      setLoader8(true); // Set Loader to true
      
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    
        // Make parallel requests using Promise.all
        const [consumableResponse, refillResponse] = await Promise.all([
          axios.get(`/api/consumableproduct/orgcode=${orgcode}/date=${currentDate}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`/api/refillproduct/orgcode=${orgcode}/date=${currentDate}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);
    
        // Update state with the responses
        setConsumable(consumableResponse.data);
        setRefill(refillResponse.data);
      } catch (error) {
        console.error("Error refreshing data:", error);
      } finally {
        setLoader8(false); // Set Loader to false after requests are completed
      }
    };
    
    const handleRefresh9 = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
      setLoader9(true);
  
      try {
      
  
       
        const response = await axios.get(
          `/api/conversion-exchange/orgcode=${orgcode}/date=${currentDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setConversion(response.data);

       
      } catch (error) {
        setLoader9(false);
  
      } finally {
        setLoader9(false);
      }
    };
   
    const handleProductTypeClick = (productTypeId) => {
      setSelectedProductType(productTypeId);
      if(productTypeId===1){
      setIsRefillModalOpen(true);
      }else{
        setIsModalOpen(true);
      }
    };
  
    const handleCloseModal = () => {
      if(selectedProductType===1){
      setIsRefillModalOpen(false);
      }else{
      setIsModalOpen(false);
      }
      getData();
    };
   
  const onClickhandler2 = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/consumableproduct",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
     
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        console.error("Error deleting :", errorMessage);
        toast.error(errorMessage);
    } else {
        console.error("Error deleting :", error);
    }
    setLoading(false);
}
};
const handleProduct = () => {
  setIsConversionModalOpen(true);
};

const handleConversionCloseModal = () => {
  setIsConversionModalOpen(false);
};

const openOfficeRefillSalesModal = async (orgcode,date,productcode) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  // setSelectedProductcode(productcode);
 try {
  const trans = await axios.get(
    `/api/refillproduct/orgcode=${orgcode}/date=${date}/productcode=${productcode}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  setOfficeRefillsales(trans.data);
  setOfficeRefillsalesModalIsOpen(true);
}catch(error){
  handleError(error);
}
};

const closeOfficeRefillSalesModal = () => {
  setOfficeRefillsalesModalIsOpen(false);
  searchName(searchDate);
};

const openOfficeConsumableModal = async (orgcode,date,productcode) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  // setSelectedProductcode(productcode);
 try {
  const trans = await axios.get(
    `/api/consumableproduct/orgcode=${orgcode}/date=${date}/productcode=${productcode}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  setConsumableDetails(trans.data);
  setOfficeConsumablesModalIsOpen(true);
}catch(error){
  handleError(error);
}
};

const closeOfficeConsumablesModal = () => {
  setOfficeConsumablesModalIsOpen(false);
  searchName(searchDate);
};

function handleError(error) {
  if (error.response && error.response.status === 401) {
    navigate(`/signin`);
    toast.error("Your session has expired. Please login.");
  } else {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('An error occurred.');
    }
  }
}

// const openExemptedModal = async (productName, date, deliveredVehicleid) => {
//   setSelectedProductName(productName);
//   try {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     const currentDate = new Date().toISOString().split('T')[0];
//     const response = await axios.get(
//       `/api/ExemptedDetails/officesales/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     setExemptedDetails(response.data);
//     setExemptedModalIsOpen(true);
//   } catch (error) {
//     handleError(error);
//   }
// };

// const closeExemptedModal = () => {
//   setExemptedModalIsOpen(false);
// };
// const openAdditionalModal = async (productName, date, deliveredVehicleid) => {
//   setSelectedProductName(productName);
//   try {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     const currentDate = new Date().toISOString().split('T')[0];
//     const response = await axios.get(
//       `/api/additionaldetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     setAdditionaldetails(response.data);
//     setAdditionalModalIsOpen(true);
//   } catch (error) {
//     handleError(error);
//   }
// };

// const closeAdditionalModal = () => {
//   setAdditionalModalIsOpen(false);
 
// };

// const openNewConnectionModal = async (productName, date, deliveredVehicleid) => {
//   setSelectedProductName(productName);
//   try {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     const currentDate = new Date().toISOString().split('T')[0];
//     const response = await axios.get(
//       `/api/newconnectiondetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     setNewConDetails(response.data);
//     setNewConnectionModalIsOpen(true);
//   } catch (error) {
//     handleError(error);
//   }
// };

// const closeNewConnectionModal = () => {
//   setNewConnectionModalIsOpen(false);
// };

// const openOnlineModal = async (productName, date, deliveredVehicleid) => {
//   setSelectedProductName(productName);
//   try {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     const currentDate = new Date().toISOString().split('T')[0];
//     const response = await axios.get(
//       `/api/OnlineDetail/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     setOnlineDetails(response.data);
//     setOnlineModalIsOpen(true);
//   } catch (error) {
//     handleError(error);
//   }
// };

// const closeOnlineModal = () => {
//   setOnlineModalIsOpen(false);
// };

const handleAddExpenses = () => {
  setSelectedExpenses(null);
  setIsExpensesModalOpen(true);
};

const handleUpdateExpenses = (exepensesDetails) => {
  setSelectedExpenses(exepensesDetails);
  setIsExpensesModalOpen(true);
};

const handleCloseExpensesModal= () => {
  setIsExpensesModalOpen(false);
  getData();
};

const onClickhandler = async (e, id) => { 
  const token = JSON.parse(localStorage.getItem("data")).token;
  setLoading(id);
  try{
    await axios({
    url: "/api/cashpaymentexpenses",
    method: "delete",

    headers: { Authorization: `Bearer ${token}` },

    data: {
      id: `${id}`,
   
      orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
    },
  });
  toast.success("Deleted successfully");
  setLoading(false); 
  getData();
} catch (error) {
  if (error.response && error.response.status === 500) {
      const errorMessage = error.response.data.message;
      console.error("Error deleting Expenses:", errorMessage);
      toast.error(errorMessage);
  } else {
      console.error("Error deleting Expenses:", error);
  }
  setLoading(false);
}
};

const handleFinanicalDelete = (id) => {
  setLoading(true);
    axios
      .delete(`/api/DeliveryAgentCashPayment`, {
        data: { id }, 
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          getData().then(() => {
            navigate(`/users/updatetin/${transaction.date}`);
          });
          toast.success("Financial Deleted Successfully");      
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        toast.error("Error deleting Financial");
      });
  }
  function rhandle1(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setVehicledetails({ vehicledetails, [field]: actualValue });
  }
  const searchbyvehi = async (e, id) => {
    e.preventDefault();
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyv(false);
    if (id === "ALL") {
      setLoader(true);
      const currentDate = new Date().toISOString().slice(0, 10); // Assuming currentDate is defined
    
      try {
        const tinResponse = await axios.get(
          `/api/DeliveryAgentCashPayment/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    
      setLoader(false);
    } else {
    const tin = await axios.get(
      `/api/DeliveryAgentCashPayment/orgid=${JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${searchDate}/vehicleid=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setLoader(false);
    setSearchbyvehicle(tin.data);
  }
  };
  function handleFinancialEdit(id) {
    setIsUpdateDaCashModalOpen(true);
   setDaCashById(id);
  }

  const handleFinancialEditColseModal = () => {
    setIsUpdateDaCashModalOpen(false);
    getData().then(() => {
      navigate(`/users/Counter`);
    });
  };

  function updateFinancials(e, searchDate) {
    navigate(`/users/dacash1/${searchDate}`);
  }
 
  const [selectedDaCashId, setSelectedDaCashId] = useState(null);
  const openDenominationsModal = async (daCashId) => {
      setSelectedDaCashId(daCashId);
      SetDenominationsModalIsOpen(true);
   
  };
  
  const closeDenominationModal = () => {
    setSelectedDaCashId(null);
    SetDenominationsModalIsOpen(false);
   
  };


  const [beyondProjectedValues, setBeyondProjectedValues] = useState([]);
  const [refillProjectedValues, setRefillProjectedValues] = useState([]);
  const [consumablesProjectedValues, setConsumablesProjectedValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader8(true); // Start loading
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
 
        const projectedValues = await axios.get(
          `/api/CloseStockDo/live-godown-stock/orgcode=${orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const filteredRefillProducts = projectedValues.data.prodDo.filter(product =>
          product.product.productCategory?.productCategoryTypeId === 1
        );
        setRefillProjectedValues(filteredRefillProducts);

         const filteredBeyondProducts = projectedValues.data.prodDo.filter(product =>
          product.product.productCategory?.productCategoryTypeId === 2
        );
        setBeyondProjectedValues(filteredBeyondProducts);
        setErrorMessage("");
        setLoader8(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // handleError(error);
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        }
      } finally {
        setLoader8(false); // End loading
      }
    };
 
    fetchData();
 
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
 
    return () => clearInterval(interval); 
  }, []);

  const tableCellStyle = {
    padding: '10px',  // Increased padding
    fontSize: '14px' // Increased font size
  };

  const headerCellStyle = {
    padding: '12px',  
    fontSize: '14px' 
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
     
      {loading && <div className="loading"></div>}

      <div className="col-12 ps-xl-10 mt-10">
      <div className="card card-raised">
      <div className="card-body">
      <h5 className="card-title" style={{ fontSize: '18px' }}>Office Sales</h5>
             <div className="row">
            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '25px'}}>
              {productTypes.map((type) => (
                <div key={type.id}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleProductTypeClick(type.id)}
                  >
                    {type.categorytype}
                  </button>
                </div>
              ))}
               <button
                    type="button"
                    className="btn btn-primary"
                    // onClick={() => handleProductTypeClick(type.id)}
                  >
                    Service
                  </button>
            </div>
            </div>
            </div>
          </div>
          {isModalOpen && selectedProductType && (
          <ConsumableModalOpen isOpen={isModalOpen} onRequestClose={handleCloseModal} productTypeId={selectedProductType} 
          date={searchDate}
          />
          )}

{isRefillModalOpen && (
          <RefillOfficeSalesModalOpen isOpen={isRefillModalOpen} onRequestClose={handleCloseModal}
          date={searchDate}
          />
          )}
         
        </div>
        <div className="col-12 ps-xl-10 mt-4">
      <div className="card card-raised">
      <div className="card-body">
        <div className="row">
        <div className="col-md-7">
  <div className="card card-raised" style={{ position: 'relative' }}>
    <div className="card-body">
      <div className="d-flex justify-content-center align-items-center mb-2">
        <div className="card-title" style={{ fontSize: '18px' }}>
          Refill Live Stock
          {loader8 && (
            <div className="loader" style={{ left: '15px', transform: 'translateY(-50%)' }}></div>
          )}
          <button
            className="btn btn-primary"
            style={{
              fontSize: '9px',
              padding: '7px 5px',
              marginLeft: '10px',
            }}
            // onClick={handleRefresh}
          >
            Refresh
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="triangle-container mt-6" style={{ position: 'relative' }}>
        <div className="triangle" style={{
          width: '0',
          height: '0',
          borderLeft: '290px solid transparent',
          borderRight: '290px solid transparent',
          borderBottom: '70px solid #6200ea',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '0', // Position the triangle at the bottom of the card
        }}>
          <div style={{
            position: 'relative',
            color: 'white',
            marginTop:"30px",
            right:"30px",
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            zIndex: 1, // Ensure the text is above the triangle
          }}>
            GODOWN
          </div>
        </div>
      </div>
      <div className="table-responsive" style={{ maxHeight: '350px', overflowY: 'auto' }}>
          <table className="table table-bordered">
            <thead>
              <tr style={{ fontSize: "14px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                <th style={headerCellStyle}>Product</th>
                <th style={headerCellStyle} id="full">Full</th>
                <th style={headerCellStyle} id="empty">Empty</th>
                <th style={headerCellStyle} id="defective">Defective</th>
              </tr>
            </thead>
            <tbody>
            {refillProjectedValues.length === 0 ? (
          <div style={{ textAlign: 'center', fontSize: '14px', padding: '20px' }}>No data available</div>
        ) : (
              refillProjectedValues.map((products) => (
                <tr key={products.product.id}>
                  <th style={tableCellStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {products.product.image && products.product.imageMeta ? (
                          <img
                            className="img-fluid rounded-circle mb-1"
                            src={`data:image/png;base64,${products.product.image}`}
                            alt="Product"
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        ) : (
                          <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                        )}
                      </div>
                      <span style={{ fontSize: '12px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                      </span>
                    </div>
                  </th>
                  {products.pdo.map((ps, index) => (
                    <td style={tableCellStyle} key={index}>{ps.qty}</td>
                  ))}
                </tr>
              )))}
            </tbody>
          </table>
      </div>
      {errorMessage && (
        <div style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>
          {errorMessage}
        </div>
      )}
    </div>
  </div>
</div>


    <div className="col-md-5">
      <div className="card card-raised">
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <div className="card-title" style={{ fontSize: '18px' }}>
              Sales Live Stock
              {loader8 && (
                <div className="loader" style={{ position: 'relative', left: '15px' }}></div>
              )}
              <button
                className="btn btn-primary"
                style={{
                  fontSize: '9px',
                  padding: '7px 5px',
                  marginLeft: '20px',
                }}
                // onClick={handleRefresh}
              >
                Refresh
              </button>
            </div>
          </div>
          <br />
          <br />
          <div className="table-responsive" style={{maxHeight: '350px', overflowY: 'auto'}}>
            <table className="table table-bordered">
              <thead>
                <tr style={{ fontSize: "14px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                  <th style={headerCellStyle}>Product</th>
                  <th style={headerCellStyle}>Quantity</th>
                </tr>
              </thead>
              <tbody>
              {beyondProjectedValues.length === 0 ? (
          <div style={{ textAlign: 'center', fontSize: '14px', padding: '20px' }}>No data available</div>
        ) : (
                beyondProjectedValues.map((products) => (
                  <tr key={products.product.id}>
                    <th style={tableCellStyle}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%"}}></div>
                          )}
                        </div>
                        <span style={{fontSize: '12px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                          {products.product.productname}
                        </span>
                      </div>
                    </th>
                    {products.pdo
                      .filter((ps) => ps.status === "full")
                      .map((ps, index) => (
                        <td style={tableCellStyle} key={index}>{ps.qty}</td>
                    ))}
                  </tr>
                )))}
              </tbody>
            </table>
          </div>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</div>


<br/>
      <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised">
            <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
               <span className="card-title"> Office Sale Details </span>          
                  {/* {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}  */}

<span>
<button className="btn btn-primary"
style={{
 fontSize: '10px',marginRight:"400px"}}  onClick={handleRefresh8}>
Refresh
</button> 
</span>
<div>
          <Search searchName={searchName} />
        </div>
              {/* <button className="btn btn-primary"onClick={handlecreatesale}>
               Create Sale
            </button> */}
          
            </div>
                  
            <br/>         
            <div className="mt-0.5 mb-0.5">
            <h2 className="card-title" style={{fontSize:"17px"}}>Refill Sales :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "12.4px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                   <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Sale</th>
                  <th>New Connection</th>
                  <th>Additional</th>

                  <th>Exempted</th>
                  <th>Onlinepay</th>
                  <th>Transfer</th>
                  <th>Total</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
              { refill.length === 0 ? (
            <tr>
              <td colSpan="14" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            
            refill.map((product) => {
                  return (
                    <tr key={product.id}>
                   <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                     <td>{product.sale}</td>

                      <td>{product.newconnection}</td>

                      <td>{product.additional}</td>
                    
                      <td>{product.exempted} </td>
                      <td>{product.onlinepay} </td>
                      <td>{product.transfer} </td>
                      <td>{product.total}</td>
                      <td>
                        <button className="btn btn-primary" onClick={() => openOfficeRefillSalesModal(product.orgcode, product.date,product.productCode,)}>View</button>
                      </td>
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <br/>
            <div className="mt-0.5 mb-0.5">
            <h6 className="card-title" style={{fontSize:"17px"}}>Sales :</h6>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "12.4px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>

                  <th>Total</th>
                 
                   <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {consumable.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
          
            consumable.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.productname}</td>

                      <td>{product.productcode}</td>
                      <td>{product.quantity}</td>

                      <td>{product.total}</td>
                    
                      <td>
                        <button className="btn btn-primary" onClick={() => openOfficeConsumableModal(product.orgcode, product.date, product.productcode)}>View</button>
                      </td>
                   
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
    
           
      </div>


          </div>

        </div>{" "}
        

      </div> 
      <br/>

           <div className="col-12 mx-xl-auto ps-xl-10">

        <div className="card card-raised">
          <div className="card-body  ">
          <div className="d-flex align-items-center justify-content-between">
               <span className="card-title"> Paid Cash Details </span>          
                  {/* {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}  */}

{/* <span>
<button className="btn btn-primary"
style={{
 fontSize: '10px',marginRight:"400px"}}  onClick={handleRefresh}>
Refresh
</button> 
</span> */}
<div>
          <Search searchName={cashPaymentSearch} />
        </div>
              <button className="btn btn-primary"  onClick={(e) => updateFinancials(e, searchDate)}>
               Add
            </button>
          
            </div>
                  
            <br />
            <table className="table table-bordered ">
              <thead>
              <tr
                  style={{
                    fontSize: "12.4px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Totalamount</th>
                 
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {payedcash.length === 0 ? (
            <tr>
              <td colSpan="2" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

            payedcash.map((pcash) => {
                  return (
                    <tr key={pcash.id}>
                   
              {/* <td>{pcash.vehicleid}</td> */}
              <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{pcash.totalamount}</span>
              <FiInfo
                onClick={() => openDenominationsModal(pcash.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </td>
              <td> <div style={{ display: "flex", justifyContent: "center" }}>

{/* Edit button  */}
{/* <Button variant="light" style={{ color: "blue" }} title="Edit" 
onClick={() => handleFinancialEdit(pcash.id)}
>
   <CiEdit />
 </Button>{" "} */}


 <span style={{ margin: "0 5px" }}></span>

  {/* Delete button  */}
  <Button variant="light"style={{ color: "black" }} title="Delete"
   onClick={() => handleFinanicalDelete(pcash.id)}
   >
   <AiFillDelete />
 </Button>

</div>
</td>
</tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            {/* <div className="row">
              <div className="form-floating-sm mb-2 d-flex">
                <h3>Total: </h3>{" "}
                <input
                  className="form-control"
                  id="totalamount"
                  value={finaltotal}
                />
              </div>
            </div> */}
          </div>
          <div>
         
         {denominationsModalIsOpen && (
        <DaDenominations
        isOpen={denominationsModalIsOpen}
        onRequestClose={closeDenominationModal}
        daCashId={selectedDaCashId}
      />
         )}
     
            {isUpdateDaCashModalOpen && (
            <UpdateDaCashDetails1
            isOpen={isUpdateDaCashModalOpen} 
            onRequestClose={handleFinancialEditColseModal} 
            // daCashDetails={daCashById}
            // daCashDenom={daCashDenom}
            daCashId={daCashById}
            />
            )}
            </div>
        </div>
      </div>
      <br/>
      <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised">
            <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
               <span className="card-title"> Expenses </span>          
                  {/* {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}  */}

<span>
<button className="btn btn-primary"
style={{
 fontSize: '10px',marginRight:"400px"}}  onClick={handleRefresh8}>
Refresh
</button> 
</span>
<div>
          <Search searchName={expensesSearch} />
        </div>
              <button className="btn btn-primary" onClick={handleAddExpenses}>
               Add Expenses
            </button>
          
            </div>
                  
            <br/>         
      
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "12.4px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Amount</th>
                  <th>Description</th>
                 <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {expenses.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

            expenses.map((exp) => {
                  return (
                    <tr key={exp.id}>
                      <td>{exp.amount}</td>
                      <td>{exp.description}</td>
                      <td> 
                     <button
                      onClick={() => handleUpdateExpenses(exp)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              exp.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
          
           
            <div>
          
           
           
      </div>
          </div>
        </div>{" "}
      </div> 
      <br/>
     
      <div className="col-12 mx-xl-auto ps-xl-10" >
          <div className="card card-raised">
            <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
               <span className="card-title"> Conversions </span>          
                  {/* {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}  */}

<span>
<button className="btn btn-primary"
style={{
 fontSize: '10px',marginRight:"400px"}}  onClick={handleRefresh9}>
Refresh
</button> 
</span>
<div>
          <Search searchName={conversionSearch} />
        </div>
<button className="btn btn-primary"onClick={handleProduct}>
               ADD
            </button> 
          
            </div>
                  
            <br/>         
            <div className="mt-0.5 mb-0.5">
            {/* <h2 className="card-title" style={{fontSize:"17px"}}>Conversion</h2> */}
            </div>  
           
            <br/>
            <div className="mt-0.5 mb-0.5">
            <h6 className="card-title" style={{fontSize:"17px"}}></h6>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "12.4px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>From Product</th>
                  <th>To Product</th>
                  <th>Quantity</th>
                  <th>Customer Name</th>
                  <th>Customer Number</th>
                  <th>Phone Number</th>

                

                </tr>
              </thead>
              <tbody>
              {conversion.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
          
            conversion.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.fromProduct}</td>

                      <td>{product.toProduct}</td>
                      <td>{product.quantity}</td>
                      <td>{product.customerName}</td>
                      <td>{product.customerNumber}</td>

                      <td>{product.phoneNumber}</td>
                    
                      <td> 
                    
                    <button
                         style={{
                           border: "none",
                           background: "white",
                           color: "grey",
                           position: "relative",
                           left: "20px",
                           fontSize: "30px",
                         }}
                         onClick={(e) => {
                           onClickhandler2(
                             e,
                             product.id,
                            
                           );
                         }}
                       >
                         <MdDeleteForever />
                       </button>
                  </td>
                   
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
          
          {isConversionModalOpen && (
            <ConversionModal isOpen={isConversionModalOpen} onRequestClose={handleConversionCloseModal} />
          )}
      </div>


          </div>

        </div>{" "}
        

      </div> 
      {officeRefillsalesModalIsOpen &&(
       <OfficeRefillSalesDetails
        isOpen={officeRefillsalesModalIsOpen} 
       onRequestClose={closeOfficeRefillSalesModal}
       refillSalesDetails={officeRefillSales}
      //  productName={selectedProductName}
      />
)}

{officeConsumablesModalIsOpen &&(
       <OfficeConsumables
        isOpen={officeConsumablesModalIsOpen} 
       onRequestClose={closeOfficeConsumablesModal}
       consumables={consumableDetails}
      //  productName={selectedProductName}
      />
)}

{isExpensesModalOpen && (
          <UpdateOfficeExpenses isOpen={isExpensesModalOpen} onRequestClose={handleCloseExpensesModal} expensesDetails={selectedExpenses} />
          )}
    
    
      </div> 
  );
}

export default Counter;
