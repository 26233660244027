import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import StockistproducteditModalPopup from "./stoclistprodycredit";
import Addstockistproduct from "./Addstockistproduct";
import Updatestockistprice from "./Updatestoclistprice";
import { FiInfo } from "react-icons/fi";
import PriceHistory from "./Pricehistory";
function ProductViewModal({ isOpen, onRequestClose, vendorId }) {
    console.log(vendorId);
  const [vendor, setVendor] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false); // New state for update price modal
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product for price update
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [priceHistory, setPriceHistory] = useState([]); 

      const fetchVendorData = async () => {
        setLoading(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;

         

          // Fetch vendor products
          const productResponse = await axios.get(`/api/vendorproduct/vendorid=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setProducts(productResponse.data);
          
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError("Error fetching vendor details");
        }
      };
     
  useEffect(() => {
    if (vendorId) {
      fetchVendorData();
    }
  }, [vendorId]);
  const handleEdit = async (vendorId) => {
    setSelectedVendorId(vendorId);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchVendorData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/vendorproduct",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    fetchVendorData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
const filteredproducts = products.filter((products) =>
    products.productname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAdd = async () => {
    setSelectedVendorId(vendorId);
 
      setIsModalOpen(true);
   
  };
  const closeModal = () => {
    setIsModalOpen(false);
    fetchVendorData();
 
  };

  const handleUpdatePrice = (productId) => {
    setSelectedProduct(productId); 
    setIsPriceModalOpen(true); 
  };

  const closePriceModal = () => {
    setIsPriceModalOpen(false);
    fetchVendorData();
  };

  const openPriceHistoryModal = async (productId) => {
    setSelectedProduct(productId); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/VendorProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
    //   handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProduct(null);
    setIsPriceHistoryModalOpen(false);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      <Modal.Title style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        Product Details
        <input
  type="text"
  className="form-control"
  style={{
    width: '200px',  
    display: 'inline-block', 
    paddingLeft: '10px', 
    marginLeft: '20px',
  }}  placeholder="Search Products"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
/>
</div>
  <button
    className="btn btn-primary"
    onClick={handleAdd}
    style={{
      fontSize: '12px', // Reduce font size
      padding: '5px 10px', // Adjust padding
      left:"210px"
    }}
  >
    ADD
  </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      <>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>SKU</th>
                      <th>HSN Code</th>
                      <th>Base Price</th>
                      <th>GST Rate</th>
                      <th>Update Price</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>
                  {filteredproducts.length > 0 ? (

filteredproducts.map((product, index) => (
                      <tr key={index}>
                        <td>{product.productname}</td>
                        <td>{product.sku}</td>
                        <td>{product.hsncode}</td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.baseprice && (
                                <>
                                  {product.baseprice}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.id)} style={{ cursor: 'pointer' }} />
                                  </span>
                                </>
                              )}
                          </div>
                        </td>

                        <td>{product.gstrate}</td>
                        <td>
                        <button
                          onClick={() => handleUpdatePrice(product.id)}
                           className="btn btn-primary btn-sm"
                          style={{
                            border: "none",
                            color: "white",          
                            padding: "2px 6px",     
                            fontSize: "10px",        
                            borderRadius: "5px",    
                            cursor: "pointer"      
                          }}
                        >
                          Update Price
                        </button>
                      </td>
                        <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                         
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "10px",
                            fontSize: "24px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
                  </tbody>
                </table>

                {isEditModalOpen && (
              <StockistproducteditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                vendorId={selectedVendorId}
                />
            )}

{isModalOpen && (
              <Addstockistproduct
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                vendorId={selectedVendorId}
                />
            )}
            {isPriceModalOpen && (
              <Updatestockistprice
                isOpen={isPriceModalOpen}
                onRequestClose={closePriceModal}
                productId={selectedProduct}
                />
            )}
             {isPriceHistoryModalOpen && (
              <PriceHistory
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                productId={selectedProduct}
              />
            )}
              </div>
           
          </>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductViewModal;
