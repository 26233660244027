import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
function StockistViewModal({ isOpen, onRequestClose, vendorId }) {
  const [vendor, setVendor] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

      const fetchVendorData = async () => {
        setLoading(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;

          // Fetch vendor profile
          const vendorResponse = await axios.get(`/api/stocklist/vendorid=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setVendor(vendorResponse.data);

        
          
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError("Error fetching vendor details");
        }
      };
     
  useEffect(() => {
    if (vendorId) {
      fetchVendorData();
    }
  }, [vendorId]);
  const handleEdit = async (vendorId) => {
    setSelectedVendorId(vendorId);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchVendorData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/stocklist",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    fetchVendorData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
const filteredVendors = vendor.filter((vendor) =>
    vendor.name && vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAdd = async () => {
    setSelectedVendorId(vendorId);
 
      setIsModalOpen(true);
   
  };
  const closeModal = () => {
    setIsModalOpen(false);
    fetchVendorData();
 
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      <Modal.Title style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
    Stockist Details 
        <input
  type="text"
  className="form-control"
  style={{
    width: '200px',  
    display: 'inline-block', 
    paddingLeft: '10px', 
    marginLeft: '20px',
  }}  placeholder="Search Stockist"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
  />
  </div>
  <button
    className="btn btn-primary"
    onClick={handleAdd}
    style={{
      fontSize: '12px', // Reduce font size
      padding: '5px 10px', // Adjust padding
      left:"210px"
    }}
  >
    ADD
  </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendor, index) => (
                    <tr key={index}>
                      <td>{vendor.name}</td>
                      <td>{vendor.email}</td>
                      <td>{vendor.phone}</td>
                      <td>{vendor.address}</td>
                      <td> 
                     <button
                      onClick={() => handleEdit(vendor.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "10px",
                            fontSize: "24px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              vendor.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {isEditModalOpen && (
              <StockisteditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                vendorId={selectedVendorId}
                />
            )}
 {isModalOpen && (
              <Addstockist
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                vendorId={selectedVendorId}
                />
            )}

          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StockistViewModal;