import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate here
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";
import jsPDF from "jspdf"; // Import jsPDF

function ExcemptedcustomerpaymentReport() {
  const navigate = useNavigate(); // Define navigate here

  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomer, setExemptedcustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const uniqueCategories = [...new Set(exemptedcustomer.map(ecustomer => ecustomer.customercategory))];

  const getData = async () => {
    setLoader(true);
    try {
      const trans = await axios.get(
        `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setExemptedcustomercategory(trans.data);

      const trans1 = await axios.get(
        `/api/exemptedcustomer/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setExemptedcustomer(trans1.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Filter data based on selected filters
  const getCurrentItems = () => {
    const filteredData = exemptedcustomer.filter(customercategory => {
      const categoryFilterPass = selectedCategoryFilter === '' || customercategory.customercategory.includes(selectedCategoryFilter);
      const statusFilterPass = selectedStatusFilter === '' ||
        (selectedStatusFilter === 'enabled' && !customercategory.disable) ||
        (selectedStatusFilter === 'disabled' && customercategory.disable);
      return categoryFilterPass && statusFilterPass;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  };
  const generatePDF = () => {
    const doc = new jsPDF();
  
    const imgData = img;
    const logoWidth = 50;
    const logoHeight = 20;
    const logoX = 10;
    const logoY = 10;
    
    // Add the logo to the left side
    doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
  
    // Add the name of the report below the logo
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    const reportName = "Exempted Customer Delivery Report";
    const reportNameY = logoY + logoHeight + 5;
    doc.text(reportName, logoX, reportNameY);
  
    // Add the address aligned to the right below the logo
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
    const textX = doc.internal.pageSize.width - 10;
    const addressY = reportNameY + 10;
    doc.text(addressText, textX, addressY, { align: "right" });
  
    // Add report name "Vijay Gas Agencies" aligned to the right
    doc.setFontSize(18);
    doc.setTextColor(86, 150, 214);
    const companyTextY = addressY + 10;
    doc.text("Vijay Gas Agencies", textX, companyTextY, { align: "right" });
  
    // Get filtered data for the table
    const filteredData = getCurrentItems(); 
  
    // Define the table headers and rows
    const headers = ["Deliverydate", "Customer Number", "Customer Name", "Category Name", "Distance", "Tariff"];
    const rows = filteredData.map((ecustomer) => [
      ecustomer.deliverydate,
      ecustomer.customerNumber,
      ecustomer.customername,
      ecustomer.customercategory,
      ecustomer.distance,
      ecustomer.tariff
    ]);
  
    // Add the table to the PDF, starting below the last section
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: companyTextY + 10, // Adjust position based on the last content
    });
  
    // Save the PDF with the desired filename
    doc.save("exempted-customer-report.pdf");
  };
  

  return (
    <div>
      <AdminDashboard />
      <br/><br/><br/><br/>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>

            {loading && <div className="loading">Loading...</div>}

            <div className="col-10 mx-xl-auto ps-xl-9 mt-10">
              <div className="card card-raised">
                <div className="card-body">
                  <div className="filter-dropdowns">
                    <div className="row">
                      <div className="col-7">
                        <div controlId="categoryFilter">
                          <label>FILTER BY CATEGORY</label>
                          <select
                            value={selectedCategoryFilter}
                            onChange={(e) => {
                              setSelectedCategoryFilter(e.target.value);
                              setCurrentPage(1); // Reset page to 1 when filter changes
                            }}
                          >
                            <option value="">All</option>
                            {uniqueCategories.map((category) => (
                              <option key={category} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <button onClick={generatePDF} className="btn btn-primary">
                          Generate PDF
                        </button>
                      </div>
                    </div>
                  </div>

                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                        <th>Deliverydate</th>
                        <th>Customer Number</th>
                        <th>Customer Name</th>
                        <th>Category Name</th>
                        <th>Distance</th>
                        <th>Tariff</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentItems().map((ecustomer) => (
                        <tr key={ecustomer.id} style={{ textAlign: "center" }}>
                          <th>{ecustomer.deliverydate}</th>
                          <th>{ecustomer.customerNumber}</th>
                          <th>{ecustomer.customername}</th>
                          <th>{ecustomer.customercategory}</th>
                          <th>{ecustomer.distance}</th>
                          <th>{ecustomer.tariff}</th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExcemptedcustomerpaymentReport;
