import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function Updatestockistprice({ isOpen, onRequestClose, productId }) {
    const date = new Date();
    date.setDate(date.getDate());
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
  const [uproduct1, setUproduct1] = useState({
    date:currentDate,
    price: "",
    productid: productId,
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/vendorproduct/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { baseprice } = response.data; // Extract baseprice from response
      setUproduct1((prevState) => ({
        ...prevState,
        price: baseprice, // Update only the price with baseprice
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
 
  useEffect(() => {
    if (productId) {
      getData();
    }
  }, [productId]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/VendorProductPrice/updateOrCreatePrice",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(" Price updated successfully");
        getData();

        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;

   
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Price</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="date">DATE</label>
                  <input
                    className="form-control"
                    id="date"
                    type="text"
                    placeholder="date"
                    value={uproduct1.date}
                    onChange={(e) => rhandle(e, "date")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="price">Price</label>
                  <input
                    className="form-control"
                    id="price"
                    type="text"
                    placeholder="price"
                    value={uproduct1.price}
                    onChange={(e) => rhandle(e, "price")}
                  />
                  </div>
              </div>
             
            
                 
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default Updatestockistprice;