import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import ProducteditModalPopup from "./productedit";
import Modalpopup from "./Modalpopup";

function Bills() {
  
}

export default Bills;
