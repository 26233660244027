import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import { FaCheck, FaEdit } from "react-icons/fa";

function ViewDriverAndTrucks() {
  const { driverId } = useParams();
  const [trucks, setTrucks] = useState([]);
  const [driverDetails, setDriverDetails] = useState([]);
  const [editMode, setEditMode] = useState(false);
//   const [newDriverName, setNewDriverName] = useState(driverName);
//   const [newContact, setNewContact] = useState(contact);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [showModal, setShowModal] = useState(false); // For merge confirmation popup

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransporterDetails = async () => {
      try {
        setLoading(true);
        const driverRes = await axios.get(
            `/api/truck-drivers/${driverId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          setDriverDetails(driverRes.data);
        const response = await axios.get(
          `/api/loadin-meta/getByDriverId/driverId=${driverId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setTrucks(response.data);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTransporterDetails();
  }, [driverId]);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  // Toggle between display and edit mode
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Handle saving the edited values
//   const handleSave = async () => {
//     // Here you can add logic to check if the driver name already exists
//     try {
//       const response = await axios.post(`/api/loadin-meta/checkByDrivername`, {
//         // orgcode,
//         // drivername: driverName,
//       },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );

//       if (response.data===true) {
//         setShowModal(true); 
//       } else {
//         await axios.post(`/api/update-driver`, {
//           orgcode,
//         //   oldDriverName: driverName,
//           newDriverName,
//           newContact
//         });
//         toast.success("Driver details updated successfully!");
//         setEditMode(false); // Switch back to display mode
//       }
//     } catch (err) {
//       handleError(err);
//     }
//   };

  // Handle cancel in the modal
  const handleCancel = () => {
    setShowModal(false);
  };

  // Handle merge confirmation
//   const handleConfirmMerge = async () => {
//     try {
//       await axios.post(`/api/loadin-meta/merge-drivers`, {
//         orgcode:orgcode,
//         // oldDrivername:driverName,
//         drivername: newDriverName,
//         contact: newContact
//       },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//       toast.success("Drivers merged successfully!");
//       setShowModal(false);
//       setEditMode(false); // Exit edit mode after merge
//       navigate("/users/view-trucks")
//     } catch (err) {
//       handleError(err);
//     }
//   };

const handleBackClick = () => {
    navigate(-1);
};
  return (
    <div>
      <AdminDashboard />
      <div className="col-10 mx-xl-auto ps-xl-10 mt-10">
      {loading && <div className="loading"></div>}
        <div className="card card-raised">
          <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-4">
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  {/* Using inline styling to keep inputs on the same line */}
                  <h6 style={{ margin: 0 }}>
                    Driver Name:{" "}
                    {editMode ? (
                      <input
                        type="text"
                        value={driverDetails?.drivername}
                        // onChange={(e) => setNewDriverName(e.target.value)}
                     
                      />
                    ) : (
                      <span>{driverDetails?.drivername}</span>
                    )}
                  </h6>
                  <h6 style={{ margin: 0, marginTop: "10px" }}>
                    Contact:{" "}
                    {editMode ? (
                      <input
                        type="text"
                        value={driverDetails?.contact}
                        // onChange={(e) => setNewContact(e.target.value)}
                      
                      />
                    ) : (
                      <span>{driverDetails?.contact}</span>
                    )}
                  </h6>
                </div>
                
                {/* Edit and Save icons on the right side */}
                {/* <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                  {editMode ? (
                    <FaCheck
                    //   onClick={handleSave}
                      style={{ cursor: "pointer", color: "green", fontSize: "20px", marginRight: "400px" }}
                      title="Save changes"
                    />
                  ) : (
                    <FaEdit
                      onClick={toggleEditMode}
                      style={{ cursor: "pointer", fontSize: "20px" , marginRight: "400px" }}
                      title="Edit"
                    />
                  )}
                </div> */}
              </div>
              <Button variant="secondary" onClick={handleBackClick}>
                Back
              </Button>
              </div>
            {/* Trucks details here */}
            <div className="trucks-details">
              <div className="card card-raised mb-5">
                <div className="card-body">
                  <div className="card-title">Trucks</div>
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                        <th>Trucks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trucks.length === 0 ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            NO DATA AVAILABLE
                          </td>
                        </tr>
                      ) : (
                        trucks.map((detail, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              title="View Truck Info"
                            >
                              {detail.truckInfo}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Modal for merge confirmation */}
            <Modal show={showModal} onHide={handleCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Driver Name Exists</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Multiple entries exist with this driver name. Do you want to merge them?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary me-3" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" 
                // onClick={handleConfirmMerge}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDriverAndTrucks;
