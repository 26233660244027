import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function ProductcategoryeditModalPopup({ isOpen, onRequestClose, categoryId }) {
  const [uproduct1, setUproduct1] = useState({
    productCategoryTypeId:"",

    categoryname: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [categories, setCategories] = useState([]); // Added to store categories
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch existing product category details
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/productcategories/id=${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false); // Ensure loading stops on error
    }
  };

  useEffect(() => {
    if (categoryId) {
      getData();
    }
  }, [categoryId]);

  // Fetch categories for the select dropdown
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get("/api/productcategorytype", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.data); // Store fetched categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Handle form submit
  const rsubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.post(
        "/api/productcategories/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLoading(false);
      toast.success("Product Categories updated successfully");
      onRequestClose(); // Close modal on success
      getData(); // Refresh data
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Handle input changes
  const rhandle = (event, field) => {
    const actualValue = event.target.value;
    setUproduct1({
      ...uproduct1,
      [field]: actualValue,
    });
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Warehouse Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={rsubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productCategoryTypeId">Update Product Category</label>
                    <select
                      className="form-control"
                      id="productCategoryTypeId"
                      value={uproduct1.productCategoryTypeId}
                      onChange={(e) => rhandle(e, "productCategoryTypeId")}
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.categorytype}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="categoryname">Category Name</label>
                    <input
                      className="form-control"
                      id="categoryname"
                      type="text"
                      placeholder="Category Name"
                      value={uproduct1.categoryname || ""}
                      onChange={(e) => rhandle(e, "categoryname")}
                    />
                  </div>
                </div>

                <br />
                <Button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading} // Disable while loading
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ProductcategoryeditModalPopup;
