import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "../api/baseurl";
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const ViewAllTransporters = ({ isOpen, onRequestClose, onTransporterSelect }) => {
  const [transportersDetails, setTransportersDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term

  const fetchTransportersDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.get(`/api/transporter-details/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTransportersDetails(response.data);
    } catch (error) {
     setLoading(false);
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchTransportersDetails();
    }
  }, [isOpen]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleSelectTransporter = (transporter) => {
    onTransporterSelect(transporter);
    onRequestClose();
  };

  const modalStyles = {
    content: {
      width: 'auto',
      height: 'auto',
      maxHeight: '70%',
      top: '15%',
      left: '40%',
      right: 'auto',
      bottom: 'auto',
      padding: '15px',
      overflow: 'auto',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    marginTop: '20px',
    fontSize: '1.2em',
  };

  const thStyles = {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '15px',
    borderBottom: '2px solid #ddd',
    fontSize: '0.8em',
    textAlign: 'left',
  };

  const tdStyles = {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    textAlign: 'center',
    fontSize: '0.8em',
    cursor: 'pointer',
    backgroundColor: '#fff',
  };

  const closeButtonStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: '15px',
  };

  // Filter transporters based on search term
  const filteredTransporters = transportersDetails.filter((details) =>
    details.transporterName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Transporter Details"
        style={modalStyles}
      >
        <h5>Transporter Details</h5>
        
        {/* Search input and icon */}
        <div style={{ position: "relative", marginBottom: "20px", width: "250px" }}>
          <input
            type="search"
            className="form-control"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              paddingLeft: "2.5rem",  // Leave space for the icon
              width: "100%",  // Full width within the container
            }}
          />
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",  // Center the icon vertically
              fontSize: "1rem",
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
  
        {/* Transporter details */}
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            {filteredTransporters.length === 0 ? (
              <div>No data available</div>
            ) : (
              <table style={tableStyles}>
                <thead>
                  <tr>
                    <th style={thStyles}>Transporter Name</th>
                    <th style={thStyles}>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransporters.map((details, index) => (
                    <tr
                      key={index}
                      style={tdStyles}
                      onClick={() => handleSelectTransporter(details)}
                    >
                      <td>{details.transporterName}</td>
                      <td>{details.phoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
  
        <button onClick={onRequestClose} style={closeButtonStyles}>
          Close
        </button>
      </Modal>
    </>
  );
  };

export default ViewAllTransporters;
