import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function VendoreditModalPopup({ isOpen, onRequestClose, vendorId }) {
  const [uproduct1, setUproduct1] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    pan:"",
    gst:"",
    image:"",
    imageMeta:"",
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/vendorprofile/${vendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (vendorId) {
      getData();
    }
  }, [vendorId]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/vendorprofile/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("updated successfully");
        onRequestClose();
        navigate("/users/Vendorcomponent");
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;

   
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    
  };
 
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
      
      setUproduct1(prevState => ({
        ...prevState,
        imageMeta: metadata,
        image: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Vendor </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="name"> Name</label>
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    placeholder="name"
                    value={uproduct1.name}
                    onChange={(e) => rhandle(e, "name")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="phone">Phone</label>
                  <input
                    className="form-control"
                    id="phone"
                    type="text"
                    placeholder="phone"
                    value={uproduct1.phone}
                    onChange={(e) => rhandle(e, "phone")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control"
                    id="email"
                    type="text"
                    placeholder="email"
                    value={uproduct1.email}
                    onChange={(e) => rhandle(e, "email")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="address">Address</label>
                  <input
                    className="form-control"
                    id="address"
                    type="text"
                    placeholder="address"
                    value={uproduct1.address}
                    onChange={(e) => rhandle(e, "address")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="pan">PAN</label>
                  <input
                    className="form-control"
                    id="pan"
                    type="text"
                    placeholder="pan"
                    value={uproduct1.pan}
                    onChange={(e) => rhandle(e, "pan")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="gst">GST</label>
                  <input
                    className="form-control"
                    id="gst"
                    type="text"
                    placeholder="gst"
                    value={uproduct1.gst}
                    onChange={(e) => rhandle(e, "gst")}
                  />
                  </div>
              </div>
              <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="image">Upload Image</label>
    {uproduct1.image && (
                  <div>
                    <img
                      src={`data:${uproduct1.imageMeta};base64,${uproduct1.image}`}
                      alt="Uploaded"
                      style={{ width: "40px", height: "auto" }}
                    />
                  </div>
                )}
                {uproduct1.imageMeta && (
                  <div>
                    <p>
                      {/* <strong>Image Metadata:</strong> {uproduct1.imageMeta} */}
                    </p>
                  </div>
                )}

    <input
      className="form-control"
      id="image"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>

                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default VendoreditModalPopup;