import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

const ConsumableModalOpen = ({ isOpen, onRequestClose, date }) => {
  const initialProductState = {
    productname: "",
    productcode: "",
    quantity: 0,
    price: 0,
    date:date,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    total:0
  };
  const [consumerDetails, setConsumerDetails] = useState({
    phoneNumber: "",
    consumerNumber: "",
    consumerName: "",
    productId: "",
    address:"",
    area:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [deliveryDetails, setDeliveryDetails] = useState({
    consumerNumber:"",
    deliverystatus: "",
    cashMemoNumber: "",
    bookingDate: date,
    cashMemoDate: date,
    productId: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [productList, setProductList] = useState([]);
  const [productListDetails, setProductListDetails] = useState([]);
  const [productQuantities, setProductQuantities] = useState([]);
  const [exemptedcategory, setExemptedcategory] = useState([]);
  const [printData, setPrintData] = useState(null); // State to store print data

  const [newProduct, setNewProduct] = useState(initialProductState);
 
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [beyondlpg, setBeyondlpg] = useState([]);

  useEffect(() => {
    if (consumerDetails) {
      setDeliveryDetails(prevDetails => ({
        ...prevDetails,
        consumerNumber: consumerDetails.consumerNumber,
      }));
    }
  }, [consumerDetails]);
  const getData = async () => {
    setLoading(true);
  
    try {
      // Retrieve token and orgCode from localStorage
      const tokenData = JSON.parse(localStorage.getItem("data"));
      const token = tokenData.token;
      const orgCode = tokenData.orgcode;
  
      // Fetch exempted customer categories
      try {
        const exemptedCategoryResponse = await axios.get(
          `/api/exemptedcustomercategories/orgcode=${orgCode}/status=false`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setExemptedcategory(exemptedCategoryResponse.data);
      } catch (error) {
        console.error("Error fetching exempted customer categories:", error);
      }
  
      // Fetch products
      try {
        const Response = await axios.get(
          `/api/consumableproduct/orgcode=${orgCode}/date=${date}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setBeyondlpg(Response.data);
        console.log(Response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
      try {
        const productResponse = await axios.get(
          `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=2`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProducts(productResponse.data);
        console.log(productResponse.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
  
    } catch (error) {
      console.error("Error in getData function:", error);
    } finally {
      setLoading(false); // Ensure loading state is reset whether or not there's an error
    }
  };
  
  

  useEffect(() => {
    getData();
  }, [date]);

 
  const handleChange = async (e) => {
    const { id, value } = e.target;
  
    if (id === "phoneNumber") {
      setConsumerDetails((prev) => ({
        ...prev,
        phoneNumber: value,
        consumerName: "", // Reset consumerName
        consumerNumber: "", // Reset consumerNumber
        address:"",
        productId:"",
        area:"",
      }));

      console.log("Phone number changed:", value);
      
      if (value) {
        await fetchConsumerDetails(value);
      }
    } else if (id === "consumerName") {
      setConsumerDetails((prev) => ({
        ...prev,
        consumerName: value
      }));
    } else if (id === "consumerNumber") {
      setConsumerDetails((prev) => ({
        ...prev,
        consumerNumber: value
      }));
    } else if (id === "address") {
      setConsumerDetails((prev) => ({
        ...prev,
        address: value
      }));
    } else if (id === "area") {
      setConsumerDetails((prev) => ({
        ...prev,
        area: value
      }));

    } else if (id === "productname") {   
         const selectedProduct = products.find((product) => product.productname === value);
      if (selectedProduct) {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgId = JSON.parse(localStorage.getItem("data")).orgcode;
  
          let price = 0; // Initialize price with a default value
  
          // Fetch price from api/officefinancial if data is present
          const financialResponse = await axios.post(`/api/officefinancial/getbyproductname`, 
            {
              orgid: orgId,
              productname: value,
              date: newProduct.date
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            });
  
          if (financialResponse.data) {
            price = financialResponse.data.salecost || 0; // Set price if available
          }
  
          // If price is not available from api/officefinancial, fetch it from api/product
          if (!price) {
            const productResponse = await axios.get(`/api/product/orgcode=${orgId}/productcode=${selectedProduct.productcode}/date=${newProduct.date}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            price = productResponse.data.pprice?.price || 0;
          }
  
          setNewProduct((prevData) => ({
            ...prevData,
            [id]: value,
            productcode: selectedProduct.productcode,
            productId: selectedProduct.id,
            price: price,
            total: (value && prevData.quantity) ? parseFloat(price) * parseFloat(prevData.quantity) : 0,
          }));
        } catch (error) {
          console.error("Error fetching price:", error);
        }
      }
    } else if (id === "quantity") {
      setNewProduct((prevData) => ({
        ...prevData,
        [id]: value,
        total: (value && prevData.price) ? parseFloat(value) * parseFloat(prevData.price) : 0,
      }));
    } else {
      setNewProduct((prevData) => ({
        ...prevData,
        [id]: value,
        total: (prevData.productname && value) ? parseFloat(prevData.productname) * parseFloat(value) : 0,
      }));
    }
  };

  const fetchConsumerDetails = async (phoneNumber) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
    try {
      // Make first API call
      const response1 = await axios.get(`/api/consumerdetails/orgcode/${orgcode}/phone=${phoneNumber}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Optionally handle the data from the first response
      console.log("Response from first API:", response1.data);
      
      // Make second API call
      const response2 = await axios.get(`/api/beyondlpg-consumerdetails/orgcode=${orgcode}/phonenumber=${phoneNumber}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Optionally handle the data from the second response
      console.log("Response from second API:", response2.data);
      
      // Combine data from both responses if needed
      setConsumerDetails((prev) => ({
        ...prev,
        consumerName: response2.data.consumername || response1.data.consumername,
        consumerNumber: response2.data.consumernumber || response1.data.consumernumber,
        address: response2.data.address1 || response1.data.address1,
        area: response2.data.area || response1.data.area

      }));
    } catch (error) {
      console.error("Error fetching consumer details:", error);
    }
  };
  const checkPhoneNumber = async (phoneNumber) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    try {
      // Check the phone number in the first API
      const consumerResponse1 = await axios.get(`/api/consumerdetails/orgcode/${orgcode}/phone=${phoneNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Check the phone number in the second API
      const consumerResponse2 = await axios.get(`/api/beyondlpg-consumerdetails/orgcode=${orgcode}/phonenumber=${phoneNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const consumer1 = consumerResponse1.data.find(item => item.phoneNumber === phoneNumber);
      const consumer2 = consumerResponse2.data.find(item => item.phoneNumber === phoneNumber);

      if (consumer1) {
        setConsumerDetails({
          phoneNumber: consumer1.phoneNumber,
          consumerName: consumer1.consumerName,
          consumerNumber: consumer1.consumerNumber,
          address: consumer1.address,

          area: consumer1.area

        });
      } else if (consumer2) {
        setConsumerDetails({
          phoneNumber: consumer2.phoneNumber,
          consumerName: consumer2.consumerName,
          consumerNumber: consumer2.consumerNumber,
          address: consumer2.address,

          area: consumer2.area

        });
      } else {
        setConsumerDetails({
          phoneNumber: phoneNumber,
          consumerName: "",
          consumerNumber: "",
          address:"",

          area:""

        });
      }
    } catch (error) {
      console.error("Error checking phone number:", error);
    }
  };

  const createConsumer = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.post("/api/beyondlpg-consumerdetails/create", consumerDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await axios.post("/api/beyondlpg-consumerdelivery/create", deliveryDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Consumer created successfully");
      
    } catch (error) {
      setLoading(false);
      toast.error("Error creating consumer");
    }
  };

  const handleAddProduct = () => {
    if (newProduct.productname && newProduct.productcode && newProduct.quantity > 0) {
      setProductList([...productList, newProduct]);
      setNewProduct(initialProductState); // Reset the form
    } else {
      toast.error("Please fill in all required fields.");
    }
  };
  const handleDeleteProduct = (index) => {
    console.log("Deleting product at index:", index);
    const updatedProductList = productList.filter((_, i) => i !== index);
    setProductList(updatedProductList);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // if (!newProduct.productname || !newProduct.productcode) {
    //   toast.error("Please select a Product Name .");
    //   setLoading(false);
    //   return;
    // }

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      // Check if consumer details are provided and create if necessary
      if (consumerDetails.consumerName) {

        await axios.post("/api/beyondlpg-consumerdetails/create", consumerDetails, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
  
      // Create delivery details
      await axios.post("/api/beyondlpg-consumerdelivery/create", deliveryDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const allProductDetails = [];

      for (const product of productList) {
        // Fetch product details by productId
        const response = await axios.get(`/api/product/productid=${product.productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        // Destructure and store details
        const { pprice, gst, productname, hsnCode } = response.data;
        allProductDetails.push({
          productId: product.productId,
          price: pprice.price,
          gst,
          productname,
          hsnCode,
        });
      }
      console.log("Setting product list details:", allProductDetails);

      // Set the product details state
      setProductListDetails(allProductDetails);
      handlePrint(allProductDetails);

    // Create consumable products
    const createResponse = await axios.post("/api/consumableproduct/create", productList, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const createdProducts = createResponse.data; // Adjust this according to your API's actual response structure

    // Step 2: Map through created products and extract quantities
    const quantities = createdProducts.map(product => {
      return {
        productname: product.productname,
        total: product.total,
        quantity: product.quantity, // Assuming the response contains a 'quantity' field
      };
    });

    console.log("Product Quantities:", quantities);
    setProductQuantities(quantities);

    handlePrint(productList, quantities);
    
          setPrintData({
        ...newProduct,
        consumerDetails,
        deliveryDetails
      });
      console.log("Data saved successfully");
      setLoading(false);
      // onRequestClose();
      // setNewProduct(initialProductState);
      toast.success("Office Consumable sales created successfully");
      setProductList([]);
      getData();
    } catch (error) {
      console.error("Error creating consumable product:", error);
      setLoading(false);
    }
  };
  const handlePrint = async (productListDetails,productQuantities) => {
    console.log("Product List Details received in handlePrint:", productListDetails);
    console.log("Product Quantities received in handlePrint:", productQuantities); // Added console log for productQuantities

    if (productListDetails && productQuantities) {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
        const consumerNumber = printData.consumerDetails.consumerNumber;
        const productId = printData.productId;
        // Fetch cashMemoNumber from the API
        const response = await axios.get(`/api/beyondlpg-consumerdelivery/consumernumber=${consumerNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('API Response:', response.data); 
        const cashMemoNumber =  response.data[0]?.cashMemoNumber ;

      
     // Fetch organization details
     const orgResponse = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log('API Response for Organization Details:', orgResponse.data);
    const orgDetails = orgResponse.data;

     // Fetch logo
     const logoResponse = await axios.get('/api/printlogo', {
      headers: { Authorization: `Bearer ${token}` },
    });
    const logoData = logoResponse.data[0];
    const logoURL = `${logoData.imageMeta},${logoData.logo}`;
    
    console.log('Product List Details:', productListDetails);

    const mergedProductListDetails = productListDetails.map(product => {
      const quantityData = productQuantities.find(q => q.productname === product.productname);
      const quantity = quantityData ? quantityData.quantity : 0;
      const totalValue = quantity * product.price;
      const totalAmount = totalValue + (totalValue * product.gst / 100);
      const cgstAmount = totalValue * (product.gst / 2) / 100;
      const sgstAmount = totalValue * (product.gst / 2) / 100;
      
      return {
        ...product,
        quantity,
        totalValue,
        cgstAmount,
        sgstAmount,
        totalAmount
      };
    });
    const grandTotal = mergedProductListDetails.reduce((sum, product) => sum + product.totalAmount, 0);


      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body { 
                font-family: Arial, sans-serif; 
                margin: 0;
                padding: 20px;
                background-color: #f4f4f4;
              }
                  @media print {
                @page {
                  size: A5;
                  margin: 10mm; /* Adjust margins as needed */
                }
              }
             .container {
                width: 100%; /* Full width for A5 paper */
                max-width: 148mm; /* A5 width in mm */
                margin: 0 auto;
                padding: 20px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              }
               .header {
                display: flex;
                align-items: flex-start;  /* Align items at the top */
                margin-bottom: 60px;
                 text-align: left;
              }
              .header img {
                max-width: 100px; /* Adjust size as needed */
                height: auto;
                margin-right: 240px; /* Space between logo and details */
              }
             .org-details {
            position: relative;

    text-align: center;
    margin: 0;
    padding: 0;
    display: inline-block; /* Prevent full-width block behavior */
    width: 100%; /* Ensure it spans the full width of the parent */
    
}

.org-details {
              
                margin: 0;
padding: 8px;
                text-align: left;                display: inline-block; /* Prevent full-width block behavior */
                width: 100%; /* Ensure it spans the full width of the parent */
              }
              .org-details div {
                margin: 0;
padding: 8px;
                text-align: left;                display: block; /* Ensure each div is a block element */
                width: 100%; /* Ensure the div spans the full width */
                line-height: 1.4; /* Consistent spacing between lines */
              }
.detail, .address-details {
  display: block; /* Ensure each detail is treated as a block element */
  margin-bottom: 4px; /* Optional: add spacing between lines */
}

.address-details {
  line-height: 2.5; /* Adjust line height for spacing between address lines */
}

.address-details .address-line {
  margin-bottom: 20px; /* Adjust margin to control spacing between lines */
}
              h1 {
                font-size: 18px;
                margin-bottom: 20px;
                text-align: center;
                font-weight: bold;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                padding: 8px;
                text-align: left;
              }
              th {
                font-weight: bold;
              }
                .section-title {
                font-size: 17px;
                margin-top: 20px;
                   border-top: 2px solid #ddd;
                padding-top: 5px;
                border-bottom: 2px solid #ddd;
                padding-bottom: 5px;
                font-weight: bold;
                 text-align: center;
              }
             .price-details th, .price-details td {
              border-top: none;
            }
               .button-container {
              text-align: right;
            }
              .button-container button {
              margin: 0 10px;
              // padding: 10px 20px;
              font-size: 14px;
              cursor: pointer;
            //  background-color: #6c757d; /* Blue color */
              // color: #fff;
              border: none;
              // border-radius: 5px;
            }
            .button-container button:hover {
              background-color: #D3D3D3;
            }
            .cancel-button {
              // background-color: #D3D3D3; /* Gray color */
            }
            .cancel-button:hover {
              background-color: #5a6268;
            }

             img {
               
                max-width: 80px; /* Adjust size as needed */
                height: auto;
              }
.address-details {
    line-height: 2.4; /* Adjust the line-height to control the gap between lines */
  }

            </style>
          </head>
          <body>
             <div class="container">
              <div class="header">
                <img src="${logoURL}" alt="Organization Logo">
                <div class="org-details">
  <div class="detail">${orgDetails.name}</div>
  <div class="detail">${orgDetails.contact}</div>
  <div class="detail">${orgDetails.username}</div>
   <div class="detail">${orgDetails.addressLine1} ${orgDetails.city}</div>
  <div class="detail">${orgDetails.state}, ${orgDetails.pincode}</div>
  <div class="detail">GSTIN: ${orgDetails.gstin}</div>
</div>

              </div>
              <div class="section-title">Consumer Details</div>
              <table>
                <tr>
                  <th>Consumer Number</th>
                <td>${printData.consumerDetails.consumerNumber}</td>
                </tr>
               <tr>
                 <th>Consumer Name</th>
                  <td>${printData.consumerDetails.consumerName}</td>
                </tr>
                
               
              
                <tr>
                 <th>Address</th>
                  <td colspan="2">${printData.consumerDetails.address}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>${printData.consumerDetails.phoneNumber}</td>
                </tr>
                <tr>
                  <th>Area</th>
                  <td>${printData.consumerDetails.area}</td>
                </tr>
              </table>
  
             
 
               <div class="section-title">Price Details</div>
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                     <th>HSN Code</th>

                      <th>Price</th>
                      <th>Quantity</th>

                      <th>GST </th>
                    <th>CGST</th>
                     <th>SGST </th> 
                    <th>Total</th> 
                    </tr>
                  </thead>
                  <tbody>
    ${mergedProductListDetails.map(product => `
                      
                      <tr key="${product.productId}">

                        <td>${product.productname}</td>
                       <td>${product.hsnCode}</td>

                        <td>${product.price}</td>
                         <td>${product.quantity}</td>

                         <td>${product.gst}%</td>              
           <td>${product.cgstAmount.toFixed(2)}</td>
             <td>${product.sgstAmount.toFixed(2)}</td> 
                          <td>${product.totalAmount.toFixed(2)}</td>
                      </tr>
                    `).join('')}
                  </tbody>
                    <tfoot>
      <tr>
        <td colspan="7" style="text-align: right;"><strong>Total Price:</strong></td>
        <td><strong>${grandTotal.toFixed(2)}</strong></td>
      </tr>
    </tfoot>
                </table>
                
              <br/>
              <div class="button-container">
                <button class="cancel-button" onclick="window.close()">Cancel</button>
                <button onclick="window.print()">Print</button>
              </div>
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
    } catch (error) {
    }
  } else {
  }
};

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Create Sale</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="row justify-content-center">
    {/* Left Card */}
  <div className="col-md-8 mb-3">
    <div
      className="card card-raised mb-3"
      style={{ 
        maxWidth: '100%', 
        margin: 'auto', 
        width: '180%', // Increase width here
        padding: '20px' // Increase padding for more space inside the card
      }}    >
      <div className="card-body p-4">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="date">Date</label>
                <input
                  className="form-control"
                  id="date"
                  type="date"
                  placeholder="date"
                  value={newProduct.date}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  className="form-control"
                  id="phoneNumber"
                  type="text"
                  placeholder="Phone Number"
                  value={consumerDetails.phoneNumber}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="productname">Product Name</label>
                <select
                  className="form-select"
                  id="productname"
                  value={newProduct.productname}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for select size
                >
                  <option value="">Select Product Name</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.productname}>
                      {product.productname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="consumerNumber">Consumer Number</label>
                <input
                  className="form-control"
                  id="consumerNumber"
                  type="text"
                  placeholder="Consumer Number"
                  value={consumerDetails.consumerNumber}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="productcode">Product Code</label>
                <input
                  className="form-control"
                  id="productcode"
                  placeholder="Product Code"
                  value={newProduct.productcode}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="consumerName">Consumer Name</label>
                <input
                  className="form-control"
                  id="consumerName"
                  type="text"
                  placeholder="Consumer Name"
                  value={consumerDetails.consumerName}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
          <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="price">Price</label>
                <input
                  className="form-control"
                  id="price"
                  type="number"
                  placeholder="Price"
                  value={newProduct.price}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="area">Area</label>
                <input
                  className="form-control"
                  id="area"
                  type="text"
                  placeholder="Area"
                  value={consumerDetails.area}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
          <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="quantity">Quantity</label>
                <input
                  className="form-control"
                  id="quantity"
                  type="text"
                  placeholder="Quantity"
                  value={newProduct.quantity}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="address">Address</label>
                <input
                  className="form-control"
                  id="address"
                  type="text"
                  placeholder="Address"
                  value={consumerDetails.address}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating-sm">
                <label htmlFor="total">Total</label>
                <input
                  className="form-control"
                  id="total"
                  type="text"
                  placeholder="Total"
                  value={newProduct.total}
                  onChange={handleChange}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="bookingDate">Booking Date</label>
                <input
                  className="form-control"
                  id="bookingDate"
                  type="date"
                  placeholder="Booking Date"
                  value={deliveryDetails.bookingDate}
                  onChange={(e) => setDeliveryDetails({ ...deliveryDetails, bookingDate: e.target.value })}
                  style={{ width: '100%', maxWidth: '500px' }} // Inline CSS for input size
                />
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleAddProduct}>
  Add 
</button>
               
              
<br/>
<br/>
              {/* <h5 className="mt-4">Product List</h5> */}
<table className="table table-bordered"
 style={{ 
  width: '70%', // Adjust the table width
  fontSize: '12px', // Adjust font size for the table text
  margin: 'auto', // Center the table horizontally
  marginLeft: '-30px',
}}>
  <thead>
    <tr
      style={{
        fontSize: "14px",
        backgroundColor: "blue",
        color: "white",
      }}
    >
      <th>Product Name</th>
      <th>Product Code</th>
      <th>Quantity</th>
      <th>Price</th>
      <th>Total</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {productList.length === 0 ? (
      <tr>
        <td colSpan="6" style={{ textAlign: 'center'}}>
          No data available
        </td>
      </tr>
    ) : (  
      productList.map((product, index) => (
        <tr key={index}>
        <td>{product.productname}</td>
        <td>{product.productcode}</td>
        <td>{product.quantity}</td>
        <td>{product.price }</td> 
        <td>{(product.price * product.quantity).toFixed(2) }</td> 
        <td>
        <Button onClick={() => handleDeleteProduct(index)}
          style={{ backgroundColor: 'transparent', border: 'none', padding: '0' }} // Remove background and border from the button
          >
            <MdDeleteForever style={{ color: 'black', fontSize: '24px' }} /> {/* Set the icon color to black */}
          </Button>
        </td>
      </tr>
    ))
  )}
</tbody>
</table>
{/* Calculate Total Sum */}
{productList.length > 0 && (
  <div style={{ textAlign: 'right', marginRight: '50px', marginTop: '10px' }}>
    <strong>Total  </strong> 
    ₹{productList.reduce((acc, product) => acc + product.price * product.quantity, 0).toFixed(2)}
  </div>
)}
<div className="row mt-4">
                  <div className="col">
                    <Button variant="primary" type="submit"className="me-2">Save</Button>
                    <button   className="btn btn-success" onClick={() => handlePrint(productListDetails,productQuantities)}>Print</button>
                    <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>


                  </div>
                {/* </div>
<div className="row mt-4">
                  <div className="col">
                    <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                    <Button variant="success" onClick={handlePrint} className="ms-2">Print</Button>

                  </div> */}
                 
                </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
    </Modal.Footer>
  </Modal>
 );
};
 
export default ConsumableModalOpen;
 