import React, { useEffect, useState } from "react";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { useNavigate,useParams } from "react-router-dom";
import { MdPersonAddAlt1 } from "react-icons/md";
import DriverDetailsModal from "./DriverDetailsmodal";

function EditLoadOutDetails() {
    const { ervId } = useParams();
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    truckinfo: "",
    date: "",
    ervid:"",
    file: null,
    fileContentType:"",
    fileSize: "",
    loadOutDetails: [
      {
        productcode: "",
        productname: "",
        quantity: "",
        status: "empty",
      },
    ],
    truckDriver:{
      id:"",
      drivername:"",
      contact:"",
    }
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const url = "/api/loadoutdetailsdo/update";

  const token = JSON.parse(localStorage.getItem("data")).token;
  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      
      const trans = await axios.get(
        `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true/productTypeId=${1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Get the load details by loadInMetaId
      const loadRes = await axios.get(
        `/api/loadoutdetailsdo/getLoadOutDetailsByErvId/ervId=${ervId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      setTransaction({
        ...loadRes.data, // Load in details data from API
        loadOutDetails: trans.data.map((item) => ({
          productcode: item.productcode,
          productname: item.productname,
          quantity: loadRes.data.loadOutDetails.find(ld => ld.productcode === item.productcode)?.quantity || 0, 
          status: "empty"
        })),
      });
  
      setProduct(trans.data);
      setLoading(false);
  
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    if (!transaction.file) {
      toast.error("Please upload a document.");
      return;
    }

    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, transaction, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        setLoading(false);
        toast.success("Loadout updated successfully");
        navigate("/users/loaddetails");

      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;

    if (field == "quantity") {
      const newproduct = [...transaction.loadOutDetails];
      newproduct[index] = { ...newproduct[index], quantity: actualValue };

      setTransaction({ ...transaction, loadOutDetails: newproduct });
    } else {
      setTransaction({ ...transaction, [field]: actualValue });
    }
  }
  console.log(transaction);
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...transaction,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setTransaction(pdfObject);
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddModalOpen = () => {
    setIsModalOpen(true); 
  };

  const handleModalClose = () => {
    setIsModalOpen(false); 
  };

  const handleDriverSelect = (driver) => {
    setTransaction({
      ...transaction,
      truckDriver: {
        id:driver.id,
        drivername:driver.drivername,
        contact: driver.contact,
      }
    });
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      {loading && <div className="loading"></div>}
      <div
        className="col-8  mx-xl-auto px-xl-5 "
        style={{ position: "relative", left: "150px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Load Outgoing Details</div>
            <br />

            <div className="col-xl-12 mx-xl-auto ">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      <label for="date">Date:</label>
                      <input
                        id="date"
                        type="date"
                        placeholder="Date"
                        value={transaction.date}
                        // onChange={(e) => rhandle(e, "date")}
                        readOnly
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;
                      {/* <label for="truckinfo">Vehicle Id:</label>
                      <input
                        id="truckinfo"
                        type="text"
                        placeholder="Truck info"
                        value={transaction.truckinfo}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase(); // Convert value to uppercase
                          rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                        }}
                      />{" "} */}
                       <label for="truckinfo">Vehicle Id:</label>
                      <input
                        id="truckinfo"
                        type="text"
                        placeholder="vehicle"
                        value={transaction.truckinfo}
                        // onChange={(e) => {
                        //   const value = e.target.value.toUpperCase(); // Convert value to uppercase
                        //   rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                        //   handleVehicle(e.target.value);
                        // }}
                        readOnly
                      />
                       
                      &nbsp;&nbsp;&nbsp; &nbsp;
              
                       <label for="ervid">ERV Id:</label>
                       <input
                        id="ervid"
                        type="text"
                        placeholder="ERV Id"
                        value={transaction.ervid}
                        onChange={(e) => rhandle(e, "ervid")}
                        readOnly
                      ></input>
                      
                       </div>
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;

                      <div className="row">
                              <div className="form-floating-sm mb-2 d-flex align-items-center">
                                <label htmlFor="file" className="me-">Upload Document:</label>
                        <input
                          className="form-control"
                          id="file"
                          type="file"
                          placeholder="Upload document"
                          onChange={(e) => handlePdfFileChange(e)}
                        />
                                    <label htmlFor="drivername" className="mx-3">Driver Name:</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

<input
  id="drivername"
  type="text"
  placeholder="Driver Name"
  value={transaction.truckDriver.drivername}
  readOnly
  onChange={(e) => rhandle(e, "drivername")}
/>
<span
className="pulse-icon"
onClick={handleAddModalOpen} 
style={{ cursor: 'pointer', marginLeft: '10px',fontSize:"17px"}}
>
<MdPersonAddAlt1 />
</span>
</div>
<label htmlFor="contact" className="mx-3">Contact:</label>
<input
  id="contact"
  type="text"
  placeholder="Contact Number"
  value={transaction.truckDriver.contact}
  readOnly
  onChange={(e) => {
    const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const limitedText = inputText.slice(0, 10); // Limit to 10 characters
    setTransaction({ ...transaction, contact: limitedText }); // Update the state with limited text
  }}
/>
</div>
</div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.loadOutDetails.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder={product.quantity}
                                        style={{ border: "none" }}
                                        onKeyPress={(e) => {
                                          // Allow only numeric characters
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => rhandle(e, "quantity", index)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) =>
                        rsubmit(e)
                      }
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isModalOpen && (
        <DriverDetailsModal isOpen={isModalOpen} onRequestClose={handleModalClose}  onDriverSelect={handleDriverSelect} />
)}
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default EditLoadOutDetails;
