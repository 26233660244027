import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
import StockistContactpersoneditModalPopup from "./Stockistcontactpersonedit";
import Addstockistcontactperson from "./AddStockistcontactperson";
function StockistcontactpersonViewModal({ isOpen, onRequestClose, vendorId ,StockistId}) {
  const [vendor, setVendor] = useState([]);
  const [stockistProducts, setStockistProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [activeTab, setActiveTab] = useState("contact");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState("");
      const fetchVendorData = async () => {
        setLoading(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;

          // Fetch vendor profile
          const vendorResponse = await axios.get(`/api/stockistcontactperson/stockistid=${StockistId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setVendor(vendorResponse.data);

             // Fetch stockist product details
      const stockistProductResponse = await axios.get(`/api/stockistmapping/stockistid=${StockistId}/vendorid=${vendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStockistProducts(stockistProductResponse.data);
        
          
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError("Error fetching vendor details");
        }
      };
     
  useEffect(() => {
    if (StockistId, vendorId) {
      fetchVendorData();
    }
  }, [StockistId,vendorId]);
  const handleEdit = async (vendorId) => {
    setSelectedVendorId(vendorId);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchVendorData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/stockistcontactperson",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    fetchVendorData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
const filteredVendors = vendor.filter((vendor) =>
    vendor.name && vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredProducts = stockistProducts.filter((product) =>
    product.vendorproduct.productname.toLowerCase().includes(productSearchTerm.toLowerCase())
  );
  const handleAdd = async (Id) => {
    setSelectedId("StockistId:",Id);
    console.log(Id);
      setIsModalOpen(true);
   
  };
  const closeModal = () => {
    setIsModalOpen(false);
    fetchVendorData();
 
  };
  const onClickHandler2 = async ( id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/vendorproduct",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    fetchVendorData();

    setLoading(false); 
  } catch (error) {
    setLoading(false); 
  }
};
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Stockist Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {/* Tabs for Stockist Contact Person and Product Details */}
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
            <Tab eventKey="contact" title="Contact Person">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <input
                  type="text"
                  className="form-control"
                  style={{
                    width: '200px',
                    display: 'inline-block',
                    paddingLeft: '10px',
                    marginLeft: '20px',
                  }}
                  placeholder="Search Contact Person"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => handleAdd(StockistId)}
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px',
                  }}
                >
                  ADD
                </button>
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredVendors.length > 0 ? (
                    filteredVendors.map((vendor, index) => (
                      <tr key={index}>
                        <td>{vendor.name}</td>
                        <td>{vendor.email}</td>
                        <td>{vendor.phone}</td>
                        <td>
                          <button
                            onClick={() => handleEdit(vendor.id)}
                            style={{ border: "none", background: "white" }}
                          >
                            <BiEditAlt />
                          </button>
                          <button
                            onClick={(e) => onClickhandler(e, vendor.id)}
                            style={{ border: "none", background: "white", color: "grey", left: "10px", fontSize: "24px" }}
                          >
                            <MdDeleteForever />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Tab>
            
            <Tab eventKey="products" title=" Product Details">
            <div>
              <input
                type="text"
                className="form-control"
                style={{ width: '200px', marginBottom: '10px' }}
                placeholder="Search Product"
                value={productSearchTerm}
                onChange={(e) => setProductSearchTerm(e.target.value)}
              />
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>HSN Code</th>
                    <th>Base Price</th>
                    <th>GST</th>
                    <th>Actions</th>

                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <tr key={index}>
 <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.vendorproduct.image && product.vendorproduct.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.vendorproduct.image}`}
          alt="Product"
          style={{ maxWidth: "40px", maxHeight: "60px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "40px", height: "60px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: "10px", marginTop: "-34px", display: "inline-block" }}>
      {product.vendorproduct.productname}</span>
     
    </div>
  </div>
</td>                        <td>{product.vendorproduct.sku}</td>
                        <td>{product.vendorproduct.hsncode}</td>
                        <td>{product.vendorproduct.baseprice}</td>
                        <td>{product.vendorproduct.gstrate}</td>
                        <td>
                        <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                        color: 'grey',
                                                        fontSize: '24px',
                                                      }}
                                                      onClick={() => onClickHandler2(product.vendorproduct.id)}
                                                    >
                                                      <MdDeleteForever />
                                                    </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">No product details available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
          </Tab>
        </Tabs>

          {/* Modals for edit and add */}
          {isEditModalOpen && (
            <StockistContactpersoneditModalPopup
              isOpen={isEditModalOpen}
              onRequestClose={closeEditModal}
              vendorId={selectedVendorId}
            />
          )}
          {isModalOpen && (
            <Addstockistcontactperson
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              vendorId={selectedVendorId}
              Id={StockistId}
            />
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StockistcontactpersonViewModal;