import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import Search from "./search";
import { useNavigate } from "react-router";

function StockTransfer() {
  const [truckInfo, setTruckInfo] = useState([]);
  const [produt,setProduct]=useState([]);
  const [loading, setLoading] = useState(false);
  const [sourceVehicle, setSourceVehicle] = useState("");
  const [destinationVehicle, setDestinationVehicle] = useState("");
  const [transactionOutData, setTransactionOutData] = useState([]);
  const [stockTransfer, setStockTransfer] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [searchDate, setSearchDate] = useState(currentDate);
  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    sourceVehicle: "",
    destinationVehicle: "",
    date: currentDate,
    products: [
        {
          productcode: "",
          productname: "",
          quantity: "",
        },
      ],
  });

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const vehicleResponse = await axios.get(`/api/vehicle/getAllActiveVehicles/orgid=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTruckInfo(vehicleResponse.data);

      const trans = await axios.get(
        `/api/product/productstatus=true/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      console.log(trans);
  
      setProduct(trans.data);
      setTransaction({
        ...transaction,
        products: trans.data.map((item) => ({
          productcode: item.productcode,
          productname: item.productname,
          quantity: 0,
        })),
      });
  
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const getTransactionOutData = async (vehicleId) => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgid = JSON.parse(localStorage.getItem("data")).orgcode;
    try {
      const response = await axios.get(`/api/availabletransfer/orgcode=${orgid}/date=${currentDate}/vehicleid=${vehicleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTransactionOutData(response.data.products);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    // setLoader(true);
   setSearchDate(text);
    // Fetch outgoing transactions
    const requests = [
      // axios.get(`/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
      //   headers: { Authorization: `Bearer ${token}` },
      // }),
      axios.get(`/api/stockTransferDetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      
    ];
  
    // Execute all requests concurrently
    Promise.all(requests)
      .then((responses) => {
        const [stockTransferRes] = responses;
        //setProductDetails(productRes.data)
        setStockTransfer(stockTransferRes.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        // setLoader(false);
      });
  }
  const navigate = useNavigate();
  
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleSourceVehicleSelect = (event) => {
    const vehicleId = event.target.value;
    setSourceVehicle(vehicleId);
    setDestinationVehicle(""); // Reset destination vehicle when source is changed
    console.log("Selected Source Vehicle:", vehicleId);
    getTransactionOutData(vehicleId);
  };
  

  const handleDestinationVehicleSelect = (event) => {
    const vehicleId = event.target.value;
    setDestinationVehicle(vehicleId);
    console.log("Selected Destination Vehicle:", vehicleId);
  };

  const url = "/api/stocktransfer/create";
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
    const updatedTransaction = {
      ...transaction,
      sourceVehicle: sourceVehicle, // Add source vehicle
      destinationVehicle: destinationVehicle, // Add destination vehicle
    };
  
    axios
      .post(url, updatedTransaction, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Stock Transfer updated successfully");
        getData();
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };
  
  function rhandle(e, field, index) {
    let actualValue = e.target.value;

    if (field == "quantity") {
      const newproduct = [...transaction.products];
      newproduct[index] = { ...newproduct[index], quantity: actualValue };

      setTransaction({ ...transaction, products: newproduct });
    } else {
      setTransaction({ ...transaction, [field]: actualValue });
    }
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
          <div className="row align-items-center">
  <div className="d-flex justify-content-between w-50">
    <div className="card-title form-group mb-4">Stock Transfer</div>
    <div>
      <Search searchName={searchName} />
    </div>
  </div>


      <br/>
  {/* <div className="col-auto ms-8">
    <label htmlFor="date" className="mb-0 me-2">Date:</label>
    <input
      type="date"
      id="date"
      value={transaction.date}
      onChange={(e) => setTransaction({ ...transaction, date: e.target.value })}
    />
  </div> */}
</div>
{searchDate===currentDate ? (
         <>
            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Source Vehicle</h5>
                    <select
                      className="form-select me-2"
                      onChange={handleSourceVehicleSelect}
                      value={sourceVehicle}
                    >
                      <option value="" disabled>
                        Select Source Vehicle
                      </option>
                      {truckInfo.map((vehicle) => (
                        <option key={vehicle.vehicleid} value={vehicle.vehicleid}>
                          {vehicle.truckinfo}
                        </option>
                      ))}
                    </select>

                    {sourceVehicle && (
                  
                  <div>
                   <br/>
                   {transactionOutData.length === 0 ? (
                <div>No data available</div>
              ) : (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Total Quantity</th>
                          <th>Available Transfer</th>
                        </tr>
                      </thead>
                      <tbody>
                      {transactionOutData.map((transaction) => (
            <tr key={transaction.productcode}>
              <td>{transaction.productname}</td>
              <td>{transaction.quantity}</td>
              <td>{transaction.availableQty}</td>
            </tr>
          ))}


                      </tbody>
                    </table>
                   )}
                  </div>
                                    )}
                
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Destination Vehicle</h5>
                    <select
                      className="form-select me-2"
                      onChange={handleDestinationVehicleSelect}
                      value={destinationVehicle}
                      disabled={!sourceVehicle} 
                    >
                      <option value="" disabled>
                        Select Destination Vehicle
                      </option>
                      {truckInfo
                        .filter((vehicle) => vehicle.truckinfo !== sourceVehicle)
                        .map((vehicle) => (
                          <option key={vehicle.vehicleid} value={vehicle.vehicleid}>
                            {vehicle.truckinfo}
                          </option>
                        ))}
                    </select>
                   
 

                    {destinationVehicle && (
                        
                      <div>
                        <br/>
                        {/* <p>Source Vehicle: {sourceVehicle}</p>
                        <p>Destination Vehicle: {destinationVehicle}</p> */}
                        {/* Add your content here for Stock Transfer */}
                        <form onSubmit={handleSubmit}>
                         
                           <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder="0"
                                        style={{ border: "none" }}
                                        onKeyPress={(e) => {
                                          // Allow only numeric characters
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => rhandle(e, "quantity", index)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                      <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                />
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            </>
):(
  <>
  
  {/* <div className="mb-4 d-flex align-items-center"> */}
  {/* <label htmlFor="vehicleId" className="me-2">FILTER BY VEHICLE ID:</label>
  <select
    id="vehicleId"
    value={filterVehicleId}
    onChange={(e) => setFilterVehicleId(e.target.value)}
  >
    <option value="">ALL</option>
   
    {Array.from(new Set(approvals.map(approval => approval.vehicle))).map(vehicleId => (
                  <option key={vehicleId} value={vehicleId}>
                    {vehicleId}
                  </option>
                ))}
  </select> */}

  {/* <label htmlFor="status">FILTER BY STATUS:</label>
  <select
    id="status"
    //value={filterStatus}
   // onChange={(e) => setFilterStatus(e.target.value)}
  >
    <option value="">ALL</option>
    <option value="APPROVED">APPROVED</option>
    <option value="REQUESTED">REQUESTED</option>
    <option value="REJECTED">REJECTED</option>
  </select>
</div> */}
            <table className="table table-bordered">
              <thead>
                <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                  <th>Source Vehicle</th>
                  <th>Destination Vehicle</th>
                  {produt.map((ps, index) => (
                    <th key={index}>{ps.productname}</th>
                  ))}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {stockTransfer.length === 0 ? (
                  <tr>
                    <td colSpan={produt.length + 4} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  stockTransfer.map((approval) => {
               return (
                 <tr>
                 <th>{approval.sourceVehicle}</th>
                 <th>{approval.destinationVehicle}</th>
                 {produt.map((ps, pIndex) => {
            const productQty = approval.products.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
           <th>{approval.approvalstatus}</th>
              
               </tr>
               )
   
                 }))
                 }
         
        
  </tbody>
</table> 
</>
)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockTransfer;
