import React from "react";
import { Link } from "react-router-dom";
import Signin from "./components/Login";
import Home from "./components/home.js";
import {  HashRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./components/register";



import Dashboard from "./components/dashboard";
import Profile from "./components/pro.js";
import Security from "./components/security.js";
import Forgotpassword from "./components/forgotpassword";
import Notification from "./components/notification";
import PrivateRoute from "./util/privateRoute";
import Invoice from "./components/invoice";
import ResetPassword from "./components/resetpassword";
import Otpvarification from "./components/otpvarification";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Approval from "./components/approval";
import Otpvalidation from "./components/otpvalidation";
import AdminDashboard from "./components/admindashboard";
import AdminApproval from "./components/adminapprovals";
import ManagerDashboard from "./components/managerdashboard";
import DeliveryagentDashboard from "./components/deliveragentdashboard";
import DashboardSidebar from "./components/dash";
import TransactionOutgoing from "./components/TransactionOutgoingDetails";
import TransactionIncoming from "./components/transactionincomingdetails";
import UpdateTransactionOutgoing from "./components/updateTransactionOutgoing";
import UpdateTransactionIncoming from "./components/updateTransactionIncoming";
import ManagerProfile from "./components/managerprofile";
import LoadIncoming from "./components/loadin";
import LoadOutgoing from "./components/loadoout";
import ManagerIncomingTransaction from "./components/managerincomingtransaction";
import ManagerTransactionOutgoing from "./components/manageroutgoingtransaction";
import AdminTransactionincoming from "./components/adminincomingtransaction";
import AdminTransactionoutgoing from "./components/adminoutgoingtransaction";
import SuperTransactionIncoming from "./components/superincomingtransaction";
import SuperTransactionOutgoing from "./components/superoutgoingtransaction";
import SuperProfile from "./components/superprofile";
import AdminProfile from "./components/adminprofile";
import AdminLoadin from "./components/adminloadin";
import AdminLoadout from "./components/adminloadout";
import Superloadin from "./components/superloadin";
import Superloadout from "./components/superloadout";
import ManagerLoadin from "./components/managerLoadin";
import ManagerLoadout from "./components/managerloadout";
import ManagerApproval from "./components/managerapprovals";
import UpdateProducts from "./components/updateproducts";
import Productdetails from "./components/productdetails";
import Addmember from "./components/addmember.js";

import UpdateLoadout from "./components/updateloadout";
import UpdateLoadin from "./components/updateloadin";
import Productquantity from "./components/productquantity";
import Openstock from "./components/openstock";
import Closestock from "./components/closestock";
import Updateclosestock from "./components/updateclosestock";
import Updateopenstock from "./components/updateopenstock";
import UpdateProductquantity from "./components/updateproductquantity";
import RegisterMd from "./components/registermandd";
import Changeofpassword from "./components/changeofpassword";
import Stock from "./components/stock";
import Transactions from "./components/transactions";
import AdminTransanctionApproval from "./components/admintransactionapproval";
import Vehicledetails from "./components/vehicleDetails";
import AddVehicles from "./components/Addvehicle";
import Dagentandvehiclemapping from "./components/Dagentandvehiclemapping";
import Vehiclereport from "./components/Vehiclereport";
import Fuel from "./components/fuel";
import Service from "./components/service";
import Insurance from "./components/insurance";
import Fuelreport from "./components/fuellogreport";
import Servicereport from "./components/servicereport";
import Dagentvehiclereport from "./components/Dagentvehiclereport";
import Fitnescertificate from "./components/Fc";
import Permit from "./components/Permit";
import Emissioncertificate from "./components/Emissioncertificate";
import AccountDetails from "./components/accountsdetails";
import Organizationdetails from "./components/Organizationdetails";
import Financialmanagment from "./components/financialManagment";
import ProductcostDetails from "./components/financialdetails";
import Subregion from "./components/Subregion";
import Supersubscriptiondetails from "./components/supersubscriptiondetails";
import Tax from "./components/Tax";
import Subscription from "./components/subscription";
import AnotherSubscription from "./components/anothersubscription";
import Totalamount from "./components/totalamount";
import Subscriptionbilling from "./components/subscriptionbilling";
import UProducts from "./components/productedit";
import Denomination from "./components/denomination";
import Dacashamount from "./components/deliveryagentcashamountenter";
import Dacashpayment from "./components/dacashpayment";
import Vehicleedit from "./components/Vehicleedit";
import Loaddetails from "./components/loaddetails";
import Productanalytics from "./components/productanalytics";
import Exemptedcustomercategory from "./components/exemptedcustomercategory";
import Exemptedcustomer from "./components/exemtedcustomer";
import UpdateCashPaymentExpenses from "./components/cashPaymentExpenses";

import Deliveryagentdetails from "./components/deliveryagentdetails";
import Customerpayment from "./components/customerpayment";
import AddCustomerPaymentdetails from "./components/AddCustomerpayment";
import Attendencedetails from "./components/Attendence";
import Customerdetails from "./components/customerdetails";
import AddCustomerdetails from "./components/AddCustomerdetails";

import Exemptedreport from "./components/exemptedreport";
import Termsandcondition from "./components/termsandconditions";
import Privacypolicy from "./components/privacypolicy";
import Exempteddelivery from "./components/exempteddelivery";
import Exemptedediting from "./components/exemptedediting";
import Transactionreport from "./components/transactionreport";
import AdminDashboardwithanalytics from "./components/admindashboardwithanalytics";
import Exemptedcustomercategoryedit from "./components/Exemptededit"
import AddExempteddelivery from "./components/AddExempteddelivery";
import Settings from "./components/Settings.js";
import AddSettings from "./components/AddSettings.js";
import Settingsedit from "./components/Settingsedit";
import Sqcedit from "./components/Sqcedit.js";
import UserProfile from "./components/userProfile.js";
// import Location from "./components/location";
// import { Map } from "leaflet";
import SQCdetails from "./components/Sqcnumber.js";
import Addsqcnumber from "./components/addsqcnumber.js";
import PrintSQC from "./components/Printsqc.js";
import OpenGoogleMap from "./components/OpenGoogleMap";
import Monthlytargetdetails from "./components/monthlytargetdetails.js";
import SuperDashboard from "./components/Superdashboard.js";
import SuperNotification from "./components/Supersubsricptiondetails.js";
import Detailsofsubscription from "./components/detailsofsubscription.js";
import ViewAdminAccount from "./components/Viewadminpopup.js";
import UpdateExceptionOpenstock from "./components/updateExeptionOpeningStock.js";
import Transanctionincomingapproval from "./components/Transactionincomingapproval.js";
import Financeapproval from "./components/Financeapproval.js";
import UserAttendance from "./components/Userattendance.js";
import Activitycheck from "./components/Activitycheck.js";
import Salary from "./components/Salary.js";
import Agentprofile from "./components/Agentprofile.js";
import Navigatesqc from "./components/Addsqcnum.js";
import Areamaster from "./components/Areamaster.js";
import VehicleAreamaster from "./components/Vehicleareamap.js";
import Csvfile from "./components/Csvupload.js";
import Agentinsurance from "./components/Agentinsurance";
import Staffrewards from "./components/Staffrewards.js";
import TeritoryComponent from "./components/Teritory.js";
import Salesareacomponent from "./components/Salesarea.js";
import OverHeadDetails from "./components/overheadDetails.js";
import Viewrewards from "./components/Viewrewards.js";
import Viewcsv from "./components/Viewcsvupload.js";
import Leaderboard from "./components/leaderboard.js";
import Wallet from "./components/Wallet.js";
 import Addamount from"./components/Addfundsamount.js";
import VehicleAreaAgentMap from "./components/vehicleAreaAgentMap.js";
import Superleaderboard from "./components/Superleaderboard.js"; 
import StockTransfer from "./components/stockTransfer.js";
import Teritoryleaderboard from "./components/Teritoryleaderboard.js";
import Leaderboardbutton from "./components/Leaderboardbutton.js";
import Adminleaderboard from "./components/Adminleaderboard.js";
import Consumerlist from "./components/Consumerlist.js";
import SuperTeritoryleaderboard from "./components/Superterritory.js";
import Supersalesarealeaderboard from "./components/Supersalesarealeaderboard.js";
import Rewardpointsbutton from "./components/Rewardpointsbutton.js";
import Refillsales from "./components/Refillsales.js";
import Consumablesales from "./components/Consumablesales.js";
import Superrewardpointsbutton from "./components/Superrewardpoints.js";
import Superrefillsales from "./components/Superrefillsales.js";
import Superconsumablesales from "./components/Superconsumable.js";
import Refillbutton from "./components/Refillbutton.js";
import Supersalesrefill from "./components/Supersalesrefill.js";
import Consumablebutton from "./components/Consumablebutton.js";
import Territoryconsumable from "./components/Territoryconsumable.js";
import Salesareaconsumable from "./components/Salesareaconsumable.js";
import ConsumerLoyaltyPoints from "./components/consumerLoyaltyPoints.js";
import Productmaster from "./components/Productmaster.js";
import Logo from "./components/Logo.js";
import ApiSettings from "./components/apiSettings.js";
import OrganizationSettings from "./components/organizationSettings.js";
import TariffFreight from "./components/tariffFreight.js";
import ConsumerBio from "./components/consumerBio.js";
import ReportFooter from "./components/Reportfooter.js";
import ReportHeader from "./components/Reportheader.js";
import Counter from "./components/Counter.js";
import Superproduct from "./components/Superproducts.js";
import LiveGodownupdate from "./components/Livegodown.js";
import LocationMap from "./components/Locationmap.js";
import HeatMap from "./components/HeatMap.js";
import Showtable from "./components/Locationtable.js";
// import SearchByInvoicesAndErv from "./components/searchByInvoicesAndErv.js";
import TruckInfo from "./components/truckinfo.js";
import ViewTruckInfo from "./components/viewTruckInfo.js";
import Campaigns from "./components/campaigns.js";

import ReportsPage from "./reports/ReportsPage.js";
import Operationalreport from "./reports/Operationalreport.js";
import ConsumableStock from "./components/ConsumableStock.js";
import Invoicereport from "./reports/invoicereport.js";
import Personnelreport from "./reports/Personnelreport.js";
import Vehiclereports from "./reports/VehicleReports.js";
import Taxreports from "./reports/Taxreports.js";
import Rolereport from "./reports/rolereport.js";
import AgentMappingReport from "./reports/agentmappingreport.js";
import VehicleMaintenanceReport from "./reports/vehiclemaintenace.js";
import FuelLog from "./reports/fuellogreport.js";
import LoadinReport from "./reports/loadinreport.js";
import CampaignRewrdPoints from "./components/campaignRewrdPointsDetails.js";
// import LoadoutReports from "./reports/loadoutreports.js";
import ProductService from "./components/ProductService.js";
import deliveryagentcashamountenter1 from "./components/deliveryagentcashamountenter1.js";
// import Dacashamount1 from "./components/deliveryagentcashamount1.js";
import Dacashamount1 from "./components/deliveryagentcashamountenter1";
import TotalErvReport from "./reports/ervreport.js";
import MonthlyincominReports from "./reports/monthlyincomingreport.js";
import LoadoutReport1 from "./reports/loadoutreport1.js";
import BeyondLpgProducts from "./components/beyondLpgProducts.js";
import ConsumableProducts from "./components/consumableProducts.js";
import Products from "./components/products.js";
import Newconnectionreport from "./reports/newconnectionreport.js";
import Additionalreport from "./reports/additionalreport.js";
import Consumabelsalesreport from "./reports/consumablesalesreport.js";
import Onlinedetailsreport from "./reports/onlinereport.js";
import Transactionoutgoingreport from "./reports/monthlyoutgoingreport.js";
import TransactionIncomingReport from "./reports/monthlyincomingreport.js";
import Deliveryreports from "./reports/Deliveryreports.js";
import SQCReport from "./reports/SQCReport.js";
import SqcTypesreports from "./reports/Sqctypesreports.js";
import SQCwithinvoiceReport from "./reports/Sqcwithinvoicereport.js";
import SQCwithsqcnumberReport from "./reports/SqcwithsqcnumberReport.js";
import SQCwithtruckinfowithdateReport from "./reports/SQCwithtruckinfowithdateReport.js";
import SQCsummaryReport from "./reports/SQCsummaryReport.js";
import TargetachivementReport from "./reports/TargetachivementReport.js";
import VendorManagementcomponent from "./components/vendorcomponent.js";
import WareHouse from "./components/Warehouse.js";
import Purchases from "./components/Purchases.js";
import PurchaseEdit from "./components/PurchaseEdit.js";
import Bills from "./components/Bills.js";
import GoodsReceived from "./components/Goodsreceived.js";
import StockExchange from "./components/Stockexchange.js";
import StockManagement from "./components/Stockexchange.js";
import VendorView from "./components/Vendorview.js";
import Quotation from "./components/Quotation.js";
import AddQuotation from "./components/AddQuotation.js";
import PurchaseList from "./components/PurchaseList.js";
import PerformanceTarget from "./components/performance.js";
import PurchaseView from "./components/PurchaseView.js";
import ViewQuotation from "./components/ViewQuotation.js";
import EditQuotation from "./components/EditQuotation.js";
import EditLoadInDetails from "./components/editLoadInDetails.js";
import EditLoadOutDetails from "./components/editLoadOutdetails.js";
// import Productcategory from "./components/Productcategory.js";
import Stocklistpersoncomponent from "./components/Stockistperson.js";
import StockistViewModal from "./components/Stoclist.js";
import StockistpersonViewModal from "./components/Stockistviewmodal.js";

import Monthlyinvoicereport from "./reports/Monthlyinvoicereport.js";

import TruckTransporters from "./components/truckTransporters.js";
import ViewTransporterProfile from "./components/transporterProfile.js";
import TransporterReport from "./reports/TransporterReport.js";
import TrucknumberReport from "./reports/TrucknumberReport.js";
import ExcemptedcustomerpaymentsummaryReport from "./reports/ExcemptedcustomerpaymentsummaryReport.js";
import ExcemptedcustomerdeliverysummaryReport from "./reports/ExemptedcustomerdeliverysummaryReport.js";
import ExcemptedcustomerdeliveryReport from "./reports/ExcemptedcustomerdeliveryReport.js";
import CreditNote from "./components/CreditNote.js";
import CreditNoteList from "./components/CreditNoteList.js";
import InvoiceList from "./components/InvoiceList.js";
import AddInvoice from "./components/AddInvoice.js";
import ViewInvoice from "./components/ViewInvoice.js";
import EditInvoice from "./components/EditInvoice.js";
import DayOperationsReport from "./reports/DayOperationalReport.js";
import DayOperationalReport from "./reports/DayOperationalReport.js";
import ExcemptedcustomerpaymentReport from "./reports/ExcemptedpaymentReport.js";

import Productcategory from "./components/productcategory.js";
import ViewAllTruckDrivers from "./components/viewAllTruckDrivers.js";
import ViewDriverAndTrucks from "./components/viewDriverAndTrucks.js";
import ProfileSettings from "./components/Profiledetails.js";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/otpvalidation/:id" element={<Otpvalidation />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/otpverification" element={<Otpvarification />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/invoice/:id/:pid" element={<Invoice />} />
          <Route
            path="/anothersubscription/:username/:orgcode"
            element={<AnotherSubscription />}
          />
          <Route path="termsandcondition" element={<Termsandcondition />} />
          <Route path="privacypolicy" element={<Privacypolicy />} />
         

          <Route path="/razorpay/:id" element={<Totalamount />} />
          <Route path="/users" element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
            <Route path="denomination" element={<Denomination />} />
            <Route path="dacash/:date" element={<Dacashamount />} />
            <Route path="dacash1/:date" element={<Dacashamount1 />} />

            {/* <Route path="dacash/:vid/:date" element={<Dacashamount />} /> */}
            <Route path="dapayment" element={<Dacashpayment />} />
            <Route path="dapayment/:date" element={<Dacashpayment />} />
            <Route path="profile" element={<Profile />} />
            <Route path="security" element={<Security />} />
            <Route path="notification" element={<Notification />} />

            <Route path="approval" element={<Approval />} />
            <Route path="admindashboard" element={<AdminDashboard />} />
            <Route path="adminapproval" element={<AdminApproval />} />
            <Route
              path="subscriptionbilling"
              element={<Subscriptionbilling />}
            />

            <Route path="managerdashboard" element={<ManagerDashboard />} />
            <Route
              path="deliveryagentdashboard"
              element={<DeliveryagentDashboard />}
            />
            <Route path="dashboardsidebar" element={<DashboardSidebar />} />
            <Route
              path="transactionoutgoing"
              element={<TransactionOutgoing />}
            />
            <Route
              path="transactionincoming"
              element={<TransactionIncoming />}
            />
            {/* <Route
              path="transactionincoming/:id"
              element={<TransactionIncoming />}
            /> */}
            <Route
              path="updatetransactionoutcoming"
              element={<UpdateTransactionOutgoing />}
            />
            <Route
              path="updatetransactionincoming"
              element={<UpdateTransactionIncoming />}
            />
            <Route path="managerprofile" element={<ManagerProfile />} />
            <Route path="loadincoming" element={<LoadIncoming />} />
            <Route path="loadoutgoing" element={<LoadOutgoing />} />
            <Route path="loaddetails" element={<Loaddetails />} />
            <Route path="loaddetails/:date" element={<Loaddetails />} />
            <Route
              path="managerincomintransaction"
              element={<ManagerIncomingTransaction />}
            />
            <Route
              path="manageroutgoingtransaction"
              element={<ManagerTransactionOutgoing />}
            />
            <Route
              path="adminincomingtransaction"
              element={<AdminTransactionincoming />}
            />
            <Route
              path="adminoutgoingtransaction"
              element={<AdminTransactionoutgoing />}
            />
            <Route
              path="superincomingtransaction"
              element={<SuperTransactionIncoming />}
            />
            <Route
              path="superinoutgoingtransaction"
              element={<SuperTransactionOutgoing />}
            />
            <Route path="superprofile" element={<SuperProfile />} />
            <Route path="notification" element={<Notification />} />

            <Route path="adminprofile" element={<AdminProfile />} />
            <Route path="adminloadin" element={<AdminLoadin />} />
            <Route path="adminloadout" element={<AdminLoadout />} />
            <Route path="superloadin" element={<Superloadin />} />
            <Route path="superloadout" element={<Superloadout />} />
            <Route path="managerloadin" element={<ManagerLoadin />} />
            <Route path="managerloadout" element={<ManagerLoadout />} />
            <Route path="managerapproval" element={<ManagerApproval />} />
           
            <Route path="uproduct" element={<UpdateProducts />} />
            <Route path="productdetails" element={<Productdetails />} />
            <Route path="productedits/:id" element={<UProducts />} />
            <Route path="updatetin" element={<UpdateTransactionIncoming />} />
            <Route path="updatetout" element={<UpdateTransactionOutgoing />} />
            <Route path="updatetin/:searchDate" element={<UpdateTransactionIncoming />} />
            <Route path="updatetout/:searchDate" element={<UpdateTransactionOutgoing />} />
            <Route path="expenses" element={<UpdateCashPaymentExpenses/>} />
            <Route path="expenses/:searchDate" element={<UpdateCashPaymentExpenses/>} />
            <Route path="updateloadout" element={<UpdateLoadout />} />
            <Route path="updateloadin" element={<UpdateLoadin />} />
            <Route path="updateloadout/:searchDate" element={<UpdateLoadout />} />
            <Route path="updateloadin/:searchDate" element={<UpdateLoadin />} />
            <Route path="productquantity" element={<Productquantity />} />
            <Route path="productanalytics" element={<Productanalytics />} />

            <Route path="openstock" element={<Openstock />} />
            <Route path="closestock" element={<Closestock />} />
            <Route path="updateclosestock" element={<Updateclosestock />} />
            <Route path="updateopenstock" element={<Updateopenstock />} />
            <Route path="updateExceptionopenstock" element={<UpdateExceptionOpenstock/>} />
            <Route
              path="updateproductquantity"
              element={<UpdateProductquantity />}
            />
            <Route path="registermd" element={<RegisterMd />} />
            <Route path="changeofpassword" element={<Changeofpassword />} />
            <Route path="stock" element={<Stock />} />
            <Route path="transaction" element={<Transactions />} />
            <Route
              path="admintransactionapproval"
              element={<AdminTransanctionApproval />}
            />

            <Route
              path="exemptedcategory"
              element={<Exemptedcustomercategory />}
            />
            <Route path="exemptedreport" element={<Exemptedreport />} />
            <Route path="exemptedcustomer" element={<Exemptedcustomer />} />
            <Route
              path="Exempteddelivery"
              element={<Exempteddelivery />}
            />
            <Route path="exemptededit/:id" element={<Exemptedediting />} />
            <Route path="transactionreport" element={<Transactionreport />} />

            <Route path="vehicledetail" element={<Vehicledetails />} />
            <Route path="vehicleedit/:id" element={<Vehicleedit />} />
            <Route path="updateopenstock/:searchDate" element={<Updateopenstock />} />
            <Route path="updateExceptionopenstock/:searchDate" element={<UpdateExceptionOpenstock/>} />

            <Route path="addvehicle" element={<AddVehicles />} />
            <Route
              path="davehiclemapping/:vid/:truckinfo"
              element={<Dagentandvehiclemapping />}
            />
            <Route
              path="vehicledocuments/:truckinfo"
              element={<Vehiclereport />}
            />
            <Route
              path="admindashboardwithanalytics"
              element={<AdminDashboardwithanalytics />}
            />
            <Route path="vehicledocuments" element={<Vehiclereport />} />
            <Route path="fuel/:truckinfo" element={<Fuel />} />
            <Route path="service/:truckinfo" element={<Service />} />
            <Route path="insurance/:truckinfo" element={<Insurance />} />
            <Route path="fuelreport" element={<Fuelreport />} />

            {/* <Route path="/loadoutreport" element={<LoadoutReport />} /> */}
            <Route path="servicereport" element={<Servicereport />} />
            <Route path="fc/:truckinfo" element={<Fitnescertificate />} />
            <Route path="permit/:truckinfo" element={<Permit />} />
            <Route
              path="emission/:truckinfo"
              element={<Emissioncertificate />}
            />
            <Route
              path="dagentvehiclereport"
              element={<Dagentvehiclereport />}
            />
            <Route path="accountdetails" element={<AccountDetails />} />
            <Route
              path="organizationdetails"
              element={<Organizationdetails />}
            />
            <Route path="financialmanagment" element={<Financialmanagment />} />
            <Route path="financialdetails" element={<ProductcostDetails />} />
            <Route path="subregion" element={<Subregion />} />

            <Route path="submodule" element={<Supersubscriptiondetails />} />
            <Route path="tax" element={<Tax />} />
            <Route path="attendance" element={<Attendencedetails />} />
            <Route path="Deliveryagentdetails" element={<Deliveryagentdetails />} />
            <Route path="overheaddetails" element={<OverHeadDetails />} />
            <Route path="Addmembers" element={<Addmember />} />
            {/* <Route path="AddDeliveryagentdetails" element={<AddDeliveryagentdetails />} /> */}
            <Route path="Customerpayment" element={<Customerpayment />} />
            <Route path="AddCustomerPaymentdetails" element={<AddCustomerPaymentdetails />} />
            {/* <Route path="Admintransaction" element={<Admintransaction />} /> */}
            {/* <Route path="AddAdmintransactiondetails" element={<AddAdmintransactiondetails />} /> */}
            
            <Route path="Customerdetails" element={<Customerdetails />} />
            <Route path="AddCustomerdetails" element={<AddCustomerdetails />} />
            <Route path="Exemptedcustomercategoryedit/:id" element={<Exemptedcustomercategoryedit />} />
            <Route path="AddExempteddelivery" element={<AddExempteddelivery />} />
            {/* <Route path="location/:vehicletruckinfo" element={<Location />} /> */}
            <Route path="OpenGoogleMap/:userid/:truckinfo/:deviceid" element={<OpenGoogleMap />} />
            <Route path="Settings" element={<Settings />} />
            
            <Route path="AddSettings" element={<AddSettings />} />  
            
            <Route path="Settingsedit/:id" element={<Settingsedit />} />

            <Route path="Settingsedit/:id" element={<Settingsedit />} />

            <Route path="api-settings" element={<ApiSettings />} />
            <Route path="organization-settings" element={<OrganizationSettings />} />
            <Route path="tariff-freight" element={<TariffFreight />} />

            <Route path="Sqcnumber" element={<SQCdetails />} />
            <Route path="sqcnumber/:loadinDate" element={<SQCdetails />} />
            <Route path="addsqcnumber" element={<Addsqcnumber />} />
            <Route path="Printsqc/:id" element={<PrintSQC />} />
            <Route path="Sqcedit/:sqcId" element={<Sqcedit />} />

            
            <Route path="monthlytargetdetails" element={<Monthlytargetdetails />} />

            <Route path="Superdashboard" element={<SuperDashboard />} />
            <Route path="Supersubsricptiondetails" element={<SuperNotification />} />

            <Route path="loadinpopup/:loadinId" element={<UpdateLoadin />} />
            <Route path="loadoutpopup/:loadoutId" element={<UpdateLoadout />} />
            <Route path="detailsofsubscription/:orgcode" element={<Detailsofsubscription />} />
            <Route path="Viewadminpopup/:orgcode/:userid/:username" element={<ViewAdminAccount />} />
            <Route path="userProfile/:userid" element={<UserProfile />} />
            <Route path="consumer-bio/:consumernumber" element={<ConsumerBio />} />
            <Route path="Transanctionincomingapproval" element={<Transanctionincomingapproval/>}/>
            <Route path="Financeapproval" element={<Financeapproval/>}/>
            <Route path="Userattendance/:userid" element={<UserAttendance/>}/>
            <Route path="Activitycheck" element={<Activitycheck/>}/>
            <Route path="Salary" element={<Salary/>}/>
            <Route path="Agentprofile/:userid" element={<Agentprofile/>}/>  openstockupdate
                      {/* <Route path="openstockupdate/:searchDate" element={<Openstockupdate/>}/> */}
            <Route path="Addsqcnum" element={<Navigatesqc/>}/>
            <Route path="Areamaster" element={<Areamaster/>}/>
                   <Route path="Vehicleareamap" element={<VehicleAreamaster/>}/>
                   <Route path="vehiclemapping" element={<VehicleAreaAgentMap/>}/>
            <Route path="Csvupload" element={<Csvfile/>}/>
            <Route path="stocktransfer" element={<StockTransfer/>}/>
            
           {/* </Route> <Route path="" element={</>}/> */}
           <Route path="Agentinsurance" element={<Agentinsurance/>}/>
           <Route path="Staffrewards" element={<Staffrewards/>}/>
           
           <Route path="Teritory" element={<TeritoryComponent/>}/>
           <Route path="Salesarea/:Id" element={<Salesareacomponent/>}/>
           
           <Route path="Viewrewards" element={<Viewrewards/>}/>
           <Route path="Viewcsvupload" element={<Viewcsv/>}/>
           
           <Route path="Leaderboard" element={<Leaderboard/>}/>
           <Route path="Wallet" element={<Wallet/>}/>
           <Route path="Addfundsamount/:amount" element={<Addamount/>}/>
           <Route path="Superleaderboard" element={<Superleaderboard/>}/>
           
           <Route path="Teritoryleaderboard" element={<Teritoryleaderboard/>}/>
           
           <Route path="Leaderboardbutton" element={<Leaderboardbutton/>}/>
           
           <Route path="Adminleaderboard" element={<Adminleaderboard/>}/>
           
           <Route path="Consumerlist" element={<Consumerlist/>}/>
           <Route path="ConsumerLoyaltyPoints" element={<ConsumerLoyaltyPoints/>}/>
           
           <Route path="SuperTeritoryleaderboard" element={<SuperTeritoryleaderboard/>}/>
           
           <Route path="Supersalesarealeaderboard" element={<Supersalesarealeaderboard/>}/>
           
           <Route path="Rewardpointsbutton" element={<Rewardpointsbutton/>}/>
           
           <Route path="Refillsales" element={<Refillsales/>}/>
           
           <Route path="Consumablesales" element={<Consumablesales/>}/>
           

           <Route path="Superrewardpoints" element={<Superrewardpointsbutton/>}/>
           
           <Route path="Superrefillsales" element={<Superrefillsales/>}/>
           
           <Route path="Supersalesrefill" element={<Supersalesrefill/>}/>

           <Route path="Superconsumable" element={<Superconsumablesales/>}/>
           
           <Route path="Refillbutton" element={<Refillbutton/>}/>
           
           <Route path="Consumablebutton" element={<Consumablebutton/>}/>
           
           <Route path="Territoryconsumable" element={<Territoryconsumable/>}/>
           
           <Route path="Salesareaconsumable" element={<Salesareaconsumable/>}/>
           <Route path="truckinfo" element={<TruckInfo/>}/>
           <Route path="view-truckinfo/:truckinfo" element={<ViewTruckInfo/>} />
           {/* <Route path="invoicesAndErv" element={<SearchByInvoicesAndErv/>}/> */}
           
           <Route path="Productmaster" element={<Productmaster/>}/>
           <Route path="Logo" element={<Logo/>}/>
           
           <Route path="ReportFooter" element={<ReportFooter/>}/>
           <Route path="ReportHeader" element={<ReportHeader/>}/>
           <Route path="Counter" element={<Counter/>}/>
           <Route path="campaigns" element={<Campaigns/>}/>
           
           <Route path="Superproduct" element={<Superproduct/>}/>
           
           <Route path="Livegodown" element={<LiveGodownupdate/>}/>
           <Route path="Locationmap/userid/:truckinfo/:deviceid" element={<LocationMap/>}/>
           <Route path="HeatMap/userid/:truckinfo/:deviceid" element={<HeatMap/>}/>
            <Route path="Locationtable/:userid/:deviceid/:truckinfo" element={<Showtable/>}/> 
            <Route path="campaign-points/:campaignId" element={<CampaignRewrdPoints/>} />
            <Route path="refill-sales" element={<Products/>} />
            <Route path="beyond-lpg" element={<BeyondLpgProducts/>} />
            <Route path="consumables" element={<ConsumableProducts/>} />
            
            <Route path="ReportsPage" element={<ReportsPage/>}/>
            <Route path="Operationalreport" element={<Operationalreport/>}/>
             
             <Route path ="ConsumableStock" element={<ConsumableStock/>}/>
             <Route path ="Invoicereport" element={<Invoicereport/>}/>
             <Route path ="Personnelreport" element={<Personnelreport/>}/>
             <Route path ="Vehiclereports" element={<Vehiclereports/>}/>
             <Route path ="Taxreports" element={<Taxreports/>}/>
             <Route path ="rolereport" element={<Rolereport/>}/>
             <Route path ="agentMappingReport" element={<AgentMappingReport/>}/>
             <Route path ="VehicleMaintenanceReport" element={<VehicleMaintenanceReport/>}/>
             <Route path ="FuelLog" element={<FuelLog/>}/>
             <Route path ="LoadinReport" element={<LoadinReport/>}/>
             <Route path = "Newconnectionreport" element={<Newconnectionreport/>}/>
             <Route path = "Additionalreport" element={<Additionalreport/>}/>
             <Route path = "Consumabelsalesreport" element={<Consumabelsalesreport/>}/>
             <Route path = "Onlinedetailsreport" element={<Onlinedetailsreport/>}/>
             <Route path = "SQCReport" element={<SQCReport/>}/>
             <Route path = "SqcTypesreports" element={<SqcTypesreports/>}/>
             <Route path = "SQCwithinvoiceReport" element={<SQCwithinvoiceReport/>}/>
             <Route path = "SQCwithsqcnumberReport" element={<SQCwithsqcnumberReport/>}/>
             <Route path = "SQCwithtruckinfowithdateReport" element={<SQCwithtruckinfowithdateReport/>}/>
             <Route path = "SQCsummaryReport" element={<SQCsummaryReport/>}/>

             <Route path ="ProductService" element={<ProductService/>}/>

             {/* <Route path ="loadinreports" element={<LoadoutReports/>}/> */}
             <Route path ="TotalErvReport" element={<TotalErvReport/>}/>
             <Route path ="Monthlyincomingreport" element={<MonthlyincominReports/>}/>
             <Route path ="Transactionoutgoingreport" element={<Transactionoutgoingreport/>}/>
             <Route path ="TransactionIncomingReport" element={<TransactionIncomingReport/>}/>
             <Route path ="TargetachivementReport" element={<TargetachivementReport/>}/>

             <Route path ="Monthlyinvoicereport" element={<Monthlyinvoicereport/>}/>

             <Route path="ervreport" element={<TotalErvReport/>}/>
             <Route  path="LoadoutReport1" element={<LoadoutReport1/>}/>
             <Route  path="DeliveryReports" element={<Deliveryreports/>}/>
             {/* <Route  path="ExemptedcustomerdeliverysummaryReport" element={<ExcemptedcustomerdeliverysummaryReport/>}/> */}
             {/* <Route  path="ExemptedcustomerdeliverysummaryReport" element={<ExcemptedcustomerdeliverysummaryReport/>}/> */}
             <Route path="ExemptedcustomerpaymentsummaryReport" element={<ExcemptedcustomerpaymentsummaryReport/>}/>
<Route path="ExcemptedcustomerdeliverysummaryReport" element={<ExcemptedcustomerdeliverysummaryReport/>}/>
<Route path="ExcemptedcustomerdeliveryReport" element={<ExcemptedcustomerdeliveryReport/>}/>
<Route path="Bytransporter" element={<TransporterReport/>}/>
<Route path="Bytruckno" element={<TrucknumberReport/>}/>

<Route path="DayOperationsReport" element={<DayOperationalReport/>}/>

<Route path="ExemptedcustomerpaymentReport" element={<ExcemptedcustomerpaymentReport/>}/>


             <Route  path="Vendorcomponent" element={<VendorManagementcomponent/>}/>
             
             <Route  path="Warehouse" element={<WareHouse/>}/>
             <Route  path="Purchases" element={<Purchases/>}/>
             <Route  path="PurchaseList" element={<PurchaseList/>}/>
             <Route path="PurchaseEdit/:purchaseOrderId" element={<PurchaseEdit/>}/>
             <Route path="PurchaseView/:purchaseOrderId" element={<PurchaseView/>}/>
             <Route path="CreditnoteList" element={<CreditNoteList/>}/>
             <Route path="Creditnote" element={<CreditNote/>}/>
             <Route  path="Bills" element={<Bills/>}/>


             <Route  path="Goodsreceived" element={<GoodsReceived/>}/>
             <Route  path="Quotation" element={<Quotation/>}/>
             <Route  path="AddQuotation" element={<AddQuotation/>}/>
             <Route  path="ViewQuotation/:id" element={<ViewQuotation/>}/>
             <Route  path="EditQuotation/:id" element={<EditQuotation/>}/>

             <Route  path="InvoiceList" element={<InvoiceList/>}/>
             <Route  path="AddInvoice" element={<AddInvoice/>}/>
             <Route  path="ViewInvoice/:id" element={<ViewInvoice/>}/>
             <Route  path="EditInvoice/:id" element={<EditInvoice/>}/>

             <Route  path="Stockadjustment" element={<StockManagement/>}/>
             
             <Route  path="Vendorview/:vendorId" element={<VendorView/>}/>
             <Route  path="stockistViewmodal/:stockistId" element={<StockistpersonViewModal/>}/>

             {/* <Route  path="Profiledetails" element={<Profiledetails/>}/> */}
             <Route  path="performance" element={<PerformanceTarget/>}/>
             
             <Route path="loadin-details/:invoiceId" element={<EditLoadInDetails />} />
             <Route path="loadout-details/:ervId" element={<EditLoadOutDetails />} />

             <Route  path="Productcategory" element={<Productcategory/>}/>
             <Route  path="Stocklistperson" element={<Stocklistpersoncomponent/>}/>
             <Route path="truck-transporter" element={<TruckTransporters />} />
             <Route path="view-transporter/:transporterId" element={<ViewTransporterProfile />} />
             <Route path="productdetails/:categoryId" element={<Productdetails />} />
             <Route path="truck-drivers" element={<ViewAllTruckDrivers />} />
             <Route path="view-trucks/:driverId" element={<ViewDriverAndTrucks/>} />
             <Route path="ProfileSettings" element={<ProfileSettings/>} />

          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
