import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button ,Spinner} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import debounce from "lodash.debounce"; 
const Addstockist = ({ isOpen, onRequestClose ,vendorId}) => {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [uproduct, setUproduct] = useState({
    vendorid:vendorId,
    name: "",
    phone: "",
    email: "",
    address:"",
    pan:"",
    gst:"",
    stockistVendorMapping:{
      vendorId:vendorId,
      },
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [gstOptions, setGstOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false); 
  const [newStockist, setNewStockist] = useState({ name: "", email: "", phone: "", address: "" ,pan:"", gst:"",});
  const url = "/api/stocklist/create";
  const navigate = useNavigate();
  useEffect(() => {
    if (isOpen) {
      fetchGstOptions(); // Fetch GST options when modal is opened
    }
  }, [isOpen]);

  const fetchGstOptions = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get('/api/tax', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGstOptions(response.data); 
    } catch (error) {
      console.error("Error fetching GST options", error);
      toast.error("Failed to load GST options");
    }
  };
  // AJAX search function with debounce
  const handleSearchQueryChange = debounce(async (query) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    if (query.trim() === "") {
      setSearchResults([]); 
      setNoResults(false);
      return;
    }

    setLoading(true); 
    setNoResults(false); 
    try {
      const response = await axios.get(
        `/api/stocklist/orgcode=${orgcode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
// Filter the stockists based on the search query
const filteredResults = response.data.filter((stockist) =>
  stockist.name.toLowerCase().includes(searchQuery.toLowerCase())
);
setSearchResults(filteredResults);  
} catch (error) {
  console.error("Error searching stockist", error);
  toast.error("Failed to search stockists");
} finally {
  setLoading(false); // Stop loading
}
}, 500); // Wait for 500ms of inactivity before making the request
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);
  handleSearchQueryChange(query); // Trigger AJAX search
};

  const handleSelectStockist = (stockist) => {
    setUproduct({
      ...uproduct,
      id:stockist.id,
      name: stockist.name,
      phone: stockist.phone,
      email: stockist.email,
      address: stockist.address,
      pan: stockist.pan,
      gst: stockist.gst,
    });
    setSearchResults([]); // Clear search results after selection
  };

  const handleCreateStockist = (e) => {
    e.preventDefault();
    setUproduct((prev) => ({
      ...prev,
      name: newStockist.name,
      email: newStockist.email,
      phone: newStockist.phone,
      address: newStockist.address,
      pan:newStockist.pan,
      gst:newStockist.gst,
    }));
    setShowCreateModal(false); // Close the small modal
  };
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    // Always create a new stockist or vendor mapping
    const apiUrl = uproduct.id
      ? "/api/stockistVendorMapping/create" 
      : "/api/stockistVendorMappingDo/create"; 
  
    const requestBody = uproduct.id
      ? { vendorId: uproduct.vendorid, stockistId: uproduct.id }
      : uproduct; 
  
    axios
      .post(apiUrl, requestBody, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Stockist mapped successfully");
        onRequestClose(); // Close the modal
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "The given stockist and vendor already mapped") {
          // Custom handling for this specific error
          toast.error("The given stockist and vendor are already mapped.");
        } else {
          handleError(error); // Handle other errors
        }
      });
  }
  const rhandle = (e) => {
    const { id, value } = e.target; 
  
    setUproduct((prevUproduct) => ({
      ...prevUproduct,
      [id]: value, 
    }));
  };    

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  
  return (
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Stockists</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {/* Search Input */}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem" }}>
            <input
              className="form-control"
              type="text"
              placeholder="Search stockist by name"
              value={searchQuery}
              onChange={handleSearchChange} // Call this function on input change
              style={{ width: "250px", marginRight: "10px" }}
            />
          <button className="btn btn-primary mt-2"  style={{ display: 'flex', alignItems: 'center',marginRight: '50px' }}>
          <FaSearch />                  
           </button>
           <button className="btn btn-success" onClick={() => setShowCreateModal(true)}>
              Create Stockist
            </button>

        </div>
 {/* Loader */}
          {loading && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center",color:"red" }}>
              <Spinner animation="border" />
            </div>
          )}

         
        {/* Display Search Results */}
        {searchResults.length > 0 && (
          <ul className="list-group mb-3">
            {searchResults.map((result) => (
              <li
                key={result.id}
                className="list-group-item"
                onClick={() => handleSelectStockist(result)}
                style={{ cursor: "pointer" }}
              >
                {result.name} - {result.email} - {result.phone}
              </li>
            ))}
          </ul>
        )}
{uproduct.name && (
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="name">Name</label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="name"
                          value={uproduct.name}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="phone">Phone</label>
                        <input
                          className="form-control"
                          id="phone"
                          type="text"
                          placeholder="phone"
                          value={uproduct.phone}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="email">Email</label>
                        <input
                          className="form-control"
                          id="email"
                          type="text"
                          placeholder="email"
                          value={uproduct.email}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="address">Address</label>
                        <input
                          className="form-control"
                          id="address"
                          type="text"
                          placeholder="address"
                          value={uproduct.address}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="pan">PAN</label>
                        <input
                          className="form-control"
                          id="pan"
                          type="text"
                          placeholder="pan"
                          value={uproduct.pan}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gst">GST</label>
                    <select
                      className="form-control"
                      id="gst"
                      value={uproduct.gst}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select GST</option>
                      {gstOptions.map((gst) => (
                        <option key={gst.id} value={gst.tax}>
                          {gst.tax}%
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                   
                    
                   
                    <input
                      type="submit"
                      value="Link"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                </div>
              </div>
            </div>
 )}

          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
   {/* Small Modal for Creating Stockist */}
   <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Stockist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateStockist}>
            <div className="mb-2">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={newStockist.name}
                onChange={(e) => setNewStockist({ ...newStockist, name: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={newStockist.email}
                onChange={(e) => setNewStockist({ ...newStockist, email: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                value={newStockist.phone}
                onChange={(e) => setNewStockist({ ...newStockist, phone: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                value={newStockist.address}
                onChange={(e) => setNewStockist({ ...newStockist, address: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>PAN</label>
              <input
                type="text"
                className="form-control"
                value={newStockist.pan}
                onChange={(e) => setNewStockist({ ...newStockist, pan: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>GST</label>
              <select
                className="form-control"
                value={newStockist.gst}
                onChange={(e) => setNewStockist({ ...newStockist, gst: e.target.value })}
              >
                <option value="">Select GST</option>
                {gstOptions.map((gst) => (
                  <option key={gst.id} value={gst.tax}>
                    {gst.tax}%
                  </option>
                ))}
              </select>
            </div>
            <div className="text-end">
              <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" className="ms-2">
                Add
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Addstockist;
