import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import img from "../images/report.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ExcemptedcustomerpaymentsummaryReport() {
  const [Customerpayment, setCustomerpayment] = useState([]);
  const [dateInput, setDateInput] = useState("");
  const [loader, setLoader] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0); // State to hold the total amount
  const [loading, setLoading] = useState(false);

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getData = async (inputDate) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/customerpayment/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${inputDate || currentDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomerpayment(trans.data);
      setLoader(false);
      setShowTable(true);
      calculateTotalAmount(trans.data); // Calculate total amount
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  }

  useEffect(() => {
    getData(); // Fetch current date data on load
  }, []);

  // Calculate the total amount
  const calculateTotalAmount = (data) => {
    const total = data.reduce((acc, item) => acc + item.amountpaid, 0);
    setTotalAmount(total);
  };

  // Generate PDF report
  const generatePDF = async () => {
    if (!Customerpayment.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Excempted Customer Payment Summary Report";
      const reportNameY = logoY + logoHeight + 5;
      doc.text(reportName, logoX, reportNameY);

      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: 'right' });

      const tableData = Customerpayment.map(item => [
        item.date,
        item.amountpaid,
        item.customerName,
        item.modeofpaymentid,
        item.paymentreferencenumber,
      ]);

      doc.autoTable({
        startY: addressY + 20,
        head: [['Date', 'Amount Paid', 'Customer Name', 'Mode Of Payment Id', 'Payment Reference Number']],
        body: tableData,
        theme: 'striped',
        styles: { overflow: 'linebreak' },
      });

      // Add total amount to PDF
      const totalText = `Total Amount Paid: ${totalAmount}`;
      doc.text(totalText, logoX, doc.lastAutoTable.finalY + 10);

      doc.save("customerpaymentsummaryreport.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  // Update date input state and fetch data
  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDateInput(selectedDate);
    getData(selectedDate);
  };

  // Generate table data for display
  const generateTableData = () => {
    getData(dateInput); // Fetch data for the selected date
  };

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <br />
      <div className="container">
        <div className="card card-raised">
          <div className="card card-raised mx-auto text-center" style={{ maxWidth: "800px" }}>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <img src={img} alt="logo" style={{ width: "250px" }} />
                <div style={{ textAlign: "right" }}>
                  <h3 style={{ fontFamily: "Sans-serif", marginBottom: "0" }}>Vijay Gas Agencies</h3>
                  <p style={{ fontFamily: "Sans-serif" }}>1010/27 Mysore Road, Gundlupete - 571111</p>
                </div>
              </div>

              <h4>Exempted Customer Payment Summary Report</h4>

              <div className="mb-4">
                <input 
                  type="date" 
                  value={dateInput} 
                  onChange={handleDateChange} 
                  className="form-control" 
                  style={{ width: "200px", margin: "0 auto" }} 
                />
              </div>
              
              <div className="d-flex justify-content-center mb-4">
                <button className="btn btn-primary mx-2" onClick={generateTableData}>Generate</button>
                <button className="btn btn-success mx-2" onClick={generatePDF}>Download PDF</button>
              </div>
              {loading && <div className="loading">Loading...</div>}

              {loader && <div className="loader text-center my-3"></div>}
              
              {showTable && (
                <>
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr style={{ fontSize: "17px", backgroundColor: "blue", color: "white" }}>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Mode Of Payment Id</th>
                        <th>Payment Reference Number</th>
                        <th>Amount Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Customerpayment.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">No Data Available</td>
                        </tr>
                      ) : (
                        Customerpayment.map((accd) => (
                          <tr key={accd.id}>
                            <td>{accd.date}</td>
                            <td>{accd.customerName}</td>
                            <td>{accd.modeofpaymentid}</td>
                            <td>{accd.paymentreferencenumber}</td>
                            <td>{accd.amountpaid}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <h5>Total Amount Paid: {totalAmount}</h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExcemptedcustomerpaymentsummaryReport;
