import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";
import { useNavigate } from 'react-router-dom';

function Deliveryreports() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");

  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgId = JSON.parse(localStorage.getItem("data")).orgcode;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  const fetchProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;

    try {
      const response = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const productsData = response.data.map((product) => ({
        id: product.id,
        productname: product.productname,
      }));

      setProducts(productsData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  const handleTypeChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  const handleProductChange = (event) => {
    setProductFilter(event.target.value);
  };

  const getData = async () => {
    if (!month || !year) {
      toast.error("Please select both month and year.");
      return;
    }

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgId = JSON.parse(localStorage.getItem("data")).orgcode;

    try {
      const response = await axios.get(
        `/api/returntransactions/orgcode=${orgId}/vehicle=${vehicleFilter}/productname=${productFilter}/transactiontype=${typeFilter}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      if (response.data.daily_values) {
        setReportData(response.data.daily_values); 
      } else {
        setReportData([]); 
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch report data.");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

      // Add report name below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportNameText = "Delivery Report";
      const reportNameY = logoY + logoHeight + 5;
      doc.text(reportNameText, logoX, reportNameY);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: 'right' });

      const tableData = reportData.map(item => [
        item.date,
        item.quantity, // Ensure your API returns this field
        item.neworg,
        item.additional,
        item.online
      ]);

      doc.autoTable({
        startY: addressY + 20,
        head: [['Date', 'Quantity', 'New org', 'Additional', 'Online']],
        body: tableData,
        theme: 'striped',
        styles: { overflow: 'linebreak' },
      });

      doc.save("delivery.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  useEffect(() => {
    fetchVehicles();
    fetchProducts(); 
  }, []);



  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="d-flex align-items-center">
                <div className="card-body p-4">
                  <div>
                    <label htmlFor="month">Month:</label>
                    <DatePicker
                      selected={month}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                  <br />
                  
                  {loading && <div className="loading">Loading...</div>}
                </div>
              </div>
              <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                <label>Type:</label>
                <select value={typeFilter} onChange={handleTypeChange}>
                  <option value="">Select Type</option>
                  <option value="sales">Sales</option>
                  <option value="additional">Additional</option>
                  <option value="new connection">New Org</option>
                  <option value="online">Online</option>
                </select>
              </div>
              <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                <label>Vehicle:</label>
                <select value={vehicleFilter} onChange={handleVehicleChange}>
                  <option value="">Select Vehicle</option>
                  {vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.truckinfo || vehicle.licenceNo}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                <label>Product:</label>
                <select value={productFilter} onChange={handleProductChange}>
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.productname}>
                      {product.productname}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                disabled={loading}
                className="btn btn-primary"
                onClick={getData}
              >
                Generate Report
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                disabled={loading || !reportData.length}
                className="btn btn-secondary"
                onClick={generatePDF}
              >
                Download PDF
              </button>
              &nbsp;&nbsp;&nbsp;
            
            </div>
            {loading && <div className="loading">Loading...</div>}

            {reportData.length > 0 && (
              <table className="table table-striped mt-4">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Quantity</th>
                    <th>New org</th>
                    <th>Additional</th>
                    <th>Online</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.quantity}</td>
                      <td>{item.neworg}</td>
                      <td>{item.additional}</td>
                      <td>{item.online}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Deliveryreports;
