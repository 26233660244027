import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust import path as needed
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router";

const EditTruckTransporter = ({ isOpen, onRequestClose, transporterDetails }) => {
  const [loading, setLoading] = useState(false);

  const [transporters, setTransporters] = useState({
    id:"",
    transporterName: "",
    email: "",
    phoneNumber: "",
    address: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  // Handle changes for input fields
  const handleChange = (e) => {
    const { id, value } = e.target;
    setTransporters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/transporter-details/update", transporters, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Transporter details updated successfully");
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  const navigate = useNavigate();

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    if (transporterDetails) {
      setTransporters(transporterDetails);
    }
  }, [transporterDetails]);

  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Transporter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Card className="mb-4">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Transporter Name</Form.Label>
                  <Form.Control
                    id="transporterName"
                    type="text"
                    placeholder="Transporter Name"
                    value={transporters.transporterName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={transporters.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    id="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    value={transporters.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    id="address"
                    type="text"
                    placeholder="Address"
                    value={transporters.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            <Button variant="secondary me-3" onClick={onRequestClose}>
            Close
          </Button>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Update"}
            </Button>
          </Form>
        </Modal.Body>
      
      </Modal>
    </>
  );
};

export default EditTruckTransporter;
