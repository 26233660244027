import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function ProfileeditModalPopup({ isOpen, onRequestClose, Id }) {
  const [uproduct1, setUproduct1] = useState({
    userid:JSON.parse(localStorage.getItem("data")).userid,
    name: "",
    orgcode: "",
    contact: "",
    username:"",
    gstin:"",
    pan: "",
    tan: "",
    addressLine1: "",
    addressLine2: "",
    city:"",
    pincode:"",
    state:"",
    logo:"",
    imageMeta:"",
  
  });
 
  const [loading, setLoading] = useState(false);
  const [consumerTypes, setConsumerTypes] = useState([]);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/organizationprofiles/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  
 
  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/organizationprofiles/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Profile updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const value = event.target.value;
    setUproduct1((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
      
      setUproduct1(prevState => ({
        ...prevState,
        imageMeta: metadata,
        logo: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Profile</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="name">Name</label>
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    placeholder="name"
                    value={uproduct1.name}
                    onChange={(e) => rhandle(e, "name")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="username">Email</label>
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="username"
                    value={uproduct1.username}
                    onChange={(e) => rhandle(e, "username")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="orgcode">Orgcode</label>
                  <input
                    className="form-control"
                    id="orgcode"
                    type="text"
                    placeholder="orgcode"
                    value={uproduct1.orgcode}
                    onChange={(e) => rhandle(e, "orgcode")}
                  />
                  </div>
                 
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="contact">Contact</label>
                  <input
                    className="form-control"
                    id="contact"
                    type="text"
                    placeholder="contact"
                    value={uproduct1.contact}
                    onChange={(e) => rhandle(e, "contact")}
                  />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="gstin">GST</label>
                  <input
                    className="form-control"
                    id="gstin"
                    type="text"
                    placeholder="0"
                    value={uproduct1.gstin}
                    onChange={(e) => rhandle(e, "gstin")}
                  />
                  </div>
                  </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="pan">Pan</label>
                  <input
                    className="form-control"
                    id="pan"
                    type="text"
                    placeholder="pan"
                    value={uproduct1.pan}
                    onChange={(e) => rhandle(e, "pan")}
                  />
                  </div>
                  </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="tan">Tan</label>
                  <input
                    className="form-control"
                    id="tan"
                    type="text"
                    placeholder="tan"
                    value={uproduct1.tan}
                    onChange={(e) => rhandle(e, "tan")}
                  />
                  </div>
                  </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="addressLine1">addressLine1</label>
                  <input
                    className="form-control"
                    id="addressLine1"
                    type="text"
                    placeholder="addressLine1"
                    value={uproduct1.addressLine1}
                    onChange={(e) => rhandle(e, "addressLine1")}
                  />
                  </div>
                  </div>
                  
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="addressLine2">AddressLine2</label>
                  <input
                    className="form-control"
                    id="addressLine2"
                    type="text"
                    placeholder="addressLine2"
                    value={uproduct1.addressLine2}
                    onChange={(e) => rhandle(e, "addressLine2")}
                  />
                  </div>
                  </div>
                  
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="city">City</label>
                  <input
                    className="form-control"
                    id="city"
                    type="text"
                    placeholder="city"
                    value={uproduct1.city}
                    onChange={(e) => rhandle(e, "city")}
                  />
                  </div>
                  </div>
                  
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="state">State</label>
                  <input
                    className="form-control"
                    id="state"
                    type="text"
                    placeholder="state"
                    value={uproduct1.state}
                    onChange={(e) => rhandle(e, "state")}
                  />
                  </div>
                  </div>
                  
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="pincode">Pincode</label>
                  <input
                    className="form-control"
                    id="pincode"
                    type="text"
                    placeholder="pincode"
                    value={uproduct1.pincode}
                    onChange={(e) => rhandle(e, "pincode")}
                  />
                  </div>
                  </div>
                  
                  
              </div>
              <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="logo">Update Image</label>
    {uproduct1.logo && (
                  <div>
                    <img
                      src={`data:${uproduct1.imageMeta};base64,${uproduct1.logo}`}
                      alt="Uploaded"
                      style={{ width: "40px", height: "auto" }}
                    />
                  </div>
                )}
                {uproduct1.imageMeta && (
                  <div>
                    <p>
                    </p>
                  </div>
                )}
    <input
      className="form-control"
      id="logo"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>

                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default ProfileeditModalPopup;