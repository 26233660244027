import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

  const Register = ({ isOpen, onRequestClose,orgcode }) => {

  // const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem("data"))?.userid;

  const url = "/api/registerAdminOrManager";
  const navigate = useNavigate();

  const [admindata, setAdata] = useState([
    {
      username: "",
      phNo:"",
      name:"",
      role: "",
      organization: {
        orgcode: orgcode,
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [accountrole, setAccountrole] = useState([]);
  const [initialSetupData, setInitialSetupData] = useState(null); // State to store initial setup data
  const getData = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;

      const accountRoleResponse = await axios.get("/api/accountRole", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccountrole(accountRoleResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "Error fetching roles");
    }
  };

  

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    // Set orgcode in admindata when orgcode prop changes
    setAdata((prevData) => ({
      ...prevData,
      organization: { orgcode: orgcode },
    }));
  }, [orgcode]);
  function rsubmit(e) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    e.preventDefault();
    console.log("Submitting admindata:", admindata);
    if (!admindata.name || !admindata.name.trim()) {
      toast.error("Please enter name");
      return;
    }
   
    if (!admindata.role || !admindata.role.trim()) {
      toast.error("Please select a role");
      return;
    }
    if (!admindata.organization.orgcode) {
      toast.error("Please select an organization");
      return;
    }
    setLoading(true);
    axios
      .post(url, {
        username: admindata.username,
        role: admindata.role,
        phNo:admindata.phNo,
        name:admindata.name,
        organization: { orgcode: orgcode},

      }, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        console.log(res.data.roleRequested);
        setLoading(false);

        toast(res.data.message);
     
      onRequestClose(); 
      navigate("/users/Activitycheck");

        setAdata({}); // Assuming setAdmindata is a state setter function
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
      setLoading(false)
  }
 
  const rhandle = (e) => {
    const { id, value } = e.target;
    console.log(`Updating field: ${id} with value: ${value}`); // Debugging line
  
    setAdata((prevData) => {
      if (id === "orgcode") {
        return {
          ...prevData,
          organization: { orgcode: value }, 
        };
      } else {
        return {
          ...prevData,
          [id]: value,
        };
      }
    });
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
          
                      <div className="text-center">
                        <Link to="users/admindashboard">
                          {" "}
                          <img
                            className="mb-3"
                            src="assets/img/icons/background.svg"
                            alt="..."
                            style={{ height: "48px" }}
                          />
                        </Link>

                        <h1 className="display-5 mb-0">Create Account</h1>
                      </div>

                      <form onSubmit={(e) => rsubmit(e)}>
                      <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="Name">Name</label>
                            <input
                              className="form-control"
                              id="name"
                              placeholder="enter name"
                              value={admindata.name}
                              onChange={rhandle}
                              />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="username">Email</label>
                            <input
                              className="form-control"
                              id="username"
                              type="email"
                              placeholder="enter email"
                              value={admindata.username}
                              onChange={rhandle}
                              />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="Phone Number">Phone</label>
                            <input
                              className="form-control"
                              id="phNo"
                            
                              placeholder="enter phone number"
                              value={admindata.phNo}
                              onChange={rhandle}
                              />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="role">Role</label>
                            <select
                              id="role"
                              className="form-control"
                              type="text"
                              placeholder="role"
                              onChange={rhandle}
                              >
                              <option>select role</option>
                              {accountrole.map((arole, index) => {
                                  // Check if the role is not "ROLE_SUPER"
                                  if (arole.role !== 'ROLE_SUPER') {
                                    return (
                                      <option key={index} value={arole.role}>
                                        {arole.role}
                                      </option>
                                    );
                                  }
                                  return null; // Skip rendering for "ROLE_SUPER"
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-2">
            <label htmlFor="orgcode">Orgcode</label>
            <input
              id="orgcode"
              className="form-control"
              value={admindata.organization?.orgcode}
              onChange={rhandle}
              readOnly
            />
             
          </div>

                        <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                          <input
                            type="submit"
                            value="create account"
                            className="btn btn-primary"
                          />
                          
                           <Button variant="secondary" onClick={onRequestClose}>
                       Close
                        </Button> 
                        </div>
                        {loading && <div className="loading"></div>} 
                      </form>
                      <br/>
                     
                  

               </Modal.Body>
      <Modal.Footer>
     
       </Modal.Footer>
    </Modal>
  );
};
export default Register;
