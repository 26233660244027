import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl"; // Replace with your actual base URL configuration
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function ExcemptedcustomerdeliverysummaryReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;
  
    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      return;
    }
  
    if (!startDate || !endDate) {
      toast.error("Please select start and end dates.");
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.get(
        `/api/exemptedDetails/totalAmountByDateRange/orgcode=${orgcode}/${startDate.toISOString().split("T")[0]}/${endDate.toISOString().split("T")[0]}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      console.log("Response data:", response.data); // Debugging response data
      setReportData(response.data.data || []);  // Update based on new response structure
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch report data.");
    } finally {
      setLoading(false);
    }
  };
  
  // Generate PDF report
  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo to the left side
      const imgData = img;
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Add the name of the report below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Exempted Customer Delivery Report";
      const reportNameY = logoY + logoHeight + 5;
      doc.text(reportName, logoX, reportNameY);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: "right" });

      const tableData = reportData.map((item) => [
        item.date,
        item.customerNumber,
        item.customerName,
        item.productName,  // Corrected property name
        item.quantity,
        item.rate,
        item.price,
        item.tariff,
        item.totalAmount,  // Corrected property name
      ]);

      doc.autoTable({
        startY: addressY + 20,
        head: [
          ["Date", "Customer Number", "Customer Name", "Product Name", "Quantity", "Rate", "Price", "Tariff", "Total"],
        ],
        body: tableData,
        theme: "striped",
        styles: { overflow: "linebreak" },
      });

      doc.save("exempted_customer_delivery_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log("Selected start date:", date); // Debugging date selection
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    console.log("Selected end date:", date); // Debugging date selection
  };

  useEffect(() => {
    if (startDate && endDate && customerNumber) {
      getData();
    }
  }, [startDate, endDate, customerNumber]);

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div>
                    <label htmlFor="startdate">Start Date:</label>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <div>
                    <label htmlFor="enddate">End Date:</label>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                  <br />
                 
                  <br />
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={getData}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    disabled={loading || !reportData.length}
                    className="btn btn-secondary"
                    onClick={generatePDF}
                  >
                    {loading ? "Generating PDF..." : "Download PDF"}
                  </button>
                  {loading && <div className="loading">Loading...</div>}
                </div>
              </div>
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Customer Number</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Price</th>
                        <th>Tariff</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.length > 0 ? (
                        reportData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.customerName}</td>
                            <td>{item.customerNumber}</td>
                            <td>{item.productName}</td> 
                            <td>{item.quantity}</td>
                            <td>{item.rate}</td>
                            <td>{item.price}</td>
                            <td>{item.tariff}</td>
                            <td>{item.totalAmount}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ExcemptedcustomerdeliverysummaryReport;
