import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Button, Select, MenuItem, Typography, Box, IconButton, FormControl, InputLabel } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../api/baseurl";

const PurchaseModal = ({ open, onClose, onSave }) => {
  const [stockistList, setStockistList] = useState([]);
  const [selectedStockist, setSelectedStockist] = useState("");

  const token = JSON.parse(localStorage.getItem("data"))?.token;

  useEffect(() => {
    if (open) {
      fetchStockists();
    }
  }, [open]);

  const fetchStockists = async () => {
    try {
      const response = await axios.get(`api/stocklist/orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStockistList(response.data);
      console.log("Stockist List Response:", response.data);
    } catch (err) {
      console.error("Failed to fetch stockists:", err);
    }
  };

  const handleStockistChange = (e) => {
    setSelectedStockist(e.target.value);
  };

  const handleSave = () => {
    const selectedStockistData = stockistList.find(stockist => stockist.id === selectedStockist);
    const { id,name, address, email, phone } = selectedStockistData || {};
    // console.log("Selected Stockist Vendor ID:", vendorid);

    // const vendorid = selectedStockistData?.vendorid || selectedStockistData?.vendor?.vendorid;
    onSave({
      // selectedStockistVendorid:vendorid,
      selectedStockistId:id,
      selectedStockistName: name,
      selectedStockistAddress: address,
      selectedStockistEmail: email,
      selectedStockistPhone: phone
    });
    onClose();
  
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Select Stockist</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Box marginBottom="20px">
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Select Stockist</InputLabel>
            <Select
              value={selectedStockist}
              onChange={handleStockistChange}
              displayEmpty
            >
              {stockistList.map((stockist) => (
                <MenuItem key={stockist.id} value={stockist.id}>
                  {stockist.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Display selected stockist details */}
        {selectedStockist && (
          <div style={{ marginBottom: "20px", padding: "10px", backgroundColor: "#f5f5f5", borderRadius: "5px" }}>
              {/* <Typography variant="subtitle1">
              <strong> Vendor Id:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.vendorid}
            </Typography> */}
             <Typography variant="subtitle1">
              <strong>Stockist ID:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.id}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Stockist Name:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.name}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Address:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.address}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Email:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.email}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Phone:</strong> {stockistList.find(stockist => stockist.id === selectedStockist)?.phone}
            </Typography>
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button onClick={handleSave} variant="contained" color="primary" disabled={!selectedStockist}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseModal;
