import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
import { Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AddTransporterContactPerson from "./addTransporterContactPerson";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";

function TransporterContactPersons({ selectedTransporterId }) {
  const [contactPersons, setContactPersons] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContactPersons, setFilteredContactPersons] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const getData = async (pageNumber = 0) => {
    if (!selectedTransporterId) return;
    setLoader(true);
    try {
      const res = await axios.get(
        `/api/transporter-contact-person/getByTransporterId/transporterId=${selectedTransporterId}`, {
          params: { page: pageNumber },
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setContactPersons(res.data.content);
        setCurrentPage(res.data.number);
        setTotalPages(res.data.totalPages);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (contactPersons.length > 0) {
      const filtered = contactPersons.filter(
        (contact) =>
          (contact?.contactPersonName?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (contact?.email?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (contact?.phoneNumber && String(contact.phoneNumber).includes(searchTerm))
      );
      setFilteredContactPersons(filtered);
    }
  }, [searchTerm, contactPersons]);

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  useEffect(() => {
    getData(currentPage);
  }, [selectedTransporterId, currentPage]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const handleAddContactPersons = () => {
    setSelectedContact(null);
    setIsModalOpen(true);
  };

  const [selectedContact, setSelectedContact] = useState(null);

  const handleEdit = async (contact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };

  const handleDelete = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/transporter-contact-person",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("Deleted successfully");
    setLoading(false); 
    getData(currentPage);
  } catch (error) {
    setLoading(false); 
    handleError(error);
  }
};

  return (
    <div className="contact-persons">
      <div className="card card-raised mb-5">
        <div className="card-body">
          {loader && (
            <div className="loading"></div>
          )}
         <div className="card-title form-group d-flex align-items-center justify-content-between mb-3"> 
          <div className="form-group position-relative" style={{ width: '320px' }}> 
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by name, phone number or email"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>
<button className="btn btn-primary" onClick={handleAddContactPersons}>ADD </button>
              </div>

              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredContactPersons.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        NO DATA AVAILABLE
                      </td>
                    </tr>
                  ) : (
                    filteredContactPersons.map((detail, index) => (
                      <tr key={index}>
                        <td>{detail.contactPersonName}</td>
                        <td>{detail.email}</td>
                        <td>{detail.phoneNumber}</td>
                        <td> 
                     <button
                      onClick={() => handleEdit(detail)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                         cursor:"pointer"
                       }}
                       title="Edit"
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "30px",
                            fontSize: "24px",
                            bottom:"0px",
                            cursor:"pointer"
                          }}
                          title="Delete"
                          onClick={(e) => {
                            handleDelete(
                              e,
                              detail.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table> 
              <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
        </div>
        {isModalOpen && (
          <AddTransporterContactPerson isOpen={isModalOpen} onRequestClose={handleCloseModal} transporterId={selectedTransporterId} selectedContact={selectedContact}/>
        )}
      </div>
    </div>
  );
}

export default TransporterContactPersons;
