import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { Modal, Button } from "react-bootstrap";

const ProductPriceUpdate = ({ isOpen, onRequestClose, productId }) => {
  const [time, setTime] = useState(new Date());
  const date = new Date();

  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const token = JSON.parse(localStorage.getItem("data")).token;
  const [selectedVehicle, setSelectedVehicle] = useState(""); 
  const [fleetDetails, setFleetDetails] = useState([]); 
  const [prices, setPrices] = useState({
    productid: productId,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    date: currentDate,
    productname: '',
   
    pprice: {
      price:0
    },
      addPrice: {
        additionalPrice:0
      },

      newConPrice: {
        newConPrice: 0
      },
      
      productPoints: [
        { pointsType: "sales", points: "" },
        { pointsType: "additional", points: "" },
        { pointsType: "new connection", points: "" },
      ],
    
      productCategory: {  
        categoryname: "",
        productCategoryTypeId:"",
        categoryType: {
          categorytype:"",
      }
      },
    officePrice: {
      salecost: 0,
      additionalcost: 0,
      newconection: 0
    },
    fleetPrice: [
      {
        vehicle:"",
        salecost: 0,
        additionalcost: 0,
        newconection: 0
      }
    ]
  });



  const [product, setProduct] = useState({});
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = "/api/productpriceupdate/update";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const currentDate = new Date().toISOString().split('T')[0];
        const [productRes, vehicleRes] = await Promise.all([
          axios.get(`/api/product/productid=${productId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
         
        ]);
       

        const productData = productRes.data;
       
        setProduct(productData);
        setVehicle(vehicleRes.data);
        const productName = productRes.data.productname;
        const orgId = JSON.parse(localStorage.getItem("data")).orgcode;
        const [officeRes, fleetRes] = await Promise.all([
          axios.get(`/api/officefinancial/orgid=${orgId}/productname=${productName}/date=${currentDate}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`/api/fleetfinancial/orgid=${orgId}/productname=${productName}/date=${currentDate}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const officeData = officeRes.data;
        const fleetData = fleetRes.data;
        const fleetPrice = vehicleRes.data.map((veh) => {
          const fleetMatch = fleetData.find((fleet) => fleet.vehicle === veh.truckinfo);
          return {
            vehicle: veh.truckinfo,
            salecost: fleetMatch ? fleetMatch.salecost || 0 : 0,
            additionalcost: fleetMatch ? fleetMatch.additionalcost || 0 : 0,
            newconection: fleetMatch ? fleetMatch.newconection || 0 : 0,
          };
        });

          // Ensure productPoints are properly structured

          const requiredPointsTypes = ["sales", "additional", "new connection"];
        let updatedProductPoints = productData.productPoints || 0;

        requiredPointsTypes.forEach((type) => {
          if (!updatedProductPoints.find((point) => point.pointsType === type)) {
            updatedProductPoints.push({ points: 0, pointsType: type });
          }
        });
        setPrices((prevPrices) => ({
          ...prevPrices,
          productid: productData.productid,
          orgcode: productData.orgcode,
          productname: productData.productname,
          productcode: productData.productcode,
          date: currentDate,
          pprice: {
            price: productData.pprice?.price || 0,
          },
          addPrice: {
            additionalPrice: productData.addPrice?.additionalPrice || 0,
          },
          newConPrice: {
            newConPrice: productData.newConPrice?.newConPrice || 0,
          },
          productPoints: updatedProductPoints,
          officePrice: {
            salecost: officeData?.salecost || 0,
            additionalcost: officeData?.additionalcost || 0,
            newconection: officeData?.newconection || 0,
          },
          
          fleetPrice: fleetPrice,
          productCategory: {
            categoryname: productData.productCategory?.categoryname || "",
            productCategoryTypeId:productData.productCategory?.productCategoryTypeId || "",
            categoryType: {
              categorytype: productData.productCategory?.categoryType?.categorytype || "",
            },
          },
        }));
       
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
    fetchData();
  }, [productId]);

  const handlePriceChange = (e, field, category, vehicleId) => {
    const value = e.target.value;
    if (category === 'product') {
      let updatedOfficePrice = prices.officePrice;
      let updatedFleetPrice = prices.fleetPrice;
  
      if (field === 'additionalPrice') {
        updatedOfficePrice = {
          ...updatedOfficePrice,
          additionalcost: value,
        };
        updatedFleetPrice = updatedFleetPrice.map((fleet) => ({
          ...fleet,
          additionalcost: value,
        }));
      }else if (field === 'price') {
        updatedOfficePrice = {
          ...updatedOfficePrice,
          salecost: value,
        };
        updatedFleetPrice = updatedFleetPrice.map((fleet) => ({
          ...fleet,
          salecost: value,
        }));
      }else if (field === 'newConPrice') {
        updatedOfficePrice = {
          ...updatedOfficePrice,
          newconection: value,
        };
        updatedFleetPrice = updatedFleetPrice.map((fleet) => ({
          ...fleet,
          newconection: value,
        }));
      }
  
      setPrices((prevPrices) => ({
        ...prevPrices,
        addPrice: {
          ...prevPrices.addPrice,
          [field]: value,
        },
        pprice: {
          ...prevPrices.pprice,
          [field]: value,
        },
        newConPrice: {
          ...prevPrices.newConPrice,
          [field]: value,
        },
        officePrice: updatedOfficePrice,
        fleetPrice: updatedFleetPrice,
      }));

      
    } else if (category === 'office') {
      setPrices((prevPrices) => ({
        ...prevPrices,
        officePrice: {
          ...prevPrices.officePrice,
          [field]: value,
        },
      }));
    } else if (category === 'fleet') {
      setPrices((prevPrices) => ({
        ...prevPrices,
        fleetPrice: prevPrices.fleetPrice.map((fleet) =>
          fleet.vehicle === vehicleId
            ? { ...fleet, [field]: value }
            : fleet
        ),
      }));
    } else if (category === 'points') {
      setPrices((prevPrices) => ({
        ...prevPrices,
        productPoints: prevPrices.productPoints.map((point) =>
          point.pointsType === field ? { ...point, points: value } : point
        ),
      }));
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    const payload = {
      ...prices,
      productPoints: prices.productCategory.productCategoryTypeId === 2
        ? prices.productPoints.filter((point) => point.pointsType === "sales")
        : prices.productPoints,
    };

    try {
      await axios.post("/api/productpriceupdate/update", payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Product price updated successfully");
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Product Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading && <div className="loading ml-3"></div>}
        <div className="col-xl-12 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-2">
              <form onSubmit={handleSubmit}>
                <div>
                  <p>Product: {product.productname}</p>
                  <p>Category Name: {prices.productCategory?.categoryname}</p>
  <p>Category Type: {prices.productCategory?.categoryType.categorytype}</p>
                </div>
                <div>
                  <label htmlFor="date">Date: </label>
                  <input
                    id="date"
                    value={currentDate}
                    readOnly
                  />
                </div>
                <br />
                <br />
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sales Cost</th>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <th>Additional Cost</th>
                       
                        <th>New Connection Cost</th>
                        </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Product Price</th>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.pprice.price}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'price', 'product')}
                          />
                        </td>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.addPrice.additionalPrice}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'additionalPrice', 'product')}
                            // disabled={product.productType !== 'Refill Sales'}
                          />
                        </td>
            
                        <td>
                          <input
                            type="text"
                            placeholder={prices.newConPrice.newConPrice}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'newConPrice', 'product')}
                            // disabled={product.productType !== 'Refill Sales'}
                          />
                        </td>
                        </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sales Point</th>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <th>Additional point</th>
                        <th>New Connection Point</th>
                        </>
                       )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Product Points</th>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.productPoints.find((point) => point.pointsType === "sales")?.points ?? 0}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, "sales", 'points', null)}
                          />
                        </td>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.productPoints.find((point) => point.pointsType === "additional")?.points ?? 0}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, "additional", 'points', null)}
                            // disabled={product.productType !== 'Refill Sales'}
                          />
                        </td>
                       
                        <td>
                          <input
                            type="text"
                            placeholder={prices.productPoints.find((point) => point.pointsType === "new connection")?.points ?? 0}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, "new connection", 'points', null)}
                            // disabled={product.productType !== 'Refill Sales'}
                          />
                        </td>
                        </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sales Cost</th>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <th>Additional Cost</th>
                        <th>New Connection Cost</th>
                        </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Office/OTC</th>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.officePrice.salecost}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'salecost', 'office')}
                          />
                        </td>
                        {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.officePrice.additionalcost}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'additionalcost', 'office')}
                            // disabled={product.productType !== 'Refill Sales'}
                            
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder={prices.officePrice.newconection}
                            style={{ border: 'none' }}
                            onChange={(e) => handlePriceChange(e, 'newconection', 'office')}
                            // disabled={product.productType !== 'Refill Sales'}
                          />
                        </td>
                        </>
                        )}
                      </tr>
                      {vehicle.map((veh, index) => (
                        <tr key={veh.vehicleid}>
                          <th>{veh.truckinfo}</th>
                          <td>
                            <input
                              type="text"
                              placeholder={
                                prices.fleetPrice.find(fleet => fleet.vehicle === veh.truckinfo)?.salecost ||
                                (prices.fleetPrice[index] ? prices.fleetPrice[index].salecost : '')
                              }
                              style={{ border: 'none' }}
                              onChange={(e) => handlePriceChange(e, 'salecost', 'fleet', veh.truckinfo)}
                            />
                          </td>
                          {prices?.productCategory?.productCategoryTypeId !== 2 && (
                          <>
                          <td>
                            <input
                              type="text"
                              placeholder={
                                prices.fleetPrice.find(fleet => fleet.vehicle === veh.truckinfo)?.additionalcost ||
                                (prices.fleetPrice[index] ? prices.fleetPrice[index].additionalcost : '')
                              }
                              style={{ border: 'none' }}
                              onChange={(e) => handlePriceChange(e, 'additionalcost', 'fleet', veh.truckinfo)}
                              // disabled={product.productType !== 'Refill Sales'}
                            />
                          </td>
                        
                          <td>
                            <input
                              type="text"
                              placeholder={
                                prices.fleetPrice.find(fleet => fleet.vehicle === veh.truckinfo)?.newconection ||
                                (prices.fleetPrice[index] ? prices.fleetPrice[index].newconection : '')
                              }
                              style={{ border: 'none' }}
                              onChange={(e) => handlePriceChange(e, 'newconection[index]', 'fleet', veh.truckinfo)}
                              // disabled={product.productType !== 'Refill Sales'}
                            />
                          </td>
                          </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="form-group d-flex align-items-center justify-content-between">
                
                <Button variant="secondary" onClick={onRequestClose}>
                  Cancel
                </Button>

                    <input
                  type="submit"
                  value="UPDATE"
                  className="btn btn-primary"
                />
                  
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductPriceUpdate;
