import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard"
import "./Spinner.css";
import { useNavigate,useParams } from "react-router-dom";
import DriverDetailsModal from "./DriverDetailsmodal";
import { MdPersonAddAlt1 } from "react-icons/md";
import ViewAllTransporters from "./viewAllTransportersModal";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function UpdateLoadin(props) {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();
  const searchDate = props.params.searchDate;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    truckinfo: "",
    date: searchDate,
    invoiceid: "",
    file: null,
    fileSize: "",
    fileContentType:"",
    taxableValue:"",
    taxes:"",
    deliveryCharges:"",
    invoiceTotal:"",
    loadDetails: [
      {
        productcode: "",
        productname: "",
        quantity: "",
        status: "full",
      },
    ],
    truckTransporter:{
      transporterId:""
    },
    truckDrivers:{
      id:"",
      drivername:"",
      contact:""
    }
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransporterModal, setIsTransporterModal] = useState(false);
  const url = "/api/loadindetailsdo/create";

  const token = JSON.parse(localStorage.getItem("data")).token;
  const getData = async () => {
    try{
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    const trans = await axios.get(
      `/api/product/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true/productTypeId=${1}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setProduct(trans.data);
    setTransaction({
      ...transaction,
      loadDetails: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
        status:"full"
      })),
    });

    setLoading(false);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);

  const [transporter, setTransporter] = useState({
    transporterName: "",
    transporterPhone: "",
  });

  const [showTransporterIcon, setShowTransporterIcon] = useState(false);

  function rsubmit(e) {
    e.preventDefault();
    // if (!transaction.file) { 
    //   toast.error("Please upload a document.");
    //   return;
    // }
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, transaction, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Loadin updated successfully");
        navigate("/users/loaddetails");
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
  
    if (field === "truckinfo") {
      setTransaction({ ...transaction, truckinfo: actualValue });
      if (actualValue) {
        axios
          .get(`/api/truck-transporter-mapping/orgcode=${orgcode}/truckInfo=${actualValue}`,{ headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            if (response.data) {

              setTransporter({
                transporterName: response.data.transporterDetails.transporterName || "",
                transporterPhone: response.data.transporterDetails.phoneNumber || "" 
              });
              // setShowTransporterIcon(false); 
            } else {
              setTransporter({
                transporterName: "",
                transporterPhone: ""
              });
              // setShowTransporterIcon(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching transporter details:", error);
          });
      }
    } else if (field === "quantity") {
      // Update product quantity in loadDetails
      const newProduct = [...transaction.loadDetails];
      newProduct[index] = { ...newProduct[index], quantity: actualValue || 0 };
      setTransaction({ ...transaction, loadDetails: newProduct });
    } else {
      // Update other fields normally
      setTransaction({ ...transaction, [field]: actualValue });
    }
  }
  

  const calculateInvoiceTotal = () => {
    const { taxableValue, taxes, deliveryCharges } = transaction;
    const invoiceTotal =
      parseFloat(taxableValue || 0) +
      parseFloat(taxes || 0) +
      parseFloat(deliveryCharges || 0);
    setTransaction({ ...transaction, invoiceTotal: invoiceTotal.toFixed(2) });
  };

  useEffect(() => {
    calculateInvoiceTotal();
  }, [transaction.taxableValue, transaction.taxes, transaction.deliveryCharges]);

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...transaction,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setTransaction(pdfObject);
  };

 
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const handleAddModalOpen = () => {
    setIsModalOpen(true); 
  };

  const handleModalClose = () => {
    setIsModalOpen(false); 
  };

  const handleAddTransporter = () => {
    setIsTransporterModal(true); 
  };

  const handleTransporterModalClose = () => {
    setIsTransporterModal(false); 
  };

  const handleDriverSelect = (driver) => {
    setTransaction({
      ...transaction,
      truckDrivers: {
        id:driver.id,
        drivername:driver.drivername,
        contact: driver.contact,
      }
    });
  };

  const handleTransporterSelect = (transporter) => {
    // Update the transaction state with the selected transporter
    setTransaction({
      ...transaction, // Spread the existing transaction state
      truckTransporter: {
        transporterId: transporter.id,
      },
    });
  
    // Update the transporter state
    setTransporter({
      transporterName: transporter.transporterName,
      transporterPhone: transporter.phoneNumber,
    });
  };
  

  const handleBackClick = () => {
    navigate(-1);
};

const handleClose = () => {
    navigate(-1); 
};


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      {loading && <div className="loading"></div>}
      <div className="col-11 mx-xl-auto ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="card-title">Load Incoming Updates</div>
      <br />
      <div className="col-xl-12 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={rsubmit}>
              <div className="form-floating-sm mb-2 d-flex align-items-center">
                <label htmlFor="date">Date <span style={{color:"red"}}>* </span>:</label>
                <input
                  id="date"
                  type="date"
                  value={transaction.date}
                  onChange={(e) => rhandle(e, "date")}
                  required
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label htmlFor="truckinfo">Vehicle Id <span style={{color:"red"}}>* </span>:</label>
                <input
                  id="truckinfo"
                  type="text"
                  placeholder="Truck Info"
                  value={transaction.truckinfo}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase(); // Convert value to uppercase
                    rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                  }}
                  required
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label htmlFor="invoiceid">Invoice Id <span style={{color:"red"}}>* </span>:</label>
                <input
                  id="invoiceid"
                  type="text"
                  placeholder="Invoice Id"
                  value={transaction.invoiceid}
                  onChange={(e) => rhandle(e, "invoiceid")}
                  required
                />
              </div>

              <div className="row mt-4">
                <div className="form-floating-sm mb-2 d-flex align-items-center">
                  <label htmlFor="file">Upload Document <span style={{color:"red"}}>* </span>:</label>
                  <input
                    className="form-control"
                    id="file"
                    type="file"
                    placeholder="Upload document"
                    onChange={(e) => handlePdfFileChange(e)}
                    required
                  />
                  <label htmlFor="drivername" className="mx-3">Driver Name:</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      id="drivername"
                      type="text"
                      placeholder="Driver Name"
                      value={transaction.truckDrivers.drivername}
                      readOnly
                      required
                    />
                    <span
                      className="pulse-icon"
                      onClick={handleAddModalOpen}
                      style={{ cursor: 'pointer', marginLeft: '10px', fontSize: "17px" }}
                    >
                      <MdPersonAddAlt1 />
                    </span>
                  </div>
                  <label htmlFor="contact" className="mx-3">Contact:</label>
                  <input
                    id="contact"
                    type="text"
                    placeholder="Contact Number"
                    value={transaction.truckDrivers.contact}
                    readOnly
                    required
                  />
                </div>
              </div>

              <div className="row mt-4">
  <div className="form-floating-sm mb-2 d-flex align-items-center">
    <label htmlFor="transporterName">Transporter Name:</label>
    <input
      id="transporterName"
      type="text"
      placeholder="Transporter Name"
      value={transporter.transporterName} // Use transporter state
      onChange={(e) => setTransporter({ ...transporter, transporterName: e.target.value })}
    />
    <label htmlFor="transporterPhone" className="mx-3">Phone Number:</label>
    <input
      id="transporterPhone"
      type="text"
      placeholder="Phone Number"
      value={transporter.transporterPhone} // Use transporter state
      onChange={(e) => setTransporter({ ...transporter, transporterPhone: e.target.value })}
    />
    {/* {showTransporterIcon && ( */}
      <span
        className="pulse-icon"
        onClick={handleAddTransporter} // Open modal to manually add transporter details
        style={{ cursor: "pointer", marginLeft: "10px", fontSize: "17px" }}
      >
        <MdPersonAddAlt1 />
      </span>
    {/* )} */}
  </div>
</div>

              <br />
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product Code</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.loadDetails.map((product, index) => {
                      return (
                        <tr key={product.productcode}>
                          <td>{product.productcode}</td>
                          <td>{product.productname}</td>
                          <td>
                            <input
                              id="quantity"
                              type="text"
                              placeholder="0"
                              style={{ border: "none" }}
                              onKeyPress={(e) => {
                                const isValidChar = /^\d+$/.test(e.key);
                                if (!isValidChar) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => rhandle(e, "quantity", index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="container">
                <div className="row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="taxableValue">Taxable Value <span style={{color:"red"}}>* </span>:</label>
                    <input
                      id="taxableValue"
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={transaction.taxableValue}
                      onChange={(e) => rhandle(e, "taxableValue")}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="taxes">Taxes <span style={{color:"red"}}>* </span>:</label>
                    <input
                      id="taxes"
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={transaction.taxes}
                      onChange={(e) => rhandle(e, "taxes")}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="deliveryCharges">Delivery Charges <span style={{color:"red"}}>* </span>:</label>
                    <input
                      id="deliveryCharges"
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={transaction.deliveryCharges}
                      onChange={(e) => rhandle(e, "deliveryCharges")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-3">
                    <label htmlFor="invoiceTotal">Invoice Total:</label>
                    <input
                      id="invoiceTotal"
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={transaction.invoiceTotal}
                      style={{ width: "150%" }}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
  <button className="btn btn-secondary" onClick={handleClose}>CLOSE</button>
  <button type="submit" className="btn btn-primary ms-2">SAVE</button>
</div>
            </form>
                </div>
              </div>
            </div>
          </div>
        </div>

{isModalOpen && (
        <DriverDetailsModal isOpen={isModalOpen} onRequestClose={handleModalClose} onDriverSelect={handleDriverSelect} />
)}
{isTransporterModal && (
        <ViewAllTransporters isOpen={isTransporterModal} onRequestClose={handleTransporterModalClose} onTransporterSelect={handleTransporterSelect} />
)}
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default withRouter(UpdateLoadin);
