import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

const Addproductcategory = ({ isOpen, onRequestClose }) => {
    const { userid } = useParams();

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [uproduct, setUproduct] = useState({
    categoryname: "",
    productCategoryTypeId:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    disable:false,
    userid:userid,
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const url = "/api/productcategories/create";
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch categories from the API when component mounts
    const fetchCategories = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get("/api/productcategorytype", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.data); 
      } catch (error) {
        handleError(error);
      }
    };

    fetchCategories();
  }, []);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        url,
        uproduct,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Product category created successfully");
        onRequestClose();
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  const rhandle = (e) => {
    const { id, value } = e.target;
    setUproduct((prevUproduct) => ({
      ...prevUproduct,
      [id]: value,
    }));
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Product Category</Modal.Title>
    </Modal.Header>
    <Modal.Body>

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="categoryname">Category Name</label>
                        <input
                          className="form-control"
                          id="categoryname"
                          type="text"
                          placeholder="categoryname"
                          value={uproduct.categoryname}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productCategoryTypeId">Category Type</label>
                    <select
                      className="form-control"
                      id="productCategoryTypeId"
                      value={uproduct.productCategoryTypeId}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.categorytype}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                   
                   
                   
                   
                    
                   
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                </div>
              </div>
            </div>


          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Addproductcategory;

