import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, InputGroup } from 'react-bootstrap';
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { IoMdAddCircleOutline } from "react-icons/io";
import Stockistmapping from "./Stockistmapping";
import { MdDeleteForever } from "react-icons/md";
function ViewStockistmapping({ isOpen, onRequestClose, params, vendorId }) {
  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  const fetchVendorData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const vendorResponse = await axios.get(`/api/stockistmapping/productid=${params.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVendor(vendorResponse.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Error fetching vendor details");
      toast.error("Error fetching vendor details");
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchVendorData();
    }
  }, [params.id]);

  const filteredVendors = vendor.filter((vendorItem) =>
    vendorItem.stockist && vendorItem.stockist.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openAddMemberModal = (productId, productname) => {
    setSelectedProduct({ productId, productname });
    setIsAddMemberModalOpen(true);
  };

  const closeAddMemberModal = () => {
    setSelectedProduct(null);
    setIsAddMemberModalOpen(false);
    fetchVendorData();
  };
  const onClickHandler = async ( id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      await axios.delete("/api/stockistmapping", {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      fetchVendorData();
    } catch (error) {
      toast.error("Error deleting vendor");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Modal 
    show={isOpen} 
    onHide={onRequestClose} 
    centered // Vertically centers the modal
    style={{ maxWidth: '500px', width: '80%', marginLeft: '400px' }} // Horizontally centers and adjusts width
  ><Modal.Header closeButton>
        <Modal.Title>
          Stockist Mapping Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Bar and Map Stockist Button below Title */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <InputGroup style={{ width: '250px' }}>
            <Form.Control
              type="text"
              placeholder="Search Stockist"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
          <Button
            variant="outline-primary"
            className="ml-3 d-flex align-items-center"
            onClick={() => openAddMemberModal(params.id, params.productname)}
          >
            <IoMdAddCircleOutline style={{ fontSize: '20px', marginRight: '5px' }} />
            Map Stockist
          </Button>
        </div>

        {/* Display Product Name below Search Bar and Button */}
        <div style={{ marginBottom: '20px', fontWeight: 'bold', fontSize: '15px' }}>
          Product Name: {params.productname}
        </div>

        {/* Loading, Error, and Vendor Table */}
        {loading ? (
  <div className="text-center">
    <span 
      className="spinner-border" 
      role="status" 
      aria-hidden="true" 
      style={{ color: 'red' }} // Set spinner color to red
    ></span>
    <span className="sr-only"></span>
  </div>
) : (
          <>
            {error && <div className="text-danger mb-3">{error}</div>}
            <Table hover responsive>
              <thead className="thead-dark">
                <tr>
                  <th>Stockist Name</th>
                  <th>Actions</th>

                </tr>
              </thead>
              <tbody>
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendorItem) => (
                    <tr key={vendorItem.id}>
                      <td>{vendorItem.stockist?.name}</td>
                      <td>
                      <button 
                                                                style={{
                                                                    border: "none",
                                                                    background: "white",
                                                                    color: "grey",
                                                                    position: "relative",
                                                                    left: "10px",
                                                                    fontSize: "24px",
                                                                  }}
                                                                   onClick={() => onClickHandler(vendorItem.id)}><MdDeleteForever /></button>
                                                                   
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center text-muted">No Data Available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}

        {isAddMemberModalOpen && selectedProduct && (
          <Stockistmapping
            isOpen={isAddMemberModalOpen}
            onRequestClose={closeAddMemberModal}
            params={{ id: selectedProduct.productId, productname: selectedProduct.productname }}
            vendorId={vendorId}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewStockistmapping;
