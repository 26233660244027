import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate,useParams } from "react-router-dom";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Search from "./search";
import { IoSearchOutline } from "react-icons/io5";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import { Button } from "react-bootstrap"; 
import { AiFillDelete } from "react-icons/ai";
import "reactjs-popup/dist/index.css";
import "./Spinner.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import UpdateDaCashDetails from "./updateDaCashPayment";
import { FiInfo } from "react-icons/fi";
import DaDenominations from "./daDenominations";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function UpdateTransactionIncoming(props) {
  const [time, setTime] = useState(new Date());
  var curr_hour = time.getHours();
  var curr_min = time.getMinutes();

  var curr_sec = time.getSeconds();

  const searchDate = props.params.searchDate;

  const text = curr_hour + ":" + curr_min + ":" + curr_sec;
  const date = new Date();
  let index = 0;
  const navigate = useNavigate();
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [isUpdateDaCashModalOpen, setIsUpdateDaCashModalOpen] = useState(false);
  const [daCashById, setDaCashById] = useState("");
  const [daCashDenom, setDaCashDenom] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  console.log(selectedVehicle);
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [expDetails, setExpDetails] = useState(0);
  const [additional, setAdditional] = useState(0);
  const [newCon, setNewCon] = useState(0);
  const [online, setOnline] = useState(0);
  const [exemptedDetails, setExemptedDetails] = useState([]);
  const [addDet, setAddDet] = useState([]);
  const [newConDet, setNewConDet] = useState([]);
  const [onlineDetails, setOnlineDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    status: "full",
    deliveryagentid: "",
    approvalstatus: "",
    approvaldate: "",
    date: searchDate,
    time: text,
    products: [
      {
        productcode: "",
        productname: "",
       // productType:"",
        quantity: 0,
        neworg: 0,
        exempted: 0,
        additional: 0,
        onlinepay: 0,
        vehicle: 0,
        transfer: 0,
        returnedproduct:0
      },
    ],
  });
  
  const [exdetails, setExdetails] = useState({
    customername: "",
    deliveredqty: "",
    productname: "",
    productcode:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    date: searchDate,
    deliveredVehicleid:"",
    transactionId:""
  });

  const [onlinedetails, setOnlinedetails] = useState({
    customername: "",
    customernumber: "",
    ordernumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phonenumber: "",
    productname: "",
    date:searchDate,
    deliveredVehicleid:"",
    transactionId:""
  });

  const [additionalDetails, setAdditionalDetails] = useState({
    customerName: "",
    customerNumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phoneNumber: "",
    productName:"",
    date:searchDate,
    deliveredVehicleid:"",
    transactionId:""
  });

  const [newConnectionDetails, setNewConnectionsDetails] = useState({
    customerName: "",
    customerNumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phoneNumber: "",
    productName:"",
    date:searchDate,
    deliveredVehicleid:"",
    transactionId:""
  });

  const [products, setProduct] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payedcash, setPayedcash] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [searchbyv, setSearchbyv] = useState(true);
  const [searchbyvehicle, setSearchbyvehicle] = useState([]);
  const [exemptedcustomer, setExemptedcustomer] = useState([]);
  const [exemptedcategory, setExemptedcategory] = useState([]);
  const [exemptedCategory, setExemptedCategory] = useState("");
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [total, setTotal] = useState([
    {
      total: 0,
    },
  ]);
  let finaltotal1 = 0;

  const [vehicledetails, setVehicledetails] = useState({
    vehicleid: "",
  });
  const url = "/api/Returntransaction/create";
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
setLoading(true)
    const trans = await axios.get(
      `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true/productTypeId=${1}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  
    setProduct(trans.data);
    setTransaction({
      ...transaction,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
        neworg: 0,
        exempted: 0,
        additional: 0,
        onlinepay: 0,
        returnedproduct:0,
        transfer:0,
        vehicle: selectedVehicle,
      })),
    });
    setLoading(false);
    const vehicles = await axios.get(
      `/api/vehicle/getAllActiveVehicles/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    // console.log(vehicles);

    setVehicle(vehicles.data);
    await axios
      .get(
        `/api/DeliveryAgentCashPayment/da-payment/orgid=${JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${searchDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setPayedcash(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    await axios
      .get(
        `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
        }/status=false`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setExemptedcategory(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  
  };

 
  

  useEffect(() => {
    getData();
  }, []);

 
  total.forEach((item) => {
    finaltotal1 += item.total;
  });

  const [modalIsOpenArray1, setModalIsOpenArray1] = useState(Array(transaction.products.length).fill(false));
  const [modalIsOpenArray2, setModalIsOpenArray2] = useState(Array(transaction.products.length).fill(false));
  const [modalIsOpenArray3, setModalIsOpenArray3] = useState(Array(transaction.products.length).fill(false));
  const [modalIsOpenArray4, setModalIsOpenArray4] = useState(Array(transaction.products.length).fill(false));
// Function to open modal for a specific product index
const openModal1 = (index) => {
  const updatedModalIsOpenArray1 = [...modalIsOpenArray1];
  setExemptedCategory("");
  updatedModalIsOpenArray1[index] = true;
  setModalIsOpenArray1(updatedModalIsOpenArray1);
};

const closeModal1 = (index) => {
  const updatedModalIsOpenArray1 = [...modalIsOpenArray1];
  updatedModalIsOpenArray1[index] = false;
  setModalIsOpenArray1(updatedModalIsOpenArray1);
};


const openModal2 = (index) => {
  const updatedModalIsOpenArray2 = [...modalIsOpenArray2];
  updatedModalIsOpenArray2[index] = true;
  setModalIsOpenArray2(updatedModalIsOpenArray2);
};

const closeModal2 = (index) => {
  const updatedModalIsOpenArray2 = [...modalIsOpenArray2];
  updatedModalIsOpenArray2[index] = false;
  setModalIsOpenArray2(updatedModalIsOpenArray2);
};
const openModal3 = (index) => {
  const updatedModalIsOpenArray3 = [...modalIsOpenArray3];
  updatedModalIsOpenArray3[index] = true;
  setModalIsOpenArray3(updatedModalIsOpenArray3);
};

const closeModal3 = (index) => {
  const updatedModalIsOpenArray3 = [...modalIsOpenArray3];
  updatedModalIsOpenArray3[index] = false;
  setModalIsOpenArray3(updatedModalIsOpenArray3);
};

const openModal4 = (index) => {
  const updatedModalIsOpenArray4 = [...modalIsOpenArray4];
  updatedModalIsOpenArray4[index] = true;
  setModalIsOpenArray4(updatedModalIsOpenArray4);
};

const closeModal4 = (index) => {
  const updatedModalIsOpenArray4 = [...modalIsOpenArray4];
  updatedModalIsOpenArray4[index] = false;
  setModalIsOpenArray4(updatedModalIsOpenArray4);
};

 
  total.forEach((item) => {
    finaltotal1 += item.total;
  });



  //Exempted
  const addToExemptedList = () => {
    if (exdetails.categoryname && exdetails.customername && exdetails.deliveredqty) {
      // Construct the new object with additional data
      const newData = {
        ...exdetails,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: transaction.date,
        deliveredVehicleid:selectedVehicle
      };
  
      const updatedExemptedDetails = [...exemptedDetails, newData];
      setExemptedDetails(updatedExemptedDetails);
  
      console.log("updatedExemptedDetails:", updatedExemptedDetails);
  
      // Calculate total delivered quantity from updatedExemptedDetails
      const totalDeliveredQtyByProduct = updatedExemptedDetails.reduce((acc, item) => {
        const parsedQty = parseInt(item.deliveredqty);
        if (!isNaN(parsedQty)) {
          acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      // Update expDetails with the total delivered quantity for each product
      setExpDetails(totalDeliveredQtyByProduct);
  
      // Update the transaction with the updated quantity
      setTransaction(prevTransaction => {
        const updatedProducts = prevTransaction.products.map(product => {
          const updatedExempted = totalDeliveredQtyByProduct[product.productname] || 0;
          return {
            ...product,
            exempted: updatedExempted
          };
        });
  
        return {
          ...prevTransaction,
          products: updatedProducts
        };
      });
        setExdetails({
          categoryname:'',
          customername: '',
          deliveredqty: ''
        });
      } else {
        alert("Please fill in all required fields");
      }
    };
  const handleExemtedDelete = (index) => {
    const quantityToDelete = parseInt(exemptedDetails[index].deliveredqty);

    setExpDetails(prev => {
      if (!isNaN(quantityToDelete)) {
        return prev - quantityToDelete;
      } else {
        return prev;
      }
    });
  
    setExemptedDetails(prevExemptedDetails => {
      const updatedList = prevExemptedDetails.filter((_, i) => i !== index);
      return updatedList;
    });
    
  
    setTransaction(prevTransaction => {
      const updatedProducts = prevTransaction.products.map(product => {
        if (product.productname === exemptedDetails[index].productname) {
          const updatedExempted = (product.exempted) - quantityToDelete;
          return {
            ...product,
            exempted: updatedExempted
          };
        }
        return product;
      });
      return {
        ...prevTransaction,
        products: updatedProducts
      };
    });
  };

  
  const addToAdditionalList = () => {
    if (
      additionalDetails.customerName &&
      additionalDetails.customerNumber &&
      additionalDetails.quantity &&
      additionalDetails.phoneNumber
    ) {
      const newData = {
        ...additionalDetails,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: transaction.date,
        deliveredVehicleid:selectedVehicle
      };
  
      const updatedAddDet = [...addDet, newData];
      setAddDet(updatedAddDet);
  
        const totalQtyByProduct = updatedAddDet.reduce((acc, item) => {
        const parsedQty = parseInt(item.quantity);
        if (!isNaN(parsedQty)) {
          acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      // Update expDetails with the total delivered quantity for each product
      setAdditional(totalQtyByProduct);
  
      // Update the transaction with the updated quantity
      setTransaction(prevTransaction => {
        const updatedProducts = prevTransaction.products.map(product => {
          const updatedAdditional= totalQtyByProduct[product.productname] || 0;
          return {
            ...product,
            additional: updatedAdditional
          };
        });
  
        return {
          ...prevTransaction,
          products: updatedProducts
        };
      });
      setAdditionalDetails({
        customerName: '',
        customerNumber: '',
        productName: '',
        quantity: '',
        phoneNumber: ''
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  const handleAdditionalDelete = (index) => {
    const quantityToDelete = parseInt(addDet[index].quantity);
    setAdditional(prev => {
      if (!isNaN(quantityToDelete)) {
        return prev - quantityToDelete;
      } else {
        return prev;
      }
    });
  
    setAddDet(prevAddDet => {
      const updatedList = prevAddDet.filter((_, i) => i !== index);
      return updatedList;
    });
  
    setTransaction(prevTransaction => {
      const updatedProducts = prevTransaction.products.map(product => {
        if (product.productname === addDet[index].productName) {
          const updatedAdditional = (product.additional) - quantityToDelete; 
          return {
            ...product,
            additional: updatedAdditional,
          };
        }
        return product;
      });
      return {
        ...prevTransaction,
        products: updatedProducts
      };
    });
  };

  const addToOnlineDetailsList = () => {
    if (
      onlinedetails.customername &&
      onlinedetails.customernumber &&
      onlinedetails.ordernumber &&
      onlinedetails.quantity &&
      onlinedetails.phonenumber
    ) {
      const newData = {
        ...onlinedetails,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        deliveredVehicleid:selectedVehicle,
        date: transaction.date
      };
      const updatedonlineDetails = [...onlineDetails, newData];
      setOnlineDetails(updatedonlineDetails);
  
        const totalQtyByProduct = updatedonlineDetails.reduce((acc, item) => {
        const parsedQty = parseInt(item.quantity);
        if (!isNaN(parsedQty)) {
          acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      // Update expDetails with the total delivered quantity for each product
      setOnline(totalQtyByProduct);
  
      // Update the transaction with the updated quantity
      setTransaction(prevTransaction => {
        const updatedProducts = prevTransaction.products.map(product => {
          const updatedOnline= totalQtyByProduct[product.productname] || 0;
          return {
            ...product,
            onlinepay: updatedOnline
          };
        });
  
        return {
          ...prevTransaction,
          products: updatedProducts
        };
      });
      setOnlinedetails({
        customername: '',
        customernumber: '',
        ordernumber: '',
        productname: '',
        quantity: '',
        phonenumber: ''
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  const handleOnlineDelete = (index) => {
    const quantityToDelete = parseInt(onlineDetails[index].quantity);
    setOnline(prev => {
      if (!isNaN(quantityToDelete)) {
        return prev - quantityToDelete;
      } else {
        return prev; 
      }
    });
  
    setOnlineDetails(prevOnlineDetails => {
      const updatedList = prevOnlineDetails.filter((_, i) => i !== index);
      return updatedList;
    });
  
    // Update the transaction with the new quantities after deletion
    setTransaction(prevTransaction => {
      const updatedProducts = prevTransaction.products.map(product => {
        if (product.productname === onlineDetails[index].productname) {
          const updatedOnlinePay = (product.onlinepay) - quantityToDelete;
          return {
            ...product,
            onlinepay: updatedOnlinePay
          };
        }
        return product;
      });
      return {
        ...prevTransaction,
        products: updatedProducts
      };
    });
  };

  const addToNewConnectionList = () => {
    if (
     newConnectionDetails.customerName &&
     newConnectionDetails.customerNumber &&
     newConnectionDetails.quantity &&
     newConnectionDetails.phoneNumber
    ) {
      const newData = {
        ...newConnectionDetails,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: transaction.date,
        deliveredVehicleid:selectedVehicle
      };
  
      const updatedNewConDet = [...newConDet, newData];
      setNewConDet(updatedNewConDet);
  
        const totalQtyByProduct = updatedNewConDet.reduce((acc, item) => {
        const parsedQty = parseInt(item.quantity);
        if (!isNaN(parsedQty)) {
          acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      // Update expDetails with the total delivered quantity for each product
      setNewCon(totalQtyByProduct);
  
      // Update the transaction with the updated quantity
      setTransaction(prevTransaction => {
        const updatedProducts = prevTransaction.products.map(product => {
          const updatedNewCon= totalQtyByProduct[product.productname] || 0;
          return {
            ...product,
            neworg: updatedNewCon
          };
        });
  
        return {
          ...prevTransaction,
          products: updatedProducts
        };
      });
      setNewConnectionsDetails({
        customerName: '',
        customerNumber: '',
        productName: '',
        quantity: '',
        phoneNumber: ''
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  const handleNewConnectionDelete = (index) => {
    const quantityToDelete = parseInt(addDet[index].quantity);
    setNewCon(prev => {
      if (!isNaN(quantityToDelete)) {
        return prev - quantityToDelete;
      } else {
        return prev;
      }
    });
  
    setNewConDet(prevNewConDet => {
      const updatedList = prevNewConDet.filter((_, i) => i !== index);
      return updatedList;
    });
  
    setTransaction(prevTransaction => {
      const updatedProducts = prevTransaction.products.map(product => {
        if (product.productname === addDet[index].productName) {
          const updatedAdditional = (product.neworg) - quantityToDelete; 
          return {
            ...product,
            neworg: updatedAdditional,
          };
        }
        return product;
      });
      return {
        ...prevTransaction,
        products: updatedProducts
      };
    });
  };


  const customStyles = {
    content: {
      width: "55%",
      backgroundColor: "white",
      position: "absolute",
      left: "420px",
      top: "100px",
      overflow: "scroll",
    },
  };
  

  function rsubmit(e) {
    e.preventDefault();
    if (!selectedVehicle) {
        toast.error("Please select a vehicle");
        return;
    }
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

        axios.post(
            url,
            transaction,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
            console.log(response.data);
            setLoading(false);
            toast.success("Transaction Incoming updated successfully");
            
            // Set productname in ExemptedDetails
            if (exemptedDetails.length > 0) {
            exemptedDetails.forEach(detail => {
                response.data.products.forEach(product => {
                    if (detail.productname === product.productname) {
                        detail.transactionId = product.returntransid;
                    }
                });
            });
            axios.post("/api/ExemptedDetails/create", exemptedDetails, {
              headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
              toast.success("Exempted details created");
              setExemptedDetails([]);
          })
          .catch((error) => {
              console.error("Error creating additional details:", error);
          });
        }
          // Set productname in ExemptedDetails
          if (addDet.length > 0) {
          addDet.forEach(detail => {
              response.data.products.forEach(product => {
                  if (detail.productName === product.productname) {
                      detail.transactionId = product.returntransid;
                  }
              });
          });
                axios.post("/api/additionaldetails/create", addDet, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    toast.success("Additional details created");
                    setAddDet([]);
                })
                .catch((error) => {
                    console.error("Error creating additional details:", error);
                });
              }

          // Set productname in ExemptedDetails
          if (onlineDetails.length > 0) {
              onlineDetails.forEach(detail => {
                  response.data.products.forEach(product => {
                      if (detail.productname === product.productname) {
                          detail.transactionId = product.returntransid;
                      }
                  });
              });
                axios.post("/api/OnlineDetail/create", onlineDetails, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    toast.success("Online details created")
                    setOnlineDetails([]);
                })
                .catch((error) => {
                    console.error("Error creating online details:", error);
                });
              }
//newcon
              if (newConDet.length > 0) {
                newConDet.forEach(detail => {
                    response.data.products.forEach(product => {
                        if (detail.productName === product.productname) {
                            detail.transactionId = product.returntransid;
                        }
                    });
                });
                      axios.post("/api/newconnectiondetails/create", newConDet, {
                          headers: { Authorization: `Bearer ${token}` },
                      })
                      .then((res) => {
                          toast.success("New connection details created");
                          setNewConDet([]);
                      })
                      .catch((error) => {
                          console.error("Error creating new connection details:", error);
                      });
                    }
           navigate("/users/transaction");
        })
        .catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message);
        });     
}

  function selectVehicle(e) {
    const selectedValue = e.target.value;
  
    setSelectedVehicle(selectedValue);
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      products: prevTransaction.products.map((product) => ({
        ...product,
        vehicle: selectedValue,
      })),
    }));
  }

  
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
   // setSelectedVehicle(actualValue);

   if (e.key === 'Backspace' || e.key === 'Delete') {
    actualValue = '0';
  } else if (actualValue.trim() === '') {
    // Handle clearing the input field
    actualValue = '0';
  }
    let tra = { ...transaction, [field]: actualValue };
    console.log(tra);

    const newproduct = [...transaction.products];
    newproduct[index] = {
      ...newproduct[index],
      [field]: actualValue,
    };

    if (field === "date") {
      setTransaction({ ...transaction, date: actualValue });
    } else {
      setTransaction({ ...transaction, products: newproduct });
    }
  }
  function dacashhandle(e) {
    navigate("/users/dacash");
  }
  let finaltotal = 0;

  if (searchbyv) {
    payedcash.forEach((item) => {
      finaltotal += item.totalamount;
    });
  } else if (Array.isArray(searchbyvehicle)) { // Check if searchbyvehicle is an array
    searchbyvehicle.forEach((item) => {
      if (item.totalamount) {
        finaltotal += item.totalamount;
      }
    });
  }
  
  function rhandle1(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setVehicledetails({ vehicledetails, [field]: actualValue });
  }
  const searchbyvehi = async (e, id) => {
    e.preventDefault();
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyv(false);
    if (id === "ALL") {
      setLoader(true);
      const currentDate = new Date().toISOString().slice(0, 10); // Assuming currentDate is defined
    
      try {
        const tinResponse = await axios.get(
          `/api/DeliveryAgentCashPayment/da-payment/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    
      setLoader(false);
    } else {
    const tin = await axios.get(
      `/api/DeliveryAgentCashPayment/orgid=${JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${searchDate}/vehicleid=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setLoader(false);
    setSearchbyvehicle(tin.data);
  }
  };

  const handleCustomer = async (selectedCategory) => {
    try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

        const entity = {
            orgcode: orgcode,
            customercategory: selectedCategory,
            disable: false,
        };

        const response = await axios.post(
            '/api/exemptedcustomer/exemptedcustomercategory', entity, {
              headers: { Authorization: `Bearer ${token}` },
          })

        setExemptedcustomer(response.data);
    } catch (error) {
        console.error(error);
    }
}



//exempted
  const handleChange = (event, field) => {
    const { value } = event.target;
    setExdetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };
  
  //Online
  const handleChange1 = (event, field) => {
    const { value } = event.target;
    setOnlinedetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };

//Additional
  const handleChange2 = (event, field) => {
    const { value } = event.target;
    setAdditionalDetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };

  //new con
  const handleChange3 = (event, field) => {
    const { value } = event.target;
    setNewConnectionsDetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };

  const handleProductClick = (productName, productCode) => {
    setAdditionalDetails(prevDetails => ({
        ...prevDetails,
        productName: productName,
        deliveredVehicleid:selectedVehicle
    }));

    setNewConnectionsDetails(prevDetails => ({
      ...prevDetails,
      productName: productName,
      deliveredVehicleid:selectedVehicle
  }));

    setOnlinedetails(prevDetails => ({
      ...prevDetails,
      productname: productName,
      deliveredVehicleid:selectedVehicle
  }));

  setExdetails(prevDetails => ({
    ...prevDetails,
    productname: productName,
    productcode: productCode,
    deliveredVehicleid:selectedVehicle
}));


// setTransaction(prevTransaction => {
//   const updatedProducts = prevTransaction.products.map(product => {
//     // Check if the productname matches the clicked productName
//     if (product.productname === productName) {
      
      
//       const updatedExempted = (product.exempted) + expDetails;
//       const updatedAdditional = (product.additional) + additional;
//       const updatedOnlinePay = (product.onlinepay) + online;
//       return {
//         ...product,

// // Assuming you update transaction with these values
// exempted: updatedExempted,
// additional: updatedAdditional,
// onlinepay: updatedOnlinePay
//       };
//     }
//     return product;
//   });
//   console.log("Updated Products:", updatedProducts);

//   setExpDetails(0);
//   setAdditional(0);
//   setOnline(0);

//   return {
//     ...prevTransaction,
//     products: updatedProducts
//   };
// });


  setSelectedProductName(productName);
  
};

const handleFinanicalDelete = (id) => {
  setLoading(true);
    axios
      .delete(`/api/DeliveryAgentCashPayment`, {
        data: { id }, 
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          getData().then(() => {
            navigate(`/users/updatetin/${transaction.date}`);
          });
          toast.success("Financial Deleted Successfully");      
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        toast.error("Error deleting Financial");
      });
  }

  function handleFinancialEdit(id) {
    setIsUpdateDaCashModalOpen(true);
   setDaCashById(id);
  }

  const handleFinancialEditColseModal = () => {
    setIsUpdateDaCashModalOpen(false);
    getData().then(() => {
      navigate(`/users/updatetin/${transaction.date}`);
    });
  };

  function updateFinancials(e, searchDate) {
    navigate(`/users/dacash/${searchDate}`);
  }

  const [selectedDaCashId, setSelectedDaCashId] = useState(null);
  const [denominationsModalIsOpen, SetDenominationsModalIsOpen] = useState(false);
 
  const openDenominationsModal = async (daCashId) => {
    setSelectedDaCashId(daCashId);
      SetDenominationsModalIsOpen(true);
  };
  
  const closeDenominationModal = () => {
    setSelectedDaCashId(null);
    SetDenominationsModalIsOpen(false);
   
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  
  
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
<div className="mt-10">
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Transaction Incoming Updates</div>
            <br />

            <div>
             
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div>
                      <label for="date">Date</label>
                      <input
                        id="date"
                        type="date"
                        placeholder="Date"
                        value={transaction.date}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      
                      <label for="truckinfo">Vehicle</label>

                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={selectedVehicle}
                        onChange={(e) => selectVehicle(e, "vehicle")}

                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>{" "}

                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;


                    </div>
                    <br />
                    <br />

                      <div>
                        <table className="table table-bordered " >
                          <thead>
                            <tr>
                              <th>Product Id</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                              <th>Exempted</th>
                              <th>New Connection</th>
                              <th>Additional</th>
                              <th>Onlinepay</th>
                              <th>Returned Product</th>
                              <th>Transfer</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}  onClick={() => handleProductClick(product.productname, product.productcode)}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                    <input
  id="quantity"
  type="text"
  placeholder="0"
  style={{
    border: "none",
    width: "80px",
    padding: "4px",
   
  }}
  onKeyPress={(e) => {
    // Allow only numeric characters
    const isValidChar = /^\d+$/.test(e.key);
    if (!isValidChar) {
      e.preventDefault();
    }
  }}
  onChange={(e) => rhandle(e, "quantity", index)}
/>
                                    </td>
                                    <td>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        
                                        <input
                                          id="exempted"
                                          type="number"
                                          placeholder="0"
                                          style={{
                                            border: "none",
                                            width: "80px",
                                            padding: "4px", 
                                          }}
                                          
                                          value={transaction.products[index].exempted}
                                          //disabled={!product.productType === "Refill Sales"}
                                         
                                        />

                                        <IoIosAddCircleOutline
                                         onClick={() => {
                                          if (selectedVehicle) {
                                              openModal1(index);
                                          } else {
                                              alert("Please select a vehicle first.");
                                          }
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                        />
                                        </div>

                                        <div>
                                          <Modal
                                            isOpen={modalIsOpenArray1[index]}
                                            onRequestClose={() => closeModal1(index)}
                                            style={customStyles}
                                          >
                                            <div>
                                              <div className="card-title">
                                                Exempted Details
                                              </div>
                                             
                                              <label htmlFor="categoryname">Category Name</label>
                                              <select
                                                id="categoryname"
                                                className="form-control"
                                                type="text"
                                                placeholder="categoryname"
                                                value={exdetails.categoryname}
                                                onChange={(e) => {
                                                  handleChange(e, "categoryname");
                                                  setSelectedCategory(e.target.value);
                                                  handleCustomer(e.target.value);
                                                }}
                                              >
                                                <option>select category</option>
                                                {exemptedcategory.map((cu) => (
                                                  <option key={cu.categoryname} value={cu.categoryname}>
                                                    {cu.categoryname}
                                                  </option>
                                                ))}
                                              </select>

                                              <label htmlFor="customername">Customer Name</label>
                                              <select
                                                id="customername"
                                                className="form-control"
                                                type="text"
                                                placeholder="customername"
                                                value={exdetails.customername}
                                                onChange={(e) => {
                                                  handleChange(e, "customername");
                                                }}
                                              >
                                                <option>select customername</option>
                                                {exemptedcustomer.map((sub) => (
                              <option value={sub.customername} key={sub.id}>
                                {sub.customername}
                              </option>
                            ))}
                                              </select>

                                              <label for="quantity">
                                                Quantity
                                              </label>
                                              <input
                                                type="number"
                                                id="deliveredqty"
                                                className="form-control"
                                                value={exdetails.deliveredqty}
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    "deliveredqty"
                                                  )
                                                }
                                                pattern="\d*"
                                                onInput={(e) => {
                                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                              />
                                              
                                              <br />
                                              <button
                                                className="btn btn-primary"
                                                onClick={addToExemptedList}
                                              >
                                                Save
                                              </button>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                     <th>Category Name</th> 
                                                    <th>Customer Name</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {exemptedDetails.map((exp,index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {exp.categoryname}
                                                          </td> 
                                                          <td>
                                                            {exp.customername}
                                                          </td>
                                                          <td>
                                                            {exp.productname}
                                                          </td>
                                                          <td>
                                                            {exp.deliveredqty}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleExemtedDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeModal1(index)}
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </Modal>
                                        </div>
                                    </td>
                                    <td>
                                    <div style={{ display: "flex", alignItems: "center" }}>
    <input
      id="neworg"
      type="text"
      placeholder="0"
      style={{
        border: "none",
        width: "80px",
        padding: "4px",
      }}
      value={transaction.products[index].neworg}
    />
    <IoIosAddCircleOutline
      onClick={() => {
        if (selectedVehicle) {
          openModal4(index);
        } else {
          alert("Please select a vehicle first.");
        }
      }}
      style={{
        cursor: "pointer",
      }}
    />
  </div>
                                          <div>
                                          <Modal
                                            isOpen={modalIsOpenArray4[index]}
                                              onRequestClose={() => closeModal4(index)}
                                            style={customStyles}
                                          >
                                            <div>
                                              <div className="card-title">
                                              New Connection Details
                                              </div>
                                              <label for="customername">
                                                Customer Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="customerName"
                                                value={newConnectionDetails.customerName}
                                                onChange={(e) =>
                                                  handleChange3(e, "customerName")
                                                }
                                              />{" "}
                                              <label for="customerNumber">
                                                Customer Number
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="customerNumber"
                                                value={newConnectionDetails.customerNumber}
                                                onChange={(e) =>
                                                  handleChange3(
                                                    e,
                                                    "customerNumber"
                                                  )
                                                }
                                                pattern="\d*"
                                                onInput={(e) => {
                                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                              />{" "}
                                             
                                              <label for="quantity">
                                                Quantity
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                id="quantity"
                                                value={newConnectionDetails.quantity}
                                                onChange={(e) =>
                                                  handleChange3(e, "quantity")
                                                }
                                                pattern="\d*"
                                                onInput={(e) => {
                                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                              />{" "}
                                              <label for="phonenumber">
                                                phone Number
                                              </label>
                                              <input
                                                type="text"
                                                id="phoneNumber"
                                                className="form-control"
                                                value={newConnectionDetails.phoneNumber}
                                                onChange={(e) =>
                                                  handleChange3(e, "phoneNumber")
                                                }
                                                pattern="\d*"
                                                onInput={(e) => {
                                                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                              />
                                              
                                              <br />
                                             
                                                <button
                                                  className="btn btn-primary"
                                                 // onClick={createAdditional}
                                                 onClick={addToNewConnectionList}
                                                >
                                                  Save
                                                </button>
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>Customer Name</th>
                                                      <th>Customer Number</th>
                                                      <th>Product Name</th>
                                                      <th>Quantity</th>
                                                      <th>Delete</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {newConDet.map((ad, index) => {
                                                        return (
                                                          <tr key={index}>
                                                            <td>
                                                              {ad.customerName}
                                                            </td>
                                                            <td>
                                                              {ad.customerNumber}
                                                            </td>
                                                            <td>
                                                              {ad.productName}
                                                            </td>
                                                            <td>
                                                              {ad.quantity}
                                                            </td>
                                                            <td>
                                                            <Button variant="light"style={{ color: "black" }}  onClick={() => handleNewConnectionDelete(index)}>
                                                          <AiFillDelete />
                                                        </Button>
                                                         </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={() => closeModal4(index)}
                                                >
                                                  Close
                                                </button>
                                               
                                            </div>
                                          </Modal>
                                        </div>
                                      </td>

                                    <td>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <input
                                        id="additional"
                                        type="number"
                                        placeholder="0"
                                        style={{
                                          border: "none",
                                          width: "80px",
                                          padding: "4px",
                                         
                                        }}
                                        value={transaction.products[index].additional}
                                       
                                      />
                                      <IoIosAddCircleOutline
                                          onClick={() => {
                                            if (selectedVehicle) {
                                                openModal2(index);
                                            } else {
                                                alert("Please select a vehicle first.");
                                            }
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        />
                                        </div>
                                        <div>
                                        <Modal
                                          isOpen={modalIsOpenArray2[index]}
                                            onRequestClose={() => closeModal2(index)}
                                          style={customStyles}
                                        >
                                          <div>
                                            <div className="card-title">
                                            Additional Details
                                            </div>
                                            <label for="customername">
                                              Customer Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerName"
                                              value={additionalDetails.customerName}
                                              onChange={(e) =>
                                                handleChange2(e, "customerName")
                                              }
                                            />{" "}
                                            <label for="customerNumber">
                                              Customer Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerNumber"
                                              value={additionalDetails.customerNumber}
                                              onChange={(e) =>
                                                handleChange2(
                                                  e,
                                                  "customerNumber"
                                                )
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                           
                                            <label for="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="quantity"
                                              value={additionalDetails.quantity}
                                              onChange={(e) =>
                                                handleChange2(e, "quantity")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            <label for="phonenumber">
                                              phone Number
                                            </label>
                                            <input
                                              type="text"
                                              id="phoneNumber"
                                              className="form-control"
                                              value={additionalDetails.phoneNumber}
                                              onChange={(e) =>
                                                handleChange2(e, "phoneNumber")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />
                                            
                                            <br />
                                           
                                              <button
                                                className="btn btn-primary"
                                               // onClick={createAdditional}
                                               onClick={addToAdditionalList}
                                              >
                                                Save
                                              </button>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Customer Name</th>
                                                    <th>Customer Number</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {addDet.map((ad, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {ad.customerName}
                                                          </td>
                                                          <td>
                                                            {ad.customerNumber}
                                                          </td>
                                                          <td>
                                                            {ad.productName}
                                                          </td>
                                                          <td>
                                                            {ad.quantity}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleAdditionalDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                       </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeModal2(index)}
                                              >
                                                Close
                                              </button>
                                             
                                          </div>
                                        </Modal>
                                      </div>
                                    </td>

                                    <td>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <input
                                        id="onlinepay"
                                        type="number"
                                        placeholder="0"
                                        style={{
                                          border: "none",
                                          width: "80px",
                                          padding: "4px",
                                         
                                        }}
                                        value={transaction.products[index].onlinepay}
                                        
                                      />

                                      <IoIosAddCircleOutline
                                        onClick={() => {
                                          if (selectedVehicle) {
                                              openModal3(index);
                                          } else {
                                              alert("Please select a vehicle first.");
                                          }
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      />
                                      </div>

                                      <div>
                                        <Modal
                                          isOpen={modalIsOpenArray3[index]}
                                          onRequestClose={() => closeModal3(index)}
                                          style={customStyles}
                                        >
                                          <div>
                                            <div className="card-title">
                                              Online Transaction Customer
                                              Details
                                            </div>
                                            <label for="customername">
                                              customer Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customername"
                                              value={onlinedetails.customername}
                                              onChange={(e) =>
                                                handleChange1(e, "customername")
                                              }
                                            />{" "}
                                            <label for="customernumber">
                                              customer Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customernumber"
                                              value={onlinedetails.customernumber}
                                              onChange={(e) =>
                                                handleChange1(
                                                  e,
                                                  "customernumber"
                                                )
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            
                                            <label for="ordernumber">
                                              Order Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="ordernumber"
                                              value={onlinedetails.ordernumber}
                                              onChange={(e) =>
                                                handleChange1(e, "ordernumber")
                                              }
                                            />{" "}
                                            <label for="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="quantity"
                                              value={onlinedetails.quantity}
                                              onChange={(e) =>
                                                handleChange1(e, "quantity")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            <label for="phonenumber">
                                              Phone Number
                                            </label>
                                            <input
                                              type="text"
                                              id="phonenumber"
                                              className="form-control"
                                              value={onlinedetails.phonenumber}
                                              onChange={(e) =>
                                                handleChange1(e, "phonenumber")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />
                                            
                                            <br />
                                          
                                              <button
                                                className="btn btn-primary"
                                                onClick={addToOnlineDetailsList}
                                              >
                                                Save
                                              </button>

                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Customer Name</th>
                                                    <th>Customer Number</th>
                                                    <th>Product Name</th>
                                                    <th>Order Number</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {onlineDetails.map((online, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {online.customername}
                                                          </td>
                                                          <td>
                                                            {online.customernumber}
                                                          </td>
                                                          <td>
                                                            {online.productname}
                                                          </td>
                                                          <td>
                                                            {online.ordernumber}
                                                          </td>
                                                          <td>
                                                            {online.quantity}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleOnlineDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeModal3(index)}
                                              >
                                                Close
                                              </button>
                                          </div>
                                        </Modal>
                                      </div>
                                    </td>

                                    <td>
                                      <input
                                        id="returnedproduct"
                                        type="text"
                                        placeholder="0"
                                        style={{
                                          border: "none",
                                          width: "80px",
                                          padding: "4px",
                                         
                                        }}
                                        onKeyPress={(e) => {
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) =>
                                          rhandle(e, "returnedproduct", index)
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        id="transfer"
                                        type="text"
                                        placeholder="0"
                                        style={{
                                          border: "none",
                                          width: "80px",
                                          padding: "4px",
                                         
                                        }}
                                        onKeyPress={(e) => {
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) =>
                                          rhandle(e, "transfer", index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
      
      {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-4">
        <div className="card card-raised">
          <div className="card-body  ">
          <div className="d-flex align-items-center justify-content-between">
  <span className="card-title me-3">Paid Cash Details:</span>

  {loader && (
    <div
      className="loader"
      style={{
        position: "relative",
        left: "20px",
        top: "5px",
      }}
    ></div>
  )}

  {/* Grouping Vehicle Id label, select, and search button together */}
  <div className="d-flex align-items-center me-3">
    <span className="me-2">Vehicle Id:</span>

    <select
      id="vehicleid"
      type="text"
      placeholder="vehicleid"
      onChange={(e) => rhandle1(e, "vehicleid")}
      style={{
        minWidth: "120px",
        padding: "4px 8px",
        marginRight: "8px",
      }}
    >
      <option>ALL</option>
      {vehicle.map((vehicle) => (
        <option value={vehicle.truckinfo} key={vehicle.truckinfo}>
          {vehicle.truckinfo}
        </option>
      ))}
    </select>

    <button
      type="submit"
      value="search"
      className="btn btn-primary d-flex align-items-center"
      id="btn"
      onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
      style={{
        padding: "6px 10px",
        marginLeft: "4px",
      }}
    >
      <IoSearchOutline id="IoSearchOutline" />
    </button>
  </div>

  <button
    className="btn btn-primary"
    onClick={(e) => updateFinancials(e, searchDate)}
  >
    ADD
  </button>
</div>

   
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Vehicle Id </th>
                  <th>Total Amount</th>
                  {/* <th>Deposit Reference Number </th>
                  <th>Deposit Amount</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
    {searchbyv
      ? payedcash.length > 0
        ? payedcash.map((pcash) => (
            <tr key={pcash.id}>
              <td>{pcash.vehicleid}</td>
              <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{pcash.totalamount}</span>
              <FiInfo
                onClick={() => openDenominationsModal(pcash.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </td>
              {/* <td>{pcash.referenceNumber}</td>
              <td>{pcash.depositAmount}</td> */}
              <td> <div style={{ display: "flex", justifyContent: "center" }}>

{/* Edit button  */}
{/* <Button variant="light" style={{ color: "blue" }} title="Edit" 
onClick={() => handleFinancialEdit(pcash.id)}
>
   <CiEdit />
 </Button>{" "} */}


 <span style={{ margin: "0 5px" }}></span>

  {/* Delete button  */}
  <Button variant="light"style={{ color: "black" }} title="Delete"
   onClick={() => handleFinanicalDelete(pcash.id)}
   >
   <AiFillDelete />
 </Button>

</div></td>
            </tr>
          ))
        : <tr>  <td colSpan="10" className="text-center">
        No Data Available
      </td></tr>
      : searchbyvehicle.length > 0
      ? searchbyvehicle.map((pcash) => (
          <tr key={pcash.id}>
            <td>{pcash.vehicleid}</td>
            <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{pcash.totalamount}</span>
              <FiInfo
                onClick={() => openDenominationsModal(pcash.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </td>
            {/* <td>{pcash.referenceNumber}</td>
              <td>{pcash.depositAmount}</td> */}
            <td> <div style={{ display: "flex", justifyContent: "center" }}>

{/* Edit button  */}
{/* <Button variant="light" style={{ color: "blue" }} title="Edit" 
onClick={() => handleFinancialEdit(pcash.id)}
>
   <CiEdit />
 </Button>{" "} */}


 <span style={{ margin: "0 5px" }}></span>

  {/* Delete button  */}
  <Button variant="light"style={{ color: "black" }} title="Delete"
   onClick={() => handleFinanicalDelete(pcash.id)}
   >
   <AiFillDelete />
 </Button>

</div></td>
          </tr>
        ))
      : <tr><td colSpan="10" className="text-center">
      No Data Available
    </td></tr>}
  </tbody>
</table>
<div className="row">
  <div className="form-floating-sm mb-2 d-flex align-items-center">
    <h3 className="me-2">Total:</h3>
    <input
      className="form-control w-25"  // Use w-25 to set it to 25% of the container
      id="totalamount"
      value={finaltotal}
    />
  </div>
</div>

          </div>
          <div>
            {isUpdateDaCashModalOpen && (
            <UpdateDaCashDetails
            isOpen={isUpdateDaCashModalOpen} 
            onRequestClose={handleFinancialEditColseModal} 
            // daCashDetails={daCashById}
            // daCashDenom={daCashDenom}
            daCashId={daCashById}
            />
            )}

{denominationsModalIsOpen && (
        <DaDenominations
        isOpen={denominationsModalIsOpen}
        onRequestClose={closeDenominationModal}
        daCashId={selectedDaCashId}
      />
         )}
     
            {/* {isUpdateDaCashModalOpen && (
            <UpdateDaCashDetails1
            isOpen={isUpdateDaCashModalOpen} 
            onRequestClose={handleFinancialEditColseModal} 
            // daCashDetails={daCashById}
            // daCashDenom={daCashDenom}
            daCashId={daCashById}
            />
            )} */}
            </div>
        </div>
        </div>
      </div>
    </div>

  );
}

export default withRouter(UpdateTransactionIncoming);
