import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import { toast } from "react-toastify";
import AddOrgprofiledetails from "./Addorgprofile";
import ProfileeditModalPopup from "./Updateprofile";
import { BiEdit } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
function ProfileSettings() {
  const userid = JSON.parse(localStorage.getItem("data")).userid;
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [orgProfile, setOrgProfile] = useState({
    name: "",
    orgcode: "",
    gstin: "",
    pan: "",
    tan: "",
    contact: "",
    userid: "",
    logo: "",
    imageMeta: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
    state: "",
    officeLatitude: "",
    officeLongitude: "",
    godownLatitude: "",
    godownLongitude: "",
  });

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedOrgCode, setSelectedOrgCode] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false)
  const fetchProfileData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/organizationprofiles/userid=${userid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProfile(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      toast.error("Error fetching profile data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [userid, token]);

  const handleCreateModalOpen1 = (orgCode) => {
    setSelectedOrgCode(orgCode);
    setIsProfileModalOpen(true);
  };

  const handleProfileCloseModal = () => {
    setIsProfileModalOpen(false);
    fetchProfileData();
  };
  const handleEditModalOpen = (id) => {
    setIsEditingModalOpen(true); 
    setSelectedId(id);
  };

  const handleEditCloseModal = () => {
    setIsEditingModalOpen(false); 
    fetchProfileData();

  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title">Profile Details</div>
            <div className="d-flex flex-column align-items-center">
              <button
                className="btn btn-primary"
                onClick={() => handleCreateModalOpen1(null)}
                style={{ alignSelf: "flex-end", marginBottom: "20px" }} // Aligning the button to the right
              >
                ADD
              </button>

              <div className="row">
                {profile.length === 0 ? (
                  <div className="col-12 text-center">
                    No Data Available
                  </div>
                ) : (
                  profile.map((profileItem) => (
                    <div
                      key={profileItem.id}
                      className="col-12 mb-7" 
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        className="card"
                        style={{
                          padding: "20px",
                          width: "100%",
                          maxWidth: "1100px", // Optional: set a max width for the card
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",

                          }}
                        >
                              {/* Edit Button */}
                          <button
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              fontSize: "1.2rem",
                            }}
                            onClick={() => handleEditModalOpen(profileItem.id)}
                          >
                              <BiEditAlt />

                          </button>
                          {/* Left Section - Logo */}
                          <div
                            style={{
                              flex: "0 0 10%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {profileItem.logo ? (
                              <img
                                className="img-fluid rounded-circle"
                                src={`data:image/png;base64,${profileItem.logo}`}
                                alt="profile"
                                style={{
                                  maxWidth: "50px",
                                  maxHeight: "70px",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "50px",
                                  height: "70px",
                                  marginRight: "10px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            )}
                          </div>

                          {/* Middle Section - Name, Email, Org Code, Phone */}
                          <div
                            style={{
                              flex: "1", // Taking the remaining space
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            <h5>Name: {profileItem.name}</h5>
                            <div>Email: {profileItem.username}</div>
                            <div>OrgCode: {profileItem.orgcode}</div>
                            <div>Phone: {profileItem.contact}</div>
                            <div>
                              Address: {profileItem.addressLine1}{" "}
                              {profileItem.addressLine2}
                            </div>
                            <div>City: {profileItem.city}</div>
                            <div>State: {profileItem.state}</div>
                            <div>Pincode: {profileItem.pincode}</div>
                          </div>

                          {/* Right Section - GST, PAN, TAN */}
                          <div
                            style={{
                              flex: "0 0 30%", // Set the right section width
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            <div>GST: {profileItem.gstin}</div>
                            <div>PAN: {profileItem.pan}</div>
                            <div>TAN: {profileItem.tan}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AddProfileDetails Modal */}
      {isProfileModalOpen && (
        <AddOrgprofiledetails
          isOpen={isProfileModalOpen}
          orgcode={selectedOrgCode}
          onRequestClose={handleProfileCloseModal}
        />
      )}
       {isEditingModalOpen && (
        <ProfileeditModalPopup
          isOpen={isEditingModalOpen}
          Id={selectedId}
          onRequestClose={handleEditCloseModal}
        />
      )}
    </div>
  );
}

export default ProfileSettings;
