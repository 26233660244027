import React from "react";
import AdminDashboard from "../components/admindashboard";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function TargetachivementReport() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Reportspage");
  };

  return (
    <div>
      <div>
        <br/>
        <br/>
        <AdminDashboard />
      </div>
      <br />
      <br />

      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            

              
              <br/>
              

              <Button
                style={{ marginLeft: "5%" }}
                variant="secondary"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
     
    </div>
  );
}

export default TargetachivementReport;
