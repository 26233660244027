import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import "./Spinner.css";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
 
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();
 
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Stockistmapping({ isOpen, onRequestClose, params,vendorId }) {
  const date = new Date();
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [davehicle, setDavehicle] = useState({
    productid: params.id,
    productname: params.productname,
    date: new Date().toISOString().split('T')[0],
    stockistid: "",
    vendorid:vendorId
  });
  const [vehicle, setVehicle] = useState([]);
  const [dAdetails, setDadetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [mappedStockists, setMappedStockists] = useState([]);
  
  const url = "/api/stockistmapping/create";
  const navigate = useNavigate();
  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setDavehicle((prev) => ({ ...prev, date: currentDate }));
    
    getData();
  }, []);
  function rsubmit(e) {
    e.preventDefault();
    if (!selectedAgents) {
      toast.error("Please select an Stockist.");
      return;
    }
    console.log("Selected Stockist IDs:", selectedAgents);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const payload = selectedAgents.map((stockistid) => ({
        ...davehicle,
        stockistid,            // Add each stockistid
        productid: params.id,  // Ensure productid is passed correctly
        date: davehicle.date,  // Ensure date is passed correctly
      }));

    axios
 
      .post(
        url,
        payload,
 
        { headers: { Authorization: `Bearer ${token}` } }
      )
 
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Stockist Mapped successfully");
        // navigate("/users/vehicledetail");
        onRequestClose();
      })
 
      .catch((error) => {
        setLoading(false);
        handleError(error);
    });
}
const handleCheckboxChange = (id) => {
    if (mappedStockists.includes(id)) {
      toast.error("Already mapped for this stockist ID for this product.");
      return;
    }
    setSelectedAgents((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((agentId) => agentId !== id)
        : [...prevSelected, id]
    );
  };
  const getData = async () => {
    
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);

 try{
    const trans = await axios.get(
      `/api/stockistVendorMapping/vendorId=${vendorId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
 
    console.log(trans);
 
    const stockistDetails = trans.data.map((item) => ({
      id: item.stockist.id,
      name: item.stockist.name,
      email: item.stockist.email,
      phone: item.stockist.phone,
      address: item.stockist.address,
    }));

    setDadetails(stockistDetails);
    const mappedStockistResponse = await axios.get(
        `/api/stockistmapping/productid=${params.id}`, // Fetch already mapped stockists
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    setMappedStockists(mappedStockistResponse.data.map((item) => item.stockistid));
    setLoading(false);

  }catch(error){
    handleError(error);
    setLoading(false);

  }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
 
  useEffect(() => {
    getData();
  }, []);
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Stockist Mapping</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="productid">Product Name</label>
                        <input
                          className="form-control"
                          id="productid"
                          type="text"
                          placeholder="productname"
                          value={params.productname}
                          readOnly
                                                />
                      </div>
                    </div>
                    
                     <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label>Stockist</label>
                    {dAdetails.map((dadetail) => (
      <div key={dadetail.id} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                        <input
                          type="checkbox"
                          id={`checkbox-${dadetail.id}`}
                          value={dadetail.id}
                          checked={selectedAgents.includes(dadetail.id)}
                          onChange={() => handleCheckboxChange(dadetail.id)}
                          disabled={mappedStockists.includes(dadetail.id)}
                          style={{
                            width: "20px", 
                            height: "20px", 
                            marginRight: "10px", 
                            accentColor: "#2196F3",  // Change checkbox color for modern browsers
                            border: "2px solid #ccc", 
                            borderRadius: "3px",
                          }}
                        />
  <label htmlFor={`checkbox-${dadetail.id}`} style={{ fontSize: "16px", color: "#333" }}>
          {dadetail.name}
          {mappedStockists.includes(dadetail.id) && (
                            <span style={{ fontSize:"10px",color: "red", marginLeft: "10px" }}>
                              (Already Mapped)
                            </span>
                          )}
        </label>
                              </div>
                    ))}
                  </div>
                </div>
                    <input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
 
                  </form>
                </div>
              </div>
            </div>
 
 
           </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
 
export default  Stockistmapping;