import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";

function TransporterReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transporterDetails, setTransporterDetails] = useState(null);
  const [transporterName, setTransporterName] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [products, setProducts] = useState([]);
  const [productTypeid, setProductTypeid] = useState("");

  const fetchReportData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      // Fetch products based on selected product type
      const productResponse = await axios.get(
        `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${productTypeid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(productResponse.data);
    } catch (error) {
      console.error("Error fetching products: ", error);
      toast.error("Error fetching products");
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/transporterdetailsdo/orgcode=${orgcode}/transportername=${transporterName}/${month}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setTransporterDetails({
        transporterName: response.data.transporterName,
        email: response.data.email,
        phoneNumber: response.data.phoneNumber,
        address: response.data.address,
      });

      setReportData(response.data.trucks || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error();
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);

      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Transporter Report";
      doc.text(reportName, logoX, logoY + logoHeight + 5);

      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      doc.text("Vijay Gas Agencies", textX, logoY + 10, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      doc.text(addressText, textX, logoY + 20, { align: 'right' });

      doc.setFontSize(14);
      doc.text(`Transporter: ${transporterDetails?.transporterName}`, logoX, logoY + 40);
      doc.text(`Email: ${transporterDetails?.email}`, logoX, logoY + 50);
      doc.text(`Phone: ${transporterDetails?.phoneNumber}`, logoX, logoY + 60);
      doc.text(`Address: ${transporterDetails?.address}`, logoX, logoY + 70);

      const tableData = reportData.flatMap(truck =>
        truck.loadMeta.map(load =>
          load.productDetails.map(product => ({
            date: load.date,
            driverName: load.driverName,
            contact: load.contact,
            productName: product.productName,
            quantity: product.quantity,
          }))
        )
      ).flat();

      doc.autoTable({
        startY: logoY + 80,
        head: [['Date', 'Driver Name', 'Contact', 'Product Name', 'Quantity']],
        body: tableData.map(item => [
          item.date,
          item.driverName,
          item.contact,
          item.productName,
          item.quantity,
        ]),
        theme: 'striped',
        styles: { overflow: 'linebreak' },
      });

      doc.save("transporter_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  useEffect(() => {
    if (transporterName) {
      getData();
    }
  }, [transporterName]);

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <div className="col-8 mx-xl-auto px-xl-10">
        <br/><br/> <br/><br/>
        <div className="card card-raised total-erv-card">
          <br />
          <br />
          <div className="card-body">
            <div className="container">
              {/* Input Section */}
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img className="top-bar__logo" src={img} alt="logo" />
                  <div className="ml-3">
                    <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
                    <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete - 571111</p>
                  </div>
                </div>
              </div>
              <br/>
              <div className="d-flex justify-content-center mb-4">
                <div className="card card-raised p-3">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="month">TransporterName:</label>
                    <input
                      type="text"
                      value={transporterName}
                      onChange={(e) => setTransporterName(e.target.value)}
                      className="form-control"
                      placeholder="Transporter Name"
                    />
                   <br/>
                    <label htmlFor="month">Month:</label>
                    <input
                      id="month"
                      type="number"
                      min="1"
                      max="12"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;
                    <label htmlFor="year">Year:</label>
                    <input
                      id="year"
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    />
                    <br /><br/>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={getData}
                      disabled={loading}
                    >
                      Generate Report
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={generatePDF}
                      disabled={!reportData.length}
                    >
                      Download PDF
                    </button>
                  </form>
                </div>
              </div>

              {/* Transporter Details Card */}
              {transporterDetails && (
                <div className="card text-center mb-4 p-3">
                  <h5>Transporter Details</h5>
                  <p><strong>Name:</strong> {transporterDetails.transporterName}</p>
                  <p><strong>Email:</strong> {transporterDetails.email}</p>
                  <p><strong>Phone Number:</strong> {transporterDetails.phoneNumber}</p>
                  <p><strong>Address:</strong> {transporterDetails.address}</p>
                </div>
              )}

              {/* Truck and Product Details Card */}
              <div className="card p-3">
  <h5 className="text-center">Truck and Load Details</h5>
  {loading && <div className="loading">Loading...</div>}

  {reportData.length > 0 ? (
    reportData.map((truck, index) => (
      <div key={index} className="mb-4">
        <h6 className="text-center">Truck: {truck.truckInfo}</h6>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Driver Name</th>
              <th>Contact</th>
              {/* Dynamically generate product name columns */}
              {truck.loadMeta[0] && truck.loadMeta[0].productDetails.map((product, idx) => (
                <th key={idx}>{product.productName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {truck.loadMeta.map((load, idx) => (
              <tr key={idx}>
                <td>{load.date}</td>
                <td>{load.driverName}</td>
                <td>{load.contact}</td>
                {load.productDetails.map((product, pIdx) => (
                  <td key={pIdx}>{product.quantity}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))
  ) : (
    <div>No data available</div>
  )}
</div>

            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TransporterReport;
