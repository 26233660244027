import React, { useEffect, useState } from "react";
import {Button, Tab, Tabs } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import Drivers from "./drivers";
import InvoiceNumbers from "./invoices";
import ErvNumbers from "./ervNumbers";
import { FaCheck, FaEdit } from "react-icons/fa";
 
  function ViewTruckInfo() {

  const { truckinfo } = useParams();

  const [transporter, setTransporter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('drivers');

  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  useEffect(() => {
    const fetchTransporterDetails = async () => {
      try {
        const response = await axios.get(`/api/truck-transporter-mapping/orgcode=${orgcode}/truckInfo=${truckinfo}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          });
      
        setTransporter(response.data);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTransporterDetails();
  }, [truckinfo]);


  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleViewTransporter = async (transporter) => {
    navigate(`/users/view-transporter/${transporter}`);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [truckInfo, setTruckInfo] = useState(truckinfo);
  const [oldTruckInfo, setOldTruckInfo] = useState(truckinfo); // old truck number before editing

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setTruckInfo(e.target.value.toUpperCase()); 
  };

  const handleUpdateClick = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        '/api/loadin-meta/updateTruckNumber',
        {
          orgcode: orgcode,
          oldTruckInfo: oldTruckInfo,
          newTruckInfo: truckInfo,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        toast.success('Truck number updated successfully!');
  
        setIsEditing(false);
        setOldTruckInfo(truckInfo);
        navigate("/users/truckinfo");
      } else {
        toast.error('Failed to update truck number. Please try again.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <AdminDashboard />
<div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="me-3">
        Truck Number:{' '}
        {isEditing ? (
          <input
            type="text"
            value={truckInfo}
            onChange={handleInputChange}
            className="form-control"
            style={{ display: 'inline-block', width: 'auto' }}
          />
        ) : (
          truckInfo
        )}
        {isEditing ? (
          <FaCheck
            onClick={handleUpdateClick}
            style={{ marginLeft: '10px', cursor: 'pointer', color: 'green' }}
          />
        ) : (
          <FaEdit
            onClick={handleEditClick}
            style={{ marginLeft: '10px', cursor: 'pointer' }}
          />
        )}
      </h5>
  <div className="flex-grow-1 text-center">
    <h5 
      onClick={() => handleViewTransporter(transporter?.transporterId)} 
      style={{ cursor: "pointer" }}
      title="View Transporter">
      Transporter: {transporter?.transporterDetails?.transporterName}
    </h5>
  </div>
  <Button variant="secondary" onClick={handleBackButtonClick}>
    Back
  </Button>
</div>



            <Tabs
              id="truckinfo-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="drivers" title="Drivers">
              {activeTab === 'drivers' && (
                <Drivers selectedTruck={truckinfo} />
              )}
              </Tab>
              <Tab eventKey="invoice" title="Invoices">
              {activeTab === 'invoice' && (
                <InvoiceNumbers selectedTruck={truckinfo} />
              )}
              </Tab>
              <Tab eventKey="erv" title="ERVs">
              {activeTab === 'erv' && (
                <ErvNumbers selectedTruck={truckinfo} />
              )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default ViewTruckInfo;