import React, { useState } from "react";
import { Modal, Button, Form, Card, Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust import path as needed
import { MdDeleteForever } from "react-icons/md";
import { useEffect } from "react";
const AddVendor = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  const currentDate = date.toISOString().split("T")[0];

  const initialVendorState = {
    name: "",
    phone: "",
    date: currentDate,
    email: "",
    address: "",
    gst:"",
    pan:"",

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    vcp: [],
    sl:[],
    vp:[],
    scp:[],
  };

  const [newVendor, setNewVendor] = useState(initialVendorState);
  const [loading, setLoading] = useState(false);
  // const [key, setKey] = useState("alpha1");
   // Contact person state
   const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  // Supplier state
  const [supplier, setSupplier] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  // Product state
  const [product, setProduct] = useState({
    productname: "",
    sku: "",
    hsncode: "",
    baseprice: "",
    gstrate: "",
    date: currentDate,

  });
  const [contact1, setContact1] = useState({
    name: "",
    email: "",
    phone: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [showContactModal, setShowContactModal] = useState(false);
  const [showContactModal1, setShowContactModal1] = useState(false);

  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);

  const [profileDetails, setProfileDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);

  // Handle changes for input fields
  const handleVendorChange = (e) => {
    const { id, value } = e.target;
    setNewVendor((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleContactChange = (e) => {
    const { id, value } = e.target;
    setContact((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleContactChange1 = (e) => {
    const { id, value } = e.target;
    setContact1((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSupplierChange = (e) => {
    const { id, value } = e.target;
    setSupplier((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleProductChange = (e) => {
    const { id, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle adding new Contact Person
  const handleAddContactPerson = () => {
    if (contact.name && contact.email && contact.phone) {

    setNewVendor((prevState) => ({
      ...prevState,
      vcp: [...prevState.vcp, { ...contact, orgcode: prevState.orgcode }],

    }));
    setContact({
      name: "",
      email: "",
      phone: "",
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    });
    setShowContactModal(false);
} else {
    toast.error("Please fill out all contact person fields.");
  }
};
const handleAddContactPerson1 = () => {
  if (contact1.name && contact1.email && contact1.phone) {

  setNewVendor((prevState) => ({
    ...prevState,
    scp: [...prevState.scp, { ...contact1, orgcode: prevState.orgcode }],

  }));
  setContact1({
    name: "",
    email: "",
    phone: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  setShowContactModal1(false);
} else {
  toast.error("Please fill out all contact person fields.");
}
};

  // Handle adding Profile Details
  const handleAddSupplier = () => {
    if (supplier.name && supplier.email && supplier.phone && supplier.address) {

    setNewVendor((prevState) => ({
      ...prevState,
      sl: [...prevState.sl, { ...supplier, orgcode: prevState.orgcode }],

    }));
    setSupplier({
      name: "",
      email: "",
      phone: "",
      address: "",
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    });
    setShowSupplierModal(false);
} else {
    toast.error("Please fill profile fields.");
  }
};
  // Handle adding new Product (vp)
  const handleAddProduct = () => {
    if (product.productname && product.sku && product.hsncode && product.baseprice && product.gstrate) {

    setNewVendor((prevState) => ({
      ...prevState,
      vp: [
        ...prevState.vp,
        product,
      ],
    }));
    setProduct({
      productname: "",
      sku: "",
      hsncode: "",
      baseprice: "",
      gstrate: "",
      date: currentDate,

    });
    setShowProductModal(false);
} else {
    toast.error("Please fill out all product fields.");
  }
};
  // Handle delete for contact, profile, and product
  const handleDeleteContact = (index) => {
    setNewVendor((prevState) => ({
      ...prevState,
      vcp: prevState.vcp.filter((_, i) => i !== index),
    }));
  };
  const handleDeleteContact1 = (index) => {
    setNewVendor((prevState) => ({
      ...prevState,
      scp: prevState.scp.filter((_, i) => i !== index),
    }));
  };
  const handleDeleteProfile = (index) => {
    setNewVendor((prevState) => ({
      ...prevState,
      sl: prevState.sl.filter((_, i) => i !== index),
    }));
  };
  const handleDeleteProduct = (index) => {
    setNewVendor((prevState) => ({
      ...prevState,
      vp: prevState.vp.filter((_, i) => i !== index),
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/vendors/create", newVendor, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Vendor added successfully");
      onRequestClose();
      setNewVendor(initialVendorState);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the vendor");
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log(newVendor.vcp);
  }, [newVendor.vcp]);
  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Card className="mb-4">
              <Card.Body>
                {/* Vendor Information */}
                <Form.Group className="mb-3">
                  <Form.Label>Vendor Name</Form.Label>
                  <Form.Control
                    id="name"
                    type="text"
                    placeholder="Vendor Name"
                    value={newVendor.name}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    id="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={newVendor.phone}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={newVendor.email}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    id="address"
                    type="text"
                    placeholder="Address"
                    value={newVendor.address}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    id="pan"
                    type="text"
                    placeholder="pan"
                    value={newVendor.pan}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>GST</Form.Label>
                  <Form.Control
                    id="gst"
                    type="text"
                    placeholder="gst"
                    value={newVendor.gst}
                    onChange={handleVendorChange}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        > */}
          {/* Alpha1 Tab */}
          {/* <Tab eventKey="alpha1" title="Contact"> */}
  {/* Contact Person Section */}
  <Card className="mb-4">
    <Card.Body className="d-flex justify-content-between align-items-center">
      <Modal.Title style={{ fontSize: "16px" }}>
        Contact Person
      </Modal.Title>
      <Button
        variant="primary"
        className="btn-sm"
        onClick={() => setShowContactModal(true)}
      >
        Add
      </Button>
    </Card.Body>
  </Card>

  {newVendor.vcp.length > 0 ? (
    
    <Card className="mb-4">
      <Card.Body>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          fontSize: '0.75rem',
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'hidden'
        }}>
          <thead style={{
            backgroundColor: '#f8f9fa',
            color: '#343a40',
            fontWeight: 'bold'
          }}>
            <tr>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Name</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Phone</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Email</th>
             
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Actions</th>
            </tr>
          </thead>
          <tbody>
          {newVendor.vcp.map((person, index) => (
              <tr key={index} style={{
                borderBottom: '1px solid #ddd',
                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff'
              }}>
                 <td style={{ padding: '0.25rem' }}>{person.name}</td>
              <td style={{ padding: '0.25rem' }}>{person.phone}</td>
              <td style={{ padding: '0.25rem' }}>{person.email}</td>
              <td style={{ padding: '0.25rem' }}>
                
                <Button
                  size="sm"
                  onClick={() => handleDeleteContact(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0',
                  }} // Remove background and border from the button
                >
                  <MdDeleteForever
                    style={{ color: 'black', fontSize: '21px' }} // Set the icon color to black
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card.Body>
  </Card>
) : (
  <p>No contact details available</p>
)}
{/* </Tab> */}

            {/* Profile Section */}
              {/* ALPHA2 Tab */}
              {/* <Tab eventKey="alpha2" title="Stockist"> */}
              {/* <Card className="mb-4">
                <Card.Body className="d-flex justify-content-between align-items-center">
                  <Modal.Title style={{ fontSize: "16px" }}>
                    Profile Details
                  </Modal.Title>
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => setShowSupplierModal(true)}
                  >
                    Add
                  </Button>
                </Card.Body>
              </Card> */}
          
              {/* Display Profile Details */}
              {newVendor.sl.length > 0 ? (
                <Card className="mb-4">
                  <Card.Body>
                  <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '0.75rem',
        border: '1px solid #ddd',
        borderRadius: '4px',
        overflow: 'hidden'
      }}>
        <thead style={{
          backgroundColor: '#f8f9fa',
          color: '#343a40',
          fontWeight: 'bold'
        }}>
                    <tr>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Name</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Email</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Phone</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Address</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Actions</th>
          </tr>
                      </thead>
                      <tbody>
                                  {newVendor.sl.map((profile, index) => (

<tr key={index} style={{
    borderBottom: '1px solid #ddd',
    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff'
  }}>
                                <td style={{ padding: '0.25rem' }}>{profile.name}</td>
                <td style={{ padding: '0.25rem' }}>{profile.email}</td>
                <td style={{ padding: '0.25rem' }}>{profile.phone}</td>
                <td style={{ padding: '0.25rem' }}>{profile.address}</td>
                <td style={{ padding: '0.25rem' }}>
                              <Button
                                size="sm"
                                onClick={() => handleDeleteProfile(index)}
                                style={{ backgroundColor: "transparent", border: "none" }}
                              >
                                <MdDeleteForever style={{ color: "black", fontSize: "24px" }} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              ) : (
                <p></p>
              )}
          
{/* Contact Person Section */}
{/* <Card className="mb-4">
    <Card.Body className="d-flex justify-content-between align-items-center">
      <Modal.Title style={{ fontSize: "16px" }}>
        Contact Person
      </Modal.Title>
      <Button
        variant="primary"
        className="btn-sm"
        onClick={() => setShowContactModal1(true)}
      >
        Add
      </Button>
    </Card.Body>
  </Card> */}

  {newVendor.scp.length > 0 ? (
    
    <Card className="mb-4">
      <Card.Body>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          fontSize: '0.75rem',
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'hidden'
        }}>
          <thead style={{
            backgroundColor: '#f8f9fa',
            color: '#343a40',
            fontWeight: 'bold'
          }}>
            <tr>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Name</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Phone</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Email</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Address</th>
              <th style={{
                padding: '0.25rem',
                textAlign: 'left',
                borderBottom: '1px solid #ddd'
              }}>Actions</th>
            </tr>
          </thead>
          <tbody>
          {newVendor.scp.map((person, index) => (
              <tr key={index} style={{
                borderBottom: '1px solid #ddd',
                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff'
              }}>
                 <td style={{ padding: '0.25rem' }}>{person.name}</td>
              <td style={{ padding: '0.25rem' }}>{person.phone}</td>
              <td style={{ padding: '0.25rem' }}>{person.email}</td>
              <td style={{ padding: '0.25rem' }}>{person.address }</td>
              <td style={{ padding: '0.25rem' }}>
                
                <Button
                  size="sm"
                  onClick={() => handleDeleteContact1(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0',
                  }} // Remove background and border from the button
                >
                  <MdDeleteForever
                    style={{ color: 'black', fontSize: '21px' }} // Set the icon color to black
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card.Body>
  </Card>
) : (
  <p></p>
)}

              {/* Product Section */}
              {/* <Card className="mb-4">
                <Card.Body className="d-flex justify-content-between align-items-center">
                  <Modal.Title style={{ fontSize: "16px" }}>
                    Product Details
                  </Modal.Title>
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => setShowProductModal(true)}
                  >
                    Add
                  </Button>
                </Card.Body>
              </Card> */}
          
              {/* Display Product Details */}
             
        {/* {newVendor.vp.length > 0 ? (
                <Card className="mb-4">
                  <Card.Body>
                  <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '0.75rem',
        border: '1px solid #ddd',
        borderRadius: '4px',
        overflow: 'hidden'
      }}>
        <thead style={{
          backgroundColor: '#f8f9fa',
          color: '#343a40',
          fontWeight: 'bold'
        }}>
                        <tr>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Product Name</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>SKU</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>HSN Code</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Base Price</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>GST Rate</th>
            <th style={{ padding: '0.25rem', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Actions</th>
          </tr>
                      </thead>
                      <tbody>
                      {newVendor.vp.map((product, index) => (

<tr key={index} style={{
    borderBottom: '1px solid #ddd',
    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff'
  }}>
    <td style={{ padding: '0.25rem' }}>{product.productname}</td>
    <td style={{ padding: '0.25rem' }}>{product.sku}</td>
    <td style={{ padding: '0.25rem' }}>{product.hsncode}</td>
    <td style={{ padding: '0.25rem' }}>{product.baseprice}</td>
    <td style={{ padding: '0.25rem' }}>{product.gstrate}</td>
    <td style={{ padding: '0.25rem' }}>
                              <Button
                                size="sm"
                                onClick={() => handleDeleteProduct(index)}
                                style={{ backgroundColor: "transparent", border: "none" }}
                              >
                                <MdDeleteForever style={{ color: "black", fontSize: "24px" }} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              ) : (
                <p>No product details available</p>
              )} */}
            {/* </Tab> */}
          {/* </Tabs> */}
            <Button
              className="mt-3"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Save"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Contact Modal */}
      <Modal show={showContactModal} onHide={() => setShowContactModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label> Name</Form.Label>
            <Form.Control
              id="name"
              type="text"
              value={contact.name}
              onChange={handleContactChange}
              placeholder="Enter Contact Person Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              id="phone"
              type="text"
              value={contact.phone}
              onChange={handleContactChange}
              placeholder="Enter Contact Person Phone Number"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label> Email</Form.Label>
            <Form.Control
              id="email"
              type="email"
              value={contact.email}
              onChange={handleContactChange}
              placeholder="Enter Contact Person Email"
            />
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowContactModal(false)}>
            Close
          </Button>
          <span style={{marginRight:"10px"}}></span>
          <Button variant="primary" onClick={handleAddContactPerson}>
            Save 
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Profile Modal */}
      <Modal show={showSupplierModal} onHide={() => setShowSupplierModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Stockist Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label> Name</Form.Label>
            <Form.Control
              id="name"
              type="text"
              value={supplier.name}
              onChange={handleSupplierChange}
              placeholder="Enter Profile Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label> Email</Form.Label>
            <Form.Control
              id="email"
              type="email"
              value={supplier.email}
              onChange={handleSupplierChange}
              placeholder="Enter Profile Email"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label> Phone Number</Form.Label>
            <Form.Control
              id="phone"
              type="text"
              value={supplier.phone}
              onChange={handleSupplierChange}
              placeholder="Enter Profile Phone Number"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Control
              id="address"
              type="address"
              value={supplier.address}
              onChange={handleSupplierChange}
              placeholder="Enter Profile Email"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSupplierModal(false)}>
            Close
          </Button>
          <span style={{marginRight:"10px"}}></span>

          <Button variant="primary" onClick={handleAddSupplier}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

{/* Contact Modal */}
<Modal show={showContactModal1} onHide={() => setShowContactModal1(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label> Name</Form.Label>
            <Form.Control
              id="name"
              type="text"
              value={contact1.name}
              onChange={handleContactChange1}
              placeholder="Enter Contact Person Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              id="phone"
              type="text"
              value={contact1.phone}
              onChange={handleContactChange1}
              placeholder="Enter Contact Person Phone Number"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label> Email</Form.Label>
            <Form.Control
              id="email"
              type="email"
              value={contact1.email}
              onChange={handleContactChange1}
              placeholder="Enter Contact Person Email"
            />
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowContactModal1(false)}>
            Close
          </Button>
          <span style={{marginRight:"10px"}}></span>

          <Button variant="primary" onClick={handleAddContactPerson1}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Product Modal */}
      <Modal show={showProductModal} onHide={() => setShowProductModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              id="productname"
              type="text"
              value={product.productname}
              onChange={handleProductChange}
              placeholder="Enter Product Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>HSN Code</Form.Label>
            <Form.Control
              id="hsncode"
              type="text"
              value={product.hsncode}
              onChange={handleProductChange}
              placeholder="Enter HSN Code"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>SKU</Form.Label>
            <Form.Control
              id="sku"
              type="text"
              value={product.sku}
              onChange={handleProductChange}
              placeholder="Enter SKU"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Base Price</Form.Label>
            <Form.Control
              id="baseprice"
              type="text"
              value={product.baseprice}
              onChange={handleProductChange}
              placeholder="Enter Base Price"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>GST Rate</Form.Label>
            <Form.Control
              id="gstrate"
              type="text"
              value={product.gstrate}
              onChange={handleProductChange}
              placeholder="Enter GST Rate"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProductModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddProduct}>
            Save Product Details
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddVendor;
