    import React, { useState, useEffect } from "react";
    import axios from "../api/baseurl";
    import Button from "@mui/material/Button";
    import DialogActions from "@mui/material/DialogActions";

    function Addcustomfields({ autoAddField, onSave, onClose }) {
        const [customFields, setCustomFields] = useState([]);

        const userid = JSON.parse(localStorage.getItem("data"))?.userid;

        const addCustomField = () => {
            const newField = {
                id: "",
                adminid: userid,
                fieldname: "",
                fieldType: "text",
                mandatory: false,
                quotelink: "",
                polink: "",
                prod: [], 
            };
            setCustomFields([...customFields, newField]);
        };

        useEffect(() => {
            if (autoAddField) {
                addCustomField();
            }
        }, [autoAddField]);

      
        const handleFieldValueChange = (index, key, value) => {
            const updatedFields = [...customFields];
            updatedFields[index][key] = value;
            setCustomFields(updatedFields);

            localStorage.setItem('customFields', JSON.stringify(updatedFields));
        };

       
        const handleProdValueChange = (index, prodIndex, value) => {
            const updatedFields = [...customFields];
            updatedFields[index].prod[prodIndex] = { fieldid: updatedFields[index].id, value }; // Update the field value
            setCustomFields(updatedFields);
        };

        
            const addProdValue = (index) => {
                const updatedFields = [...customFields];
                updatedFields[index].prod.push({ fieldid: updatedFields[index].id, value: "" });
                setCustomFields(updatedFields);

                localStorage.setItem('customFields', JSON.stringify(updatedFields));
            };

        const handleSave = async () => {
           
            try {
                const tokenData = localStorage.getItem("data");
                if (!tokenData) {
                    console.error("No user data found in local storage");
                    return;
                }
        
                const token = JSON.parse(tokenData).token;
                if (!token) {
                    console.error("No token found in local storage");
                    return;
                }
    
                const fieldToSend = {
                    adminid: customFields[0].adminid,
                    fieldname: customFields[0].fieldname,
                    fieldType: customFields[0].fieldType,
                    mandatory: customFields[0].mandatory,
                    quotelink: customFields[0].quotelink,
                    polink: customFields[0].polink,
                    prod: customFields[0].prod.map((prodItem) => ({
                        value: prodItem.value,
                    })),
                };
        
                console.log("Field to send:", fieldToSend);

                const selectedFields = customFields.map(field => ({ fieldType: field.fieldType }));
                localStorage.setItem('selectedFieldTypes', JSON.stringify(selectedFields));
        
                const response = await axios.post("/api/customfield/create", fieldToSend, {
                    headers: { Authorization: `Bearer ${token}` },
                });
        
                console.log("Field saved successfully:", response.data);
                onSave(response.data);
                onClose();
            } catch (error) {
                console.error("Error saving field:", error.response ? error.response.data : error);
            }
        };
        
        

        return (
            <div className="container">
                <h4>Add Custom Fields</h4>

                {customFields.map((field, index) => (
                    <div key={index} className="mb-3">
                        {/* Form fields */}
                        <label>Field Label</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Field Label"
                            value={field.fieldname}
                            onChange={(e) => handleFieldValueChange(index, "fieldname", e.target.value)}
                        />

                        <label>Select Field Type</label>
                        <div className="d-flex mb-3" style={{ gap: "10px" }}>
                            {["text", "number", "select", "date", "user"].map((type) => (
                                <Button
                                    key={type}
                                    variant={field.fieldType === type ? "contained" : "outlined"}
                                    onClick={() => handleFieldValueChange(index, "fieldType", type)}
                                >
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Button>
                            ))}
                        </div>

                        {/* Custom field data */}
                        <div>
                            <label>Custom Field Data</label>
                            {field.prod.map((prodValue, prodIndex) => (
                                <input
                                    key={prodIndex}
                                    type="text"
                                    className="form-control mb-2"
                                    value={prodValue.value}
                                    onChange={(e) => handleProdValueChange(index, prodIndex, e.target.value)}
                                    placeholder="Enter field value"
                                />
                            ))}
                            <Button onClick={() => addProdValue(index)} variant="outlined">
                                Add Custom Field Data
                            </Button>
                        </div>

                        {/* Additional fields: quotelink and polink */}
                        <label>Quote Link</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Quote Link"
                            value={field.quotelink}
                            onChange={(e) => handleFieldValueChange(index, "quotelink", e.target.value)}
                        />

                        <label>PO Link</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="PO Link"
                            value={field.polink}
                            onChange={(e) => handleFieldValueChange(index, "polink", e.target.value)}
                        />

                        <div className="form-check mt-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={field.mandatory}
                                onChange={(e) => handleFieldValueChange(index, "mandatory", e.target.checked)}
                            />
                            <label className="form-check-label">Is mandatory?</label>
                        </div>
                    </div>
                ))}

                {/* Footer actions */}
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </div>
        );
    }

    export default Addcustomfields;
