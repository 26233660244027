import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { IoSearchOutline } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import img from "../images/report.png";
import jsPDF from "jspdf";
import { BiEditAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import { FiInfo } from "react-icons/fi";
import AdminDashboard from "../components/admindashboard";
import Search from "../components/search";
import Overheadmodal from "../components/overheadmodal";
import Overheadeditmodal from "../components/OverheadEdit";
import DaPaidAmountDetails from "../components/daPaidAmountDetailsPopup";
import PerformModal from "../components/Performmodal";
import ReciveopenModal from "../components/Receivemodal";
import OfficePaymentReceiveModal from "../components/officePaymentRecieveModal";

function DayOperationalReport() {
  const [openstock, setOpenstock] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbytoutvehicle, setSearchbytoutvehicle] = useState([]);
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [closestock, setClosestock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    datetime: currentDate,
  });
  const [closestockone, setClosestockone] = useState([]);
  const [orgStartDate, setOrgStartDate] = useState("");
  const [closestocktwo, setClosestocktwo] = useState([]);
  const [closestockByCurrentdate, setClosestockByCurrentdate] = useState([]);
  const [openingstock, setOpeningstock] = useState([]);
  const [closestockBydate, setClosestockByDate] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [tsearchstate, setTsearchstate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [tto1, setTto1] = useState([]);
  const [ttin, setTtin] = useState([]);
  const [tlin, setTLin] = useState([]);
  const [tlout, setTLout] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState(1); 
  const [productTypeFilter1, setProductTypeFilter1] = useState(1); 
  const [searchbyvehicle, setSearchbyvehicle] = useState([]);
  const [searchToutByProduct, setSearchToutByProduct] = useState([]);
  const [searchTinbyProduct, setSearchTinbyProduct] = useState([]);
  const [searchbyv, setSearchbyv] = useState(true);
  const [searchbyPro, setSearchbyPro] = useState(true);
  const [vehicledetails, setVehicledetails] = useState({
    vehicleid: "",
  });
  const [productname, setProductname] = useState({
    productname: "",
  });
  const [amountdenominationdetails, setdenominationdetails] = useState([]);
  const [totalamountdetails, setTotalamountdetails] = useState([]);
  const [loader0, setLoader0] = useState(false);

  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [loader7, setLoader7] = useState(false);
  const [loader8, setLoader8] = useState(false);

  const [searchDate, setSearchDate]= useState(currentDate);

  const [lastUpdatedClosestock, setLastUpdatedClosestock] = useState("");
  const [dapaymentdetails, setDapaymentdetails] = useState([]);
  const [officePaymentDetails, setOfficePaymentDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isoverheadModalOpen, setIsoverheadModalOpen] = useState(false);
  const [overhead, setOverhead] = useState([]);
  const [consumable, setConsumable] = useState([]);
  const [refill, setRefill] = useState([]);
  const [isReciveopenModalModalOpen, setIsReciveopenModalModalOpen] = useState(false);
  const [isOfficePaymentReciveModalOpen, setIsOfficePaymentReciveModalOpen] = useState(false);
  const [selectedReceiveVehicleId, setSelectedReceiveVehicleId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const[isPerformdatendModalIsOpen, setIsPerformdayendModalOpen] = useState(false);
  const [selectedPerformdate, setSelectedPerformdate] = useState(null);
  const [isoverheadEditModalOpen, setIsoverheadEditModalOpen] = useState(false);
  const [selectedoverheadId, setSelectedoverheadId] = useState(null);
  const [iscreatesaleModalOpen, setIscreatesaleModalOpen] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}/${monthNames[monthIndex]}/${year}`;
  }
  const navigate = useNavigate();
  function dateHandler(e, searchDate) {
    navigate(`/users/updateExceptionopenstock/${searchDate}`);
  }

  function updateStockDateHandler(e, searchDate) {
    navigate(`/users/updateopenstock/${searchDate}`);
  }

  const [productTypes, setProductTypes] = useState([]);
  useEffect(() => {
    const token= JSON.parse(localStorage.getItem("data")).token;
    const orgcode= JSON.parse(localStorage.getItem("data")).orgcode;
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(`/api/productcategorytype`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductTypes(response.data);

        const res = await axios
        .get(
          `/api/product/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/productstatus=true/productTypeId=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
          setProduct(res.data);

          const vehicles = await axios
          .get(
            `/api/vehicle/orgid=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
        setVehicle(vehicles.data);
     
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };

    fetchProductTypes();
  }, []);
  const searchName = async (text) => {
    const fSearchDate = formatDate(text);
    const fSearchDateObj = new Date(fSearchDate);
const orgStartDateObj = new Date(orgStartDate);
setSearchstate(false);
setSearchbyPro(true);
setSearchbyv(true);
setSearchDate(text);

setTsearchstate(false);
if (fSearchDateObj < orgStartDateObj) {
  setErrorMessage(true);
  setLoading(false); 
} else {
  setErrorMessage(false);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await Promise.all([
      axios
        .get(
          `/api/overhead/duedate/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOverhead(response.data);
        }),
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpenstock(response.data.prodDo);
          console.log(response.data);
        }),
        axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpeningstock(response.data);
        }),
        (async () => {
          try {
            const loadInResponse = await axios.get(
              `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setTLin(loadInResponse.data);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadinDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadinDo:", error);
            }
          }
        })(),
        (async () => {
          try {
            const loadOutResponse = await axios.get(
              `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setTLout(loadOutResponse.data);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadoutDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadoutDo:", error);
            }
          }
        })(),
        axios
        .get(
          `/api/totaltransactionDo/refill-transactions/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTto1(response.data.prodDo);
        }),
        axios
        .get(
          `/api/totaltransaction/refillsales/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTtin(response.data);
        }),
        
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setConsumable(response.data);
        }),
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRefill(response.data);
        }),

        axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDapaymentdetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
          } else {
          }
        }),
        axios
        .get(
          `/api/DacashDetails/office-Refill-payments/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setOfficePaymentDetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
          } else {
          }
        }),
        
      
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClosestockone(response.data.prodDo);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClosestockByDate(response.data);
        })
    ])


 .finally(() => {
    setLoading(false); 
});  
}  
  };
  function denominationdetails(text, vehicleid){
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); 
      });
  
      axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    setLoading(true)

    await Promise.all([
      axios
      .get(
        `/api/overhead/duedate/${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response);
        setOverhead(response.data);
      }),
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
         // setLoader(false);
          setOpenstock(response.data.prodDo);
        }),

        axios
        .get(
          `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLin(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
         
          } else {
        
          }
        }),
      axios
        .get(
          `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLout(res.data);

        })
        .catch((error) => {
          if (error.response.status === 404) {
         
          } else {
            
          }
        }),

        axios
        .get(
          `/api/totaltransactionDo/refill-transactions/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTto1(res.data.prodDo);
       
        })
        .catch((error) => {
          if (error.response.status === 404) {
         
          } else {
          
          }
        }),

      axios
        .get(
          `/api/totaltransaction/refillsales/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTtin(res.data);
          console.log(res)
         
        })
        .catch((error) => {
          if (error.response.status === 404) {
          
          } else {
           
          }
        }),
      
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setRefill(response.data);
        }),
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setConsumable(response.data);
        }),
      
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
        
          setClosestockone(response.data.prodDo);
        
          console.log("openstock:", openstock);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setClosestockByCurrentdate(response.data);
          
          console.log("openstock:", openstock);
        }),
     
      axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
        
          setOpeningstock(response.data);
        }),

        axios
        .get(
          `/api/dayendtransactiondate/lastUpdated/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          
          setLastUpdatedClosestock(response.data);
         
        }),

      axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDapaymentdetails(res.data);
          console.log(res.data);
         
        })
        .catch((error) => {
          if (error.response.status === 404) {
          
          } else {
                    }
        }),
        axios
        .get(
          `/api/DacashDetails/office-Refill-payments/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setOfficePaymentDetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            
          } else {
            
          }
        })
      ])
      .finally(() => {
        setLoading(false); 
      });
    };

  const getOrganizationProfile = async () => {
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      const response = await axios.get(
        `/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOrgStartDate(response.data.createddate);
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Organization profile not found:', error.response.data.message);
       
      } else {
        console.error('Error fetching organization profile:', error.response.data.message);
        
      }
    }
  };

  useEffect(() => {
    getData();
    getOrganizationProfile();
  }, []);
  const searchbyvehi = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyv(false);
    setSearchbyPro(true);
    if (id === "ALL") {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/refillsales/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
           { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
        
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/refill-transactions/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    } else {
      setLoading(true);
      
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/refillsales/vehicleid=${id}/date=${searchDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/refill-transactions/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/vehicleid=${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
      }
    }
  };

  const searchbyProductname = async (e, productname) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyPro(false);
    setSearchbyv(true);
    if (productname === "ALL") {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
           { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchTinbyProduct(tinResponse.data);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchToutByProduct(toutvehicleResponse.data.prodDo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/productname=${productname}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchTinbyProduct(tinResponse.data);
      
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/productname=${productname}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchToutByProduct(toutvehicleResponse.data.prodDo);
      
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
    }
  }
  };
 
  const onclickhandler = async (e,searchDate) => {
    setIsPerformdayendModalOpen(true);
    setSelectedPerformdate(searchDate) ; 
    e.preventDefault();
    setLoading(true);
  };
  const closePerformdayendModal = () => {
    setIsPerformdayendModalOpen(false);
    searchName(searchDate);
  };
  function rhandle(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setVehicledetails({ vehicledetails, [field]: actualValue });
  }

  function handleProduct(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setProductname({ productname, [field]: actualValue });
  }
 
  function openModal(e, vehicleid) {
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
       
      });
      
    axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
       
      });
  }


  function closeModal() {
    setModalIsOpen(false);
  }
  const customStyles = {
    content: {
      width: "25%",
      backgroundColor: "rgba(0, 0, 0, .1)",
      position: "relative",
      left: "550px",
      top: "200px",
    },
  };
  const handleRefresh= async () => {
    setLoader0(true); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await
      axios
      .get(
        `/api/overhead/duedate/${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,      {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOverhead(response.data);
    } catch (error) {
    } finally {
      setLoader0(false); 
    }
  };
  const handleRefresh1 = async () => {
    setLoader1(true); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await
      axios
      .get(
        `/api/OpeningstockDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,       {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOpenstock(response.data.prodDo);
    } catch (error) {
    } finally {
      setLoader1(false); 
    }
  };
  const handleRefresh2 = async () => {
    setLoader2(true); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/loadindetailsdo/getLoadinDetailsByOrgcodeAndDate/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,{
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTLin(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader2(false); 
    }
  };
  const handleRefresh3 = async () => {
    setLoader3(true); 
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/loadoutdetailsdo/getLoadoutDetailsByOrgcodeAndDate/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,   {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTLout(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader3(false);
    }
  };
  const handleRefresh4 = async () => {
    setLoader4(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totaltransactionDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,  {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTto1(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader4(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh5 = async () => {
    setLoader5(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totaltransaction/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTtin(res.data);
      setLoader5(false);
    } catch (error) {
      setLoader5(false);      // Handle error
    } finally {
      setLoader5(false);
    }
  };
  const handleRefresh6 = async () => {
    setLoader6(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await 
      axios
      .get(
        `/api/DacashDetails/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDapaymentdetails(res.data);
    } catch (error) {
    } finally {
      setLoader6(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh7 = async () => {
    setLoader7(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await 
      axios
      .get(
        `/api/CloseStockDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setClosestockone(response.data.prodDo);
    } catch (error) {
    } finally {
      setLoader7(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh8 = async () => {
    setLoader8(true); // Set Loader to true
    
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
      // Make parallel requests using Promise.all
      const [consumableResponse, refillResponse] = await Promise.all([
        axios.get(`/api/consumableproduct/orgcode=${orgcode}/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/refillproduct/orgcode=${orgcode}/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);
  
      // Update state with the responses
      setConsumable(consumableResponse.data);
      setRefill(refillResponse.data);
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setLoader8(false); // Set Loader to false after requests are completed
    }
  };
  const handleAdd = () => {
    setIsoverheadModalOpen(true);
   };
   const handleoverheadCloseModal = () => {
    setIsoverheadModalOpen(false);
    searchName(searchDate);
  };

  const handlecreatesale = () => {
    setIscreatesaleModalOpen(true);
   };

   const handlecreatesaleCloseModal = () => {
    setIscreatesaleModalOpen(false);
    searchName(searchDate);
  };
  const handleoverheadedit = async (id) => {
    setSelectedoverheadId(id);
   
    setIsoverheadEditModalOpen(true);
    
  };
  
  const closeoverheadEditModal = () => {
    setIsoverheadEditModalOpen(false);
    searchName(searchDate);
  
  };
  function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(true);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    axios
      .post(
        "/api/overhead/update",
        {
          id: id,
          completed: status,      },
  
        { headers: { Authorization: `Bearer ${token}` } }
      )
  
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        getData();
      })
  
      .catch((error) => {
        console.log(error);
      });
  }

  const reciveopenModal = async (date, vehicleid) => {
    setSelectedReceiveVehicleId(vehicleid);
    setSelectedDate(date);
    setIsReciveopenModalModalOpen(true);


  };
 
  const closereciveopenModal = () => {
    setIsReciveopenModalModalOpen(false);
    searchName(searchDate);
 
  };

  const officePaymentRecive = async (date) => {
    setSelectedDate(date);
    setIsOfficePaymentReciveModalOpen(true);
  };
 
  const closeOfficePaymentsReciveModal = () => {
    setIsOfficePaymentReciveModalOpen(false);
    searchName(searchDate);
  };

  const [isDaPaidDetailsModalOpen, setIsDaPaidDetailsModalOpen] = useState(false);
const [selectedCashPaymentIds, setSelectedCashPaymentIds] = useState([]);

const openDaPaidDetailsModal = (cashPaymentIds) => {
  setSelectedCashPaymentIds(cashPaymentIds);
  setIsDaPaidDetailsModalOpen(true);
};

const closeDaPaidDetailsModal = () => {
  setIsDaPaidDetailsModalOpen(false);
  setSelectedCashPaymentIds([]);
};

const [isOfficePaidDetailsModalOpen, setIsOfficePaidDetailsModalOpen] = useState(false);

const openOfficePaidDetailsModal = (cashPaymentIds) => {
  setSelectedCashPaymentIds(cashPaymentIds);
  setIsOfficePaidDetailsModalOpen(true);
};

const closeOfficePaidDetailsModal = () => {
  setIsOfficePaidDetailsModalOpen(false);
  setSelectedCashPaymentIds([]);
};


const handleGeneratePDF = () => {
    const doc = new jsPDF();
    
    try {
        // Logo
        const imgData = img; // Ensure that img is defined with the path to your logo image
        const logoWidth = 50;
        const logoHeight = 20;
        const logoX = 10;
        const logoY = 10;
        doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);
        
        // Address and company name
        doc.setFontSize(18);
        doc.setTextColor(86, 150, 214);
        doc.text("Vijay Gas Agencies", doc.internal.pageSize.width - 10, logoY + 10, { align: 'right' });
        
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text("1010/27 Mysore Road, Gundlupete", doc.internal.pageSize.width - 10, logoY + 20, { align: 'right' });
        
        const reportName = "Daily Operational Report";
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.text(reportName, 10, logoY + 30);
        
        // Table Header
        const tableColumn = ["Vehicle", "Product Name", "Type", "Given Quantity", "Taken Quantity", "Amount", "Created By", "Created Date", "Due Date", "Description", "Status"];
        const tableRows = [];

        // Assuming 'overhead' is the data array you want to display in the table
        overhead.forEach(product => {
            const productData = [
                product.vehicleid,
                product.productname,
                product.type,
                product.givenQty,
                product.takenQty,
                product.amount,
                product.userrole,
                product.createddate,
                product.duedate,
                product.description,
                product.completed ? "Completed" : "Pending"
            ];
            tableRows.push(productData);
        });

        // Add the table to the PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: logoY + 40,
            theme: 'grid',
            headStyles: { fillColor: [98, 0, 234] },
            styles: { cellPadding: 5, fontSize: 10 },
        });

        // Save the PDF with the specified name
        doc.save('day-operational-report.pdf');

    } catch (error) {
        console.error("Error generating PDF:", error); // Handle any errors here
    }
};


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      
      <br /> <br /> <br /> <br />
      <div className="card card-raised" style={{ width: "100%", maxWidth: "2500px" }}>
      <div className="card-body d-flex justify-content-center align-items-center">
  <img className="top-bar__logo" src={img} alt="logo" style={{ marginRight: '10px' }} />
  <div className="text-left">
    <h3 className="report-title" style={{ marginBottom: '0' }}>Vijay Gas Agencies</h3>
    <p className="report-address" style={{ marginTop: '0' }}>1010/27 Mysore Road, Gundlupete</p>
  </div>
</div>
{loading && <div className="loading">Loading...</div>}

      
      <div
        style={{ position: "relative", top: "40px" }}
        className="col-13  mx-xl-auto ps-xl-10"
      >

              <div >
  <div className="card-body">
    <div className="d-flex">
    <br />
    <table className="table table-bordered ">
      <thead>
        <tr
          style={{
            fontSize: "17px",
            color: "black",
            backgroundColor:"white",
            textAlign: "center",
          }}
        >
          <th>
            Last Day End Transaction Completed on :{" "}
            {lastUpdatedClosestock && (
              <span className="ml-2">{formatDate(lastUpdatedClosestock.dedate)}</span>
              
            )}
            <br/>
            <br/>
              <Search searchName={searchName} />{" "}

          </th>
        </tr>
      </thead>
    </table>
    </div>
    <div className="text-center mt-4">
    <button className="btn btn-primary" onClick={handleGeneratePDF}>Generate PDF</button>
  </div> 
  </div>
</div>
</div>{" "}

{(searchstate && tsearchstate) && (
 <>
        {openingstock.length === 0 ? (
          <>
           <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />

            </div>
            </div>
            </div>
          </>
          
        ) : (
          <>
        <br></br>
        <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Over Head  Details{""}
           
                  {loader0 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

<button className="btn btn-primary"
style={{
 fontSize: '9px',
 padding: '7px 5px',
 top: '10px',
 right: '330px',
}}  onClick={handleRefresh}>
Refresh
</button> 

              <button className="btn btn-primary"onClick={handleAdd}>
               ADD
            </button>
          
            </div>
                  
                      
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "15px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Vehicle</th>
                  <th>Product Name</th>
                  <th>Type</th>
                  <th>Given Quantity</th>
                  <th>Taken Quantity</th>
                  <th>Amount</th>
                  <th>Created By</th>
                  <th>Created Date</th>
                  <th>Due Date</th>
                  <th>Description</th>
                  <th>Status</th>
                   <th>Edit</th>

                </tr>
              </thead>
              <tbody>
              {overhead.length === 0 ? (
            <tr>
              <td colSpan="12" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            overhead.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.vehicleid}</td>

                      <td>{product.productname}</td>
                      <td>{product.type}</td>
                      <td>{product.givenQty}</td>
                      <td>{product.takenQty}</td>
                      <td>{product.amount}</td>
                      <td>{product.userrole}</td>
                    
                      <td>{product.createddate} </td>
                      <td>{product.duedate} </td>
                      <td>{product.description} </td>
                      {product.completed == false ? (
                        <td id="icon" value={product.status}>
                          &nbsp; Pending &nbsp;
                          <BsCheckCircle
                            className="icon"
                            id="approval.approvalstatus"
                            style={{ backgroundColor: "green" }}
                            value="true"
                            onClick={(e) => rsubmit(e, product.id, true)}
                            />
                            &ensp;
      &ensp;
      <BsXCircle
                            className="icon"
                            value="Notverified"
                            style={{ backgroundColor: "red" }}
                            id="product.status"
                            onClick={(e) => rsubmit(e, product.id, false, product.orgcode)}
                            />
                             </td>
                        ) : (
                        <td id="icon" value={product.status}>
                          
                          <div className="d-flex">
                           
                            <AiOutlineCheck style={{ color: "green" }} />
                          </div>
                        </td>
                      )}
                      <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>
     
      <br/>
        
             <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Open Stock Details</div>
              {" "}
                  {loader1 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 
                    {/* <span style={{ marginLeft: '25px' }}></span> */}

                    <div className="d-flex align-items-center">
                    <button
            className="btn btn-primary"
            style={{
              fontSize: '9px',
              padding: '7px 5px',
              // marginLeft: '15px'
            }}
            onClick={handleRefresh1}
          >
            Refresh
          </button>
          <select
                  value={productTypeFilter}
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginLeft:"250px",
                    position:"absolute",
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
             
                <button className="btn btn-primary"   style={{ fontSize: '10px', padding: '8px 6px', marginLeft: '700px' }}onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>
              
            </div>
            </div>
            <br />
            {productTypeFilter === 1 ? (
  <>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 1)
              .map(products => (

                <tr key={products.product.productname}>
                  <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>    
                                    {products.pdo.map((ps, index) => (
                    <th key={index}>{ps.qty}</th>
                  ))}
                </tr>
              ))
          )}
              </tbody>
            </table>
            </>
          ) : null}
            {productTypeFilter === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",         
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 2)
              .map(products => (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>              
                                 <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
          </tr>
        ))
    )}
  </tbody>
</table>
</>
          ) : null}
           
          </div>
        </div>
       </div>
      

      <br />  
      <div>
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Load In Details{" "}
                  {loader2 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
<span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh2}>
        Refresh
      </button>  
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlin.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlin.map((loadIn) => {
                      return (
                        <tr>
                          <th>{loadIn.truckinfo}</th>
                          {product.map((ps, pIndex) => {
                                  const productQty = loadIn.loadDetails.find((item) => item.productname === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty.quantity!==null ? productQty.quantity : 0}
                                    </th>
                                  );
                                })}
                        </tr>
                      );
                    }))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br />  
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Total Load Out Details
                  {loader3 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                                                        <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh3}>
        Refresh
      </button>  
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlout.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlout.map((loadOut) => {
                      return (
                        <tr>
                          <th>{loadOut.truckinfo}</th>
                          {product.map((ps, pIndex) => {
            const productQty = loadOut.loadOutDetails.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
                        </tr>
                      );
                    }))}
                    
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />  
      <div>
      <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Transaction Out
                  {loader4 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
               <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh4}>
        Refresh
      </button>  
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div>FILTER BY VEHICLE:
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>ALL</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>

                  &ensp; &ensp; &ensp; &ensp; &ensp;
                  FILTER BY PRODUCT: 
                  <select
                    id="productname"
                    type="text"
                    placeholder="productname"
                    onChange={(e) => handleProduct(e, "productname")}
                  >
                    <option>ALL</option>
                    {product.map((p) => {
                      return (
                        <option value={p.productname}>
                          {p.productname}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                   onClick={(e) => searchbyProductname(e, productname.productname)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th key={prod.productname}>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {tto1.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                    searchbyv && searchbyPro &&
                    searchstate &&
                    tto1.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttin.vehicle.truckinfo}</th>
                          )}
                         
                         {ttin.pdo.map((ps, index) => {
                            return <th key={index}> {ps.qty === null ? ps.totalQty : ps.qty}</th>;
                          })}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbytoutvehicle.map((ttout) => {
                      return (
                        <tr>
                          {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                         
                         {ttout.pdo.map((ps, index) => {
                            return <th key={index}> {ps.qty === null ? ps.totalQty : ps.qty}</th>;
                          })}
                        </tr>
                      );
                    })}

                {!searchbyPro &&
                    searchToutByProduct.map((ttout) => {
                      return (
                        <tr>
                         {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                          {product.map((prod) => {
  const productQty = ttout.pdo.find((ps1) => ps1.product === prod.productname)?.qty;
  const displayQty = productQty != null ? productQty : ttout.pdo.find((ps1) => ps1.product === prod.productname)?.totalQty || 0;
  return <th key={prod.productname}>{displayQty}</th>;
})}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br />  
        <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Transaction In
                  {loader5 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                                                        <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh5}>
        Refresh
      </button>  
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <div>
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>select vehicle</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
              </div> */}
             </div>

              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    <th>Product Name</th>
                    <th>Sales</th>
                    <th>New Connection</th>
                    <th>Additionals</th>
                    <th>Onlinepay</th>
                    <th>Exempted</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Total</th>
                    <th>Total Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                {ttin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                  searchbyv && searchbyPro &&
                    tsearchstate &&
                    ttin.map((ttin) => {
                      return (
                        <tr style={{ color: ttin.fromOutgoing === true ? 'red' : 'black' }}>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                          {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                           
                           {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                           {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                          {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                          
                           {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                         
                         
                         
                         
                         
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          

                         
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbyvehicle.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                           {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                          
                          {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                            {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                            {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    })}


                {!searchbyPro &&
                    searchTinbyProduct.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                           {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                          
                          {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                            {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                            {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    })}
                  
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br/>
      <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Office Sale Details{""}
           
                  {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

<button className="btn btn-primary"
style={{
 fontSize: '9px',
 padding: '7px 5px',
 top: '10px',
 right: '310px',
}}  onClick={handleRefresh8}>
Refresh
</button> 

              <button className="btn btn-primary"onClick={handlecreatesale}>
               Create Sale
            </button>
          
            </div>
                  
            <div className="mt-2 mb-2">
            <h2 className="card-title">Refill Sales :</h2>
            </div>    
    
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                   <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Sale</th>
                  <th>New Connection</th>
                  <th>Additional</th>

                  <th>Exempted</th>
                  <th>Onlinepay</th>
                  <th>Transfer</th>
                  <th>Total</th>
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {searchstate && refill.length === 0 ? (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            refill.map((product) => {
                  return (
                    <tr key={product.id}>
                   <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                     <td>{product.sale}</td>

                      <td>{product.newconnection}</td>

                      <td>{product.additional}</td>
                    
                      <td>{product.exempted} </td>
                      <td>{product.onlinepay} </td>
                      <td>{product.transfer} </td>
                      <td>{product.total}</td>

                     
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>

            {/* <div className="mt-3 mb-2">
            <h2 className="card-title">Consumables :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>

                  <th>Total</th>
      

                </tr>
              </thead>
              <tbody>
              {searchstate && consumable.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            consumable.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.productname}</td>

                      <td>{product.productcode}</td>
                      <td>{product.quantity}</td>

                      <td>{product.total}</td>
                    
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table> */}
           
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>
<br/>
      </div>
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div
                className="card-title"
                style={{
                  fontSize: "20px",
                  // backgroundColor: "blue",
                }}
              >
                Payment Details
                {loader6 && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                                      <span style={{ marginLeft: '25px' }}></span>

                  <button className="btn btn-primary" 
                   style={{
                    fontSize: '9px',
                    padding: '7px 5px'
                  }}onClick={handleRefresh6}>
        Refresh
      </button>  
              </div>
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Id</th>
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Expenses</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
              {dapaymentdetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                dapaymentdetails.map((dacash, index) => {
                  return (
                    <tr key={index}>
                      {dacash.vehicleid === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{dacash.vehicleid}</th>
                          )}

                      {dacash.totalamount !==null ? (
                      <th style={{ color: dacash.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{dacash.totalamount}</th>
                      ):(
                        <th>
                        {dacash.totalOfAllAmount}</th>
                      )}

                      {dacash.paidamount!==null ?(
                      <th>
                       <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
            
            {dacash.paidamount}
            {dacash.paidamount!==0 && (
          <span>
                          <FiInfo 
                          onClick={() => openDaPaidDetailsModal(dacash.cashPaymentIds)} 
                          style={{ cursor: 'pointer' }} title="View Details"
                          />
                        </span>
            )}
                        </div>
                      </th>
                      ):(
                        <th>{dacash.totalOfAllPaidAmount}</th>
                      )}

{dacash.expenses !==null ? (
                      <th>{dacash.expenses}</th>
                      ):(
                        <th>
                        {dacash.totalOfAllExpenses}</th>
                      )}

<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {dacash.penddingamount !== null ? dacash.penddingamount : dacash.totalOfAllPendinAmount}
  </span>
  {dacash.penddingamount !== null && (
  <button
    className="btn btn-primary"
    style={{
      fontSize: '9px',
      padding: '7px 5px',
      marginLeft: '10px', // Adjust the margin as needed
    }}
    onClick={() => reciveopenModal(dacash.date, dacash.vehicleid)}
  >
    Receive
  </button>
  )}
</th>

                    </tr>
                  );

                  
                }))}
                
              </tbody>
            </table>
            <br/>
            <div
                className="card-title"
                style={{
                  fontSize: "20px",
                  // backgroundColor: "blue",
                }}
              >
                Office Payment Details
               
                  </div>                                
            <table className="table table-bordered mt-3">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Office</th>
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Expenses</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
              {officePaymentDetails===null ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate && officePaymentDetails && (
              <tr>
                     
                            <th>Office</th>
                      
                      {officePaymentDetails.totalamount !==null ? (
                      <th>{officePaymentDetails.totalamount}</th>
                      ):(
                        <th>
                        {officePaymentDetails.totalOfAllAmount}</th>
                      )}

                      {officePaymentDetails.paidamount!==null ?(
                      <th>
                       <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
            
            {officePaymentDetails.paidamount}
            {officePaymentDetails.paidamount!==0 && (
          <span>
                          <FiInfo 
                          onClick={() => openOfficePaidDetailsModal(officePaymentDetails.cashPaymentIds)} 
                          style={{ cursor: 'pointer' }} title="View Details"
                          />
                        </span>
            )}
                        </div>
                      </th>
                      ):(
                        <th>{officePaymentDetails.totalOfAllPaidAmount}</th>
                      )}

{officePaymentDetails.expenses !==null ? (
                      <th>{officePaymentDetails.expenses}</th>
                      ):(
                        <th>
                        {officePaymentDetails.totalOfAllExpenses}</th>
                      )}

<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {officePaymentDetails.penddingamount !== null ? officePaymentDetails.penddingamount : officePaymentDetails.totalOfAllPendinAmount}
  </span>
  {officePaymentDetails.penddingamount !== null && (
  <button
    className="btn btn-primary"
    style={{
      fontSize: '9px',
      padding: '7px 5px',
      marginLeft: '10px', // Adjust the margin as needed
    }}
    onClick={() => officePaymentRecive(officePaymentDetails.date)}
  >
    Receive
  </button>
  )}
</th>

                    </tr>
            ))}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <br />
      <br />
          {closestockByCurrentdate.length===0 ? (
          <div
            className="endtransaction"
            style={{ position: "relative", marginLeft: "80%" }}
          >
            <button className="btn btn-primary" onClick={(e) => onclickhandler(e,searchDate)}>
              Perform Day End
            </button>
          </div>
          ):(
        <h5 style={{ position: "relative", marginLeft: "45%"}}> 
     Transaction completed for {formatDate(closestockByCurrentdate[0].datetime)}.</h5>
          )}
      <br />
      <br />{" "}
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Close Stock Details
              {loader7 && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                                      <span style={{ marginLeft: '25px' }}></span>

              <button className="btn btn-primary"
               style={{
                fontSize: '9px',
                padding: '7px 5px'
              }} onClick={handleRefresh7}>
        Refresh
      </button> 
      <select
            value={productTypeFilter1}
            onChange={(e) => setProductTypeFilter1(Number(e.target.value))}
            style={{
              width: '200px',
              height: '45px',
              fontSize: '16px',
              padding: '10px 14px',
              border: 'none',
              borderRadius: '8px',
              backgroundColor: '#f8f9fa',
              color: '#495057',
              fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
              cursor: 'pointer',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
              outline: 'none',
              appearance: 'none',
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 10px center',
              backgroundSize: '16px 16px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              marginLeft:"200px",
              position:"absolute",
            }}
            onFocus={(e) => {
              e.target.style.backgroundColor = '#e9ecef';
              e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
            }}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f8f9fa';
              e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
            }}
          >
           <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
          </select> 
              </div>
            </div>
            <br />
            <br />
            {productTypeFilter1 === 1 ? (
  <>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="empty">Defective</th>
                </tr>{" "}
               </thead>
               <tbody>
               {closestockone===null? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                  closestockone
                  .filter(products => products.product.productCategory?.productCategoryTypeId === 1)

                  .map((products) => {
                    return (
                      <tr>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>  
                                             {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            </>
          ) : null}
           {productTypeFilter1 === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
              <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                    textAlign: "center",
                    padding: '10px' // Reduce padding
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  
                </tr>{" "}
               </thead>
               <tbody>
               {closestockone===null? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                  closestockone
                  .filter(products => products.product.productCategory?.productCategoryTypeId === 2)

                  .map((products) => {
                    return (
                      <tr>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th> 
                                             <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            </>
          ) : null}
            
            </div>
  </div>
</div>
</>
)}
</>
)}

 {(!searchstate && !tsearchstate) && (
 <>
        {errorMessage ===true ? (
          <>
          <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
<h5 style={{ color: "red" }}>No data exists for your organization before {orgStartDate}.</h5>
</div>
           </div>
           </div>
         </>
        ) : (
          <>
            {openingstock.length ===0 ? (
              <>
              <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />
              
                  <button className="btn btn-primary" onClick={(e) => dateHandler(e, searchDate)}>Create Exception</button>
                  </div>
                  </div>
                  </div>
              </>  
        ) : (
          <>
        <br></br>

         <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body ">
        <div className="d-flex">
          <div className="card-title">Open Stock Details</div>
       
                <select
                  value={productTypeFilter}
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginLeft:"250px",
                    position:"absolute",
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
         
        </div>
        <br />
        
       
        {productTypeFilter === 1 ? (
  <>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 1)
              .map(products => (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>   
                                      {products.pdo.map((ps, index) => (
                    <th key={index}>{ps.qty}</th>
                  ))}
                </tr>
              ))
          )}
              </tbody>
            </table>
            </>
          ) : null}
            {productTypeFilter === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            openstock
            .filter(products => products.product.productCategory?.productCategoryTypeId === 2)
              .map(products => (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>                        <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
          </tr>
        ))
    )}
  </tbody>
</table>
</>
          ) : null}


          </div>
        </div>
       </div>
      

      <br />
  <div>
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Load In Details{" "}
              {/* {loader1 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {tlin.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                tlin.map((loadIn) => {
                  return (
                    <tr>
                      <th>{loadIn.truckinfo}</th>
                      {product.map((ps, pIndex) => {
                                  const productQty = loadIn.loadDetails.find((item) => item.productname === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty.quantity!==null ? productQty.quantity : 0}
                                    </th>
                                  );
                                })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br />  
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
              }}
            >
              Total Load Out Details
              {/* {loader2 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {tlout.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                tlout.map((loadOut) => {
                  return (
                    <tr>
                      <th>{loadOut.truckinfo}</th>
                      {product.map((ps, pIndex) => {
            const productQty = loadOut.loadOutDetails.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />  
  <div>
  <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Transaction Out
              {/* {loader4 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div> FILTER BY VEHICLE:
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>ALL</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
              &ensp; &ensp; &ensp; &ensp; &ensp;
              FILTER BY PRODUCT:
                  <select
                    id="productname"
                    type="text"
                    placeholder="productname"
                    onChange={(e) => handleProduct(e, "productname")}
                  >
                    <option>ALL</option>
                    {product.map((p) => {
                      return (
                        <option value={p.productname}>
                          {p.productname}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                   onClick={(e) => searchbyProductname(e, productname.productname)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th key={prod.productname}>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {!searchbyv && 
                searchbytoutvehicle.map((ttout) => {
                  return (
                    <tr>
                      {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                    {ttout.pdo.map((ps1, index) => {
                        return <th key={index}> {ps1.qty === null ? ps1.totalQty : ps1.qty}</th>;
                      })}
                    </tr>
                  );
                })}

         {!searchbyPro &&
                searchToutByProduct.map((ttout) => {
                  return (
                    <tr>
                       {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                     {product.map((prod) => {
  const productQty = ttout.pdo.find((ps1) => ps1.product === prod.productname)?.qty;
  const displayQty = productQty != null ? productQty : ttout.pdo.find((ps1) => ps1.product === prod.productname)?.totalQty || 0;
  return <th key={prod.productname}>{displayQty}</th>;
})}
                    </tr>
                  );
                })}
               {tto1.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbyPro && searchbytoutvehicle && tto1.map((ttin) => {
              return (
                    <tr>
                       {ttin.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttin.vehicle.truckinfo}</th>
                          )}
                    {ttin.pdo.map((ps, index) => {
                        return <th key={index}> {ps.qty === null ? ps.totalQty : ps.qty}</th>;
                      })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br />  
    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Transaction In
              {/* {loader3 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <div>
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>select vehicle</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
          </div> */}
         </div>

          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                <th>Product Name</th>
                <th>Sales</th>
                <th>New Connection</th>
                <th>Additionals</th>
                <th>Onlinepay</th>
                <th>Exempted</th>
                <th>Returned Product</th>
                <th>Transfer</th>
                <th>Total</th>
                <th>Total Amount</th>
                
              </tr>
            </thead>
            <tbody>
            {!searchbyv && 
                searchbyvehicle.map((ttin) => {
                  return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                     
                      {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                        {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}
                       {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
          
                      {/* {ttin.totaloutgoingcylinder == null ? (
                        <td>{ttin.totalofalloutgoingcylinder}</td>
                      ) : (
                        <td>{ttin.totaloutgoingcylinder}</td>
                      )} */}
                      
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                })}
                 {!searchbyPro &&
                searchTinbyProduct.map((ttin) => {
                  return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                     
                      {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                        {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}
                       {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
          
                      {/* {ttin.totaloutgoingcylinder == null ? (
                        <td>{ttin.totalofalloutgoingcylinder}</td>
                      ) : (
                        <td>{ttin.totaloutgoingcylinder}</td>
                      )} */}
                      
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                })}
              {ttin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbyPro && searchbyvehicle && ttin.map((ttin) => {
              return (
                <tr style={{ color: ttin.fromOutgoing === true ? 'red' : 'black' }}>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                       {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                      {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}

                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}

                      {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
                     
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br/>
      <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Office Sale Details{""}
           
                  {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

            </div>
                  
                      
            <div className="mt-2 mb-2">
            <h2 className="card-title">Refill Sales :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                   <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Sale</th>
                  <th>New Connection</th>
                  <th>Additional</th>

                  <th>Exempted</th>
                  <th>Onlinepay</th>
                  <th>Transfer</th>
                  <th>Total</th>
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {!searchstate && refill.length === 0 ? (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            !searchstate &&
            refill.map((product) => {
                  return (
                    <tr key={product.id}>
                   <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                     <td>{product.sale}</td>

                      <td>{product.newconnection}</td>

                      <td>{product.additional}</td>
                    
                      <td>{product.exempted} </td>
                      <td>{product.onlinepay} </td>
                      <td>{product.transfer} </td>
                      <td>{product.total}</td>

                     
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>

            {/* <div className="mt-2 mb-2">
            <h2 className="card-title">Consumables :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>

                  <th>Total</th>

                </tr>
              </thead>
              <tbody>
              {!searchstate && consumable.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            !searchstate &&
            consumable.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.productname}</td>

                      <td>{product.productcode}</td>
                      <td>{product.quantity}</td>

                      <td>{product.total}</td>
                    
                    
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table> */}
           
            <div>
        
      </div>
          </div>
        </div>{" "}
      </div>

  </div>
  <br/>
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          <div
            className="card-title"
            style={{
              fontSize: "20px",
            }}
          >
           Payment Details
            {/* {loader5 && (
              <div
                className="loader"
                style={{
                  position: "relative",
                  left: "20px",
                  top: "10px",
                }}
              ></div>
            )} */}
          </div>
          &nbsp;&nbsp;&nbsp;
          &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th>Vehicle Id</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Expenses</th>
              <th>Pending Amount</th>
            </tr>
          </thead>
          <tbody>
          
          {dapaymentdetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
dapaymentdetails.map((searchDa, index) => {
return (
  <tr key={index}>
    {searchDa.vehicleid === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{searchDa.vehicleid}</th>
                          )}

{searchDa.totalamount !== null ? (

    <th style={{ color: searchDa.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{searchDa.totalamount}</th>
  ) : (
    <th>{searchDa.totalOfAllAmount}</th>
  )}

  {searchDa.paidamount!==null ?(
    <th>
      <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
            
                      {searchDa.paidamount}
                      {searchDa.paidamount!==0 && (
                    <span>
                                    <FiInfo 
                                    onClick={() => openDaPaidDetailsModal(searchDa.cashPaymentIds)} 
                                    style={{ cursor: 'pointer' }} title="View Details"
                                    />
                                  </span>
                      )}
                                  </div>
                  </th>
                   ) : (
                    <th>{searchDa.totalOfAllPaidAmount}</th>
                  )}

{searchDa.expenses !==null ? (
                      <th>{searchDa.expenses}</th>
                      ):(
                        <th>
                        {searchDa.totalOfAllExpenses}</th>
                      )}
<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {searchDa.penddingamount !== null ? searchDa.penddingamount : searchDa.totalOfAllPendinAmount}
  </span>
  {searchDa.penddingamount !== null && (
    <button
      className="btn btn-primary"
      style={{
        fontSize: '9px',
        padding: '7px 5px',
        marginLeft: '10px', // Adjust the margin as needed
      }}
      onClick={() => reciveopenModal(searchDa.date, searchDa.vehicleid)}
    >
      Receive
    </button>
  )}
</th>

  </tr>
);
}))
}
          </tbody>
        </table>

        <br />
        <div
            className="card-title mb-3"
            style={{
              fontSize: "20px",
            }}
          >
           Office Payment Details
           </div>
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th>Office</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Expenses</th>
              <th>Pending Amount</th>
            </tr>
          </thead>
          <tbody>
          
          {officePaymentDetails === null ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
officePaymentDetails && (
  <tr>
                            <th>Office</th>

{officePaymentDetails.totalamount !== null ? (

    <th>{officePaymentDetails.totalamount}</th>
  ) : (
    <th>{officePaymentDetails.totalOfAllAmount}</th>
  )}

  {officePaymentDetails.paidamount!==null ?(
    <th>
                 <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
            
            {officePaymentDetails.paidamount}
            {officePaymentDetails.paidamount!==0 && (
          <span>
                          <FiInfo 
                          onClick={() => openOfficePaidDetailsModal(officePaymentDetails.cashPaymentIds)} 
                          style={{ cursor: 'pointer' }} title="View Details"
                          />
                        </span>
            )}
                        </div>
                  </th>
                   ) : (
                    <th>{officePaymentDetails.totalOfAllPaidAmount}</th>
                  )}

{officePaymentDetails.expenses !==null ? (
                      <th>{officePaymentDetails.expenses}</th>
                      ):(
                        <th>
                        {officePaymentDetails.totalOfAllExpenses}</th>
                      )}
<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {officePaymentDetails.penddingamount !== null ? officePaymentDetails.penddingamount : officePaymentDetails.totalOfAllPendinAmount}
  </span>
  {officePaymentDetails.penddingamount !== null && (
    <button
      className="btn btn-primary"
      style={{
        fontSize: '9px',
        padding: '7px 5px',
        marginLeft: '10px', // Adjust the margin as needed
      }}
      onClick={() => officePaymentRecive(officePaymentDetails.date)}
    >
      Receive
    </button>
  )}
</th>

  </tr>
))}
          </tbody>
        </table>
      </div>
    </div>
  </div>

 
  <br />
  <br />
        {closestockBydate.length===0 ?(
        <div
          className="endtransaction"
          style={{ position: "relative", marginLeft: "80%" }}
        >
          
        </div>
        ):(
      <h5 style={{ position: "relative", marginLeft: "45%"}}> 
      Transaction completed for {formatDate(closestockBydate[0].datetime)}.</h5>
        )}
  <br />
  <br />{" "}
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          
         
        <div
            className="card-title"
            style={{
              fontSize: "20px",
            }}
          >
            Close Stock Details{""}
            <select
            value={productTypeFilter1}
            onChange={(e) => setProductTypeFilter1(Number(e.target.value))}
            style={{
              width: '200px',
              height: '45px',
              fontSize: '16px',
              padding: '10px 14px',
              border: 'none',
              borderRadius: '8px',
              backgroundColor: '#f8f9fa',
              color: '#495057',
              fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
              cursor: 'pointer',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
              outline: 'none',
              appearance: 'none',
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 10px center',
              backgroundSize: '16px 16px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              marginLeft:"200px",
              position:"absolute",
            }}
            onFocus={(e) => {
              e.target.style.backgroundColor = '#e9ecef';
              e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
            }}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f8f9fa';
              e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
            }}
          >
            <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
          </select> 
            {/* {loader6 && (
              <div
                className="loader"
                style={{
                  position: "relative",
                  left: "20px",
                  top: "10px",
                }}
              ></div>
            )} */}
          </div>
          &nbsp;&nbsp;&nbsp;
          &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        {productTypeFilter1 === 1 ? (
  <>
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th id="date">Product</th>

              <th id="full">Full</th>

              <th id="empty">Empty</th>
              <th id="empty">Defective</th>
            </tr>{" "}
           </thead>
           <tbody>
              
           {closestockone.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            closestockone
             .filter(products => products.product.productCategory?.productCategoryTypeId === 1)

            .map((products) => {                return (
                  <tr>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>  
                    {products.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                  </tr>
               );
              })
          )}
        </tbody>
      </table>
      </>
          ) : null}
           {productTypeFilter1 === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
              <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                    textAlign: "center",
                    padding: '10px' // Reduce padding
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  
                </tr>{" "}
               </thead>
               <tbody>
               {closestockone===null? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
           
                  closestockone
                  .filter(products => products.product.productCategory?.productCategoryTypeId === 2)

                  .map((products) => {
                    return (
                      <tr>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>          
                                    <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            </>
          ) : null}
         
    </div>
  </div>
</div>
</>

)}
</>
)}
</>
)}
      {loading && <div className="loading"></div>}
      {/* {iscreatesaleModalOpen && (
      <Createofficesale isOpen={iscreatesaleModalOpen} onRequestClose={handlecreatesaleCloseModal} 
      date={searchDate}
      />
     )} */}

      <Overheadmodal isOpen={isoverheadModalOpen} onRequestClose={handleoverheadCloseModal} />
      {isoverheadEditModalOpen && (
              <Overheadeditmodal
                isOpen={isoverheadEditModalOpen}
                onRequestClose={closeoverheadEditModal}
                id={selectedoverheadId}
              />
            )}
            {isReciveopenModalModalOpen && (
              <ReciveopenModal
                isOpen={isReciveopenModalModalOpen}
                onRequestClose={closereciveopenModal}
                vehicleid={selectedReceiveVehicleId}
                receiveDate={selectedDate}
              />
            )}

{/* {isOfficePaymentReciveModalOpen && (
              <OfficePaymentRecieveModal
                isOpen={isOfficePaymentReciveModalOpen}
                onRequestClose={closeOfficePaymentsReciveModal}
                receiveDate={selectedDate}
              />
            )} */}
 {isPerformdatendModalIsOpen && (
              <PerformModal
                isOpen={isPerformdatendModalIsOpen}
                onRequestClose={closePerformdayendModal}
                performdate={selectedPerformdate}
              />
            )}

            {isDaPaidDetailsModalOpen && (
              <DaPaidAmountDetails 
              isOpen={isDaPaidDetailsModalOpen} 
              onRequestClose={closeDaPaidDetailsModal} 
              cashPaymentIds={selectedCashPaymentIds}
            />
            )}

{isOfficePaidDetailsModalOpen && (
              <DaPaidAmountDetails
              isOpen={isOfficePaidDetailsModalOpen} 
              onRequestClose={closeOfficePaidDetailsModal} 
              cashPaymentIds={selectedCashPaymentIds}
            />
            )}
</div>
    </div>
  );
}

export default DayOperationalReport;

