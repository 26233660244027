import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
  const Financialmanagment = ({ isOpen, onRequestClose }) => {
    const [time, setTime] = useState(new Date());
  const date = new Date();

  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");

  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [selectedVehicle, setSelectedVehicle] = useState(""); 
  const [fleetDetails, setFleetDetails] = useState([]); 
  const [uproductcost, setUproductcost] = useState({
    date: currentDate,
    vehicle: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [
      {
        productcode: "",
        productname: "",
        productType:"",
        salesCost: "",
        additionalCost:"",
        newConCost:""
      },
    ],
  });

  const [product, setProduct] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = "/api/fleetfinancial/create";
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
setLoading(true)
    const pro = await axios.get(
      `/api/product/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/productstatus=true`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );


    setProduct(pro.data);
    setUproductcost({
      ...uproductcost,
      products: pro.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        productType:item.productType,
        salesCost:item.pprice.price,
        additionalCost: item.addPrice.additionalPrice,
        newConCost: item.newConPrice.newConPrice,
      })),
    });
    setLoading(false)
    const vehicles = await axios.get(
      `/api/vehicle/getAllActiveVehicles/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);


  function rsubmit(e) {
    e.preventDefault();
    if (!selectedVehicle) {
      toast.error("Please select a vehicle");
      return;
    }
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproductcost,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        navigate("/users/financialdetails");
        toast.success("Productcost updated successfully");
        onRequestClose(); 
        
      })
      .catch((error) => {
        setLoading(false);
       handleError(error);
      });
  }
  
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
  
    if (field === "vehicle") {
      setSelectedVehicle(actualValue);
      if (actualValue !== "select vehicle") {
        fetchFleetDetails(actualValue);
      } else {
        setFleetDetails([]); // Clear fleet details if no vehicle selected
      }
    } else if (field === "salesCost" || field === "newConCost" || field === "additionalCost") {
      const newproduct = [...uproductcost.products];
      newproduct[index] = { ...newproduct[index], [field]: actualValue };
      setUproductcost({ ...uproductcost, products: newproduct });
    } else {
      // Keep the previous value if the input is cleared
      actualValue = actualValue.trim() === "" ? uproductcost[field] : actualValue;
      setUproductcost({ ...uproductcost, [field]: actualValue });
    }
  }
  
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }

      const fetchFleetDetails = async (selectedVehicle) => {
        try {
          const response = await axios.get(
            `/api/fleetfinancial/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/vehicle=${selectedVehicle}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const fetchedFleetDetails = response.data;
          console.log("Fetched Fleet Details:", fetchedFleetDetails);
      
          if (fetchedFleetDetails.length > 0) {
            // Update product costs based on fetched fleet details
            const updatedProducts = uproductcost.products.map((product) => {
              const fleetDetail = fetchedFleetDetails.find((item) => item.productcode === product.productcode);
              if (fleetDetail) {
                return {
                  ...product,
                  salesCost: fleetDetail.salecost,
                  additionalCost: fleetDetail.additionalcost,
                  newConCost: fleetDetail.newconection,
                };
              }
              return product;
            });
      
            setUproductcost({
              ...uproductcost,
              vehicle: selectedVehicle,
              products: updatedProducts,
            });
          } else {
            // If no fleet details found, reset product costs to default values
            const updatedProducts = product.map((item) => ({
              productcode: item.productcode,
              productname: item.productname,
              salesCost: item.pprice.price,
              additionalCost: item.addPrice.additionalPrice,
              newConCost: item.newConPrice.newConPrice,
            }));
      
            setUproductcost({
              ...uproductcost,
              vehicle: selectedVehicle,
              products: updatedProducts,
            });
          }
      
          setFleetDetails(fetchedFleetDetails);
        } catch (error) {
          handleError(error);
        }
      };
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Update Product Cost</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            

      <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div>
                      <label for="date">Date : </label>
                      <input
                        id="date"
                        value={currentDate}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="truckinfo">Vehicle : </label>
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={uproductcost.vehicle}
                        onChange={(e) => {
                          rhandle(e, "vehicle");
                      
                        }}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Sales Cost</th>
                              <th>Additional Cost</th>
                              <th>New Connection Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                           {uproductcost.products.map((product, index) => {
  return (
    <tr key={product.productcode}>
      <td>{product.productcode}</td>
      <td>{product.productname}</td>
      <td>
        <input
          id="id"
          type="text"
          placeholder={uproductcost.products[index].salesCost}
          style={{ border: "none" }}
          onChange={(e) => rhandle(e, "salesCost", index)}
          onInput={(e) => {
            const newValue = e.target.value.replace(/[^0-9]/g, "");
         // e.target.value = newValue !== "" ? newValue : uproductcost.products[index].salesCost;
          }}
        />
      </td>

      <td>
        <input
          id="id"
          type="text"
          placeholder={uproductcost.products[index].additionalCost}
          style={{ border: "none" }}
          onChange={(e) => rhandle(e, "additionalCost", index)}
          onInput={(e) => {
            const newValue = e.target.value.replace(/[^0-9]/g, "");
            //e.target.value = newValue !== "" ? newValue : uproductcost.products[index].additionalCost;
          }}
          disabled={product.productType === "Consumables"}
        />
      </td>

      <td>
        <input
          id="id"
          type="text"
          placeholder={uproductcost.products[index].newConCost}
          style={{ border: "none" }}
          onChange={(e) => rhandle(e, "newConCost", index)}
          onInput={(e) => {
            const newValue = e.target.value.replace(/[^0-9]/g, "");
            //e.target.value = newValue !== "" ? newValue : uproductcost.products[index].newConCost;
          }}
          disabled={product.productType === "Consumables"}
        />
      </td>
    </tr>
  );
})}

                          </tbody>
                        </table>
                      </div>{" "}
                    </div>
<div className="form-group d-flex align-items-center justify-content-between mt-5">
                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                    <Button variant="secondary" onClick={onRequestClose}>
Close
</Button>
</div>
                  </form>
                </div>
              </div>
            </div>


            </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Financialmanagment;
