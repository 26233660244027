import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import ViewQuotation from "./ViewQuotation";

import {
  Card, CardContent, Grid, Typography, TextField, Button,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip, InputAdornment
} from '@mui/material';
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEye, AiOutlineShareAlt, AiOutlinePlus } from 'react-icons/ai';
import { MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AddQuotation from "./AddQuotation";

function Quotation() {
  const navigate = useNavigate();
  const [quotation, setQuotation] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const getCurrentFiscalYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiscalStartMonth = 3; // April is month 3 (0-based index)

    // If today's month is after March, we are in the next fiscal year
    const fiscalYearStart = new Date(today.getFullYear(), fiscalStartMonth, 1);
    const fiscalYearEnd = new Date(today.getFullYear() + 1, fiscalStartMonth - 1, 31);

    // Check if the current date is before or after March 31st and adjust the year accordingly
    return today >= fiscalYearStart ? { startYear: currentYear, endYear: currentYear + 1 }
      : { startYear: currentYear - 1, endYear: currentYear };
  };

  const { startYear, endYear } = getCurrentFiscalYear();

  // Default date range for current fiscal year
  const defaultStartDate = `${startYear}-04-01`; // April 1st of startYear
  const defaultEndDate = `${endYear}-03-31`; // March 31st of endYear

  const [dateRange, setDateRange] = useState({ startDate: defaultStartDate, endDate: defaultEndDate });

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/quotes`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setQuotation(response.data || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen, isEditModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

 

  const handleEditQuotation = (id) => {
    navigate(`/users/EditQuotation/${id}`);
  };


  const handleViewQuotation = (id) => {
    navigate(`/users/ViewQuotation/${id}`);
  };

  const filteredquotation = quotation.filter((purchase) => {
    const quoteNumber = String(purchase.number || "");
    return quoteNumber.toLowerCase().includes(searchTerm.toLowerCase());
  });



  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">

          <Card>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" component="div">Quotes</Typography>
                <Grid container item xs={12} justifyContent="flex-end" alignItems="center"> {/* Align everything to the right */}
                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiSearch style={{ fontSize: '16px' }} /> {/* Make search icon smaller */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '16px' }, // Smaller height and font size
                      }}
                      sx={{ width: '150px', height: '30px' }} // Smaller search bar width and height
                    />
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.startDate}
                      onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  <Grid item sx={{ marginRight: '8px' }}>
                    <TextField
                      variant="outlined"
                      type="date"
                      value={dateRange.endDate}
                      onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                      InputProps={{
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />
                  </Grid>

                  {/* <Grid item container alignItems="center" spacing={1} sx={{ marginLeft: '8px' }}> Move icons closer to fields */}
                  <Tooltip title="Filter">
                    <IconButton sx={{ padding: '5px' }}>
                      <MdFilterList style={{ fontSize: '20px' }} /> {/* Smaller filter icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineEye style={{ fontSize: '20px' }} /> {/* Smaller eye icon */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share">
                    <IconButton sx={{ padding: '5px' }}>
                      <AiOutlineShareAlt style={{ fontSize: '20px' }} /> {/* Smaller share icon */}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<span style={{ color: 'white' }}>New Quote</span>} arrow>
                    <NavLink to="/users/AddQuotation" style={{ textDecoration: 'none' }}>
                      <IconButton
                        sx={{
                          padding: '5px',
                          backgroundColor: 'blue',
                          color: 'white',
                          borderRadius: '4px',
                          width: '100px',
                          height: '40px',
                          '&:hover': { backgroundColor: 'darkblue' }
                        }}
                      >
                        <AiOutlinePlus style={{ fontSize: '15px', marginRight: '5px' }} />
                        <span style={{ fontSize: '12px' }}>New Quotes</span>
                      </IconButton>
                    </NavLink>
                  </Tooltip>




                </Grid>

              </Grid>
              {/* </Grid> */}






              <br />

              {/* Product Table */}
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>Quotaion Id</TableCell>
                    <TableCell>ConsumerName</TableCell>
                    <TableCell>Quote Date</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredquotation.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">No Data Available</TableCell>
                    </TableRow>
                  ) : (
                    filteredquotation.map((Quotation) => (
                      <TableRow key={Quotation.id}>
                        <TableCell>{Quotation.quotationid}</TableCell>
                        <TableCell>{Quotation.consumername}</TableCell>
                        <TableCell>{Quotation.quotesdate}</TableCell>
                        <TableCell>{Quotation.duedate}</TableCell>
                        <TableCell>{Quotation.totalAmount}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEditQuotation(Quotation.id)}>
                            <BiEditAlt />
                          </IconButton>
                          
                          <Tooltip title="View">
                            <IconButton sx={{ padding: '5px' }} onClick={() => handleViewQuotation(Quotation.id)}>
                              <AiOutlineEye style={{ fontSize: '25px' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>

             
            

            </CardContent>
          </Card>
        </div>
      </div>
    </div >
  );
}

export default Quotation;
