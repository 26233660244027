import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";

function TrucksDetails({ selectedTransporterId }) {
  const [trucks, setTrucks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const getData = async (pageNumber = 0) => {
    if (!selectedTransporterId) return;
    setLoader(true);
    try {
      const res = await axios.get(
        `/api/truck-transporter-mapping/getByTransporterId/transporterId=${selectedTransporterId}`, {
          params: { page: pageNumber },
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setTrucks(res.data.content);
        setCurrentPage(res.data.number);
        setTotalPages(res.data.totalPages);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (trucks.length > 0) {
      const filtered = trucks.filter(
        (truck) =>
          (truck?.truckInfo?.toLowerCase().includes(searchTerm.toLowerCase())) 
      );
      setFilteredTrucks(filtered);
    }
  }, [searchTerm, trucks]);

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  useEffect(() => {
    getData(currentPage);
  }, [selectedTransporterId, currentPage]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const handleViewTruckInfo = async (truck) => {
    navigate(`/users/view-truckinfo/${truck}`);
  };

  const handleDelete = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try{
      await axios({
      url: "/api/truck-transporter-mapping",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("Deleted successfully");
    setLoader(false); 
    getData(currentPage);
  } catch (error) {
    setLoader(false); 
    handleError(error);
  }
};

  return (
    <div className="trucks-details">
      <div className="card card-raised mb-5">
        <div className="card-body">
          {loader && (
            <div className="loading"></div>
          )}
          
          <div className="form-group position-relative mb-3" style={{ width: '250px' }}> 
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by Truck Number"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>

              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    <th>Trucks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTrucks.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        NO DATA AVAILABLE
                      </td>
                    </tr>
                  ) : (
                    filteredTrucks.map((detail, index) => (
                      <tr key={index}>
                        <td onClick={() => handleViewTruckInfo(detail.truckInfo)} 
                        style={{ cursor: "pointer" }}
                        title="View Truck Info">
                          {detail.truckInfo}</td>

                          <td> 
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "30px",
                            fontSize: "24px",
                            bottom:"0px",
                            cursor:"pointer"
                          }}
                          title="Delete"
                          onClick={(e) => {
                            handleDelete(
                              e,
                              detail.id,
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
        </div>
      </div>
    </div>
  );
}

export default TrucksDetails;
