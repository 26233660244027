import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import ContacteditModalPopup from "./Contacteditmodal";
import Addcontact from "./Addcontact";
import { useNavigate, useParams } from "react-router-dom";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
import AdminDashboard from "./admindashboard";
import StockistcontactpersonViewModal from "./Stockistcontactperson";
import AddVendorProduct from "./Createvendorproduct";
import StockistproducteditModalPopup from "./stoclistprodycredit";
import Stockistmapping from "./Stockistmapping";
import { IoMdAddCircleOutline } from "react-icons/io";
import ViewStockistmapping from "./Viewstockist";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";

function VendorView() {
    const { vendorId } = useParams();
    console.log("Vendor ID:", vendorId);
    const navigate = useNavigate();

    const [vendor, setVendor] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVendorId, setSelectedVendorId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen1, setIsEditModalOpen1] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [activeTab, setActiveTab] = useState('contactPersons');
    const [isUpdateVendorModalOpen, setIsUpdateVendorModalOpen] = useState(false);
    const [selectedStockistId, setSelectedStockistId] = useState(null);
    const [contactSearchTerm, setContactSearchTerm] = useState("");
    const [stockistSearchTerm, setStockistSearchTerm] = useState("");
    const [productSearchTerm, setProductSearchTerm] = useState("");
    const [isEditModalOpen2, setIsEditModalOpen2] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [stockists, setStockists] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const fetchVendorData = async (pageNumber = 0) => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
    
        // Run both API calls concurrently using Promise.all
        const [vendorResponse, stockistResponse] = await Promise.all([
          axios.get(`/api/vendors/vendorid=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`/api/stockistVendorMapping/stockist/vendorId=${vendorId}`, {
              params: { page: pageNumber },  
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
    
        // Set state with responses from both APIs
        setVendor(vendorResponse.data);
        setStockists(stockistResponse.data.content);
        setCurrentPage(stockistResponse.data.number);
        setTotalPages(stockistResponse.data.totalPages);
    
      } catch (err) {
        setError("Error fetching data");
        toast.error("Failed to fetch vendor or stockist data");
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
        if (vendorId) {
          fetchVendorData();
        }
    }, [vendorId]);
  
    const handleEdit = (vendorId) => {
      setSelectedVendorId(vendorId);
      setIsEditModalOpen(true);
    };
  
    const closeEditModal = () => {
      setIsEditModalOpen(false);
      fetchVendorData();
    };
  
    const handleAdd = () => {
      setSelectedVendorId(vendorId);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      fetchVendorData();
    };
  
    const confirmDelete = (id) => {
      if (window.confirm("Are you sure you want to delete this Contact person?")) {
        onClickHandler(id); // Proceed with the delete if confirmed
      }
    };
    
    const onClickHandler = async ( id) => { 
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      try {
        await axios.delete("/api/vendorcontactperson", {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            id: `${id}`,
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("Deleted successfully");
        fetchVendorData();
      } catch (error) {
        toast.error("Error deleting vendor");
      } finally {
        setLoading(false);
      }
    };
  
    const handleEdit1 = async (vendorId) => {
        setSelectedVendorId(vendorId);
     
          setIsEditModalOpen1(true);
       
      };
      const closeEditModal1 = () => {
        setIsEditModalOpen1(false);
        fetchVendorData();
     
      };
      const confirmDelete1 = (id) => {
        if (window.confirm("Are you sure you want to delete this Stockist?")) {
          onClickHandler1(id); // Proceed with the delete if confirmed
        }
      };
      const onClickHandler1 = async ( id) => { 
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoading(id);
        try{
          await axios({
          url: "/api/stockistVendorMapping",
          method: "delete",
     
          headers: { Authorization: `Bearer ${token}` },
     
          data: {
            id: `${id}`,
           
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("deleted successfully");
        fetchVendorData();
    
        setLoading(false); 
      } catch (error) {
        setLoading(false); 
      }
    };
    const handleAdd1= async () => {
      setSelectedVendorId(vendorId);

          setIsModalOpen1(true);
       
      };
      const closeaddModal = () => {
        setIsModalOpen1(false);
        fetchVendorData();
     
      };
      const handleEdit2 = async (vendorId) => {
        setSelectedVendorId(vendorId);
     
          setIsEditModalOpen2(true);
       
      };
      const closeEditModal2 = () => {
        setIsEditModalOpen2(false);
        fetchVendorData();
     
      };
      const confirmDelete2 = (id) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
          onClickHandler2(id); // Proceed with the delete if confirmed
        }
      };
            const onClickHandler2 = async ( id) => { 
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoading(id);
        try{
          await axios({
          url: "/api/vendorproduct",
          method: "delete",
     
          headers: { Authorization: `Bearer ${token}` },
     
          data: {
            id: `${id}`,
           
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("deleted successfully");
        fetchVendorData();
    
        setLoading(false); 
      } catch (error) {
        setLoading(false); 
      }
    };
    const handleAdd2= async () => {
      setSelectedVendorId(vendorId);

          setIsModalOpen2(true);
       
      };
      const closeaddproductModal = () => {
        setIsModalOpen2(false);
        fetchVendorData();
     
      };
      const handleUpdateVendor = (StockistId) => {
        setSelectedStockistId(StockistId);
        setIsUpdateVendorModalOpen(true);
      };
    
      const closeUpdateVendorModal = () => {
        setIsUpdateVendorModalOpen(false);
        setSelectedStockistId(null);
      };
      const openAddMemberModal = (productId, productname) => {
        setSelectedProduct({ productId, productname });
        setIsAddMemberModalOpen(true);
      };
     
      const closeAddMemberModal = () => {
        setSelectedProduct(null);
        setIsAddMemberModalOpen(false);
        fetchVendorData();
      };
      const handleViewStockist = (productId, productname) => {
        setSelectedProduct({ productId, productname });
        setIsViewModalOpen(true);
      };
     
      const closeviewModal = () => {
        setSelectedProduct(null);
        setIsViewModalOpen(false);
        fetchVendorData();
      };


      // Filter contacts based on search term
    const filteredContacts = vendor.vcp?.filter(contact => 
        contact.name.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
        contact.email.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
        contact.phone.includes(contactSearchTerm)
    ) || [];

    const filteredStockists = stockists.filter((stockist) => 
      (stockist.stockist.name && stockist.stockist.name.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.email && stockist.stockist.email.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.phone && stockist.stockist.phone.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.address && stockist.stockist.address.toLowerCase().includes(stockistSearchTerm.toLowerCase()))
    );
    
    const filteredProducts = vendor.vp?.filter(product => 
      product.productname.toLowerCase().includes(productSearchTerm.toLowerCase()) ||
      product.sku.toLowerCase().includes(productSearchTerm.toLowerCase()) ||
      product.hsncode.toLowerCase().includes(productSearchTerm.toLowerCase())
  ) || [];
  const handlePageChange = (pageNumber) => {
    fetchVendorData(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

      return (
        <div>
          <AdminDashboard />
        <br/>
        <br/>
        <br/>
        <br/>
          <div className="row col-12">
                <div className="col-12 ps-xl-10">
                    <div className="card card-raised">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="card-title mb-3">Vendor Details</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "center", alignItems: 'center' }}>
                            <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {vendor.image && vendor.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${vendor.image}`}
          alt="vendor"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}></span>
     
    

  </div>
                            {/* Middle 40% (Name, Email, Address, Phone) */}
  <div style={{ flex: '0 0 40%', textAlign: 'left' }}>
    <div className="mb-2">Name: {vendor.name}</div>
    <div className="mb-2">Email: {vendor.email}</div>
    <div className="mb-2">Phone: {vendor.phone}</div>
    <div className="mb-2">Address: {vendor.address}</div>
  </div>

  {/* Right 30% (PAN, GST) */}
  <div style={{ flex: '0 0 50%', textAlign: 'left', position: 'relative', top: '-30px' }}>
  <div className="mb-2">PAN: {vendor.pan}</div>
    <div className="mb-2">GST: {vendor.gst}</div>
  </div>
</div>
</div>
<br/>
<br/>
        {/* <div className="d-flex align-items-center">
         
          <button
            className="btn btn-primary ml-2"
            onClick={handleAdd}
            style={{
              fontSize: '12px',
              padding: '5px 10px',
            }}
          >
            ADD
          </button>
        </div> */}
       

                            <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)} className="mb-3">
                            <Tab eventKey="contactPersons" title="Contact Persons">
                            <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Contact Persons..." 
                                            value={contactSearchTerm}
                                            onChange={(e) => setContactSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
                                        <Button variant="primary" onClick={handleAdd}
                                         style={{
                                            padding: '5px 10px', 
                                            fontSize: '12px',
                                            marginLeft: 'auto' 
                                        }}>Add Contact</Button>
                                    </div>
                                    <br/>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
                                    ) : (
                                        
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {filteredContacts.length > 0 ? (
                                                    filteredContacts.map((contact) => (
                                                        <tr key={contact.id}>
                                                            <td>{contact.name}</td>
                                                            <td>{contact.email}</td>
                                                            <td>{contact.phone}</td>
                                                            <td>
                                                                <button
                                                                 style={{
                                                                    border: "none",
                                                                    background: "white",
                                                                    size: "150px",
                                                                  }}
                                                                   onClick={() => handleEdit(contact.id)}><BiEditAlt /></button>
                                                                <button 
                                                                style={{
                                                                    border: "none",
                                                                    background: "white",
                                                                    color: "grey",
                                                                    position: "relative",
                                                                    left: "10px",
                                                                    fontSize: "24px",
                                                                  }}
                                                                   onClick={() => confirmDelete(contact.id)}><MdDeleteForever /></button>
                                                                   
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </Tab>

                                <Tab eventKey="stockList" title="Stockist">
                                <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Stockists..." 
                                            value={stockistSearchTerm}
                                            onChange={(e) => setStockistSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
                                        <Button variant="primary" onClick={handleAdd1}
                                         style={{
                                            padding: '5px 10px', // Adjust padding for size
                                            fontSize: '12px', // Adjust font size
                                            marginLeft: 'auto' // Move button to the right end
                                        }}>Link Stockist</Button>
                                    </div>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
                                      ) : filteredStockists.length > 0 ? (
                                        <div className="row">
                                          {filteredStockists.map((stockist) => (
                                            <div className="col-12 mb-4" key={stockist.id}>                                              <div className="card">
                                              <div className="card-body d-flex">
                                                {/* Image - 10% */}
              <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {stockist.stockist.image && stockist.stockist.imageMeta ? (
                  <img
                    className="img-fluid rounded-circle mb-1"
                    src={`data:image/png;base64,${stockist.stockist.image}`}
                    alt="stockist"
                    style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
                  />
                ) : (
                  <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
                )}
              </div>
   
              <div style={{ flex: '0 0 20%', textAlign: 'left' }}>
              <td
  onClick={() => navigate(`/users/stockistViewmodal/${stockist.stockistId}`)}
  style={{
    cursor: 'pointer',
  }}
>
  <h5
    className="card-title"
    style={{
      color: 'black',
      textDecoration: 'none',
    }}
    onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
    onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
  >
    {stockist.stockist.name}
  </h5>
</td>

                <p className="card-text">Email: {stockist.stockist.email}</p>
                <p className="card-text">Phone: {stockist.stockist.phone}</p>
                <p className="card-text">Address: {stockist.stockist.address}</p>
              </div>

 {/* PAN, GST, and Actions */}
 <div style={{ flex: '1', textAlign: 'right', marginTop: '10px', marginRight: '20px' }}>
 <p className="card-text">PAN: {stockist.stockist.pan}</p>
                <p className="card-text">GST: {stockist.stockist.gst}</p>
                <div className="d-flex justify-content-end mt-2">
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                      }}
                                                      onClick={() => handleEdit1(stockist.stockistId)}
                                                    >
                                                      <BiEditAlt />
                                                    </button>
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                        color: 'grey',
                                                        fontSize: '24px',
                                                      }}
                                                      onClick={() => confirmDelete1(stockist.id)}
                                                    >
                                                      <MdDeleteForever />
                                                    </button>
                                                    <button
                                                      onClick={() => handleUpdateVendor(stockist.stockistId)}
                                                      className="btn btn-primary btn-sm"
                                                    >
                                                      VIEW
                                                      </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
       <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
    </div>
  ) : (
    <p className="text-center">No data available</p>
  )}
</Tab>
<Tab eventKey="product" title="Product">
                                <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Products..." 
                                            value={productSearchTerm}
                                            onChange={(e) => setProductSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
                                        <Button variant="primary" onClick={handleAdd2}
                                         style={{
                                            padding: '5px 10px', // Adjust padding for size
                                            fontSize: '12px', // Adjust font size
                                            marginLeft: 'auto' // Move button to the right end
                                        }}>Add Product</Button>
                                    </div>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
) : (
                                        
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th> Product Name</th>
                                                    <th>SKU</th>
                                                    <th>HSN Code</th>
                                                    <th>Base Price</th>
                                                    <th>GST</th>
                                                    <th>Actions</th>
                                                    <th>Stockist Mapping</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                            {filteredProducts.length > 0 ? (
                                                    filteredProducts.map((product) => (
                                                        <tr key={product.id}>
                                                            <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.image && product.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.image}`}
          alt="Product"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: "10px", marginTop: "-34px", display: "inline-block" }}>
      {product.productname}</span>
     
    </div>
  </div>
</td>
                                                            <td>{product.sku}</td>
                                                            <td>{product.hsncode}</td>
                                                            <td>{product.baseprice}</td>
                                                            <td>{product.gstrate}</td>

                                                            <td>
                                      
                                      
                                      
                                      
                                      
                                      
                                  
             
   
      

                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                      }}
                                                      onClick={() => handleEdit2(product.id)}
                                                    >
                                                      <BiEditAlt />
                                                    </button>
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                        color: 'grey',
                                                        fontSize: '24px',
                                                      }}
                                                      onClick={() => confirmDelete2(product.id)}
                                                    >
                                                      <MdDeleteForever />
                                                    </button>
                                                    {/* <button
                                                      onClick={() => handleUpdateVendor(product.id)}
                                                      className="btn btn-primary btn-sm"
                                                    >
                                                      VIEW
                                                      </button> */}
            </td>

            <td>
            {/* <button
                            onClick={() =>
                              openAddMemberModal(product.id, product.productname)
                            }
                          >
                          <IoMdAddCircleOutline />


                          </button> */}
                          
                          <span style={{marginRight:'10px'}}></span>
                          <Button
                                      variant="contained"

                                    onClick={() => handleViewStockist(product.id , product.productname)} // Passing product ID
                                >
                                      <VisibilityIcon />

                                </Button>
            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </Tab>
                               
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        {/* Edit Modal */}
        {isEditModalOpen && (
          <ContacteditModalPopup
            isOpen={isEditModalOpen}
            onRequestClose={closeEditModal}
            vendorId={selectedVendorId}
          />
        )}
        
        {/* Add Contact Modal */}
        {isModalOpen && (
          <Addcontact
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            vendorId={selectedVendorId}
          />
        )}
         {isEditModalOpen1 && (
              <StockisteditModalPopup
                isOpen={isEditModalOpen1}
                onRequestClose={closeEditModal1}
                vendorId={selectedVendorId}
                />
            )}
 {isModalOpen1 && (
              <Addstockist
                isOpen={isModalOpen1}
                onRequestClose={closeaddModal}
                vendorId={selectedVendorId}
                />
            )}
            {isEditModalOpen2 && (
              <StockistproducteditModalPopup
                isOpen={isEditModalOpen2}
                onRequestClose={closeEditModal2}
                vendorId={selectedVendorId}
                />
            )}
 {isModalOpen2 && (
              <AddVendorProduct
                isOpen={isModalOpen2}
                onRequestClose={closeaddproductModal}
                vendorId={selectedVendorId}
                />
            )}
             {isUpdateVendorModalOpen && (
                <StockistcontactpersonViewModal
                  isOpen={isUpdateVendorModalOpen}
                  onRequestClose={closeUpdateVendorModal}
                  StockistId={selectedStockistId}
                  vendorId={vendorId}

                />
              )}
   {isAddMemberModalOpen && selectedProduct && (
        <Stockistmapping
          isOpen={isAddMemberModalOpen}
          onRequestClose={closeAddMemberModal}
          params={{ id: selectedProduct.productId, productname: selectedProduct.productname }}
          vendorId={vendorId}

        />
      )}
       {isViewModalOpen && selectedProduct && (
        <ViewStockistmapping
          isOpen={isViewModalOpen}
          onRequestClose={closeviewModal}
          params={{ id: selectedProduct.productId, productname: selectedProduct.productname }}
          vendorId={vendorId}


        />
      )}

        </div>
    );
}


export default VendorView;
