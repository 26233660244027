import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";

function TrucknumberReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transporterName, setTransporterName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [truckNumbers, setTruckNumbers] = useState([]);
  const [truckNumber, setTruckNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Fetch truck numbers based on selected date
  const fetchTruckNumbers = async (date) => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);
    try {
      const formattedDate = date.toISOString().slice(0, 10);
      const response = await axios.get(`/api/loadin-meta/orgcode=${orgcode}/date=${formattedDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Truck Numbers Response:", response.data);

      if (Array.isArray(response.data)) {
        const truckNumbersData = response.data.map((truck) => ({
          id: truck.id,
          truckNumber: truck.truckInfo,
        }));

        setTruckNumbers(truckNumbersData);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching truck numbers:", error);
      toast.error("Failed to fetch truck numbers");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data from API based on filters
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      return;
    }

    if (!fromDate || !toDate) {
      toast.error("Please enter both From and To dates.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/loadin-meta/orgcode=${orgcode}/truckinfo=${truckNumber}/from=${fromDate}/to=${toDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReportData(response.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch report data.");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!reportData.length) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo to the left side
      const logoWidth = 50;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);

      // Add the name of the report below the logo
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      const reportName = "Transporter Report";
      doc.text(reportName, logoX, logoY + logoHeight + 5);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10;
      doc.text("Vijay Gas Agencies", textX, logoY + 10, { align: 'right' });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      doc.text(addressText, textX, logoY + 20, { align: 'right' });

      const tableData = reportData.map(item => ({
        date: item.date,
        truckinfo: item.truckinfo,
        drivername: item.drivername,
        contact: item.contact,
        taxableValue: item.taxableValue,
        taxes: item.taxes,
        deliveryCharges: item.deliveryCharges,
        invoiceTotal: item.invoiceTotal,
      }));

      doc.autoTable({
        startY: logoY + 40,
        head: [['Date', 'Truck Info', 'Driver Name', 'Contact', 'Taxable Value', 'Taxes', 'Delivery Charges', 'Invoice Total']],
        body: tableData.map(item => [
          item.date,
          item.truckinfo,
          item.drivername,
          item.contact,
          item.taxableValue,
          item.taxes,
          item.deliveryCharges,
          item.invoiceTotal,
        ]),
        theme: 'striped',
        styles: { overflow: 'linebreak' },
      });

      doc.save("transporter_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleTruckNumberChange = (event) => {
    setTruckNumber(event.target.value);
  };

  useEffect(() => {
    if (fromDate) {
      fetchTruckNumbers(new Date(fromDate)); // Fetch truck numbers when date changes
    }
  }, [fromDate]);

  useEffect(() => {
    if (transporterName) {
      getData();
    }
  }, [transporterName]);

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br /><br/>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-raised">
          <div className="card-body">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img className="top-bar__logo" src={img} alt="logo" />
          <div className="ml-3">
            <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
            <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete - 571111</p>
          </div>
        </div>
      </div>

      <br />
      <div>
        <label htmlFor="fromDate">From Date:</label>
        <input
          type="date"
          id="fromDate"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="form-control"
        />
      </div>
      <br />
      <div>
        <label htmlFor="toDate">To Date:</label>
        <input
          type="date"
          id="toDate"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="form-control"
        />
      </div>
      <br />
      <button
        type="button"
        disabled={loading}
        className="btn btn-primary"
        onClick={getData}
      >
        Generate Report
      </button>
      &nbsp;&nbsp;&nbsp;
      <button
        type="button"
        disabled={loading || !reportData.length}
        className="btn btn-secondary"
        onClick={generatePDF}
      >
        {loading ? "Generating PDF..." : "Download PDF"}
      </button>
      {loading && <div className="loading">Loading...</div>}

      <div className="card card-raised mb-3">
        <div className="card-body p-4">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Truck Info</th>
                <th>Driver Name</th>
                <th>Contact</th>
                <th>Taxable Value</th>
                <th>Taxes</th>
                <th>Delivery Charges</th>
                <th>Invoice Total</th>
              </tr>
            </thead>
            <tbody>
              {reportData.length > 0 ? (
                reportData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.truckinfo}</td>
                    <td>{item.drivername}</td>
                    <td>{item.contact}</td>
                    <td>{item.taxableValue}</td>
                    <td>{item.taxes}</td>
                    <td>{item.deliveryCharges}</td>
                    <td>{item.invoiceTotal}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
}

export default TrucknumberReport;
