import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";
import './totalervreport.css';

function Monthlyinvoicereport() {
  const [reportParams, setReportParams] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({
      ...prevState,
      [field]: field === "month" || field === "year" ? parseInt(value, 10) : value,
    }));
  };

  const fetchInvoiceData = async () => {
    const { month, year } = reportParams;
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get(
        `/api/invoicetotal/orgcode=${orgCode}/month=${month}/year=${year}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setInvoiceData(response.data);
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      toast.error("Failed to load invoice data.");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (!invoiceData) {
      toast.error("No data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const products = Object.keys(invoiceData.product_totals);
    const tableColumn = ["Date", "InvoiceID", "Truck Info", ...products];
    const tableRows = [];

    invoiceData.product_totals[products[0]].forEach(({ date, invoiceid, truckinfo }, index) => {
      const rowData = [date, invoiceid, truckinfo];
      products.forEach((product) => {
        const productData = invoiceData.product_totals[product].find(
          (item) => item.invoiceid === invoiceid
        );
        rowData.push(productData ? productData.quantity : 0);
      });
      tableRows.push(rowData);
    });

    const imgData = img;
    const logoWidth = 50;
    const logoHeight = 20;
    const logoX = 10;
    const logoY = 10;
    doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

    doc.setFontSize(18);
    doc.setTextColor(86, 150, 214);
    const textX = doc.internal.pageSize.width - 10;
    const textY = logoY + 10;
    doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
    const addressY = textY + 10;
    doc.text(addressText, textX, addressY, { align: 'right' });

    doc.setFontSize(18);
    doc.setTextColor(86, 150, 214);
    const reportTitle = "Monthly Invoive Report";
    const reportTitleY = logoY + logoHeight + 20;
    doc.text(20, reportTitleY, reportTitle);

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Month: ${reportParams.month}, Year: ${reportParams.year}`, 20, reportTitleY + 10);

    doc.autoTable(tableColumn, tableRows, { startY: reportTitleY + 20 });
    doc.save("erv.pdf");
  };

  return (
    <div>
      <AdminDashboard />
      <br/><br/>
      <div className="col-8 mx-xl-auto px-xl-10">
        <br/><br/> <br/><br/>
        <div className="card card-raised total-erv-card">
          <br />
          <br />
          <div className="card-body">
            {/* <div className="card-title"> InvoiceReport</div> */}

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img className="top-bar__logo" src={img} alt="logo" />
                <div className="ml-3">
                  <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
                  <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete - 571111</p>
                </div>
              </div>
            </div>
            <br/><br/>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="month">Month:</label>
                <input
                  id="month"
                  type="number"
                  min="1"
                  max="12"
                  value={reportParams.month}
                  onChange={(e) => handleInputChange(e, "month")}
                />{" "}
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="year">Year:</label>
                <input
                  id="year"
                  type="number"
                  value={reportParams.year}
                  onChange={(e) => handleInputChange(e, "year")}
                />
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary"
                onClick={fetchInvoiceData}
                disabled={loading}
              >
                Generate Report
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-secondary"
                onClick={generatePDF}
                disabled={!invoiceData}
              >
                Download PDF
              </button>
            </form>
            {loading && <div className="loading">Loading...</div>}
            {invoiceData && (
              <div className="table-container">
                <br />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>InvoiceID</th>
                      <th>Truck Info</th>
                      {Object.keys(invoiceData.product_totals).map((product) => (
                        <th key={product}>{product}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.product_totals[Object.keys(invoiceData.product_totals)[0]].map(
                      ({ date, invoiceid, truckinfo }) => (
                        <tr key={invoiceid}>
                          <td>{date}</td>
                          <td>{invoiceid}</td>
                          <td>{truckinfo}</td>
                          {Object.keys(invoiceData.product_totals).map((productKey) => {
                            const productData = invoiceData.product_totals[productKey].find(
                              (entry) => entry.invoiceid === invoiceid
                            );
                            return <td key={productKey}>{productData ? productData.quantity : 0}</td>;
                          })}
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan="3"><strong>Total Quantity</strong></td>
                      {Object.keys(invoiceData.product_totals).map((product) => {
                        const totalQuantity = invoiceData.product_totals[product].reduce(
                          (sum, { quantity }) => sum + quantity,
                          0
                        );
                        return <td key={`total-${product}`}><strong>{totalQuantity}</strong></td>;
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Monthlyinvoicereport;
