import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { FiInfo } from "react-icons/fi";

const PerformModal = ({ isOpen, onRequestClose, performdate }) => {
  const [attendencedetails, setAttendencedetails] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [sqcDetails, setSqcDetails] = useState(null);
  const [stockValues, setStockValues] = useState(null);
  const [closestocktwo, setClosestocktwo] = useState([]);
  const [projectedValues, setProjectedValues] = useState([]);
  const [openingstock, setOpeningstock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceErrorModal, setAttendanceErrorModal] = useState(false);
  const [stockvaluesErrorModal, setStockValuesErrorModal] = useState(false);
  const [transactionErrorModal, setTransactionErrorModal] = useState(false);
  const [sqcErrorModal, setSqcErrorModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    attendance: '',
    transaction: '',
    sqc: '',
    positiveValues:""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

        // Fetch attendance details
        const attendanceResponse = await axios.get(
          `/api/attendance/checkattendance/orgcode=${orgcode}/date=${performdate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setAttendencedetails(attendanceResponse.data);

        // Fetch transaction details
        const transactionResponse = await axios.get(
          `/api/transactionoutgoing/orgcode=${orgcode}/date=${performdate}/checkAllTransactionOutAccountedInTransactionIn`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setTransactionDetails(transactionResponse.data);

        // Fetch SQC details
        const sqcResponse = await axios.get(
          `/api/sqcform/orgcode=${orgcode}/date=${performdate}/checkAllLoadInTrucksLinkedWithSqc`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSqcDetails(sqcResponse.data);

        const positiveRes = await axios.get(
          `/api/CloseStock/orgcode=${orgcode}/date=${performdate}/checkAllValuesShouldBeInPositive`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setStockValues(positiveRes.data);

        const projectedValues = await axios.get(
          `/api/CloseStockDo/projected-values/orgcode=${orgcode}/date=${performdate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProjectedValues(projectedValues.data.prodDo);

        const openingStockResponse = await axios.get(
          `/api/Openingstock/orgcode=${orgcode}/datetime=${performdate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setOpeningstock(openingStockResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.response ? error.response.data.message : "An error occurred");
        setLoading(false);
      }
    };

    fetchData();
  }, [performdate]);

  // Check if all details are true and set error messages
  const allDetailsTrue = attendencedetails?.allAccounted===true && transactionDetails?.allAccounted===true && sqcDetails?.allAccounted===true && stockValues?.status===true ;

  useEffect(() => {
    // if (!attendencedetails?.attendancestatus) {
    //   setErrorMessages(prev => ({ ...prev, attendance: 'Attendance not marked' }));
    // }
    // if (!transactionDetails) {
    //   setErrorMessages(prev => ({ ...prev, transaction: 'Transactions not accounted' }));
    // }
    // if (!sqcDetails) {
    //   setErrorMessages(prev => ({ ...prev, sqc: 'No frozen SQC form found for some trucks' }));
    // }
  }, []);

  const onclickhandler = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoading(true);
    try {
      const res = await axios.post("/api/CloseStock/update", openingstock, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(res);
      setLoading(false);
      setClosestocktwo(res.data);
      window.location.reload();
      toast.success("Transaction End and Closestock updated Successfully");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const closeAttendanceErrorModal = () => {
    setAttendanceErrorModal(false);
  };
  
  const closeTransactionErrorModal = () => {
    setTransactionErrorModal(false);
  };

  const closeSqcErrorModal = () => {
    setSqcErrorModal(false);
  };

  const closeStockValuesErrorModal = () => {
    setStockValuesErrorModal(false);
  };

  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Perform Day End</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <div>
              <p>Attendance: {attendencedetails.allAccounted===true ? '✓' : '❌'}
              {attendencedetails.allAccounted===false && (
                <span style={{ marginLeft: '40px' }}>
                  <FiInfo onClick={() => setAttendanceErrorModal(true)} />
                </span>
              )}
              </p>
              <p>Transaction: {transactionDetails.allAccounted===true ? '✓' : '❌'}
              {transactionDetails.allAccounted===false && (
                <span style={{ marginLeft: '40px' }}>
                  <FiInfo onClick={() => setTransactionErrorModal(true)} />
                </span>
              )}
              </p>

              <p>SQC Details: {sqcDetails.allAccounted===true ? '✓' : '❌'}
              {sqcDetails.allAccounted===false && (
                <span style={{ marginLeft: '40px' }}>
                  <FiInfo onClick={() => setSqcErrorModal(true)} />
                </span>
                )}
              </p>

              <p>Stock Verification: {stockValues.status===true ? '✓' : '❌'}
              {stockValues.status===false && (
                <span style={{ marginLeft: '40px' }}>
                  <FiInfo onClick={() => setStockValuesErrorModal(true)} />
                </span>
              )}
              </p>
            
              <div>
            <h5>Projected Closing Stock:</h5>
            {projectedValues.length > 0 && (
                  <>
                    {projectedValues.filter(pv => pv.product.productCategory?.productCategoryTypeId === 1).length > 0 && (
                      <>
                       <h5>Refill :</h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th id="full">Full</th>
                            <th id="empty">Empty</th>
                            <th id="empty">Defective</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectedValues.filter(pv => pv.product.productCategory?.productCategoryTypeId === 1).map((products) => (
                            <tr key={products.product.productId}>
                              <th>{products.product.productname}</th>
                              {products.pdo.map((ps, index) => (
                                <th key={index}>{ps.qty}</th>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </>
                    )}
                    
                    {projectedValues.filter(pv => pv.product.productCategory?.productCategoryTypeId === 2).length > 0 && (
                      <>
                      <h5>Sales :</h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th id="quantity">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectedValues.filter(pv => pv.product.productCategory?.productCategoryTypeId === 2).map((products) => (
                            <tr key={products.product.productId}>
                              <th>{products.product.productname}</th>
                              {products.pdo.map((ps, index) => (
                                ps.status === 'full' && (
                                  <td key={index}>{ps.qty}</td>
                                )
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </>
                    )}
                  </>
                )}
              </div>


              {allDetailsTrue && (
               <div style={{ display: 'flex', alignItems: 'center' }}>
    <p style={{ margin: '0 10px 0 0', color:"black" }}>Do you wish to proceed?</p>
                <button className="btn btn-primary" onClick={onclickhandler}>
                  Proceed
                </button>
                </div>
              )}
              </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={attendanceErrorModal} onHide={closeAttendanceErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attendance Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{color:"red"}}>{attendencedetails?.errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAttendanceErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={transactionErrorModal} onHide={closeTransactionErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p style={{color:"red"}}>{transactionDetails?.errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeTransactionErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={sqcErrorModal} onHide={closeSqcErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>SQC Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Convert the error message into an array of lines and map each line to a list item */}
    <ul style={{ color: "red" }}>
      {sqcDetails?.errorMessage?.split('\n').map((line, index) => (
        <li key={index}>{line}</li>
      ))}
    </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeSqcErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={stockvaluesErrorModal} onHide={closeStockValuesErrorModal} centered >
        <Modal.Header closeButton>
          <Modal.Title>Stock Verification Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{color:"red"}}>{stockValues?.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeStockValuesErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PerformModal;
