import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";

function SQCwithinvoiceReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/sqc/invoice/orgcode=${orgcode}/${invoiceNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Full API Response:", response);
      console.log("Data:", response.data);

      setReportData(response.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (reportData.length === 0) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: "a4",
      });

      const imgData = new Image();
      imgData.src = img;

      imgData.onload = () => {
        const logoWidth = 100;
        const logoHeight = 50;
        const logoX = 30;
        const logoY = 20;
        doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

        doc.setFontSize(18);
        doc.setTextColor(86, 150, 214);
        const textX = doc.internal.pageSize.width - 40;
        const textY = logoY + 10;
        doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
        const addressY = textY + 15;
        doc.text(addressText, textX, addressY, { align: "right" });

        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        const invoiceText = `Invoice Number: ${invoiceNumber}`;
        const reportTitle = "SQC Invoice Report";
        const reportY = logoY + logoHeight + 30;
        doc.text(invoiceText, logoX, reportY);
        doc.text(reportTitle, logoX, reportY + 15);

        const tableData = reportData.map((item) => [
          item.productName,
          item.productCode,
          item.serialNumber,
          item.sealIntact,
          item.grossWeight,
          item.tareWeight,
          item.actualWeight,
          `${item.difference} g`,
          item.remarks,
          item.productReturned,
          item.ervNumber,
          item.ervDate,
        ]);

        doc.autoTable({
          startY: reportY + 30,
          head: [
            [
              "Product Name",
              "Product Code",
              "Serial Number",
              "Seal Intact",
              "Gross Weight",
              "Tare Weight",
              "Actual Weight",
              "Difference (g)",
              "Remarks",
              "Product Returned",
              "Erv Number",
              "Erv Date",
            ],
          ],
          body: tableData,
          theme: "striped",
          styles: { overflow: "linebreak" },
        });

        doc.save("sqc_report_landscape.pdf");
      };

      imgData.onerror = () => {
        toast.error("Failed to load logo image.");
      };
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleInvoiceNumberChange = (event) => {
    setInvoiceNumber(event.target.value);
  };

  useEffect(() => {
    if (invoiceNumber) {
      getData();
    }
  }, [invoiceNumber]);

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="container mt-4 d-flex justify-content-end">
        {/* The card container is aligned to the right */}
        <div className="card card-raised" style={{ width: "70%" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <br />

            <div className="form-group">
              <label>Invoice Number:</label>
              <input
                type="text"
                className="form-control"
                value={invoiceNumber}
                onChange={handleInvoiceNumberChange}
                placeholder="Enter Invoice Number"
              />
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={getData}
              disabled={loading || !invoiceNumber}
            >
              {loading ? "Loading..." : "Generate Report"}
            </button>
            {reportData.length > 0 && (
              <button
                className="btn btn-success mt-3 ml-2"
                onClick={generatePDF}
              >
                Download PDF
              </button>
            )}
            <br />
            <br />
            {loading && <div className="loading">Loading...</div>}

            {reportData.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Serial Number</th>
                      <th>Seal Intact</th>
                      <th>Gross Weight</th>
                      <th>Tare Weight</th>
                      <th>Actual Weight</th>
                      <th>Difference (g)</th>
                      <th>Remarks</th>
                      <th>Product Returned</th>
                      <th>Erv Number</th>
                      <th>Erv Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productCode}</td>
                        <td>{item.serialNumber}</td>
                        <td>{item.sealIntact}</td>
                        <td>{item.grossWeight}</td>
                        <td>{item.tareWeight}</td>
                        <td>{item.actualWeight}</td>
                        <td>{`${item.difference} g`}</td>
                        <td>{item.remarks}</td>
                        <td>{item.productReturned}</td>
                        <td>{item.ervNumber}</td>
                        <td>{item.ervDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SQCwithinvoiceReport;
