import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";
import DatePicker from "react-datepicker";
import './totalervreport.css'; // Import your CSS file
import './sqc.css';

function SQCwithtruckinfowithdateReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [truckNumber, setTruckNumber] = useState("");
  const [truckNumbers, setTruckNumbers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [truckinfo, settruckinfo] = useState("");

  const fetchTruckNumbers = async (date) => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);
    try {
      const formattedDate = date.toISOString().slice(0, 10);
      const response = await axios.get(`/api/loadin-meta/getUniqueTruks/orgcode=${orgcode}/date=${formattedDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Unique Truck Numbers Response:", response.data);

      if (Array.isArray(response.data)) {
        const truckNumbersData = response.data.map((truck) => ({
          id: truck.id,
          truckNumber: truck.truckInfo || truck.truckInfo || truck.truckInfo,
        }));

        console.log("Mapped Unique Truck Numbers:", truckNumbersData);

        const filteredTruckNumbers = truckNumbersData.filter(
          (truck) => truck.truckNumber
        );

        setTruckNumbers(filteredTruckNumbers);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching unique truck numbers:", error);
      toast.error("Failed to fetch unique truck numbers");
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    if (!truckNumber) {
      toast.error("Please select a truck number.");
      return;
    }

    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;
    const formattedDate = selectedDate.toISOString().slice(0, 10);

    setLoading(true);
    try {
      const response = await axios.get(
        `/api/sqctruckinf/orgcode=${orgcode}/truckinfo=${truckNumber}/date=${formattedDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Report Data Response:", response.data);

      setReportData(response.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    if (reportData.length === 0) {
        toast.error("No data available to generate PDF.");
        return;
    }

    try {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "pt",
            format: "a4",
        });

        const imgData = new Image();
        imgData.src = img;

        imgData.onload = () => {
            const logoWidth = 100;
            const logoHeight = 50;
            const logoX = 30;
            const logoY = 20;
            doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

            // Set up report information
            const reportName = "SQC Report"; // Set the report name
            const invoiceNumber = "Invoice No: XYZ123"; // Set your invoice number here
            const formattedDate = selectedDate.toLocaleDateString(); // Format the date
            const truckInfoNumber = `Truck Info: ${truckinfo}`; // Add the truck info number
            const selectedTruckNumber = `Truck Number: ${truckNumber}`; // Add the selected truck number

            doc.setFontSize(18);
            doc.setTextColor(86, 150, 214);
            const textX = doc.internal.pageSize.width - 40;
            const textY = logoY + 10;
            doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
            const addressY = textY + 15;
            doc.text(addressText, textX, addressY, { align: "right" });

            doc.setFontSize(16);
            doc.setTextColor(0, 0, 0);
            doc.text(selectedTruckNumber, logoX, logoY + logoHeight + 30); // Truck Number
            doc.text(reportName, logoX, logoY + logoHeight + 60); // Report Name
            doc.text(invoiceNumber, logoX, logoY + logoHeight + 75); // Invoice Number
            doc.text(`Date: ${formattedDate}`, logoX, logoY + logoHeight + 90); // Date

            const tableData = reportData.map((item) => [
                item.productName,
                item.productCode,
                item.serialNumber,
                item.sealIntact,
                item.grossWeight,
                item.tareWeight,
                item.actualWeight,
                `${item.difference} g`,
                item.remarks,
                item.productReturned,
                item.ervNumber,
                item.ervDate,
            ]);

            doc.autoTable({
                startY: logoY + logoHeight + 110, // Adjust start position to below the text
                head: [
                    [
                        "Product Name",
                        "Product Code",
                        "Serial Number",
                        "Seal Intact",
                        "Gross Weight",
                        "Tare Weight",
                        "Actual Weight",
                        "Difference (g)",
                        "Remarks",
                        "Product Returned",
                        "Erv Number",
                        "Erv Date",
                    ],
                ],
                body: tableData,
                theme: "striped",
                styles: { overflow: "linebreak" },
            });

            doc.save("sqc_report_landscape.pdf");
        };

        imgData.onerror = () => {
            toast.error("Failed to load logo image.");
        };
    } catch (error) {
        console.error("Error generating PDF: ", error);
        toast.error("Failed to generate report.");
    }
};

  const handleTruckNumberChange = (event) => {
    setTruckNumber(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchTruckNumbers(date); // Fetch truck numbers when the date changes
  };

  useEffect(() => {
    fetchTruckNumbers(selectedDate); // Fetch truck numbers initially
  }, []);

  useEffect(() => {
    if (truckNumber) {
      getData();
    }
  }, [truckNumber]);

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="container mt-4 d-flex justify-content-end">
        <div className="card card-raised" style={{ width: "70%" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="date-truck-container">
                <div className="table-container">
                  <label>Select Date:</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>
                <div className="table-container">
                  <label>Select Truck Number:</label>
                  <select
                    className="form-control"
                    value={truckNumber}
                    onChange={handleTruckNumberChange}
                  >
                    <option value="">Select Truck Number</option>
                    {truckNumbers.length > 0 ? (
                      truckNumbers.map((truck) => (
                        <option key={truck.id} value={truck.truckNumber}>
                          {truck.truckNumber}
                        </option>
                      ))
                    ) : (
                      <option value="">No truck numbers available</option>
                    )}
                  </select>
                </div>
              </div>
              <br /><br />
              <div className="form-group">
                <button className="btn btn-primary" onClick={getData} disabled={loading}>
                  {loading ? "Loading..." : "Download"}
                </button>
              </div>
              <br />
              <div className="form-group">
                <button className="btn btn-success" onClick={generatePDF} disabled={loading}>
                  {loading ? "Generating PDF..." : "Generate PDF"}
                </button>
              </div>
            </div>
            {loading && <div className="loading">Loading...</div>}

            {reportData.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Serial Number</th>
                      <th>Seal Intact</th>
                      <th>Gross Weight</th>
                      <th>Tare Weight</th>
                      <th>Actual Weight</th>
                      <th>Difference (g)</th>
                      <th>Remarks</th>
                      <th>Product Returned</th>
                      <th>Erv Number</th>
                      <th>Erv Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productCode}</td>
                        <td>{item.serialNumber}</td>
                        <td>{item.sealIntact ? "Yes" : "No"}</td>
                        <td>{item.grossWeight}</td>
                        <td>{item.tareWeight}</td>
                        <td>{item.actualWeight}</td>
                        <td>{item.difference} g</td>
                        <td>{item.remarks}</td>
                        <td>{item.productReturned ? "Yes" : "No"}</td>
                        <td>{item.ervNumber}</td>
                        <td>{item.ervDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No data available for the selected truck and date.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SQCwithtruckinfowithdateReport;
