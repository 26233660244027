import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function ProducteditModalPopup({ isOpen, onRequestClose, productId }) {
  const [uproduct1, setUproduct1] = useState({
    id: "",
    productname: "",
    productcode: "",
    productCategoryTypeId: "",
    customertypeid: "",
    hsnCode: "",
    netWeight: "",
    date: "",
    gst: "",
    image:"",
    imageMeta:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    productCategory: {  
      categoryname: "",
      categoryType: {
        categorytype:"",
    }
    },
    prodType:{
      productType:"",
    },
    consumerType:{
      consumerType:"",
    }
    // productPoints: [
    //   { points: "", pointsType: "sales" },
    //   { points: "", pointsType: "additional" },
    //   { points: "", pointsType: "new connection" },
    // ],
  });

  const [loading, setLoading] = useState(false);
  const [consumerTypes, setConsumerTypes] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      // const response = await axios.get(`/api/product/productid=${productId}`, {
      //   headers: { Authorization: `Bearer ${token}` },
      // });
      const response = await axios.get(`/api/product/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const productData = response.data;
      if (productData.productCategory.categoryType.categorytype === "Sales") {
        productData.netWeight = "0";
      }
      // Ensure productPoints are properly structured
      // if (!productData.productPoints) {
      //   productData.productPoints = [
      //     { points: "", pointsType: "sales" },
      //     { points: "", pointsType: "additional" },
      //     { points: "", pointsType: "new connection" },
      //   ];
      // } else {
      //   const pointsTypes = ["sales", "additional", "new connection"];
      //   pointsTypes.forEach((type) => {
      //     if (!productData.productPoints.find((point) => point.pointsType === type)) {
      //       productData.productPoints.push({ points: "", pointsType: type });
      //     }
      //   });
      // }

      setUproduct1(productData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getConsumerTypes = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get("/api/consumertype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConsumerTypes(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productId) {
      getData();
      getConsumerTypes();
    }
  }, [productId]);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Filter product points for Consumables
    // const payload = {
    //   ...uproduct1,
    //   productPoints: uproduct1.productType === "Consumables" 
    //     ? uproduct1.productPoints.filter((point) => point.pointsType === "sales")
    //     : uproduct1.productPoints,
    // };

    const payload = {
      ...uproduct1,
      netWeight: uproduct1.productCategory?.productCategoryTypeId === 2 ? "0" : uproduct1.netWeight,
    };

    axios
      .post(
        "/api/product/update-product",
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Product updated successfully");
        onRequestClose();
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const rhandle = (event, field) => {
    const actualValue = event.target.value;
    const { id } = event.target;

    // if (id.startsWith("points")) {
    //   const pointsType = id.split("-")[1];
    //   setUproduct1((prevState) => ({
    //     ...prevState,
    //     productPoints: prevState.productPoints.map((point) =>
    //       point.pointsType === pointsType
    //         ? { ...point, points: actualValue }
    //         : point
    //     ),
    //   }));
    // } else {
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    // }
  };
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
     
      setUproduct1(prevState => ({
        ...prevState,
        imageMeta: metadata,
        image: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={(e) => rsubmit(e)}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productCategoryTypeId">Category Name</label>
                    <input
                      className="form-control"
                      id="productCategoryTypeId"
                      type="text"
                      placeholder="Category Name"
                      value={uproduct1.productCategory.categoryname}
                      onChange={(e) => rhandle(e, "productCategoryTypeId")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productCategoryTypeId">Category Type</label>
                    <input
                      className="form-control"
                      id="productCategoryTypeId"
                      type="text"
                      placeholder="Category Name"
                      value={uproduct1.productCategory.categoryType.categorytype}
                      onChange={(e) => rhandle(e, "productCategoryTypeId")}
                      readOnly
                    />
                  </div>
                </div>
                
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productname">Product Name</label>
                    <input
                      className="form-control"
                      id="productname"
                      type="text"
                      placeholder="productname"
                      value={uproduct1.productname}
                      onChange={(e) => rhandle(e, "productname")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productcode">Product Code</label>
                    <input
                      className="form-control"
                      id="productcode"
                      type="text"
                      placeholder="productcode"
                      value={uproduct1.productcode}
                      onChange={(e) => rhandle(e, "productcode")}
                      readOnly
                    />
                  </div>
                </div>
                {uproduct1.productCategory?.productCategoryTypeId !== 2 && (
                <div className="row">
                <div className="form-floating-sm mb-2">
                  <label htmlFor="customertypeid">Customer Type</label>
                  <select
                    className="form-select"
                    id="customertypeid"
                    value={uproduct1.customertypeid}
                    onChange={(e) => rhandle(e, "customertypeid")}
                  >
                    <option value="">Select Customer Type</option>
                    {consumerTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.consumerType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
                )}
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="hsnCode">HSN Code</label>
                    <input
                      className="form-control"
                      id="hsnCode"
                      type="text"
                      placeholder="HSN Code"
                      value={uproduct1.hsnCode}
                      onChange={(e) => rhandle(e, "hsnCode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gst">GST</label>
                    <input
                      className="form-control"
                      id="gst"
                      type="text"
                      placeholder="0"
                      value={uproduct1.gst}
                      onChange={(e) => rhandle(e, "gst")}
                    />
                  </div>
                </div>
                {uproduct1.productCategory?.productCategoryTypeId !== 2 && (
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="netWeight">Net Weight</label>
                    <input
                      className="form-control"
                      id="netWeight"
                      type="text"
                      placeholder="0"
                      value={uproduct1.netWeight}
                      onChange={(e) => rhandle(e, "netWeight")}
                      // disabled={uproduct1.productTypeId !== "1"}
                    />
                  </div>
                </div>
                )}
                {/* <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="points-sales">Sales Points</label>
                    <input
                      className="form-control"
                      id="points-sales"
                      type="text"
                      placeholder="0"
                      value={uproduct1.productPoints.find((point) => point.pointsType === "sales")?.points ?? ""}
                      onChange={(e) => rhandle(e, "sales")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="points-additional">Additional Points</label>
                    <input
                      className="form-control"
                      id="points-additional"
                      type="text"
                      placeholder="0"
                      value={uproduct1.productPoints.find((point) => point.pointsType === "additional")?.points ?? ""}
                      onChange={(e) => rhandle(e, "additional")}
                      disabled={uproduct1.productType === "Consumables"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="points-new connection">New Connection Points</label>
                    <input
                      className="form-control"
                      id="points-new connection"
                      type="text"
                      placeholder="0"
                      value={uproduct1.productPoints.find((point) => point.pointsType === "new connection")?.points ?? ""}
                      onChange={(e) => rhandle(e, "new connection")}
                      disabled={uproduct1.productType === "Consumables"}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="image">Upload Image</label>
    {uproduct1.image && (
                  <div>
                    <img
                      src={`data:${uproduct1.imageMeta};base64,${uproduct1.image}`}
                      alt="Uploaded"
                      style={{ width: "40px", height: "auto" }}
                    />
                  </div>
                )}
                {uproduct1.imageMeta && (
                  <div>
                    <p>
                      {/* <strong>Image Metadata:</strong> {uproduct1.imageMeta} */}
                    </p>
                  </div>
                )}
    <input
      className="form-control"
      id="image"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>


                <br />
                <input type="submit" value="Update" className="btn btn-primary" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ProducteditModalPopup;
