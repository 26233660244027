import { useState, useEffect } from 'react';
import AdminDashboard from './admindashboard';
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Tooltip, IconButton } from '@mui/material';

const CreditNote = () => {
  const [loader, setLoader] = useState(false);
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  const [orgAddress, setOrgAddress] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [customers, setCustomers] = useState([]);
  const [tariffCharge, setTariffCharge] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);

    getData();
    fetchOrgAddress();
    fetchExemptedCustomers();
  }, []);

  const getData = async () => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/product/getProductAndPriceDetails/orgcode=${orgcode}/productstatus=${true}/productCategoryTpeId=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const fetchOrgAddress = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { addressLine1, addressLine2, name, state, city, pincode, gstin, pan, contact } = response.data;
      setOrgAddress({
        name,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        gstin,
        pan,
        contact
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    console.log('Fetched Customers:', customers);
  }, [customers]);


  const fetchExemptedCustomers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/exemptedcustomer/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomers(response.data);

    } catch (error) {
      handleError(error);
    }
  };

  const handleCustomerChange = (event) => {
    const selectedCustomerId = event.target.value;
    const selectedCustomerData = customers.find((customer) => customer.id.toString() === selectedCustomerId); // Convert to string
    setSelectedCustomer(selectedCustomerData);
    setTariffCharge(selectedCustomerData ? selectedCustomerData.tariff : 0);
  };



  const handleAddProduct = () => {
    setSelectedProducts([...selectedProducts, { product: '', quantity: 1, amount: '', calculatedAmount: '' }]);
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...selectedProducts];

    if (field === 'product') {
      updatedProducts[index][field] = value;
      const selectedProductData = product.find((prod) => prod.productname === value);
      updatedProducts[index].amount = selectedProductData ? selectedProductData.pprice.price : '';
      updatedProducts[index].calculatedAmount = selectedProductData ? selectedProductData.pprice.price * updatedProducts[index].quantity : '';
    } else if (field === 'quantity') {
      updatedProducts[index][field] = value;
      const baseAmount = updatedProducts[index].amount || 0;
      updatedProducts[index].calculatedAmount = baseAmount * value;
    } else {
      updatedProducts[index][field] = value;
    }

    setSelectedProducts(updatedProducts);
  };

  const calculateTotalQuantity = () => {
    return selectedProducts.reduce((total, item) => total + (parseInt(item.quantity) || 0), 0);
  };

  const calculateTotalAmount = () => {
    const totalProductAmount = selectedProducts.reduce((total, item) => total + (item.calculatedAmount || 0), 0);
    const totalTariffAmount = tariffCharge * calculateTotalQuantity();
    return totalProductAmount + totalTariffAmount;
  };




  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
  };


  return (
    <div>
      <AdminDashboard className="no-print" />
      <br /><br /><br /><br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Tooltip title="Back" arrow className="no-print">
          <IconButton onClick={() => navigate(-1)} style={{ paddingLeft: '10px', marginLeft: '20px' }}>
            <AiOutlineArrowLeft style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
        <div style={{
          width: '70%',
          backgroundColor: '#fff',
          margin: '0 auto',
          padding: '20px',
          border: '1px solid #000',
          fontFamily: 'Arial, sans-serif',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}>
          {/* Header */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #000', paddingBottom: '10px' }}>
            <div>
              {/* Logo Image */}
              {orgAddress?.logo && (
                <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`data:image/png;base64,${orgAddress.logo}`}
                  alt="Company Logo"
                  style={{ width: '80px', height: '80px', marginRight: '15px' }}
                />
              )}

              <h2 style={{ margin: 0, fontSize: '1.5rem' }}>{orgAddress?.name || ''}</h2>
              <br />
              <p>{orgAddress?.addressLine1 || ''}</p>
              <p>{orgAddress?.addressLine2 || ''}</p>
              <p>{orgAddress ? `${orgAddress.city}, ${orgAddress.state} - ${orgAddress.pincode}` : ''}</p>
              <p>GSTIN: {orgAddress?.gstin || ''}</p>
            </div>
            <div style={{ textAlign: 'right' }}>
              <p>Date: <input type="text" value={currentDate} placeholder="DD/MM/YYYY" style={{ border: 'none', borderBottom: '1px dashed #000', outline: 'none', width: '120px' }} /></p>
              <h3 style={{ margin: 0 }}>Credit Note</h3>
            </div>
          </div>

          {/* To Section */}
          <div style={{ margin: '20px 0' }}>
            <p>To</p>
            <select
              style={{ width: '100%', border: 'none', borderBottom: '1px dashed #000', outline: 'none', margin: '5px 0' }}
              value={selectedCustomer ? selectedCustomer.id : ""}
              onChange={handleCustomerChange}
            >
              <option value="" disabled>Select Customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.customername} - {customer.customerNumber}
                  {/* <p>{customer.customercategory}</p> */}
                </option>
              ))}
            </select>
          </div>

          {/* Credit Note Details */}
          <div style={{ margin: '20px 0', padding: '10px', border: '1px solid #000' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left' }}>Description</th>
                  <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'right' }}>Quantity</th>
                  <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'right' }}> Base Amount</th>
                  <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'right' }}>  Amount</th>
                  <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'center' }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px 5px', borderBottom: '1px dashed #000' }}>
                      <select
                        value={item.product}
                        onChange={(e) => handleProductChange(index, 'product', e.target.value)}
                        style={{ width: '100%', border: 'none', outline: 'none' }}
                      >
                        <option value="">Select Product/Service</option>
                        {product.map((prod) => (
                          <option key={prod.id} value={prod.productname}>{prod.productname}</option>
                        ))}
                      </select>
                    </td>
                    <td style={{ padding: '10px 5px', textAlign: 'right', borderBottom: '1px dashed #000' }}>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                        placeholder="Qty"
                        style={{ width: '60px', border: 'none', outline: 'none', textAlign: 'right' }}
                      />
                    </td>
                    <td style={{ padding: '10px 5px', textAlign: 'right', borderBottom: '1px dashed #000' }}>
                      <input
                        type="text"
                        value={item.amount}
                        onChange={(e) => handleProductChange(index, 'amount', e.target.value)}
                        placeholder="Amount"
                        style={{ width: '100px', border: 'none', outline: 'none', textAlign: 'right' }}
                      />
                    </td>
                    <td style={{ padding: '10px 5px', textAlign: 'right', borderBottom: '1px dashed #000' }}>
                      {item.calculatedAmount || 0}
                    </td>
                    <td style={{ padding: '10px 5px', textAlign: 'center', borderBottom: '1px dashed #000' }}>
                      <Tooltip title="Remove" arrow>
                        <IconButton onClick={() => handleRemoveProduct(index)}>
                          <span style={{ color: 'red', fontSize: '18px' }}>X</span>
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button onClick={handleAddProduct} style={{ marginTop: '10px' }}>Add Another Product</button>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0, marginRight: '10px' }}>Tariff Charge</p>
                <input
                  id="tariff"
                  type="number"
                  value={tariffCharge}
                  onChange={(e) => setTariffCharge(e.target.value)}
                  style={{ width: '80px', padding: '5px', border: '1px solid #ccc' }}
                />
              </div>
            </div>
            <p style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', marginTop: '10px' }}>
              Total Amount: {calculateTotalAmount()}
            </p>


            {/* Footer */}
            <div style={{ marginTop: '20px' }}>
              <p>(Amount in words: <input type="text" style={{ width: '90%', border: 'none', borderBottom: '1px dashed #000', outline: 'none' }} />)</p>
              <p style={{ fontStyle: 'italic', textAlign: 'center', margin: '20px 0' }}>Note: This credit note is applicable only for the date mentioned above.</p>
              <p style={{ textAlign: 'center', marginTop: '30px' }}>For {orgAddress?.name || 'Your Company Name'}</p>
              <p style={{ textAlign: 'center' }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNote;
