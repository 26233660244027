import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";

import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

import Pagination from 'react-bootstrap/Pagination'; 
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import Addstockistperson from "./AddStockistperson";
import StockistpersoneditModalPopup from "./Stockistpersonedit";
function Stocklistpersoncomponent() {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);
  const [stockList, setStockList] = useState([]); // Holds stock list data
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

 useEffect(() => {
  if (searchTerm) {
    searchData(); // Call search function when there is a search term
} else {
    getData(currentPage);  // Fetch default data when searchTerm is cleared
  }
}, [searchTerm, currentPage]);
 
  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const searchUrl =`/api/stocklist/orgcode=${orgCode}`;
      const response = await axios.get(searchUrl,  {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStockList(response.data);
      setLoader(false);

    } catch (error) {
      handleError(error);
    }
  };
  const getData = async (page = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.get(`/api/stocklist/stock/orgcode=${orgCode}?page=${page}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStockList(response.data.content);
      setCurrentPage(response.data.number);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleError = (error) => {
    const errorMsg = error.response?.data?.message || "An error occurred while fetching data";
    toast.error(errorMsg);
  };

  useEffect(() => {
    getData();
  }, [isCreateModalOpen]);

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleCategoryEdit = (Id) => {
    setSelectedCategoryId(Id);
    setIsCategoryEditModalOpen(true);
  };

  const closeCategoryEditModal = () => {
    setIsCategoryEditModalOpen(false);
    setSelectedCategoryId(null);
    getData();
  };

  const onClickHandler = async (id) => {
    setLoading(id);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.delete("/api/stocklist", {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id,
          orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        },
      });
      toast.success("Deleted successfully");
      getData();
    } catch (error) {
      toast.error("Failed to delete");
    } finally {
      setLoading(false);
    }
  };

  const filteredStockList = stockList.filter((stock) =>
    stock.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stock.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFirst = () => getData(0);
  const handlePrevious = () => getData(currentPage - 1);
  const handleNext = () => getData(currentPage + 1);
  const handleLast = () => getData(totalPages - 1);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);  
    let endPage = Math.min(totalPages - 1, currentPage + 2); 

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => getData(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
  const handleVendorNameClick = (stockistId) => {
    console.log(stockistId);
    navigate(`/users/stockistViewmodal/${stockistId}`);
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Stockist Details
              <input
                type="text"
                className="form-control"
                style={{ width: "200px", marginLeft: "20px" }}
                placeholder="Search Stockist"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button>
            </div>

            {loader && <div className="loading"></div>}
            <br/>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "blue", color: "white" }}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>PAN</th>
                    <th>GST</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStockList.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredStockList.map((item) => (
                      <tr key={item.id}>
                          <td>
                         <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {item.image && item.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${item.image}`}
          alt="Product"
          style={{ maxWidth: "50px", maxHeight: "50px", marginRight: '15px' }}
        />
      ) : (
        <div style={{ width: "20px", height: "20px", marginRight: '15px' }}></div>
      )}
      <span style={{ marginRight: "15px", marginTop: "10px", display: "inline-block" }}>
      <span
    style={{
      color: 'black',
      textDecoration: 'none',
      cursor: 'pointer',
    }}
    onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
    onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
    onClick={() => handleVendorNameClick(item.id)}
  >
    {item.name}
  </span>
      </span>
     
    </div>
  </div>
</td>
                      
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.address}</td>
                        <td>{item.pan}</td>
                        <td>{item.gst}%</td>

                        <td style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-around" }}>
  <button
    onClick={() => handleCategoryEdit(item.id)}
    style={{ border: "none", background: "white", marginRight: "10px" }}  // Add margin for spacing
  >
    <BiEditAlt />
  </button>
  <button
    style={{
      border: "none",
      background: "white",
      color: "grey",
      fontSize: "24px",
    }}
    onClick={() => onClickHandler(item.id)}
  >
    <MdDeleteForever />
  </button>
</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination controls */}
            <Pagination style={{ justifyContent: 'center', flexGrow: 1 }}>
              <Pagination.First onClick={handleFirst} disabled={currentPage === 0} />
              <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 0} />
              {renderPageNumbers()}
              <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages - 1} />
              <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages - 1} />
            </Pagination>

            {isCategoryEditModalOpen && (
              <StockistpersoneditModalPopup
                isOpen={isCategoryEditModalOpen}
                onRequestClose={closeCategoryEditModal}
                Id={selectedCategoryId}
              />
            )}

            {isCreateModalOpen && (
              <Addstockistperson isOpen={isCreateModalOpen} onRequestClose={handleCreateModalClose} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stocklistpersoncomponent;
