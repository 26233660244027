import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";

const RefillOfficeSalesModalOpen = ({ isOpen, onRequestClose, date }) => {
    const [exemptedDetails, setExemptedDetails] = useState([]);
    const [addDet, setAddDet] = useState([]);
    const [newDet, setNewDet] = useState([]);
    const [onlineDetails, setOnlineDetails] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [exemptedcustomer, setExemptedcustomer] = useState([]);
    const [exemptedcategory, setExemptedcategory] = useState([]);
    const [printData, setPrintData] = useState(null); // State to store print data
    const [officeConsumablesModalIsOpen, setOfficeConsumablesModalIsOpen] = useState(false);
    const [consumableDetails, setConsumableDetails] = useState([]);

  const [refillSales , setRefillSales] = useState({
    date:date,
    productName: "",
    productCode: "",
    sale: 0,
    newconnection: 0,
    additional: 0,
    exempted: 0,
    onlinepay: 0,
    transfer: 0,
    total: 0,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const tokenData = JSON.parse(localStorage.getItem("data"));
  const token = tokenData.token;
  const orgCode = tokenData.orgcode;

  const [exdetails, setExdetails] = useState({
    customername: "",
    deliveredqty: "",
    productname: "",
    productcode:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    date: date,
    officeSalesId:""
  });
 
  const [onlinedetails, setOnlinedetails] = useState({
    customername: "",
    customernumber: "",
    ordernumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phonenumber: "",
    productname: "",
    date:date,
    officeSalesId:""
  });
 
  const [additionalDetails, setAdditionalDetails] = useState({
    customerName: "",
    customerNumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phoneNumber: "",
    productName:"",
    date:date,
    officeSalesId:""
  });

  const [newConDetails, setNewConDetails] = useState({
    customerName: "",
    customerNumber: "",
    quantity: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    phoneNumber: "",
    productName:"",
    date:date,
    officeSalesId:""
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [beyondlpg, setBeyondlpg] = useState([]);

  const getData = async () => {
    setLoading(true);
  
      try {
        const productResponse = await axios.get(
          `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=1`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setProducts(productResponse.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
  };
  
  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    calculateTotal();
  }, [refillSales]);


   //Exempted
   const addToExemptedList = () => {
    
    if (exdetails.categoryname && exdetails.customername && exdetails.deliveredqty) {
      const newData = {
        ...exdetails,
        date: refillSales.date,
        productname: refillSales.productName, // Set selected product name
        productcode: refillSales.productCode,
      };
  
      const updatedExemptedDetails = [...exemptedDetails, newData];
      setExemptedDetails(updatedExemptedDetails);
  
      const totalDeliveredQtyByProduct = updatedExemptedDetails.reduce((acc, item) => {
        const parsedQty = parseInt(item.deliveredqty);
        if (!isNaN(parsedQty)) {
          acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      const totalExemptedQuantity = Object.values(totalDeliveredQtyByProduct).reduce((acc, qty) => acc + qty, 0);
  
      setRefillSales(prevTransaction => ({
        ...prevTransaction,
        exempted: totalExemptedQuantity,
        products: Array.isArray(prevTransaction.products)
          ? prevTransaction.products.map(product => ({
              ...product,
              exempted: totalDeliveredQtyByProduct[product.productname] || 0,
            }))
          : [], // Initialize as an empty array if undefined
      }));
  
      setExdetails({
        categoryname: '',
        customername: '',
        deliveredqty: '',
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: date,
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  
  // Function to handle exempted delete
  const handleExemtedDelete = (index) => {
    const quantityToDelete = parseInt(exemptedDetails[index].deliveredqty);

    setExemptedDetails(prev => {
        const updatedExemptedDetails = prev.filter((_, i) => i !== index);

        // Update refillSales after deleting an exempted item
        const totalDeliveredQtyByProduct = updatedExemptedDetails.reduce((acc, item) => {
            const parsedQty = parseInt(item.deliveredqty);
            if (!isNaN(parsedQty)) {
                acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
            }
            return acc;
        }, {});

        const totalExemptedQuantity = Object.values(totalDeliveredQtyByProduct).reduce((acc, qty) => acc + qty, 0);

        setRefillSales(prevTransaction => ({
            ...prevTransaction,
            exempted: totalExemptedQuantity,
            products: Array.isArray(prevTransaction.products)
                ? prevTransaction.products.map(product => ({
                    ...product,
                    exempted: totalDeliveredQtyByProduct[product.productname] || 0,
                }))
                : [], // Initialize as an empty array if undefined
        }));

        return updatedExemptedDetails;
    });
};
 
  const addToAdditionalList = () => {
    if (
      additionalDetails.customerName &&
      additionalDetails.customerNumber &&
      additionalDetails.quantity &&
      additionalDetails.phoneNumber &&
      refillSales.productName   
      ) {
      const newData = {
        ...additionalDetails,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: refillSales.date,
        productName: refillSales.productName, // Set selected product name
            productCode: refillSales.productCode, // Set selected product code if needed
        };
  
      const updatedAddDet = [...addDet, newData];
      setAddDet(updatedAddDet);
  
      const totalQtyByProduct = updatedAddDet.reduce((acc, item) => {
        const parsedQty = parseInt(item.quantity);
        if (!isNaN(parsedQty)) {
          acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
        }
        return acc;
      }, {});
  
      const totalAdditionalQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);
  
      setRefillSales((prevTransaction) => ({
        ...prevTransaction,
        additional: totalAdditionalQuantity,
        products: prevTransaction.products ? prevTransaction.products.map((product) => ({
          ...product,
          additional: totalQtyByProduct[product.productName] || 0,
        })) : [],
      }));
  
      setAdditionalDetails({
        customerName: '',
        customerNumber: '',
        productName: '',
        quantity: '',
        phoneNumber: ''
      });
    } else {
      alert("Please fill in all required fields");
    }
  };
  
  const handleAdditionalDelete = (index) => {
    const quantityToDelete = parseInt(addDet[index].quantity);

    setAddDet((prevAddDet) => {
        const updatedList = prevAddDet.filter((_, i) => i !== index);

        // Update refillSales after deleting an additional item
        const totalQtyByProduct = updatedList.reduce((acc, item) => {
            const parsedQty = parseInt(item.quantity);
            if (!isNaN(parsedQty)) {
                acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
            }
            return acc;
        }, {});

        const totalAdditionalQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);

        setRefillSales((prevTransaction) => ({
            ...prevTransaction,
            additional: totalAdditionalQuantity,
            products: prevTransaction.products ? prevTransaction.products.map((product) => ({
                ...product,
                additional: totalQtyByProduct[product.productName] || 0,
            })) : [],
        }));

        return updatedList;
    });
};

const addToNewConList = () => {
  if (
    newConDetails.customerName &&
    newConDetails.customerNumber &&
    newConDetails.quantity &&
    newConDetails.phoneNumber &&
    refillSales.productName   
    ) {
    const newData = {
      ...newConDetails,
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      date: refillSales.date,
      productName: refillSales.productName, // Set selected product name
          productCode: refillSales.productCode, // Set selected product code if needed
      };

    const updatedNewDet = [...newDet, newData];
    setNewDet(updatedNewDet);

    const totalQtyByProduct = updatedNewDet.reduce((acc, item) => {
      const parsedQty = parseInt(item.quantity);
      if (!isNaN(parsedQty)) {
        acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
      }
      return acc;
    }, {});

    const totalNewConQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);

    setRefillSales((prevTransaction) => ({
      ...prevTransaction,
      newconnection: totalNewConQuantity,
      products: prevTransaction.products ? prevTransaction.products.map((product) => ({
        ...product,
        newconnection: totalQtyByProduct[product.productName] || 0,
      })) : [],
    }));

    setNewConDetails({
      customerName: '',
      customerNumber: '',
      productName: '',
      quantity: '',
      phoneNumber: ''
    });
  } else {
    alert("Please fill in all required fields");
  }
};

const handleNewConDelete = (index) => {
  const quantityToDelete = parseInt(newDet[index].quantity);

  setNewDet((prevNewDet) => {
      const updatedList = prevNewDet.filter((_, i) => i !== index);

      // Update refillSales after deleting an additional item
      const totalQtyByProduct = updatedList.reduce((acc, item) => {
          const parsedQty = parseInt(item.quantity);
          if (!isNaN(parsedQty)) {
              acc[item.productName] = (acc[item.productName] || 0) + parsedQty;
          }
          return acc;
      }, {});

      const totalNewConQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);

      setRefillSales((prevTransaction) => ({
          ...prevTransaction,
          newconnection: totalNewConQuantity,
          products: prevTransaction.products ? prevTransaction.products.map((product) => ({
              ...product,
              newconnection: totalQtyByProduct[product.productName] || 0,
          })) : [],
      }));

      return updatedList;
  });
};
 
const addToOnlineDetailsList = () => {
  if (
      onlinedetails.customername &&
      onlinedetails.customernumber &&
      onlinedetails.ordernumber &&
      onlinedetails.quantity &&
      onlinedetails.phonenumber &&
      refillSales.productName // Ensure product name is set in refillSales
  ) {
      const newData = {
          ...onlinedetails,
          date: refillSales.date,
          productname: refillSales.productName, // Set selected product name
          productCode: refillSales.productCode, // Set selected product code if needed
      };

      const updatedOnlineDetails = [...onlineDetails, newData];
      setOnlineDetails(updatedOnlineDetails);

      // Update refillSales with total online pay quantity and per product online pay quantities
      const totalQtyByProduct = updatedOnlineDetails.reduce((acc, item) => {
          const parsedQty = parseInt(item.quantity);
          if (!isNaN(parsedQty)) {
              acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
          }
          return acc;
      }, {});

      const totalOnlineQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);

      setRefillSales(prevTransaction => ({
          ...prevTransaction,
          onlinepay: totalOnlineQuantity,
          products: prevTransaction.products ? prevTransaction.products.map(product => ({
              ...product,
              onlinepay: totalQtyByProduct[product.productname] || 0,
          })) : [],
      }));

      setOnlinedetails({
          customername: '',
          customernumber: '',
          ordernumber: '',
          productname: '',
          quantity: '',
          phonenumber: '',
          orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
          date: date,
      });
  } else {
      alert("Please fill in all required fields");
  }
};

const handleOnlineDelete = (index) => {
  const quantityToDelete = parseInt(onlineDetails[index].quantity);

  setOnlineDetails(prevOnlineDetails => {
      const updatedList = prevOnlineDetails.filter((_, i) => i !== index);

      // Update refillSales after deleting an online pay item
      const totalQtyByProduct = updatedList.reduce((acc, item) => {
          const parsedQty = parseInt(item.quantity);
          if (!isNaN(parsedQty)) {
              acc[item.productname] = (acc[item.productname] || 0) + parsedQty;
          }
          return acc;
      }, {});

      const totalOnlineQuantity = Object.values(totalQtyByProduct).reduce((acc, qty) => acc + qty, 0);

      setRefillSales(prevTransaction => ({
          ...prevTransaction,
          onlinepay: totalOnlineQuantity,
          products: prevTransaction.products ? prevTransaction.products.map(product => ({
              ...product,
              onlinepay: totalQtyByProduct[product.productname] || 0,
          })) : [],
      }));

      return updatedList;
  });
};
  
  const handleChange1 = async (e) => {
    const { id, value } = e.target;

    if (id === "productName") {
      const selectedProduct = products.find((product) => product.productname === value);
      if (selectedProduct) {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgId = JSON.parse(localStorage.getItem("data")).orgcode;
          let price = 0;
          let additionalPrice = 0;
          let newConPrice = 0;

          // Fetch price from api/officefinancial
          const financialResponse = await axios.post(
            '/api/officefinancial/getbyproductname',
            { orgid: orgId, productname: value, date: refillSales.date },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (financialResponse.data) {
            price = financialResponse.data.salecost || 0;
            additionalPrice = financialResponse.data.additionalcost || 0;
            newConPrice = financialResponse.data.newconection || 0;
          }

          // Fetch price from api/product if not available
          if (!price && !additionalPrice && !newConPrice) {
            const productResponse = await axios.get(
              `/api/product/orgcode=${orgId}/productcode=${selectedProduct.productcode}/date=${refillSales.date}`,
              { headers: { Authorization: `Bearer ${token}` } }
            );

            price = productResponse.data.pprice?.price || 0;
            additionalPrice = productResponse.data.addPrice?.additionalPrice || 0;
            newConPrice = productResponse.data.newConPrice?.newConPrice || 0;
          }

          setRefillSales((prevData) => ({
            ...prevData,
            [id]: value,
            productCode: selectedProduct.productcode,
            price: price,
            additionalPrice: additionalPrice,
            newConPrice: newConPrice,
          }));
        } catch (error) {
          console.error("Error fetching price:", error);
        }
      }
    } else {
      setRefillSales((prevData) => ({
        ...prevData,
        [id]: value || 0,
      }));
    }
  };

  const calculateTotal = () => {
    const {
      sale,
      additional,
      newconnection,
      price,
      additionalPrice,
      newConPrice,
    } = refillSales;
    const total =
      (sale || 0) * (price || 0) +
      (additional || 0) * (additionalPrice || 0) +
      (newconnection || 0) * (newConPrice || 0);
  
    setRefillSales((prevData) => {
      if (prevData.total !== total) {
        return {
          ...prevData,
          total: total,
        };
      }
      return prevData; // No change, so no state update
    });
  };
  
const handleSubmit1 = async (e) => {
  e.preventDefault();
  setLoading(true);
  const token = JSON.parse(localStorage.getItem("data")).token;

  try {
    // Step 1: Save refill sales details
    const salesResponse = await axios.post(
      `/api/refillproduct/create`,
      refillSales,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const officeSalesId = salesResponse.data.id; // Assuming salesResponse contains an 'id'

    // Step 2: Save exempted details
    if (exemptedDetails.length > 0) {
      const formattedExemptedDetails = Array.isArray(exemptedDetails) ? exemptedDetails : [exemptedDetails];
      await axios.post(
        `/api/ExemptedDetails/create`,
        formattedExemptedDetails.map(detail => ({
          ...detail,
          officeSalesId
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Exempted details created");
      setExemptedDetails([]);
    }

    // Step 3: Save additional details
    if (addDet.length > 0) {
      const formattedAdditionalDetails = Array.isArray(addDet) ? addDet : [addDet];
      await axios.post(
        `/api/additionaldetails/create`,
        formattedAdditionalDetails.map(detail => ({
          ...detail,
          officeSalesId
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Additional details created");
      setAddDet([]);
    }

    // Step 4: Save online details
    if (onlineDetails.length > 0) {
      const formattedOnlineDetails = Array.isArray(onlineDetails) ? onlineDetails : [onlineDetails];
      await axios.post(
        `/api/OnlineDetail/create`,
        formattedOnlineDetails.map(detail => ({
          ...detail,
          officeSalesId
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Online details created");
      setOnlineDetails([]);
    }

    // Step 3: Save new con details
    if (newDet.length > 0) {
      const formattedNewConDetails = Array.isArray(newDet) ? newDet : [newDet];
      await axios.post(
        `/api/newconnectiondetails/create`,
        formattedNewConDetails.map(detail => ({
          ...detail,
          officeSalesId
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("New connection details created");
      setNewDet([]);
    }

    toast.success("Data saved successfully!");
    onRequestClose();
  } catch (error) {
    console.error("Error saving data:", error);
    toast.error("Failed to save data. Please try again.");
  } finally {
    setLoading(false);
  }
};
 
  const [exemptedModalIsOpen, setExemptedModalIsOpen] = useState(false);
  const [additionalModalIsOpen, setAdditionalModalIsOpen] = useState(false);
  const [onlinePayModalIsOpen, setOnlinePayModalIsOpen] = useState(false);
  const [newConnectionModalIsOpen, setNewConnectionModalIsOpen] = useState(false);
 
const openExemptedModal = () => {
  setExemptedModalIsOpen(true);
};
 
const closeExemptedModal = () => {
  setExemptedModalIsOpen(false);
};
 
 
const openAdditionalModal = () => {
  setAdditionalModalIsOpen(true);
};
 
const closeAdditionalModal = () => {
  setAdditionalModalIsOpen(false);
  calculateTotal();
};

const openNewConModal = () => {
  setNewConnectionModalIsOpen(true);
};
 
const closeNewConModal = () => {
  setNewConnectionModalIsOpen(false);
  calculateTotal();
};
const openOnlinepayModal = () => {
  setOnlinePayModalIsOpen(true);
};
 
const closeOnlinepayModal = () => {
  setOnlinePayModalIsOpen(false);
};
 
const handleCustomer = async (selectedCategory) => {
  try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
 
      const entity = {
          orgcode: orgcode,
          customercategory: selectedCategory,
          disable: false,
      };
 
      const response = await axios.post(
          '/api/exemptedcustomer/exemptedcustomercategory', entity, {
            headers: { Authorization: `Bearer ${token}` },
        })
 
      setExemptedcustomer(response.data);
  } catch (error) {
      console.error(error);
  }
}
 
 
 
//exempted
const handleChangeExempted = (e, field) => {
  setExdetails({
    ...exdetails,
    [field]: e.target.value,
  });
};

const handleChangeOnlinepay = (e, field) => {
  setOnlinedetails({
    ...onlinedetails,
    [field]: e.target.value,
  });
};
 
const handleChangeAdditional = (e, field) => {
  setAdditionalDetails({
    ...additionalDetails,
    [field]: e.target.value,
  });
};
const handleChangeNewCon = (e, field) => {
  setNewConDetails({
    ...newConDetails,
    [field]: e.target.value,
  });
};

  return (
     <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
     <Modal.Header closeButton>
       <Modal.Title>Create Refill Sales</Modal.Title>
     </Modal.Header>
     <Modal.Body>
     <div className="col-xl-10 mx-xl-auto">
         <div className="card card-raised mb-3">
           <div className="card-body p-4">
             <form onSubmit={handleSubmit1}>
               <div className="row mb-3">
                 <div className="form-floating-sm">
 
                 <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="date"
                      value={refillSales.date}
                      onChange={handleChange1}
                    />
                  </div>
                </div>
                   <label htmlFor="productname">Product Name</label>
                   <select
                     className="form-select"
                     id="productName"
                     value={refillSales.productName}
                     onChange={handleChange1}
                   >
                     <option value="">Select Product Name</option>
                     {products.map((product) => (
                       <option key={product.id} value={product.productname}>
                         {product.productname}
                       </option>
                     ))}
                   </select>
                 </div>
               </div>
 
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="productcode">Product Code</label>
                   <input
                     className="form-control"
                     id="productCode"
                     placeholder="Product Code"
                     value={refillSales.productCode}
                    // onChange={handleChange1}
                   />
                   
                 </div>
               </div>
             
 
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="sale">Sale</label>
                   <input
                     className="form-control"
                     id="sale"
                     type="text"
                     placeholder="0"
                     value={refillSales.sale}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               
               <div className="row mb-3">
  <div className="form-floating-sm position-relative">
    <label htmlFor="newconnection">New connection</label>
    <div className="input-group">
      <input
        className="form-control"
                     id="newconnection"
                     type="text"
                     placeholder="0"
                     value={refillSales.newconnection}
                     //onChange={handleChange1}
                   />
                 <div
        className="input-group-append"
        style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
      >
       <IoIosAddCircleOutline
  onClick={() => {
    if (refillSales.productName) {
      openNewConModal();
    } else {
      alert("Please select a product first.");
    }
  }}
  style={{ cursor: "pointer" }}
  title="Add New connection Details"
/>
      </div>
    </div>
  </div>
</div>
 
                                        <div>
                                        <Modal
                                        show={newConnectionModalIsOpen}
                                        onHide={closeNewConModal}
                                        centered
                                       
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>New Connection Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <label for="customername">
                                              Customer Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerName"
                                              value={newConDetails.customerName}
                                              onChange={(e) =>
                                                handleChangeNewCon(e, "customerName")
                                              }
                                            />{" "}
                                            <label for="customerNumber">
                                              Customer Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerNumber"
                                              value={newConDetails.customerNumber}
                                              onChange={(e) =>
                                                handleChangeNewCon(
                                                  e,
                                                  "customerNumber"
                                                )
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                           
                                            <label for="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="quantity"
                                              value={newConDetails.quantity}
                                              onChange={(e) =>
                                                handleChangeNewCon(e, "quantity")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            <label for="phonenumber">
                                              phone Number
                                            </label>
                                            <input
                                              type="text"
                                              id="phoneNumber"
                                              className="form-control"
                                              value={newConDetails.phoneNumber}
                                              onChange={(e) =>
                                                handleChangeNewCon(e, "phoneNumber")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />
                                           
                                            <br />
                                           
                                              <button
                                                className="btn btn-primary"
                                               // onClick={createAdditional}
                                               onClick={addToNewConList}
                                              >
                                                Save
                                              </button>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Customer Name</th>
                                                    <th>Customer Number</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {newDet.map((ad, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {ad.customerName}
                                                          </td>
                                                          <td>
                                                            {ad.customerNumber}
                                                          </td>
                                                          <td>
                                                            {ad.productName}
                                                          </td>
                                                          <td>
                                                            {ad.quantity}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleNewConDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                       </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeNewConModal()}
                                              >
                                                Close
                                              </button>
                                             
                                        </Modal.Body>
                                      </Modal>
                                      </div>
 
               <div className="row mb-3">
  <div className="form-floating-sm position-relative">
    <label htmlFor="additional">Additional</label>
    <div className="input-group">
      <input
        className="form-control"
        id="additional"
        type="text"
        placeholder="0"
        value={refillSales.additional}
        // onChange={handleChange1}
      />
      <div
        className="input-group-append"
        style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
      >
       <IoIosAddCircleOutline
  onClick={() => {
    if (refillSales.productName) {
      openAdditionalModal();
    } else {
      alert("Please select a product first.");
    }
  }}
  style={{ cursor: "pointer" }}
  title="Add Additional Details"
/>
      </div>
    </div>
  </div>
</div>
 
                                        <div>
                                        <Modal
                                        show={additionalModalIsOpen}
                                        onHide={closeAdditionalModal}
                                        centered
                                       
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>Additional Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <label for="customername">
                                              Customer Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerName"
                                              value={additionalDetails.customerName}
                                              onChange={(e) =>
                                                handleChangeAdditional(e, "customerName")
                                              }
                                            />{" "}
                                            <label for="customerNumber">
                                              Customer Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customerNumber"
                                              value={additionalDetails.customerNumber}
                                              onChange={(e) =>
                                                handleChangeAdditional(
                                                  e,
                                                  "customerNumber"
                                                )
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                           
                                            <label for="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="quantity"
                                              value={additionalDetails.quantity}
                                              onChange={(e) =>
                                                handleChangeAdditional(e, "quantity")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            <label for="phonenumber">
                                              phone Number
                                            </label>
                                            <input
                                              type="text"
                                              id="phoneNumber"
                                              className="form-control"
                                              value={additionalDetails.phoneNumber}
                                              onChange={(e) =>
                                                handleChangeAdditional(e, "phoneNumber")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />
                                           
                                            <br />
                                           
                                              <button
                                                className="btn btn-primary"
                                               // onClick={createAdditional}
                                               onClick={addToAdditionalList}
                                              >
                                                Save
                                              </button>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Customer Name</th>
                                                    <th>Customer Number</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {addDet.map((ad, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {ad.customerName}
                                                          </td>
                                                          <td>
                                                            {ad.customerNumber}
                                                          </td>
                                                          <td>
                                                            {ad.productName}
                                                          </td>
                                                          <td>
                                                            {ad.quantity}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleAdditionalDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                       </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeAdditionalModal()}
                                              >
                                                Close
                                              </button>
                                             
                                        </Modal.Body>
                                      </Modal>
                                      </div>
                 
                                      <div className="row mb-3">
                                        <div className="form-floating-sm position-relative">
                                          <label htmlFor="exempted">Exempted</label>
                                          <div className="input-group">
                                            <input
                                              className="form-control"
                                              id="exempted"
                                              type="text"
                                              placeholder="0"
                                              value={refillSales.exempted}
                                            />
                                            <div
                                              className="input-group-append"
                                              style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                                            >
                                             <IoIosAddCircleOutline
  onClick={() => {
    if (refillSales.productName) {
      openExemptedModal();
    } else {
      alert("Please select a product first.");
    }
  }}
  style={{ cursor: "pointer" }}
  title="Add Exempted Details"
/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
 
                                        <div>
                                        <Modal
                                          show={exemptedModalIsOpen}
                                          onHide={closeExemptedModal}
                                          centered
                                         
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title>Exempted Details</Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                          <label htmlFor="categoryname">Category Name</label>
                                              <select
                                                id="categoryname"
                                                className="form-control"
                                                type="text"
                                                placeholder="categoryname"
                                                value={exdetails.categoryname}
                                                onChange={(e) => {
                                                  handleChangeExempted(e, "categoryname");
                                                  setSelectedCategory(e.target.value);
                                                  handleCustomer(e.target.value);
                                                }}
                                              >
                                                <option>select category</option>
                                                {exemptedcategory.map((cu) => (
                                                  <option key={cu.categoryname} value={cu.categoryname}>
                                                    {cu.categoryname}
                                                  </option>
                                                ))}
                                              </select>
 
                                              <label htmlFor="customername">Customer Name</label>
                                              <select
                                                id="customername"
                                                className="form-control"
                                                type="text"
                                                placeholder="customername"
                                                value={exdetails.customername}
                                                onChange={(e) => {
                                                  handleChangeExempted(e, "customername");
                                                }}
                                              >
                                                <option>select customername</option>
                                                {exemptedcustomer.map((sub) => (
                              <option value={sub.customername} key={sub.id}>
                                {sub.customername}
                              </option>
                            ))}
                                              </select>
 
                                              <label for="quantity">
                                                Quantity
                                              </label>
                                              <input
                                                type="text"
                                                id="deliveredqty"
                                                className="form-control"
                                                value={exdetails.deliveredqty}
                                                onChange={(e) =>
                                                  handleChangeExempted(
                                                    e,
                                                    "deliveredqty"
                                                  )
                                                }
                                                pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                              />
                                             
                                              <br />
                                              <button
                                                className="btn btn-primary"
                                                onClick={addToExemptedList}
                                              >
                                                Save
                                              </button>
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                     <th>Category Name</th>
                                                    <th>Customer Name</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {exemptedDetails.map((exp,index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {exp.categoryname}
                                                          </td>
                                                          <td>
                                                            {exp.customername}
                                                          </td>
                                                          <td>
                                                            {exp.productname}
                                                          </td>
                                                          <td>
                                                            {exp.deliveredqty}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleExemtedDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeExemptedModal()}
                                              >
                                                Close
                                              </button>
                                           
                                        </Modal.Body>
                                      </Modal>
                                         
                                        </div>
 
 

 <div className="row mb-3">
                                        <div className="form-floating-sm position-relative">
                                          <label htmlFor="onlinepay">Onlinepay</label>
                                          <div className="input-group">
                                            <input
                                              className="form-control"
                                              id="onlinepay"
                                              type="text"
                                              placeholder="0"
                                              value={refillSales.onlinepay}
                                              // onChange={handleChange1}
                                            />
                                            <div
                                              className="input-group-append"
                                              style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                                            >
                                             <IoIosAddCircleOutline
  onClick={() => {
    if (refillSales.productName) {
      openOnlinepayModal();
    } else {
      alert("Please select a product first.");
    }
  }}
  style={{ cursor: "pointer" }}
  title="Add Online Pay Details"
/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
 
                                      <div>
                                      <Modal
                                        show={onlinePayModalIsOpen}
                                        onHide={closeOnlinepayModal}
                                        centered size="lg"
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>Online Pay Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <label for="customername">
                                              customer Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customername"
                                              value={onlinedetails.customername}
                                              onChange={(e) =>
                                                handleChangeOnlinepay(e, "customername")
                                              }
                                            />{" "}
                                            <label for="customernumber">
                                              customer Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customernumber"
                                              value={onlinedetails.customernumber}
                                              onChange={(e) =>
                                                handleChangeOnlinepay(
                                                  e,
                                                  "customernumber"
                                                )
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                           
                                            <label for="ordernumber">
                                              Order Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="ordernumber"
                                              value={onlinedetails.ordernumber}
                                              onChange={(e) =>
                                                handleChangeOnlinepay(e, "ordernumber")
                                              }
                                            />{" "}
                                            <label for="quantity">
                                              Quantity
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="quantity"
                                              value={onlinedetails.quantity}
                                              onChange={(e) =>
                                                handleChangeOnlinepay(e, "quantity")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />{" "}
                                            <label for="phonenumber">
                                              Phone Number
                                            </label>
                                            <input
                                              type="text"
                                              id="phonenumber"
                                              className="form-control"
                                              value={onlinedetails.phonenumber}
                                              onChange={(e) =>
                                                handleChangeOnlinepay(e, "phonenumber")
                                              }
                                              pattern="\d*"
                                              onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                              }}
                                            />
                                           
                                            <br />
                                         
                                              <button
                                                className="btn btn-primary"
                                                onClick={addToOnlineDetailsList}
                                              >
                                                Save
                                              </button>
 
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Customer Name</th>
                                                    <th>Customer Number</th>
                                                    <th>Product Name</th>
                                                    <th>Order Number</th>
                                                    <th>Quantity</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {onlineDetails.map((online, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            {online.customername}
                                                          </td>
                                                          <td>
                                                            {online.customernumber}
                                                          </td>
                                                          <td>
                                                            {online.productname}
                                                          </td>
                                                          <td>
                                                            {online.ordernumber}
                                                          </td>
                                                          <td>
                                                            {online.quantity}
                                                          </td>
                                                          <td>
                                                          <Button variant="light"style={{ color: "black" }}  onClick={() => handleOnlineDelete(index)}>
                                                        <AiFillDelete />
                                                      </Button>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => closeOnlinepayModal()}
                                              >
                                                Close
                                              </button>
                                        </Modal.Body>
                                      </Modal>
                                           
                                          </div>
                                     
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="transfer">Transfer</label>
                   <input
                     className="form-control"
                     id="transfer"
                     type="text"
                     placeholder="0"
                     value={refillSales.transfer}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="total">Total</label>
                   <input
                     className="form-control"
                     id="total"
                     type="text"
                     placeholder="total"
                     value={refillSales.total}
                    // onChange={handleChange1}
                   />
                 </div>
               </div>
 
               <div className="row mt-4">
                 <div className="col">
                   <Button variant="primary" type="submit" >Save</Button>
                   <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </Modal.Body>
     <Modal.Footer>
     
     </Modal.Footer>
   </Modal>
 );
};
 
export default RefillOfficeSalesModalOpen;
 