import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./transaction.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router";
import { Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function ViewAllTruckDrivers() {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total pages
  const [pageSize] = useState(40); // Number of items per page
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const token = JSON.parse(localStorage.getItem("data")).token;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDrivers(currentPage);
  }, [currentPage]);

  const fetchDrivers = async (page = 0) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/truck-drivers/getAllDrivers/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}?page=${page}&size=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDrivers(response.data.content); // Assuming response.data contains paginated data in `content`
      setTotalPages(response.data.totalPages); // Assuming response.data contains total pages
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    if (drivers.length > 0) {
      const filtered = drivers.filter(
        (driver) =>
          (driver.drivername && driver.drivername.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (driver.contact && driver.contact && String(driver.contact).includes(searchTerm))
        );
      setFilteredDrivers(filtered);
    }
  }, [searchTerm, drivers]);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleViewTruckinfo = (driverId) => {
    navigate(`/users/view-trucks/${driverId}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const startPage = Math.max(currentPage - 2, 0);
    const endPage = Math.min(currentPage + 3, totalPages);

    if (currentPage > 0) {
      paginationItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(0)} />
      );
      paginationItems.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let page = startPage; page < endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page + 1}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 1) {
      paginationItems.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
      paginationItems.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages - 1)} />
      );
    }

    return paginationItems;
  };

  return (
    <div>
      <AdminDashboard />
      {loading && <div className="loading">Loading...</div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between mb-4">
                <span>Drivers</span>
                <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by Driver name or phone number"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>
</div>

            <div className="row">
              {filteredDrivers.length === 0 ? (
                <div className="col text-center mb-4">No Data Available</div>
              ) : (
                filteredDrivers.map((driver, index) => (
                  <div key={index} className="col-md-3 mb-4">
                    <Card
                      className="shadow-sm border-2"
                      style={{ cursor: 'pointer', marginRight: "10px" }}
                      title="View Details"
                      onClick={() => handleViewTruckinfo(driver.id)}
                    >
                      <Card.Body>
                        <Card.Text className="font-weight-bold">
                          <h6>{driver.drivername}</h6>
                          <h6>{driver.contact}</h6>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Pagination>
                {renderPaginationItems()}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAllTruckDrivers;
