import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";

function Drivers({ selectedTruck }) {
  const [drivers, setDrivers] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page

  const applyFilters = () => {
    let filteredDetails = [...drivers];
    if (filterStatus) {
      filteredDetails = filteredDetails.filter(
        (detail) => detail.status === filterStatus
      );
    }
    return filteredDetails;
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredDetails = applyFilters();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDetails = filteredDetails.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredDetails.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const getData = async () => {
    if (!selectedTruck) return;
    setLoader(true);

    try {
      const res = await axios.get(
        `/api/loadin-meta/get-drivers/orgcode=${orgcode}/truckinfo=${selectedTruck}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDrivers(res.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedTruck, reducerValue]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <div className="delivery-history">
      <div className="card card-raised mb-5">
        <div className="card-body">
          {loader && (
            <div className="loading"></div>
          )}
          
              {/* <div className="mb-4 d-flex align-items-center">
                <label htmlFor="status" className="ms-6 me-2">FILTER BY STATUS:</label>
                <select
                  id="status"
                  value={filterStatus}
                  onChange={handleFilterChange}
                >
                  <option value="">ALL</option>
                  <option value="completed">COMPLETED</option>
                  <option value="pending">PENDING</option>
                </select>
              </div> */}
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    <th>Name</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDetails.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        NO DATA AVAILABLE
                      </td>
                    </tr>
                  ) : (
                    currentDetails.map((detail, index) => (
                      <tr key={index}>
                        <td>{detail.name}</td>
                        <td>{detail.number}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {/* Pagination controls */}
              <nav>
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handlePrevPage}>
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${number === currentPage ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => setCurrentPage(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
          
        </div>
      </div>
    </div>
  );
}

export default Drivers;
